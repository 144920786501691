define("campaign-warrior-app/templates/components/pages/delete-confirm/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZBYoMd6S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"alert alert-danger\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-fw fa-exclamation-triangle\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"pages.delete.confirm.body\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"input\"],[14,0,\"form-control\"],[15,\"placeholder\",[30,[36,0],[\"pages.delete.confirm.placeholder\"],null]],[15,\"oninput\",[30,[36,2],[[32,0],[35,1]],[[\"value\"],[\"target.value\"]]]],[14,4,\"text\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"updatePromptValue\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/delete-confirm/body.hbs"
    }
  });

  _exports.default = _default;
});