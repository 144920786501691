define("campaign-warrior-app/templates/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2yNLanmf",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"portals\"],[[35,0]]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container-internal-wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"internal-content\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"top-nav\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/profile.hbs"
    }
  });

  _exports.default = _default;
});