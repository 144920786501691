define("campaign-warrior-app/templates/components/cw-table/add-tag-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YfVOD9XE",
    "block": "{\"symbols\":[\"dd\",\"ddm\",\"tag\"],\"statements\":[[8,\"bs-dropdown\",[[24,0,\"page-tags-dropdown\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"toggle\"]],[[24,0,\"page-tag-toggle\"]],[[],[]],[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"circle-icon\"],[12],[10,\"i\"],[14,0,\"far fa-plus\"],[12],[13],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"menu\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"link\",[30,[36,0],[[32,0],\"createTag\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Create Tag\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,2,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"a\"],[15,\"onclick\",[30,[36,0],[[32,0],\"addTagToPage\",[32,3]],null]],[12],[2,\"\\n          \"],[1,[30,[36,1],null,[[\"tag\",\"selectionView\"],[[32,3],true]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"cw-tag\",\"tagsList\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/add-tag-dropdown.hbs"
    }
  });

  _exports.default = _default;
});