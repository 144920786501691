define("campaign-warrior-app/components/domains/show-tracking-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{domains/show-tracking-code record=domain onClick=(action "showTrackingCode")}}
   * ```
   *
   * @class ShowTrackingCode
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    currentPortal: Ember.inject.service(),
    tag: 'span',
    classNames: ['show-tracking-code'],

    /**
     * @property domain
     * @type Models.Domain
     * @default null
     */
    record: null,

    /**
     * @event onClick
     */
    onClick: null,

    click() {// get(this, 'onClick')(get(this, 'record'));
    },

    notFree: Ember.computed(function () {
      return true;
    }),
    actions: {
      copyTrackingCode() {
        this.toggleProperty('isShowingTrackingCode');
        const el = document.createElement('textarea');
        el.value = Ember.get(this, 'record').trackingCode;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }

    }
  });

  _exports.default = _default;
});