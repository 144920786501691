define("campaign-warrior-app/utils/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setCurrentTime = setCurrentTime;
  _exports.setMidnightTime = setMidnightTime;

  /**
   * @param {number} timestamp original timestamp
   * @returns {number} updated timestamp
   */
  function setCurrentTime(timestamp) {
    const currentDate = new Date();
    const ret = new Date(timestamp);
    ret.setSeconds(currentDate.getSeconds());
    ret.setMinutes(currentDate.getMinutes());
    ret.setHours(currentDate.getHours());
    return ret.getTime();
  }
  /**
   * @param {number} timestamp original timestamp
   * @returns {number} updated timestamp
   */


  function setMidnightTime(timestamp) {
    const ret = new Date(timestamp);
    ret.setSeconds(59);
    ret.setMinutes(59);
    ret.setHours(23);
    return ret.getTime();
  }
});