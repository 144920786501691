define("campaign-warrior-app/components/cw-table/image-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```js
   * columns: [
   *   {
   *     propertyName: 'property.name.with.src',
   *     component: 'cw-table/image-cell',
   *     style: {
   *       width: '200px',
   *       height: '150px'
   *     }
   *   }
   * ]
   * ```
   *
   * @class CwTableImageCell
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property imgStyle
     * @type string
     * @private
     * @readonly
     */
    imgStyle: Ember.computed('column.style', function () {
      const styles = Ember.get(this, 'column.style');

      if (!styles) {
        return null;
      }

      const stylesStr = Object.keys(styles).map(k => `${k}: ${styles[k]}`).join(';');
      return Ember.String.htmlSafe(stylesStr);
    }).readOnly()
  });

  _exports.default = _default;
});