define("campaign-warrior-app/components/users/invite-user-form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    classNames: ['invite-user-form'],
    roleOptions: ['admin', 'user'],
    onSubmit: null,
    formData: Ember.computed('{portals,globalPermissions}.[]', 'userToManage.{permissionsMap,email}', function () {
      const userToManage = Ember.get(this, 'userToManage') || {};
      const currentUserId = Ember.get(this, 'currentUser.user.id');
      const Validations = (0, _emberCpValidations.buildValidations)({
        email: (0, _emberCpValidations.validator)('format', {
          type: 'email'
        }),
        role: (0, _emberCpValidations.validator)('presence', true)
      });
      return Ember.Object.extend(Validations, {
        currentUserId,
        role: null,
        portal: Ember.get(this, 'currentPortal.portal.id'),
        email: Ember.computed(function () {
          return userToManage ? Ember.get(userToManage, 'email') : '';
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }),
    actions: {
      /**
       * @method actions.onSubmit
       * @returns {RSVP.Promise} submit result
       */
      onSubmit() {
        const data = this.mapFormData(); //console.log('Submit mapped form data', data);

        return Ember.get(this, 'onSubmit')(data);
      }

    },

    /**
     * @method mapFormData
     * @returns {object} data to send
     * @private
     */
    mapFormData() {
      const formData = Ember.get(this, 'formData'); //console.log('Map form data', formData);

      const data = {
        email: Ember.get(formData, 'email'),
        portal: Ember.get(formData, 'portal'),
        invitedBy: Ember.get(formData, 'currentUserId'),
        role: Ember.get(formData, 'role')
      };
      return data;
    }

  });

  _exports.default = _default;
});