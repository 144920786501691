define("campaign-warrior-app/components/modals/single-process", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```js
   * modalsManager.show('modals/single-process', {
   *   title: '',
   *   body: 'some message',
   *   process: () => new Promise(...)
   * })
   * ```
   *
   * @class ModalsSingleProcess
   * @namespace Components
   * @extends EmberBootstrapModalsManager.Components.Base
   */
  var _default = _base.default.extend({
    didInsertElement() {
      const process = Ember.get(this, 'options.process');

      if (process) {
        process().then(d => this.send('confirm', d)).catch(e => this.send('decline', e));
      }
    }

  });

  _exports.default = _default;
});