define("campaign-warrior-app/templates/components/cw-table/funnel-stage-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iHBlR0g7",
    "block": "{\"symbols\":[\"funnelStage\"],\"statements\":[[6,[37,2],null,[[\"options\",\"selected\",\"searchEnabled\",\"onChange\"],[[35,1],[32,0,[\"funnelStage\"]],false,[30,[36,0],[[32,0],\"setFunnelStage\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"funnelOptions\",\"power-select\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/funnel-stage-dropdown.hbs"
    }
  });

  _exports.default = _default;
});