define("campaign-warrior-app/adapters/post", ["exports", "campaign-warrior-app/adapters/application", "campaign-warrior-app/mixins/adapter-portal-related"], function (_exports, _application, _adapterPortalRelated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Post
   * @namespace Adapters
   * @extends Adapters.Application
   * @uses Mixins.AdapterPortalRelated
   */
  var _default = _application.default.extend(_adapterPortalRelated.default, {
    /**
     * URL for query to get some `Posts`
     *
     * @param {object} query query items
     * @returns {string} url for request
     */
    urlForQuery(query) {
      if (query.campaignId) {
        return this.urlForCampaignQuery(...arguments);
      }

      return this._super(...arguments);
    },

    /**
     * URL for query to get all `Posts` related to some `Campaign`
     *
     * @method urlForCampaignQuery
     * @param {object} query query items
     * @param {string} modelName 'post'
     * @returns {string} url for request
     */
    urlForCampaignQuery(query, modelName) {
      const campaignId = query.campaignId;
      const host = Ember.get(this, 'host') || '';
      const namespace = Ember.get(this, 'namespace') || '';
      const pathChild = this.pathForType(modelName);
      const pathParent = this.pathForType('campaign');
      delete query.campaignId;
      return `${host}/${namespace}/${pathParent}/${campaignId}/${pathChild}`;
    }

  });

  _exports.default = _default;
});