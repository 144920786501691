define("campaign-warrior-app/components/modals/password-reset-expired-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    router: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    actions: {
      confirm() {
        Ember.get(this, 'router').transitionTo('login');
      },

      decline() {
        Ember.get(this, 'router').transitionTo('login');
      }

    }
  });

  _exports.default = _default;
});