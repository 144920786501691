define("campaign-warrior-app/torii-providers/shopify", ["exports", "torii/providers/oauth2-code"], function (_exports, _oauth2Code) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2Code.default.extend({
    baseUrl: 'https://app.hubspot.com/oauth/authorize',
    name: 'shopify',
    scopes: 'content%20social%20timeline%20oauth',
    responseType: 'code',
    responseParams: Ember.computed(function () {
      return ['code'];
    }),
    redirectUri: Ember.computed(function () {
      const {
        protocol,
        host
      } = window.location;
      return [protocol, '//', host, '/torii/redirect.html'].join('');
    }),
    open: function (options) {
      console.log("ShopName", options.shopName);
      const clientId = 'fe558602cfd9e4b1a9bb94e10cee5f91';
      const url = `https://${options.shopName}.myshopify.com/admin/oauth/authorize?client_id=${clientId}&scope=read_products,read_product_listings,read_content&state=${options.nonce}&redirect_uri=${this.redirectUri}`;
      console.log("Shopify auth url", url);
      return Ember.get(this, 'popup').open(url, ['code'], {
        width: 800,
        height: 800
      });
    }
  });

  _exports.default = _default;
});