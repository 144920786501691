define("campaign-warrior-app/templates/invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ve0Xj8u5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"create-portal-logo\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"https://d25xioqhg7be2.cloudfront.net/static-assets/cw-logo.png\"],[15,\"alt\",[30,[36,0],[\"logo.alt\"],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/invite.hbs"
    }
  });

  _exports.default = _default;
});