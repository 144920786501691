define("campaign-warrior-app/templates/components/ajax-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YDXerlIv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"alert alert-danger\"],[12],[2,\"\\n    \"],[1,[35,0,[\"message\"]]],[10,\"br\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"options\"],[[30,[36,1],null,[[\"error\"],[[35,0]]]]]]]],[2,\"\\n    \"],[10,\"a\"],[14,6,\"javascript:history.back(-1);\"],[12],[1,[30,[36,3],[\"ajaxErrors.goBack\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"hash\",\"modals/request-error-body\",\"t\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/ajax-error.hbs"
    }
  });

  _exports.default = _default;
});