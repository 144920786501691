define("campaign-warrior-app/routes/portals/portal/pages/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * @class PagesList
   * @namespace Routes
   * @extends Ember.Route
   */
  let PagesListRoute = (_dec = Ember._action, (_class = (_temp = class PagesListRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        size: {
          refreshModel: true
        },
        title: {
          refreshModel: true
        },
        url: {
          refreshModel: true
        },
        metaSizeFrom: {
          refreshModel: true
        },
        metaSizeTo: {
          refreshModel: true
        },
        meta: {
          refreshModel: true
        },
        domain: {
          refreshModel: true
        },
        tagsList: {
          refreshModel: true
        },
        tagsLogic: {
          refreshModel: true
        },
        omitTags: {
          refreshModel: true
        },
        omitTagsLogic: {
          refreshModel: true
        },
        funnelStage: {
          refreshModel: true
        },
        sortBy: {
          refreshModel: true
        }
      });

      _defineProperty(this, "title", 'Web Pages | Campaign Warrior');
    }

    // rootElementClasses: Object.freeze(['web-pages-list-page']),
    model(params) {
      const store = Ember.get(this, 'store');
      const portal = this.modelFor('portals.portal');
      const portal_id = portal.id;
      params = params || {};
      params.page = params.page || 0;
      params.size = params.size || 20;
      let filter = {
        title: params.title,
        url: params.url,
        metaSizeFrom: params.metaSizeFrom,
        metaSizeTo: params.metaSizeTo,
        meta: params.meta,
        domain: params.domain
      };

      if (params.tagsList && params.tagsList.length) {
        try {
          const decodedTagsList = decodeURIComponent(params.tagsList);
          filter.tags = JSON.parse(decodeURIComponent(params.tagsList));
        } catch (err) {
          console.error('Error, invalid format for tags filter');
        }
      }

      if (params.omitTags && params.omitTags.length) {
        try {
          filter.omitTags = JSON.parse(decodeURIComponent(params.omitTags));
        } catch (err) {
          console.error('Error, invalid format for omitTags filter');
        }
      }

      if (params.omitTagsLogic) {
        try {
          filter.omitTagsLogic = params.omitTagsLogic;
        } catch (err) {
          console.error('Error, invalid format for omitTags logic filter');
        }
      }

      if (params.tagsLogic) {
        try {
          filter.tagsLogic = params.tagsLogic;
        } catch (err) {
          console.error('Error, invalid format for omitTags logic filter');
        }
      }

      if (params.funnelStage && params.funnelStage !== 'Any') {
        try {
          filter.funnelStage = params.funnelStage;
        } catch (err) {
          console.error('Error, Invalid funnel stage');
        }
      }

      if (params.domain && params.domain !== 'any') {
        try {
          filter.domain = params.domain;
        } catch (err) {
          console.error('Error, Invalid domain');
        }
      }

      let sort = '-hsLastUpdated';

      if (params.sortBy) {
        sort = params.sortBy;
      }

      const pages = store.query('page', {
        portal_id,
        page: {
          number: params.page,
          size: params.size,
          filter
        },
        sort
      }); // console.log('Pages model', pages);

      pages.then(allPages => {
        // console.log('All pages', allPages.meta);
        let nextPage = allPages.meta['current-page'];
        let previousPage = allPages.meta['current-page'];
        const lastPage = allPages.meta['page-count'];

        if (nextPage < lastPage) {
          nextPage++;
        }

        if (previousPage > 0) {
          previousPage--;
        }

        allPages.meta['next-page'] = nextPage;
        allPages.meta['previous-page'] = previousPage;
      });
      return Ember.RSVP.hash({
        model: pages,
        tags: store.query('tag', {
          portal_id
        }),
        domains: store.query('domain', {
          portal_id
        }),
        portal,
        size: params.size,
        sortOrder: sort,
        filter
      });
    }

    setupController(controller, model) {
      Ember.setProperties(controller, {
        portal: model.portal,
        model: model.model,
        tags: model.tags,
        size: model.size,
        filter: model.filter
      });
      Ember.set(controller, 'newFilters', controller.settingFilters(model.filter)); //set(controller, 'newPages', controller.settingPages());

      Ember.set(controller, 'sortOrder', model.sortOrder);
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.setProperties(controller, {
          filters: null,
          metricNameToFilter: '',
          tagsToAddForPages: {
            tags: []
          }
        });
      }
    }

    refreshRoute() {
      this.refresh();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "refreshRoute", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "refreshRoute"), _class.prototype)), _class));
  _exports.default = PagesListRoute;
});