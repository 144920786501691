define("campaign-warrior-app/templates/components/cw-table/link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LtLWrCDb",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,8],[[35,5,[\"className\"]],\"meta-cell\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"\\n      \",[30,[36,2],[[30,[36,7],[[30,[36,1],[[35,0,[\"metaSize\"]],30],null],[30,[36,3],[[35,0,[\"metaSize\"]],160],null]],null],\"blueColor\"],null],\"\\n            \",[30,[36,2],[[30,[36,3],[[35,0,[\"metaSize\"]],30],null],\"redColor\"],null],\"\\n            \",[30,[36,2],[[30,[36,1],[[35,0,[\"metaSize\"]],160],null],\"orangeColor\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,6],[[35,0],[35,5,[\"propertyName\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,2],[[30,[36,3],[[35,0,[\"metaSize\"]],30],null],\"redColor\"],null],\" \",[30,[36,2],[[30,[36,1],[[35,0,[\"metaSize\"]],160],null],\"orangeColor\"],null]]]],[4,[38,4],[[32,0],\"go\"],null],[12],[2,\"\\n    \"],[1,[30,[36,6],[[35,0],[35,5,[\"propertyName\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"record\",\"gt\",\"if\",\"lt\",\"action\",\"column\",\"get\",\"and\",\"eq\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/link-to.hbs"
    }
  });

  _exports.default = _default;
});