define("campaign-warrior-app/models/user", ["exports", "@ember-data/model", "campaign-warrior-app/models/permission", "ember-cp-validations"], function (_exports, _model, _permission, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 64
    })],
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 64
    })]
  });
  /**
   * @class User
   * @namespace Models
   * @extends DS.Model
   */

  var _default = _model.default.extend(Validations, {
    /**
     * @type DS.StringAttribute
     * @default null
     * @property email
     */
    email: (0, _model.attr)('string'),

    /**
     * @type DS.StringAttribute
     * @default null
     * @property firstName
     */
    firstName: (0, _model.attr)('string'),

    /**
     * @type DS.StringAttribute
     * @default null
     * @property lastName
     */
    lastName: (0, _model.attr)('string'),

    /**
     * @type DS.StringAttribute
     * @default null
     * @property status
     */
    status: (0, _model.attr)('string'),

    /**
     * @type DS.StringAttribute
     * @default null
     * @property role
     */
    role: (0, _model.attr)('string'),

    /**
     * @type DS.StringAttribute
     * @default null
     * @property country
     */
    country: (0, _model.attr)('string'),
    timezone: (0, _model.attr)('string'),

    /**
     * @property authType
     * @type string
     */
    authType: (0, _model.attr)('string'),
    internalType: (0, _model.attr)('string'),

    /**
     * {{#crossLink "Models.Company"}}Models.Company{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property company
     */
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),

    /**
     * {{#crossLink "Models.File"}}Models.File{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property picture
     */
    picture: (0, _model.belongsTo)('file'),

    /**
     * {{#crossLink "Models.Permission"}}Models.Permission{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property permissions
     */
    permissions: (0, _model.hasMany)('permission'),
    userRoles: (0, _model.hasMany)('user-role'),

    /**
     * {{#crossLink "Models.User"}}Models.User{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property invitedBy
     */
    invitedBy: (0, _model.belongsTo)('user', {
      inverse: null
    }),

    /**
     * {{#crossLink "Models.User"}}Models.User{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property invitedUsers
     */
    invitedUsers: (0, _model.hasMany)('user', {
      inverse: null
    }),

    /**
     * {{#crossLink "Models.User"}}Models.User{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property invitedComapnyUsers
     */
    invitedCompanyUsers: (0, _model.hasMany)('user', {
      inverse: null
    }),

    /**
     * {{#crossLink "Models.Portals"}}Models.Portals{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property portals
     */
    portals: (0, _model.belongsTo)('portal'),

    /**
     * @property originalFirstName
     * @type string
     * @default null
     */

    /**
     * @property originalLastName
     * @type string
     * @default null
     */

    /**
     * @property fullName
     * @type string
     * @default ''
     */
    fullName: Ember.computed('originalFirstName', 'originalLastName', function () {
      return `${this.originalFirstName} ${this.originalLastName}`;
    }),

    /**
     * @property permissionsMap
     * @type object
     */
    permissionsMap: Ember.computed('permissions.[]', function () {
      const permissionsMap = {};
      this.permissions.map(p => {
        permissionsMap[_permission.default.getTypeId(p)] = true;
      });
      return permissionsMap;
    }),

    /**
     * @property isCompanyAdmin
     * @type boolean
     */
    isCompanyAdmin: Ember.computed('permissions.@each.permissionType', function () {
      const permissions = this.permissions;
      return !permissions.length || !!permissions.findBy('permissionType', 'admin_company');
    }),

    /**
     * @property changeTracker
     * @type Object
     */
    changeTracker: {
      // eslint-disable-line
      auto: true,
      enableIsDirty: true,
      only: ['picture']
    },

    init() {
      //this.startTrack();
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});