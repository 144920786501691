define("campaign-warrior-app/validators/uniq-by", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Validator for values that must be uniq for all records.
   * Validator checks only existing in the store records (`peekAll` is used and not `findAll`)!
   *
   * `modelClass` is required option for this validator.
   *
   * It's a custom validator for [ember-cp-validations](https://github.com/offirgolan/ember-cp-validations)
   *
   * Usage:
   * ```js
   * buildValidations({
   *   name: validator('uniq-by', {
   *     modelName: 'tag' // filename from 'app/models/*',
   *     parentName: 'portal'
   *   })
   * });
   * ```
   *
   * @class UniqBy
   * @extends BaseValidator
   * @namespace Validators
   */
  var _default = _base.default.extend({
    /**
     * @property store
     * @type Ember.Service
     */
    store: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    validate(value, options, model, attribute) {
      return new Ember.RSVP.Promise(resolve => Ember.run.debounce(this, this._doRequest, value, options, model, attribute, resolve, 250));
    },

    _doRequest(value, options, model, attribute, resolve) {
      const {
        modelName
      } = options;
      const descriptionKey = options.descriptionKey || 'errors.uniqBy';
      return Ember.get(this, 'store').query(modelName, {
        filter: {
          [attribute]: value
        }
      }).then(instances => resolve(instances.length ? Ember.get(this, 'i18n').t(descriptionKey, {
        description: attribute,
        value
      }).toString() : true));
    }

  });

  _exports.default = _default;
});