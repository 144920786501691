define("campaign-warrior-app/templates/components/cw-table/expand-all-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rkEdSWxH",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,6,\"#\"],[16,0,[34,0,[\"expandAllRows\"]]],[4,[38,1],[[32,0],\"expandAllRows\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[[34,0,[\"expand-all-rows\"]]]]],[12],[13],[2,\"\\n\"],[13],[10,\"br\"],[12],[13],[2,\"\\n\"],[11,\"a\"],[24,6,\"#\"],[16,0,[34,0,[\"collapseAllRows\"]]],[4,[38,1],[[32,0],\"collapseAllRows\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[[34,0,[\"collapse-all-rows\"]]]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/expand-all-toggle.hbs"
    }
  });

  _exports.default = _default;
});