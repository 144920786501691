define("campaign-warrior-app/templates/components/cw-table/tags-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HVejAIIZ",
    "block": "{\"symbols\":[\"tag\",\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"tags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"tag\",\"removable\",\"selfRemove\"],[[32,1],true,[30,[36,0],[[32,0],\"removeTag\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[1,[30,[36,7],null,[[\"page\",\"tagsList\"],[[35,6],[35,5]]]]],[2,\"\\n\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"action\",\"cw-tag\",\"page\",\"-track-array\",\"each\",\"tagsList\",\"record\",\"cw-table/add-tag-dropdown\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/tags-list.hbs"
    }
  });

  _exports.default = _default;
});