define("campaign-warrior-app/torii-providers/facebook-pages", ["exports", "campaign-warrior-app/torii-providers/facebook"], function (_exports, _facebook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This provider doesn't do login. It's used only to get access_token with access to `manage_pages`, `pages_show_list` and `publish_pages`.
   *
   * Use original [Facebook-OAuth2](ToriiProviders.FacebookOauth2.html) for Facebook login
   *
   * @class FacebookPagesOauth2
   * @namespace ToriiProviders
   * @extends ToriiProviders.FacebookOauth2
   */
  var _default = _facebook.default.extend({
    name: 'facebook-pages'
  });

  _exports.default = _default;
});