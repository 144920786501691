define("campaign-warrior-app/components/cw-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display `Models.Tag` as bootstrap-label.
   *
   * Component may be used in the `models-table` as a cell-component.
   *
   * Usage
   *
   * ```hbs
   * {{cw-tag tag=tag}}
   * ```
   *
   * @class CwTag
   * @extends Ember.Component
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['style'],
    classNames: ['cw-tag', 'label'],
    selectionView: null,

    /**
     * @property tag
     * @type Models.Tag
     * @default null
     */
    tag: null,

    /**
     * @property removable
     * @type boolean
     * @default false
     */
    removable: false,

    /**
     * External handler for `x`-click
     *
     * @event selfRemove
     */
    selfRemove: null,

    /**
     * @property selfRemoveIcon
     * @default '@times;'
     * @type string
     * @private
     * @readonly
     */
    selfRemoveIcon: Ember.String.htmlSafe('&times;'),

    /**
     * Record is bound from the `models-table` if `cw-tag` is used as cell-component
     *
     * @type Models.Tag
     * @default null
     * @property record
     * @private
     * @readonly
     */
    record: Ember.computed.alias('tag'),

    /**
     * @property style
     * @type string
     * @default ''
     * @private
     * @readonly
     */
    style: Ember.computed('tag.color', function () {
      const color = Ember.get(this, 'tag.color');

      if (this.selectionView) {
        return Ember.String.htmlSafe(color ? `color: ${color}; background-color: ${color}` : '');
      } else {
        return Ember.String.htmlSafe(color ? `background-color: ${color}` : '');
      }
    }).readOnly(),
    actions: {
      /**
       * Internal handler for `x`-click. Calls external handler
       *
       * @method actions.selfRemove
       */
      selfRemove() {
        Ember.get(this, 'selfRemove')(Ember.get(this, 'tag'));
      }

    }
  });

  _exports.default = _default;
});