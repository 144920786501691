define("campaign-warrior-app/models/related-content-settings", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    limit: _emberData.default.attr('number'),
    sort: _emberData.default.attr('string'),
    funnelStage: _emberData.default.attr('string'),
    layout: _emberData.default.attr('string'),
    columns: _emberData.default.attr('string'),
    hideNoFeaturedImage: _emberData.default.attr('boolean'),
    gridGap: _emberData.default.attr('string'),
    tagExclusivity: _emberData.default.attr('string'),
    tags: _emberData.default.attr(),
    page: _emberData.default.belongsTo('page')
  });

  _exports.default = _default;
});