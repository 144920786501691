define("campaign-warrior-app/templates/components/iframe-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UE7kf9pH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"iframe\"],[14,5,\"height:500px; overflow-y: scroll;\"],[15,\"src\",[31,[[34,0]]]],[15,\"width\",[31,[[34,1]]]],[14,\"height\",\"100%\"],[14,\"sandbox\",\"\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"src\",\"width\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/iframe-wrapper.hbs"
    }
  });

  _exports.default = _default;
});