define("campaign-warrior-app/controllers/portals/portal/setup/google", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GoogleSetupController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('currentPortal.portal.googleConnected'), _dec7 = Ember.computed('currentPortal.portal.gaAccounts'), _dec8 = Ember.computed('currentPortal.portal.gaViewId'), _dec9 = Ember.computed('currentPortal.portal.gaAccounts', 'gaConnecting'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = (_temp = class GoogleSetupController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['redirectUrl']);

      _initializerDefineProperty(this, "currentPortal", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "torii", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor5, this);

      _defineProperty(this, "gaConnecting", false);
    }

    get googleConnected() {
      return this.currentPortal.portal.googleConnected;
    }

    get gaAccounts() {
      return this.currentPortal.portal.gaAccounts;
    }

    get gaCurrentAccount() {
      return this.currentPortal.portal.gaViewId;
    }

    get connecting() {
      if (this.currentPortal.portal.gaAccounts) {
        return false;
      }

      return this.gaConnecting;
    }

    connectGoogle() {
      const ajax = Ember.get(this, 'ajax');

      const _this = this;

      const portalId = Ember.get(this, 'currentPortal.portal.id');
      Ember.get(this, 'torii').open('google-analytics', {
        width: 800,
        height: 800
      }).then(function (data) {
        Ember.set(_this, 'gaConnecting', true);
        ajax.post('/google-analytics/authorize', {
          data: {
            portalId,
            data
          }
        }).then(function (gaAccounts) {
          Ember.set(_this, 'gaConnecting', false);

          _this.send('refreshRoute');
        });
      });
    }

    askPermission(gaAccount) {
      const _this = this;

      _this.modalsManager.confirm({
        title: 'Google Analytics Account',
        confirm: 'Continue',
        decline: 'Cancel',
        body: 'You are going to change the Google Analytics account for this portal'
      }).then(() => {
        _this.selectAccount(gaAccount);
      });
    }

    selectAccount(gaAccount) {
      const ajax = Ember.get(this, 'ajax');

      const _this = this;

      const portalId = Ember.get(this, 'currentPortal.portal.id');
      const router = this.router;
      const gaAccountId = gaAccount.accountId;
      const gaViewId = gaAccount.viewId;
      const gaWebsite = gaAccount.website;
      ajax.post('/google-analytics/connect-ga-account', {
        data: {
          portalId,
          gaAccountId,
          gaViewId,
          gaWebsite
        }
      }).then(function (response) {
        _this.modalsManager.alert({
          title: 'Google Analytics',
          confirm: 'Done',
          body: 'Succesfully connected to Google Analytics'
        }).then(() => {
          router.transitionTo('portals.portal.setup.complete');

          _this.send('refreshRoute');
        });
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "torii", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "googleConnected", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "googleConnected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gaAccounts", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "gaAccounts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gaCurrentAccount", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "gaCurrentAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connecting", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "connecting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connectGoogle", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "connectGoogle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "askPermission", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "askPermission"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAccount", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectAccount"), _class.prototype)), _class));
  _exports.default = GoogleSetupController;
});