define("campaign-warrior-app/transforms/mixed", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Leave attribute value "as is" without any changes on serialize and deserialize
   *
   * @class MixedAttribute
   * @namespace Transforms
   * @extends DS.Transform
   */
  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      return Ember.typeOf(serialized) === 'string' ? JSON.parse(serialized) : serialized;
    },

    serialize(deserialized) {
      return deserialized;
    }

  });

  _exports.default = _default;
});