define("campaign-warrior-app/components/manage-billing-form", ["exports", "campaign-warrior-app/data/countries", "campaign-warrior-app/data/states", "ember-cp-validations"], function (_exports, _countries, _states, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{manage-billing-form onSubmit=(action "submitHandler")}}
   * ```
   *
   * @class ManageBillingForm
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['manage-billing-form'],

    /**
     * @event onSubmit
     */
    onSubmit: null,

    /**
     * @property countries
     * @private
     * @readonly
     * @type {{name: string, code: string}[]}
     */
    countries: _countries.default,

    /**
     * @property states
     * @private
     * @readonly
     */
    states: _states.default,

    /**
     * @property formData
     * @type object
     * @private
     * @readonly
     */
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        country: (0, _emberCpValidations.validator)('presence', true),
        'state.name': (0, _emberCpValidations.validator)('presence', true),
        city: (0, _emberCpValidations.validator)('presence', true),
        address1: (0, _emberCpValidations.validator)('presence', true),
        postal_code: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
          allowString: true,
          integer: true
        })],
        first_name: (0, _emberCpValidations.validator)('presence', true),
        last_name: (0, _emberCpValidations.validator)('presence', true)
      });
      return Ember.Object.extend(Validations, {
        state: Ember.computed(function () {
          return {
            name: ''
          };
        }),
        couponCode: Ember.computed(function () {
          return '';
        }),
        showStatesAsDropdown: Ember.computed('country.code', function () {
          return Ember.get(this, 'country.code') in _states.default;
        }),
        countryChanged: Ember.observer('country.code', function () {
          Ember.set(this, 'state', {
            name: ''
          });
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }),

    didInsertElement() {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (!window.recurly) {
        // just for tests
        return;
      }

      recurly.configure(config.recurly);
    },

    actions: {
      onSubmit() {
        const form = this.$('form:eq(0)');
        const formData = Ember.get(this, 'formData');
        return Ember.get(this, 'onSubmit')(form, formData);
      }

    }
  });

  _exports.default = _default;
});