define("campaign-warrior-app/components/modals-container", ["exports", "ember-bootstrap-modals-manager/components/modals-container", "jquery"], function (_exports, _modalsContainer, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ModalsContainer
   * @namespace Components
   * @extends EmberBootstrapModalsManager.Components.ModalsContainer
   */
  var _default = _modalsContainer.default.extend({
    /**
     * Try to focus on primary-button when modal is opened
     *
     * @method focusOnConfirmButton
     */
    focusOnConfirmButton: Ember.computed('modalIsOpened', function () {
      if (this.modalIsOpened) {
        Ember.run.later(() => (0, _jquery.default)('.modal-footer button').focus(), 500);
      }
    })
  });

  _exports.default = _default;
});