define("campaign-warrior-app/macros/filter-by-includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterByIncludes;

  /**
   * Computed Property similar to the `filterBy`. Main difference is that current CP checks `includes` and not `===`
   *
   * **IMPORTANT** Filter value and property value will be compared as strings
   *
   * Usage:
   *
   * ```javascript
   * import EmberObject from '@ember/object';
   * import filterByIncludes from '../macros/filter-by-includes';
   * export default EmberObject.extend({
   *   obj: null,
   *   ignoreCase: false,
   *   filter: '',
   *   result: filterByIncludes('obj', 'prop1', 'filter', 'ignoreCase')
   * });
   * ```
   *
   * In this case `result` will be an object with fields `prop1`, `prop2` and values from `obj.prop1` and `obj.prop2`
   *
   * @param {string} collectionName collection to filter
   * @param {string} propertyName property name used to compare with filter value
   * @param {string} valueName property name with filter value
   * @param {string} ignoreCasePropertyName property name with flag about case sensitive comparison
   * @returns {Ember.ComputedProperty} new computed property macro
   */
  function filterByIncludes(collectionName, propertyName, valueName, ignoreCasePropertyName) {
    return Ember.computed(`${collectionName}.@each.${propertyName}`, valueName, ignoreCasePropertyName, function () {
      const collection = Ember.get(this, collectionName);
      const ignoreCase = Ember.get(this, ignoreCasePropertyName);
      let value = String(Ember.get(this, valueName));

      if (ignoreCase) {
        value = value.toLowerCase();
      }

      return Ember.isArray(collection) ? collection.filter(item => {
        let itemValue = String(Ember.get(item, propertyName));

        if (ignoreCase) {
          itemValue = itemValue.toLowerCase();
        }

        return itemValue.includes(value);
      }) : [];
    });
  }
});