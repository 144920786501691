define("campaign-warrior-app/components/domains/sitemap-error-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class SitemapErrorModal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    classNames: ['modals-branding', 'sitemap-error']
  });

  _exports.default = _default;
});