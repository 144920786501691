define("campaign-warrior-app/templates/components/cw-table/publish-source-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M7VHN/8G",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],[[30,[36,3],[\"services.\",[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],null]],null]]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\",\"concat\",\"t\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/publish-source-type.hbs"
    }
  });

  _exports.default = _default;
});