define("campaign-warrior-app/controllers/portals/create/step2", ["exports", "campaign-warrior-app/controllers/portals/create/step", "campaign-warrior-app/macros/get-props-from"], function (_exports, _step, _getPropsFrom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = ['name', 'description'];
  /**
   * First step for Create-a-Portal wizard. Here user provides a name and description for the new portal
   *
   * @namespace Controllers.CreatePortal
   * @class Step1
   * @extends Controllers.CreatePortal.WizardStep
   */

  var _default = _step.default.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property notify
     * @type Ember.Service
     */
    notify: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    activePortal: null,
    createdDomain: null,
    gotFocus: false,

    /**
     * Properties `slug` and `description` are taken from the LS
     *
     * @property initialData
     */
    initialData: (0, _getPropsFrom.default)('portal', fields),
    actions: {
      finish(data) {
        fields.forEach(key => Ember.set(this, `portal.${key}`, Ember.get(data, key)));
        const newPortalData = Ember.getProperties(Ember.get(this, 'portal'), ['name', 'description']);
        const store = Ember.get(this, 'store');
        const modalsManager = Ember.get(this, 'modalsManager');
        modalsManager.process({
          body: '',
          iconClass: 'text-center fal fa-spinner fa-spin fa-3x fa-fw',
          title: 'Creating portal, please wait',
          process: () => new Promise(resolve => resolve(store.createRecord('portal', newPortalData).save().then(portal => {
            Ember.set(this, 'activePortal', portal);
            Ember.get(this, 'portal').reset();
            Ember.get(this, 'currentUser').load().then(() => {
              modalsManager.process({
                body: '',
                iconClass: 'text-center fal fa-spinner fa-spin fa-3x fa-fw',
                title: 'Connecting domain, please wait',
                process: () => new Promise(resolve => resolve(Ember.get(this, 'store').createRecord('domain', {
                  portal: Ember.get(this, 'activePortal'),
                  url: Ember.get(data, 'domain')
                }).save().then(domain => {
                  Ember.set(this, 'createdDomain', domain);
                }) // store.createRecord('domain', {
                //   portal: get(this, 'activePortal'),
                //   url: get(data, 'domain')
                // })
                //   .save()
                //   .then((domain) => {
                //     console.log('DOMAINS CREATED: ', domain);
                //     set(this, 'createdDomain', domain);
                //   })
                ))
              }).then(() => {
                this.send('goToStep3');
              }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
            }); // .then(() => get(this, 'store').createRecord('domain', {
            //   portal,
            //   url: get(data, 'domain')
            // })
            //   .save().then((domain) => {
            //     console.log('DOMAINS CREATED: ', domain);
            //     set(this, 'createdDomain', domain);
            //   }))
          })))
        }).then(() => {// console.log('opening model');
          // this.send('editPortal');
        }) // return store
        //   .createRecord('portal', newPortalData)
        //   .save()
        //   .then(portal => {
        //     set(this, 'activePortal', portal);
        //     get(this, 'portal').reset();
        //     get(this, 'currentUser')
        //       .load()
        //       .then(() => get(this, 'store').createRecord('domain', {portal, url: get(data, 'domain')}).save())
        //       .then(() => {
        //         this.send('editPortal');
        //       });
        //     // get(this, 'router').transitionTo('portals.portal', portal));
        //   })
        .catch(e => modalsManager.detailedAjaxErrorHandler(e));
      },

      /**
       * Open modal to edit current Portal info
       *
       * @method actions.goToStep3
       */
      goToStep3() {
        Ember.set(Ember.get(this, 'portal'), 'step', 3);
        Ember.set(this, 'portal.activePortal', Ember.get(this, 'activePortal'));
        Ember.set(this, 'portal.trackingCode', Ember.get(this, 'createdDomain.trackingCode'));
        let featureLevel = Ember.get(this, 'currentUser.user.company.featureLevel');

        if (featureLevel === 'free') {
          Ember.get(this, 'router').transitionTo('portals');
        } else {
          Ember.get(this, 'router').transitionTo('portals.create.step3');
        }
      }

    },

    setFocus() {
      Ember.set(this, 'gotFocus', false);
      Ember.run.later(() => {
        Ember.set(this, 'gotFocus', true);
      }, 300);
    }

  });

  _exports.default = _default;
});