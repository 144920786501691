define("campaign-warrior-app/components/domains/refresh-domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class RefreshDomain
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    classNames: ['btn'],
    classNameBindings: ['btnClass'],

    /**
     * Additional css-classes to the shown button
     *
     * @type string
     * @default ''
     * @property btnClass
     */
    btnClass: '',

    /**
     * Custom icon may be shown at the right of the button-text (classes `fa` and `fa-fw` are already added)
     *
     * @type string
     * @default ''
     * @property btnIcon
     */
    btnIcon: '',
    actions: {
      refresh(domain) {
        return domain.save().then(domain => {
          const data = {
            title: 'Request Sent',
            body: 'Update is in progress'
          };
          return Ember.get(this, 'modalsManager').successHandler(data); // this.send('refreshRoute');
        }).catch(e => Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e));
      }

    }
  });

  _exports.default = _default;
});