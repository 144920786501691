define("campaign-warrior-app/components/domains/connect-domain-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    formData: null,
    actions: {
      save() {
        const record = Ember.get(this, 'record');
        let dataToSend = {};

        if (record.subdomain) {}

        dataToSend = Ember.getProperties(Ember.get(this, 'record'), ['url']);

        if (dataToSend.url) {
          return this._super(dataToSend);
        }
      }

    }
  });

  _exports.default = _default;
});