define("campaign-warrior-app/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kxWurA5Q",
    "block": "{\"symbols\":[],\"statements\":[[10,\"title\"],[12],[2,\"Campaign Warrior\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/head.hbs"
    }
  });

  _exports.default = _default;
});