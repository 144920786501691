define("campaign-warrior-app/templates/components/home/home-tiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AZgVtSYV",
    "block": "{\"symbols\":[\"tile\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"hidden\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"tile\"],[15,\"onclick\",[30,[36,0],[[32,0],\"tileClick\",[32,1]],null]],[15,\"itemRow\",[31,[[32,1,[\"row\"]]]]],[15,\"itemCol\",[31,[[32,1,[\"column\"]]]]],[15,1,[31,[[32,1,[\"id\"]]]]],[12],[2,\"\\n\"],[2,\"      \"],[10,\"div\"],[14,0,\"tile-content\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"tile-image\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"tile-icon-wrapper\"],[12],[2,\"\\n\"],[2,\"            \"],[10,\"i\"],[15,0,[31,[\"icon \",[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n\\n\"],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"tile-title\"],[12],[2,\"\\n          \"],[10,\"h4\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"tile-description\"],[12],[2,\"\\n          \"],[1,[32,1,[\"description\"]]],[2,\"\\n\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"not\",\"if\",\"tilesFiltered\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/home/home-tiles.hbs"
    }
  });

  _exports.default = _default;
});