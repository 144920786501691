define("campaign-warrior-app/components/extra-pay-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    defaultQuantity: {
      impression: 10000,
      pages: 1000,
      extra: 0,
      total: 19,
      costPerUnit: 5,
      pagesPerUnit: 250,
      impressionsPerUnit: 1000
    },
    defaultUnit: 1,
    isAgreed: true,
    unit: Ember.computed('portal.chargeableBlocks', function () {
      if (this.portal.potentialPages < 1000) {
        return 0;
      }

      return Math.ceil((this.portal.potentialPages - this.defaultQuantity.pages) / this.defaultQuantity.pagesPerUnit);
    }),
    impression: Ember.computed('defaultQuantity', 'unit', function () {
      return this.defaultQuantity.impression + this.defaultQuantity.impressionsPerUnit * this.unit;
    }),
    pages: Ember.computed('defaultQuantity', 'unit', function () {
      return this.defaultQuantity.pages + this.defaultQuantity.pagesPerUnit * this.unit;
    }),
    extraCost: Ember.computed('defaultQuantity', 'unit', function () {
      return this.defaultQuantity.costPerUnit * this.unit;
    }),
    total: Ember.computed('defaultQuantity', 'extraCost', function () {
      return this.defaultQuantity.total + this.extraCost;
    }),
    units: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 24, 29, 34, 39, 44, 49, 54, 59, 64, 69, 74, 79, 89, 99, 109, 119, 129, 139, 149, 159, 169, 179, 189, 199, 209],
    submitInProgress: false,
    currentUnit: Ember.computed('portal.chargeableBlocks', function () {
      const current = this.portal.chargeableBlocks == null || this.portal.chargeableBlocks == 0 ? 0 : this.portal.chargeableBlocks;
      return {
        percentage: 100 / 209 * current + "%",
        value: current
      };
    }),
    potantialUnit: Ember.computed('defaultQuantity', 'unit', 'portal.potentialPages', function () {
      if (this.portal.potentialPages > 1000) {
        const pot = Math.ceil((this.portal.potentialPages - this.defaultQuantity.pages) / this.defaultQuantity.pagesPerUnit) * (100 / 209);
        return pot;
      } else {
        return 0;
      }
    }),

    selectOption(value) {
      this.set('unit', value.target.value);
    },

    selectOptionMain(value) {
      this.set('unit', value);
    },

    actions: {
      onCheck() {
        if (this.isAgreed) {
          this.set('isAgreed', false);
        } else {
          this.set('isAgreed', true);
        }
      },

      onSubmit() {
        const _this = this;

        Ember.set(this, 'submitInProgress', true);
        this.ajax.put('/billing/update/blocks', {
          data: {
            portal_id: this.portal.id,
            chargeableBlocks: this.unit
          }
        }).then(res => {
          Ember.set(this, 'submitInProgress', false);
          Ember.set(this, 'portal.chargeableBlocks', this.unit);
          this.callback(res);
        });
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "selectOption", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectOption"), _obj), _applyDecoratedDescriptor(_obj, "selectOptionMain", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectOptionMain"), _obj)), _obj)));

  _exports.default = _default;
});