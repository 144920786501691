define("campaign-warrior-app/templates/portals/create/setup-wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZyCKX1uc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"page-controls\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Welcome \"],[1,[35,0,[\"user\",\"firstName\"]]],[2,\", \"],[1,[30,[36,1],[\"portals.create.wizard.setName.title\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"control-group\"],[12],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-12 col-md-9\"],[12],[2,\"\\n        \"],[1,[30,[36,4],null,[[\"expandable\",\"class\",\"title\",\"bodyComponent\"],[false,\"branding filter-panel\",\"Create a new Portal\",[30,[36,3],[\"setup-new-portal\"],[[\"model\"],[[35,2]]]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"currentUser\",\"t\",\"model\",\"component\",\"cw-panel\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/create/setup-wizard.hbs"
    }
  });

  _exports.default = _default;
});