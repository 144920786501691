define("campaign-warrior-app/components/pages/toggle-favorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{pages/toggle-favorite record=page onClick=(action "toggleFavorite")}}
   * ```
   *
   * @class ToggleFavoritePage
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tag: 'span',
    classNames: ['toggle-favorite'],

    /**
     * @property page
     * @type Models.Page
     * @default null
     */
    record: null,

    /**
     * @event onClick
     */
    onClick: null,

    click() {
      if (!Ember.get(this, 'record.favorite.isSaving')) {
        Ember.get(this, 'onClick')(Ember.get(this, 'record'));
      }
    }

  });

  _exports.default = _default;
});