define("campaign-warrior-app/components/manage-hashtags-form", ["exports", "campaign-warrior-app/components/manage-record-form"], function (_exports, _manageRecordForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for edit or add a Domain
   *
   * Usage:
   *
   * ```hbs
   * {{manage-campaign-form record=model save=(action "save") tags=tags}}
   * ```
   *
   * @class ManageDomainForm
   * @namespace Components
   * @extends Components.ManageRecordForm
   */
  var _default = _manageRecordForm.default.extend({
    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),
    classNames: ['manage-hashtags-form'],

    /**
     * Allow to hide submit-button. Useful when form is shown in the modal
     *
     * @property hideControls
     * @type boolean
     * @default false
     */
    hideControls: false,

    /**
     * @property inputDisabled
     * @type boolean
     * @default false
     */
    inputDisabled: Ember.computed('can', 'record.isNew', function () {
      if (Ember.get(this, 'record.isNew')) {
        return false;
      }

      return Ember.get(this, 'can').cannot('edit domain');
    }),
    actions: {
      save() {
        const hashtags = Ember.get(this, 'record');
        window.location.reload(true);
        return this._super(hashtags);
      }

    }
  });

  _exports.default = _default;
});