define("campaign-warrior-app/services/ajax", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Ajax
   * @namespace Services
   * @extends EmberAjax
   */
  var _default = _ajax.default.extend({
    telemetryService: Ember.inject.service(),

    /**
     * @property contentType
     * @type string
     */
    contentType: 'application/json; charset=utf-8',

    /**
     * Use empty host for test-mode (Mirage will track all requests), use real host for any other case
     *
     * @type string
     * @property host
     */
    host: Ember.computed(function () {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      const buildMode = Ember.get(config, 'environment');
      return ['test'].includes(buildMode) ? Ember.get(config, 'testServer') : Ember.get(config, 'prodServer');
    }),

    /**
     * @property namespace
     * @type string
     * @default 'api/v1'
     */
    namespace: 'api/v1',

    /**
     * @property session
     * @type Ember.Service
     */
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    modalsManager: Ember.inject.service(),

    /**
     * Override original `request` to fix possible issues with `url`
     *
     * @method request
     * @param {string} url The url to make a request to
     * @param {Object} options The options for the request
     * @return {Promise} The result of the request
     */
    request(url, options) {
      return this._super(this.prepareUrl(url), options);
    },

    /**
     * Override original `raw` to fix possible issues with `url`
     *
     * @method raw
     * @param {string} url The url to make a request to
     * @param {Object} options The options for the request
     * @return {Promise} The result of the request
     */
    raw(url, options) {
      return this._super(this.prepareUrl(url), options);
    },

    prepareUrl(url) {
      return url.split('://').map(c => c.replace(/\/+/g, '/')).join('://');
    },

    /**
     * Handle response to check if user session is valid. Session is invalidated in the `ensureResponseAuthorized`-method
     *
     * Normally this is done in the `DataAdapterMixin` from `ember-simple-auth` (it's used in the Application Adapter),
     * however we use a `AjaxServiceSupport` from `ember-ajax` and need to handle response
     *
     * @method handleResponse
     * @param {number} status response status
     * @param {object} headers request headers
     * @param {object} payload response data
     * @param {object} requestData request data
     * @override
     * @returns {*} mixed
     */
    handleResponse(status, headers, payload, requestData) {
      // console.log('Handle Response', payload);
      // console.log("Response Status", status);
      let errorData = this.handleResponseErrors(status, payload);

      if (errorData) {
        payload.errorDetail = errorData.errorDetail;

        if (status === 429) {
          console.error('User blocked by refresh cooldown');
        } else if (status === 422) {
          Ember.get(this, 'router').transitionTo('expired');
        } else if (status === 400) {
          Ember.get(this, 'modalsManager').show('server-error-modal', {
            message: `Client requested a restricted resource. Please report this to support`
          });
        } else if (status === 403) {
          //forbidden
          // console.error('Forbidden', payload);
          Ember.get(this, 'modalsManager').alert({
            title: "Forbidden",
            confirm: "Confirm",
            body: "You don't have access to do this"
          });
        } else {
          //get(this, 'router').transitionTo('suspended');
          Ember.get(this, 'modalsManager').show('server-error-modal', {
            message: `A server error has occurred (Code ${status}). Please report this to support`
          });
        }
      }

      this.ensureResponseAuthorized(status);

      if (payload && typeof payload !== 'string') {
        payload.requestData = requestData;
      }

      try {
        // console.log("Handle Response -> try super() payload", arguments);
        return this._super(...arguments);
      } catch (err) {
        console.error('handleResponse error', err);
      }
    },

    /**
     * Method is taken from `DataAdapterMixin`
     *
     * @method ensureResponseAuthorized
     * @param {number} status response status
     */
    ensureResponseAuthorized(status
    /* ,headers, payload, requestData */
    ) {
      if (status === 401 && Ember.get(this, 'session.isAuthenticated')) {
        Ember.get(this, 'session').invalidate();
      }
    },

    handleResponseErrors(status, payload) {
      // console.log(`Handle response (${status}) errors`, payload);
      if (payload && payload.errors) {
        let error;

        for (let e of payload.errors) {
          // console.error('Payload error', e);
          error = e;
          Ember.get(this, 'telemetryService').ajaxError(status, e);
        }

        if (error) {
          if (error && error.status) {
            if (error.status === '401') {
              this.modalsManager.alert({
                title: 'An error has occurred. (Unauthorized)',
                body: 'You are not authorised to access this resource, if you feel that this is incorrect, please contact Campaign Warrior support.',
                confirm: 'Log Out'
              }).then(() => {
                Ember.get(this, 'session').invalidate();
              });
            }
          }
        } // return true;


        return {
          errorDetail: payload.errors[0].details
        }; //console.log("Payload had errors", payload.errors);
      }
    },

    /**
     * Auth-header must be added to almost any request done to the server (but not for `login` and `register`)
     *
     * @param {string} url request url
     * @param {object} options options for request
     * @returns {object} updated options for request
     */
    options(url, options = {}) {
      options = this._super(...arguments);

      if (url.includes('/login') || url.includes('/register')) {
        return options;
      }

      let token = Ember.get(this, 'session.data.authenticated.token');
      options.headers.Authorization = `Bearer ${token}`;
      options.headers['accept-version'] = 'v2';
      return options;
    }

  });

  _exports.default = _default;
});