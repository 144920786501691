define("campaign-warrior-app/components/pages/page-notes-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PageNotesPanel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class PageNotesPanel extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modalsManager", _descriptor, this);

      _initializerDefineProperty(this, "currentPortal", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);
    }

    async newNote(me) {
      console.log("New Note", me);

      try {
        const noteData = await this.modalsManager.show('notes/create-note-modal', {
          creating: true
        });
        if (!noteData) return;
        let description = Ember.get(noteData, 'description');
        console.log("New Note Description", description);

        if (description && description.length) {
          console.log("Creating note", description);
          let notePayload = {
            page: this.page,
            portal: Ember.get(this, 'currentPortal.portal'),
            description
          };
          console.log("Note Payload", notePayload);
          let newNote = this.store.createRecord('note', notePayload);
          console.log("new Note", newNote);
          await newNote.save(); //this.notes.pushObject(newNote);

          console.log("Created Note", newNote);
          this.notes.update();
        } else {
          console.error("Note was empty", description);
        }
      } catch (err) {// console.error("New Note error", err);
      }
    }

    async editNote(note) {
      console.log("Edit note");

      try {
        const noteData = await this.modalsManager.show('notes/create-note-modal', {
          creating: false,
          description: note.description
        });
        let description = Ember.get(noteData, 'description');
        console.log("Update Note Description", description);

        if (description && description.length) {
          Ember.set(note, 'description', description);
          await note.save();
          this.notes.update();
        } else {
          console.error("Note was empty", description);
        }
      } catch (err) {
        console.error("New Note error", err);
      }
    }

    async deleteNote(note) {
      try {
        await this.modalsManager.confirm({
          title: 'Delete Note',
          body: 'Are you sure you want to delete this?',
          confirm: 'Yes',
          decline: 'No'
        });
        await note.destroyRecord();
        console.log("Delete Note", note);
      } catch (err) {}
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "newNote", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "newNote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editNote", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "editNote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteNote", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "deleteNote"), _class.prototype)), _class));
  _exports.default = PageNotesPanel;
});