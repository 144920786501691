define("campaign-warrior-app/components/cw-table/metasize-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const metaUnder = 50;
  const metaOver = 160;
  let MetasizeCell = (_dec = Ember.computed('record.metaSize'), _dec2 = Ember.computed('record.metaSize'), _dec3 = Ember.computed('record.metaSize'), (_class = class MetasizeCell extends Ember.Component {
    get metaUnder() {
      if (this.record.metaSize < metaUnder) {
        this.metaState = 'meta-under';
        return true;
      }
    }

    get metaOver() {
      if (this.record.metaSize > metaOver) {
        this.metaState = 'meta-over';
        return true;
      }
    }

    get metaSizeState() {
      if (this.record.metaSize > metaOver) {
        return 'meta-over';
      } else if (this.record.metaSize < metaUnder) {
        return 'meta-under';
      } else {
        return 'regular';
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "metaUnder", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "metaUnder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "metaOver", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "metaOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "metaSizeState", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "metaSizeState"), _class.prototype)), _class));
  _exports.default = MetasizeCell;
  ;
});