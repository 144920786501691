define("campaign-warrior-app/routes/portals/portal/integrations/googleanalytics/googleanalytics", ["exports", "campaign-warrior-app/mixins/portal-internal-route"], function (_exports, _portalInternalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @namespace Routes
   * @class Page
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_portalInternalRoute.default, {
    model({
      integration_id
    }) {
      //console.log('in google analytics');
      const store = Ember.get(this, 'store'); //const portal = this.modelFor('portals.portal');
      //const portal_id = get(portal, 'id');

      const integration = store.findRecord('integration', integration_id);
      return {
        integration
      };
    },

    afterModel(model) {
      Ember.set(this, 'breadCrumb', {
        title: Ember.get(model, 'title')
      });
    }

  });

  _exports.default = _default;
});