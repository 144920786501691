define("campaign-warrior-app/components/create-portal/step4-form", ["exports", "campaign-warrior-app/components/floating-form"], function (_exports, _floatingForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for fourth step in the Create-a-Portal wizard. it allows to connect a Bitly account
   *
   * Usage
   *
   * ```hbs
   * {{create-portal/step4-form
   *   connectSocialNetwork=(action "connectSocialNetwork")
   *   onSubmit=(action "onSubmit")
   * }}
   * ```
   *
   * @namespace Components.CreatePortal
   * @class Step4Form
   * @extends Components.FloatingForm
   */
  var _default = _floatingForm.default.extend({
    classNames: ['create-portal-step4-form'],

    /**
     * External action-handler for connect-click, bound from the parent context
     *
     * Handler will receive one argument - network name
     *
     * @event connectSocialNetwork
     */
    connectSocialNetwork: null,
    actions: {
      /**
       * Internal handler for connect-click. It calls external handler
       *
       * @method actions.connectSocialNetwork
       * @param {string} networkName network name user tries to connect to
       */
      connectSocialNetwork(networkName) {
        Ember.get(this, 'connectSocialNetwork')(networkName);
      }

    }
  });

  _exports.default = _default;
});