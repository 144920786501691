define("campaign-warrior-app/services/restrictor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * @property session
     * @type Ember.Service
     */
    session: Ember.inject.service(),

    /**
     * @property store
     * @type Ember.Service
     */
    store: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    ajax: Ember.inject.service(),
    portal: Ember.computed.reads('currentPortal.portal'),

    /**
     * Info about current portals company. Check [models/user](Models.User.html) for list of attributes
     *
     * @property company
     * @type Models.Company
     * @default null
     */
    featureLevel: null,
    limits: Ember.computed(function () {
      return {};
    }),

    /**
     * Load info about current user (must be called only after user authorization)
     *
     * This method is already called on [routes/application](Routes.Application.html)
     *
     * @method load
     * @returns {Promise} request for current user
     */
    load() {
      if (Ember.get(this, 'session.isAuthenticated')) {
        const featureLevel = Ember.get(this, 'currentPortal.portal.featureLevel');

        if (featureLevel) {
          Ember.set(this, 'featureLevel', featureLevel);
          return featureLevel;
        }
      } else {
        return Ember.RSVP.resolve();
      }
    },

    getFeatureLevel() {
      if (Ember.get(this, 'session.isAuthenticated')) {
        const featureLevel = Ember.get(this, 'portal.featureLevel');

        if (featureLevel) {
          Ember.set(this, 'featureLevel', featureLevel);
          return featureLevel;
        }
      } else {
        return Ember.RSVP.resolve();
      }
    },

    checkFeature(requiredLevel) {
      if (!this.hasActivePortal()) return true;

      if (this.getFeatureLevel() === requiredLevel) {
        return true;
      } else {
        Ember.get(this, 'modalsManager').restrictedFeatureHandler();
        throw new Error("Feature is restricted");
      }
    },

    checkUiFeature(requiredLevel, featureName) {
      console.log("Check ui feature", requiredLevel);

      if (!this.hasActivePortal() || this.getFeatureLevel() === requiredLevel) {
        return true;
      }
    },

    hasActivePortal() {
      const currentPortal = Ember.get(this, 'portal');

      if (currentPortal) {
        return true;
      }
    },

    checkPostLimits(showPopup) {
      if (this.getFeatureLevel() === 'free') {
        const portalId = Ember.get(this, 'portal.id');
        console.log('check post limits portalid', portalId);
        const modalsManager = Ember.get(this, 'modalsManager');
        Ember.get(this, 'ajax').post('/checkPostLimit', {
          data: {
            portalId
          }
        }).then(result => {
          const dailyCount = Ember.get(result, 'daily.count');
          const monthlyCount = Ember.get(result, 'monthly.count');

          if (dailyCount >= 2 || monthlyCount >= 20) {
            if (showPopup) {
              return modalsManager.alert({
                title: 'Limit Exceed',
                body: 'You have reached your daily/monthly post limit. Upgrade now for unlimited posting',
                confirm: 'Ok'
              });
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
      }
    },

    checkTagLimits(showPopup) {
      if (this.getFeatureLevel() === 'free') {
        const portalId = Ember.get(this, 'portal.id');
        console.log('check tag limits portalid', portalId);
        const modalsManager = Ember.get(this, 'modalsManager');
        Ember.get(this, 'ajax').post('/checkTagLimit', {
          data: {
            portalId
          }
        }).then(result => {
          const count = Ember.get(result, 'count');

          if (count >= 5) {
            if (showPopup) {
              return modalsManager.alert({
                title: 'Limit Exceed',
                body: 'You have reached your tag limit. Upgrade now for unlimited tagging',
                confirm: 'Ok'
              });
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
      }
    },

    checkCampaignLimits(showPopup) {
      if (this.getFeatureLevel() === 'free') {
        const portalId = Ember.get(this, 'portal.id');
        console.log('check campaign limits portalid', portalId);
        const modalsManager = Ember.get(this, 'modalsManager');
        Ember.get(this, 'ajax').post('/checkCampaignLimits', {
          data: {
            portalId
          }
        }).then(result => {
          const count = Ember.get(result, 'count');

          if (count >= 3) {
            if (showPopup) {
              return modalsManager.alert({
                title: 'Limit Exceed',
                body: 'You have reached your Campaign limit. Upgrade now to create unlimited Campaigns',
                confirm: 'Ok'
              });
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
      }
    },

    checkPublishingSourceLimits(showPopup) {
      if (this.getFeatureLevel() === 'free') {
        const portalId = Ember.get(this, 'portal.id');
        console.log('check campaign limits portalid', portalId);
        const modalsManager = Ember.get(this, 'modalsManager');
        Ember.get(this, 'ajax').post('/checkPublishingSourceLimits', {
          data: {
            portalId
          }
        }).then(result => {
          const count = Ember.get(result, 'count');

          if (count >= 2) {
            if (showPopup) {
              return modalsManager.alert({
                title: 'Limit Exceed',
                body: 'You have reached your Publishing Source limit. Upgrade now to connect unlimited publishing sources',
                confirm: 'Ok'
              });
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
      }
    },

    getRemainingLimit(resourceType) {
      console.log("getRemainingLimit", resourceType);

      if (this.getFeatureLevel() === 'free') {
        console.log("Feature Level is free");
        const portal = Ember.get(this, 'portal');
        console.log("Portal", portal);

        switch (resourceType) {
          case 'publishingSources':
            return Ember.get(portal, 'remainingPublishingSourceCount');

          case 'tags':
            return Ember.get(portal, 'remainingTagCount');

          case 'posts':
            return Ember.get(portal, 'remainingMonthlyPostCount');

          case 'domains':
            {
              const domains = Ember.get(portal, 'remainingDomainCount');
              console.log("Remaining domains count", domains);
              return domains;
            }

          default:
            return 0;
        }
        /*
              const portalId = get(this, 'currentPortal.portal.id');
        
              get(this, 'ajax').post('/checkRemainingLimits', {
                data: {
                  portalId,
                  resourceType
                }
              }).then((result) => {
                const remaining = get(result, 'remaining');
                console.log("getRemainingLimit() result", remaining);
                return get(result, 'remaining');
              })*/

      }

      return 1000;
    },

    getAllRemaining() {
      console.log("Get all remaining");
      const portal = Ember.get(this, 'currentPortal.portal');
      const portalId = Ember.get(this, 'currentPortal.portal.id');

      if (this.getFeatureLevel() === 'free') {
        console.log("Portal is free, check remaining");
        Ember.get(this, 'ajax').post('/checkPortalRemainingLimits', {
          data: {
            portalId
          }
        }).then(result => {
          console.log("Restrictor getAllRemaining", result);
          Ember.set(portal, 'limits', result);
        }).catch(err => {
          console.error("restrictor.getAllRemaining() error:", err);
        });
      }
    },

    refreshLimit(resourceType) {
      const portal = Ember.get(this, 'currentPortal.portal');
      if (this.getFeatureLevel() !== 'free') return -1;

      switch (resourceType) {
        case 'publishingSources':
          return Ember.get(portal, 'remainingPublishingSourceCount');

        case 'tags':
          return Ember.get(portal, 'remainingTagCount');

        case 'posts':
          return Ember.get(portal, 'remainingMonthlyPostCount');

        case 'domains':
          {
            const domains = Ember.get(portal, 'remainingDomainCount');
            console.log("Remaining domains count", domains);
            return domains;
          }

        default:
          return 0;
      }
    },

    refreshCampaignLimit() {
      //const portalId = get(this, 'currentPortal.portal.id');

      /*
      return new Promise((resolve, reject) => {
        get(this, 'ajax').post('/checkPublishingSourceLimits', {
          data: {
            portalId
          }
        }).then((result) => {
          console.log("refreshCampaignLimit result", result)
          resolve(result);
        }).catch((err) => {
          reject(err);
        })
      })*/
      return Ember.get(this, 'ajax').post('/checkPublishingSourceLimits', {
        data: {
          portalId
        }
      });
      /*.then((result) => {
      console.log("refreshCampaignLimit result", result)
      return result;
      })*/
    }

  });

  _exports.default = _default;
});