define("campaign-warrior-app/mixins/manage-record-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for Routes for pages where some Record is created or updated.
   *
   * Usually `edit`-route has path `edit` and placed like `modelName/edit`.
   * `create`-route looks same and placed like `modelName/create`.
   *
   * This mixin can be applied for both of them. It handles model loading and transition out from page.
   *
   * @namespace Mixins
   * @class ManageRecordRoute
   */
  var _default = Ember.Mixin.create({
    /**
     * File-name from `app/models`
     *
     * @property modelClassName
     * @type string
     * @default ''
     */
    modelClassName: '',

    /**
     * Sometimes `edit`-route may be called not `edit`. Override `editRouteName` with your value
     *
     * @type string
     * @property editRouteName
     * @default 'edit'
     */
    editRouteName: 'edit',

    model() {
      const parentRouteName = Ember.get(this, 'routeName').split('.').slice(0, -1).join('.');
      const currentRoute = Ember.get(this, 'routeName').replace(`${parentRouteName}.`, '');
      const parentModel = this.modelFor(parentRouteName);
      const modelClassName = Ember.get(this, 'modelClassName');
      (true && !(!!modelClassName) && Ember.assert('`modelClassName` must be defined', !!modelClassName));
      return currentRoute === Ember.get(this, 'editRouteName') ? parentModel : Ember.get(this, 'store').createRecord(modelClassName);
    },

    actions: {
      /**
       * Fires when user is moving out from page.
       *
       * If current model exists only in the UI, it will be deleted
       *
       * If current model is not only in the UI and it's changed, it will be restored to initial state
       *
       * @method actions.willTransition
       */
      willTransition() {
        const model = Ember.get(this, 'controller.model');

        if (Ember.get(model, 'isNew')) {
          model.destroyRecord();
        } else {
          if (Ember.get(model, 'hasDirtyAttributes')) {
            model.rollbackAttributes();
          }
        }

        this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});