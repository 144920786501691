define("campaign-warrior-app/templates/components/tags/tags-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jcH3NbjA",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[6,[37,5],null,[[\"options\",\"search\",\"searchField\",\"selected\",\"renderInPlace\",\"onChange\"],[[35,4],[30,[36,1],[[32,0],\"searchInstances\"],null],[35,3],[35,2],true,[30,[36,1],[[32,0],\"updateColumnFilter\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"tag\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"cw-tag\",\"action\",\"selectedInstance\",\"searchField\",\"instances\",\"power-select-multiple\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/tags/tags-filter.hbs"
    }
  });

  _exports.default = _default;
});