define("campaign-warrior-app/macros/get-props-from", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPropsFrom;

  /**
   *
   * Computed Property similar to the `Ember.getProperties`
   *
   * Dynamic source and props may be set to it
   *
   * Usage:
   *
   * ```javascript
   * import EmberObject from '@ember/object';
   * import getPropsFrom from '../macros/get-props-from';
   * export default EmberObject.extend({
   *   obj: null,
   *   result: getPropsFrom('obj', ['prop1', 'prop2'])
   * });
   * ```
   *
   * In this case `result` will be an object with fields `prop1`, `prop2` and values from `obj.prop1` and `obj.prop2`
   *
   * @param {string} source property-name with needed data
   * @param {string[]} props list of properties that should be get
   * @returns {Ember.ComputedProperty} new computed property macro
   */
  function getPropsFrom(source, props) {
    return Ember.computed(`${source}.{${props.join(',')}}`, function () {
      return Ember.getProperties(Ember.get(this, source), props);
    });
  }
});