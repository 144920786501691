define("campaign-warrior-app/components/domains/table-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DomainTableControls = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class DomainTableControls extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modalsManager", _descriptor, this);

      _initializerDefineProperty(this, "i18n", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "currentPortal", _descriptor4, this);

      _initializerDefineProperty(this, "ajax", _descriptor5, this);
    }

    copyTrackingCode() {
      this.toggleProperty('isShowingTrackingCode');
      const el = document.createElement('textarea');
      el.value = Ember.get(this, 'record').trackingCode;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }

    deleteDomain() {
      const _this = this;

      const i18n = Ember.get(this, 'i18n');
      const modalsManager = Ember.get(this, 'modalsManager');
      const portal_id = Ember.get(this.currentPortal.portal, 'id');
      return modalsManager.confirm({
        title: i18n.t('modals.defaultConfirmOptions.title'),
        body: i18n.t('modals.defaultConfirmOptions.body'),
        confirm: i18n.t('modals.defaultConfirmOptions.confirm')
      }).then(() => {
        if (this.record) {
          this.record.destroyRecord().then(function () {
            _this.store.findRecord('portal', portal_id);
          });
        }
      }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
    }

    async refreshDomain() {
      try {
        await this.ajax.post('/scrape/refreshdomain', {
          data: {
            domain: this.record.id
          }
        });
        this.modalsManager.alert({
          title: 'Refreshing Domain',
          body: 'Your domain has been queued for refresh',
          confirm: 'Ok'
        });
      } catch (err) {
        let message = 'There was an error refreshing the domain';

        if (err.status === 429) {
          message = 'You must wait 5 minutes to refresh';
        }

        this.modalsManager.alert({
          title: 'Refresh Domain Error',
          body: message,
          confirm: 'Ok'
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "copyTrackingCode", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "copyTrackingCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteDomain", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "deleteDomain"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshDomain", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "refreshDomain"), _class.prototype)), _class));
  _exports.default = DomainTableControls;
});