define("campaign-warrior-app/utils/routing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.portalRoutes = void 0;

  const getTportals = suffix => `portals.portal.nav.titles.${suffix}`;

  const accountsHref = 'portals.portal.accounts.index';
  /**
   * Same routes and icons are used for side menu and breadcrumbs
   *
   * @type {object}
   */

  const portalRoutes = {
    /*
      stats: {
        icon: 'icon fal fa-fw fa-dashboard',
        href: 'portals.portal.stats',
        title: getTportals('stats')
      },
      */
    pages: {
      href: 'portals.portal.pages.list',
      currentWhen: 'portals.portal.pages',
      title: getTportals('webpages')
      /*
      routes: [
        {
          icon: 'icon fal fa-fw fa-list-ul',
          href: 'portals.portal.pages.list',
          title: 'portals.portal.nav.pages.list'
        },
        {
            icon: 'icon fal fa-fw fa-sitemap',
          href: 'portals.portal.domains.list',
          title: 'portals.portal.nav.domains.manage'
        }
      ]*/

    },
    tags: {
      href: 'portals.portal.tags',
      title: getTportals('tags'),
      currentWhen: 'portals.portal.tags'
    },
    domains: {
      href: 'portals.portal.domains.list',
      title: 'portals.portal.nav.domains.manage',
      currentWhen: 'portals.portal.domains.list'
    }
    /* newPortal: {
       href: 'portals.create.step1',
       title: 'New Portal',
       currentwhen: 'portals.create',
     }*/

    /*
    media: {
      icon: 'icon fal fa-fw fa-fw fa-picture-o',
      href: 'portals.portal.media',
      title: getTportals('media')
    }*/

  };
  _exports.portalRoutes = portalRoutes;
});