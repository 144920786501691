define("campaign-warrior-app/abilities/domain", ["exports", "campaign-warrior-app/abilities/cw-ability", "campaign-warrior-app/utils/ability"], function (_exports, _cwAbility, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Domain
   * @namespace Abilities
   * @extends Abilities.CwAbility
   * @uses Mixins.PostingAbilityMixin
   */
  var _default = _cwAbility.default.extend({
    canCreate: (0, _ability.portalRelatedAbility)('view_portal'),
    canDelete: (0, _ability.portalRelatedAbility)('view_portal')
  });

  _exports.default = _default;
});