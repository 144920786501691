define("campaign-warrior-app/templates/components/modals/billing-error-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VG57NypM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"billing-error\"],[12],[1,[34,0]],[13],[2,\"\\n\\n\"],[10,\"style\"],[12],[2,\"\\n  .billing-error {\\n    font-size: 1.8rem;\\n  }\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"msg\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/billing-error-body.hbs"
    }
  });

  _exports.default = _default;
});