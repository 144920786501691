define("campaign-warrior-app/controllers/portals/portal/setup/related-content-settings", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RelatedContentSettingsController = (_dec = Ember.inject.service, _dec2 = Ember.computed('currentPortal.portal'), _dec3 = Ember.computed('portal.relatedContentSettings', 'defaultSettings'), _dec4 = Ember.computed('formData.layout'), _dec5 = Ember._action, (_class = (_temp = class RelatedContentSettingsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "defaultSettings", {
        funnelStage: 'any',
        sort: 'best',
        layout: 'grid',
        limit: 6,
        columns: 3,
        hideNoFeaturedImage: true,
        gridGap: '20px',
        tagExclusivity: 'AND'
      });

      _defineProperty(this, "valueRanges", {
        limit: {
          min: 1,
          max: 20
        },
        columns: {
          min: 1,
          max: 6
        }
      });

      _defineProperty(this, "funnelOptions", ['any', 'TOFU', 'TOFU_MOFU', 'MOFU', 'MOFU_BOFU', 'BOFU']);

      _defineProperty(this, "sortOptions", ['best', 'latest', 'popular', 'weight']);

      _defineProperty(this, "layoutOptions", ['grid', 'list']);

      _defineProperty(this, "tagExclusivityOptions", ['AND', 'OR']);

      _defineProperty(this, "isSubmitProgress", false);

      _initializerDefineProperty(this, "currentPortal", _descriptor, this);
    }

    get portal() {
      return this.currentPortal.portal;
    } // @computed('portal.relatedContentSettings')
    // get formData(){
    //   return {
    //     funnelStage: this.portal.relatedContentSettings.funnelStage || this.defaultSettings.funnelStage,
    //     sort: this.portal.relatedContentSettings.sort || this.defaultSettings.sort,
    //     layout: this.portal.relatedContentSettings.layout || this.defaultSettings.layout,
    //     limit: this.portal.relatedContentSettings.limit || this.defaultSettings.limit,
    //     columns: this.portal.relatedContentSettings.columns || this.defaultSettings.columns,
    //     hideNoFeaturedImage: this.portal.relatedContentSettings.hideNoFeaturedImage || this.defaultSettings.hideNoFeaturedImage,
    //     gridGap: this.portal.relatedContentSettings.gridGap || this.defaultSettings.gridGap,
    //     tagExclusivity: this.portal.relatedContentSettings.tagExclusivity || this.defaultSettings.tagExclusivity,
    //   }
    // }


    get formData() {
      const portal = Ember.get(this, 'portal.relatedContentSettings');
      const defaultSetting = Ember.get(this, 'defaultSettings');
      const Validations = (0, _emberCpValidations.buildValidations)({
        funnelStage: (0, _emberCpValidations.validator)('presence', true),
        sort: (0, _emberCpValidations.validator)('presence', true),
        layout: (0, _emberCpValidations.validator)('presence', true),
        limit: (0, _emberCpValidations.validator)('number', {
          allowString: true,
          integer: true,
          gt: 0,
          lte: 20
        }),
        columns: (0, _emberCpValidations.validator)('number', {
          allowString: true,
          integer: true,
          gt: 0,
          lte: 6
        }),
        hideNoFeaturedImage: (0, _emberCpValidations.validator)('presence', true),
        gridGap: (0, _emberCpValidations.validator)('presence', true),
        tagExclusivity: (0, _emberCpValidations.validator)('presence', true)
      });
      return Ember.Object.extend(Validations, {
        funnelStage: Ember.computed(function () {
          return Ember.get(portal, 'funnelStage') || Ember.get(defaultSetting, 'funnelStage');
        }),
        sort: Ember.computed(function () {
          return Ember.get(portal, 'sort') || Ember.get(defaultSetting, 'sort');
        }),
        layout: Ember.computed(function () {
          return Ember.get(portal, 'layout') || Ember.get(defaultSetting, 'layout');
        }),
        limit: Ember.computed(function () {
          return Ember.get(portal, 'limit') || Ember.get(defaultSetting, 'limit');
        }),
        columns: Ember.computed(function () {
          return Ember.get(portal, 'columns') || Ember.get(defaultSetting, 'columns');
        }),
        hideNoFeaturedImage: Ember.computed(function () {
          return Ember.get(portal, 'hideNoFeaturedImage') || Ember.get(defaultSetting, 'hideNoFeaturedImage');
        }),
        gridGap: Ember.computed(function () {
          return Ember.get(portal, 'gridGap') || Ember.get(defaultSetting, 'gridGap');
        }),
        tagExclusivity: Ember.computed(function () {
          return Ember.get(portal, 'tagExclusivity') || Ember.get(defaultSetting, 'tagExclusivity');
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }

    get isGridLayout() {
      return this.formData.layout === 'grid';
    }

    saveRelatedContent() {
      Ember.set(this, "isSubmitProgress", true);
      let keys = Object.keys(this.formData);

      for (let k of keys) {
        this.portal.relatedContentSettings[k] = this.formData[k];
      }

      this.portal.save().then(() => {
        Ember.set(this, "isSubmitProgress", false);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "portal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "portal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formData", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "formData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isGridLayout", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isGridLayout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveRelatedContent", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "saveRelatedContent"), _class.prototype)), _class));
  _exports.default = RelatedContentSettingsController;
});