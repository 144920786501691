define("campaign-warrior-app/controllers/portals/portal/domains/list", ["exports", "campaign-warrior-app/utils/consts"], function (_exports, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class DomainsList
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /*router: service('-routing'),*/
    router: Ember.inject.service(),

    /**
     * @property notify
     * @type Ember.Service
     */
    notify: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),
    //currentPortal.portal.company

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    //currentUser.company
    can: Ember.inject.service(),

    /**
     * @property tags
     * @type Models.Tag[]
     * @default null
     */
    tags: null,

    /**
     * @property newDomain
     * @type Models.Domain
     * @default null
     */
    newDomain: null,
    componentRef: null,
    domains: null,
    openDialog: false,
    elem: null,

    /**
     * @property portal
     * @type Models.Portal
     * @default null
     */
    portal: Ember.computed.reads('currentPortal.portal'),
    actions: {
      popover() {
        if (this.openDialog == true) {
          Ember.set(this, "openDialog", false);
        } else {
          Ember.set(this, "openDialog", true);
        }
      },

      submitDomainForm() {
        const action = Ember.get(this, 'componentRef.actions');
        action.submitForm(Ember.get(this, 'componentRef'));
      },

      openModal() {
        // $('#ember-bootstrap-wormhole .popover').hide();
        Ember.set(this, "openDialog", false);
        let modalsManager = Ember.get(this, 'modalsManager');
        return modalsManager.show('create-domain-modal', {
          domains: Ember.get(this, 'domains'),
          record: Ember.get(this, 'newDomain')
        }).then(model => {
          console.log('Model in list domain: ', model);

          if (model.buy && model.url) {
            modalsManager.show('domains/confirm-create-domain').then(() => {
              return model.save().then(domain => {
                modalsManager.show('domains/domain-created', {
                  trackingCode: domain.trackingCode
                }).then(() => {
                  this.send('refreshRoute');
                }).catch(() => console.log('Rejected'));
              }).catch(e => {
                Ember.get(this, 'modalsManager').userFriendlyErrorHandler(e).then(() => {
                  this.send('refreshRoute');
                });
              });
            }).catch(() => console.log('Rejected')); // } else if (model.add && model.domain && model.subdomain) {
          } else if (model.domain && model.subdomain) {
            Ember.set(model, 'url', model.subdomain + model.domain);

            const domains = Ember.get(this, 'domains')._objects;

            let domainExist = false;

            for (let i = 0; i < domains.length; i++) {
              if (model.domain.charAt(0) === '.') {
                model.domain = model.domain.substr(1);
              }

              if (domains[i].url.indexOf(model.domain) !== -1) {
                domainExist = true;
                Ember.set(model, 'subdomainOf', domains[i].id);
                break;
              }
            }

            if (domainExist) {
              return model.save().then(domain => {
                modalsManager.show('domains/domain-created', {
                  trackingCode: domain.trackingCode
                }).then(() => {
                  this.send('refreshRoute');
                  console.log('Code Copied');
                }).catch(() => {
                  console.log('Rejected');
                  this.send('refreshRoute');
                });
              }).catch(e => {
                Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e).then(() => {
                  this.send('refreshRoute');
                });
              });
            } else {
              const data = {
                title: 'Error',
                body: 'Domain not found for subdomain'
              };
              return Ember.get(this, 'modalsManager').errorHandler(data);
            }
          } else {
            const data = {
              title: 'Error',
              body: 'Some Fields are missing'
            };
            return Ember.get(this, 'modalsManager').errorHandler(data);
          }
        }).catch(e => {
          console.log('Error: ', e);
        });
      },

      registerComponent(componentRef) {
        Ember.set(this, 'componentRef', componentRef);
      },

      deregisterComponent() {
        Ember.set(this, 'componentRef', null);
      },

      domainDeleted(check) {
        if (check) {
          this.send('refreshRoute');
        }
      },

      refreshDomain() {
        const i18n = Ember.get(this, 'i18n');
        const store = Ember.get(this, 'store');
        const portal = Ember.get(this, 'portal');
        const portal_id = Ember.get(portal, 'id');
        const data = store.query('domain', {
          portal_id,
          ...(0, _consts.defaultPaginationSettings)()
        }).then(() => {
          Ember.set(this, 'domains', data);
          Ember.get(this, 'notify').success(i18n.t('portals.portal.topNav.updateStats.success'));
        });
      }

    },

    /**
     * @method setupNewDomain
     * @returns {Models.Domain} created Domain
     */
    setupNewDomain() {
      return Ember.get(this, 'store').createRecord('domain', {
        portal: Ember.get(this, 'portal')
      });
    },

    refreshDomains() {
      const refreshInterval = window.setInterval(() => {
        const route = Ember.get(this, 'router');
        const path = Ember.get(route, 'currentRouteName');

        if (path.includes('portal.domains.list')) {
          const store = Ember.get(this, 'store');
          const portal = Ember.get(this, 'portal');
          const portal_id = Ember.get(portal, 'id');
          let newPortal = store.findRecord('portal', portal_id);
          const data = store.query('domain', {
            portal_id,
            ...(0, _consts.defaultPaginationSettings)()
          });
          Ember.run.later(() => {
            Ember.set(this, 'domains', data);
          }, 500);
        } else {
          const interval = window.localStorage.getItem('domainRefreshInterval');

          if (Number(interval)) {
            window.clearInterval(Number(interval));
          }
        }
      }, 10000);
      window.localStorage.setItem('domainRefreshInterval', refreshInterval.toString());
    },

    settingDomains() {
      const store = Ember.get(this, 'store');
      const portal = Ember.get(this, 'portal');
      const portal_id = Ember.get(portal, 'id');
      const data = store.query('domain', {
        portal_id,
        ...(0, _consts.defaultPaginationSettings)()
      });
      Ember.set(this, 'domains', data);
    },

    clearCreateInterval() {
      const interval = window.localStorage.getItem('domainRefreshInterval');

      if (Number(interval)) {
        window.clearInterval(Number(interval));
      }

      this.refreshDomains();
    }

  });

  _exports.default = _default;
});