define("campaign-warrior-app/templates/components/modals/login-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k9N+UVgp",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[8,\"bs-modal\",[],[[\"@keyboard\",\"@backdropClose\",\"@size\",\"@open\",\"@onSubmit\"],[\"false\",\"false\",\"lg\",[34,0],[30,[36,1],[[32,0],\"confirm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Login Error\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"error-message\"],[12],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[[24,0,\"single-button\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"primary\",[30,[36,1],[[32,0],\"confirm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Close\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"modalIsOpened\",\"action\",\"message\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/login-error.hbs"
    }
  });

  _exports.default = _default;
});