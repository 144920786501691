define("campaign-warrior-app/helpers/multiply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multiply = multiply;
  _exports.default = void 0;

  function multiply(params) {
    return params.reduce((a, b) => {
      return a * b;
    });
  }

  ;

  var _default = Ember.Helper.helper(multiply);

  _exports.default = _default;
});