define("campaign-warrior-app/templates/components/limits-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zmg7zOc1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"limits-bar\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"limit\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"label\"],[12],[2,\"\\n      Tags:\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"value\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"limit\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"label\"],[12],[2,\"\\n      Domains:\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"value\"],[12],[2,\"\\n      \"],[1,[34,1]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"remainingTags\",\"remainingDomains\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/limits-bar.hbs"
    }
  });

  _exports.default = _default;
});