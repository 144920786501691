define("campaign-warrior-app/instance-initializers/reopen-router", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * @param {Ember.ApplicationInstance} appInstance current application instance
   */
  function initialize(appInstance) {
    appInstance.lookup('service:router').reopen({
      portalTraverse: (0, _emberLocalStorage.storageFor)('portals-traverse'),
      currentPortal: Ember.inject.service(),
      moving: Ember.observer('currentURL', function () {
        const currentPortal = Ember.get(this, 'currentPortal');
        const portal = Ember.get(currentPortal, 'portal');
        const currentURL = Ember.get(this, 'currentURL');

        if (currentURL.includes('portals/') && portal) {
          Ember.set(this, `portalTraverse.${Ember.get(portal, 'id')}`, currentURL);
        }
      })
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});