define("campaign-warrior-app/models/page", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 255
    })],
    meta: (0, _emberCpValidations.validator)('length', {
      max: 350
    }),
    url: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'url',
      allowBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 2048
    }), (0, _emberCpValidations.validator)('uniq-by', {
      modelName: 'page',
      parentName: 'portal'
    })]
  });
  /**
   * @class Page
   * @namespace Models
   * @extends DS.Model
   */

  var _default = _emberData.default.Model.extend(Validations, {
    title: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    url: _emberData.default.attr('string'),
    source: _emberData.default.attr('string', {
      defaultValue: 'Sitemap'
    }),
    meta: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    previewImage: _emberData.default.attr('string'),
    metaPicIsFeatured: _emberData.default.attr('boolean'),
    created: _emberData.default.attr('number', {
      defaultValue() {
        return new Date().getTime();
      }

    }),
    hsLastUpdated: _emberData.default.attr('number'),
    lastDataRefresh: _emberData.default.attr('number'),
    weight: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    aggregateTechRating: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    aggregateJourneyRating: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    aggregateDesignRating: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    aggregateSalesRating: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    overallRating: _emberData.default.attr('number', {
      defaultValue: 50
    }),
    funnelStage: _emberData.default.attr('string', {
      defaultValue: 'Unknown'
    }),
    hsPageType: _emberData.default.attr('string'),
    relatedContentSettings: _emberData.default.attr(),
    viewsCount: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    clicksCount: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    hidden: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    hiddenFromRelated: _emberData.default.attr('boolean'),
    analysisWhatDoYouLike: _emberData.default.attr('string'),
    analysisWhatDontYouLike: _emberData.default.attr('string'),
    analysisWhatWouldYouChange: _emberData.default.attr('string'),
    analysisUpdatedTextForCopyImprovement: _emberData.default.attr('string'),
    portal: _emberData.default.belongsTo('portal'),
    favorite: _emberData.default.belongsTo('favorite'),
    tags: _emberData.default.hasMany('tag'),
    notes: _emberData.default.hasMany('note'),
    snippets: _emberData.default.hasMany('snippet'),
    domain: _emberData.default.belongsTo('domain'),

    /**
     * @property isFavorite
     * @type boolean
     * @default false
     */
    isFavorite: Ember.computed.bool('favorite.id'),

    /**
     * @property metaSize
     * @type number
     */
    metaSize: Ember.computed.reads('meta.length')
  });

  _exports.default = _default;
});