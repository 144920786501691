define("campaign-warrior-app/templates/portals/portal/setup/tracking-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/jRyWtWi",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[30,[36,0],null,[[\"record\"],[[32,1]]]]],[2,\"\\n\\n\\n\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"setup/setup-install-tracking-code-panel\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/setup/tracking-code.hbs"
    }
  });

  _exports.default = _default;
});