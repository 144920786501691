define("campaign-warrior-app/components/user-journey", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    classNames: ['home-tiles'],
    slides: Ember.computed(function () {
      return [];
    })
  });

  _exports.default = _default;
});