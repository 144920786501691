define("campaign-warrior-app/components/tags/add-many-tags-form/confirmation-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```js
   * import Component from '@ember/component';
   * import {get} from '@ember/object';
   * import {inject as service} from '@ember/service';
   *
   * export default Component.extend({
   *   modalsManager: service(),
   *
   *   actions: {
   *     showModal() {
   *       get(this, 'modalsManager')
   *         .show('tags/add-many-tags-form/confirmation-modal', {
   *           newTags: get(this, 'newTags'),
   *           existingTagNames: get(this, 'existingTagNames')
   *         })
   *         .then(tags => console.log(tags));
   *     }
   *   }
   * });
   * ```
   *
   * Here `newTags` is an array of Tags that user want to add and `existingTagNames` is a list of already registered Tags.
   * Last one may be get by `store.query('tag', {portal_id: '1'}).then(tags=> tags.mapBy('name'))`
   *
   * @class AddManyTagsConfirmationModal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    /**
     * @property tagsToAdd
     * @type object[]
     * @default null
     * @private
     */
    tagsToAdd: null,

    /**
     * @property data
     * @type object[]
     * @private
     * @readOnly
     */
    data: Ember.computed('options.newTags.[]', function () {
      return Ember.get(this, 'options.newTags').map(tag => ({
        tag
      }));
    }).readOnly(),

    /**
     * @property preselectedItems
     * @type object[]
     * @private
     * @readonly
     */
    preselectedItems: Ember.computed('{options.existingTagNames,data}.[]', function () {
      const existingTagNames = Ember.get(this, 'options.existingTagNames');
      return Ember.get(this, 'data').filter(item => !existingTagNames.includes(item.tag));
    }).readOnly(),

    /**
     * @property columns
     * @type object[]
     * @private
     * @readOnly
     */
    columns: Ember.computed(function () {
      return [{
        propertyName: 'tag'
      }];
    }).readOnly(),
    actions: {
      onRowClick(data) {
        Ember.set(this, 'tagsToAdd', data.selectedItems);
      },

      confirm() {
        let tagsToAdd = Ember.get(this, 'tagsToAdd');

        if (!tagsToAdd) {
          tagsToAdd = Ember.get(this, 'preselectedItems');
        }

        return this._super(tagsToAdd);
      }

    }
  });

  _exports.default = _default;
});