define("campaign-warrior-app/routes/settings/users/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class UserSettings
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    model({
      user_id
    }) {
      const store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        portals: store.findAll('portal'),
        user: store.findRecord('user', user_id)
      });
    },

    afterModel(model) {
      if (!model.user) {
        return Ember.get(this, 'router').transitionTo('settings.users');
      }
    },

    setupController(controller, model) {
      Ember.setProperties(controller, {
        model: Ember.get(model, 'user'),
        portals: Ember.get(model, 'portals')
      });
    },

    actions: {
      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});