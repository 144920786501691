define("campaign-warrior-app/templates/settings/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "emVTzuvB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"internal-content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-controls\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"Users\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"control-group\"],[12],[2,\"\\n\"],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,3],[[35,0,[\"user\"]]],null]],[2,\"\\n\"],[6,[37,4],null,[[\"expandable\",\"class\",\"title\"],[false,\"branding\",\"Company Users\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"columns\",\"data\",\"showColumnsDropdown\",\"showGlobalFilter\"],[[35,1],[35,0,[\"user\",\"invitedCompanyUsers\"]],false,false]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],null,[[\"expandable\",\"class\",\"title\"],[false,\"branding\",\"Outstanding Invites\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"columns\",\"data\",\"showColumnsDropdown\",\"showGlobalFilter\"],[[35,1],[35,0,[\"user\",\"invitedUsers\"]],false,false]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentUser\",\"userColumns\",\"models-table\",\"log\",\"cw-panel\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/settings/users/index.hbs"
    }
  });

  _exports.default = _default;
});