define("campaign-warrior-app/templates/portals/portal/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1VnPIJQF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"internal-content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-lg-6\"],[12],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-controls\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"Live Stats\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"control-group\"],[12],[2,\"\\n\\n      \"],[6,[37,9],null,[[\"class\",\"route\"],[\"btn btn-submenu\",\"portals.portal.accounts\"]],[[\"default\"],[{\"statements\":[[2,\"Add Accounts\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n\"],[6,[37,10],null,null,[[\"default\"],[{\"statements\":[[6,[37,8],null,null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,7],null,[[\"class\",\"name\",\"title\",\"bodyComponent\"],[\"branding\",\"dashboard-pages\",[30,[36,6],[\"portals.portal.nav.titles.webpages\"],null],[30,[36,2],[\"models-table-server-paginated\"],[[\"class\",\"data\",\"columns\",\"selectRowOnClick\",\"showColumnsDropdown\",\"showGlobalFilter\",\"columnComponents\"],[\"pages-table\",[35,5],[35,4],false,false,false,[30,[36,3],null,[[\"delete-record\"],[[30,[36,2],[\"cw-table/delete-record\"],[[\"forceDisabled\",\"btnClass\",\"btnIcon\",\"btnText\",\"deleteRecord\"],[[30,[36,1],[\"delete page\"],null],\"btn-xs btn-link pull-right\",\"fa-remove text-danger\",\"\",[30,[36,0],[[32,0],\"deletePage\"],null]]]]]]]]]]]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,11],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"action\",\"cannot\",\"component\",\"hash\",\"pagesColumns\",\"pages\",\"t\",\"cw-panel\",\"ember-dragula-container\",\"link-to\",\"ember-dragula\",\"-outlet\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/stats.hbs"
    }
  });

  _exports.default = _default;
});