define("campaign-warrior-app/components/users/users-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    ajax: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    classNames: ['users-list'],

    /**
     * @property pages
     * @type Models.Page[]
     * @default []
     */
    users: Ember.computed(function () {
      return [];
    }),

    /**
     * @property columns
     * @type object[]
     * @private
     * @readonly
     */
    columns: Ember.computed(function () {
      const i18n = Ember.get(this, 'i18n');
      return [{
        className: 'user-first-name',
        propertyName: 'firstName',
        title: i18n.t('users.list.firstName'),
        useFilter: false
      }, {
        className: 'user-last-name',
        propertyName: 'lastName',
        title: i18n.t('users.list.lastName'),
        useFilter: false
      }, {
        className: 'user-email',
        propertyName: 'email',
        title: i18n.t('users.list.email'),
        useFilter: false //component: 'cw-table/link-to',
        //route: 'portals.portal.users.user.manage',

      }, {
        className: 'user-last-name',
        propertyName: 'status',
        component: 'users/user-status',
        title: 'Status',
        useFilter: false
      },
      /*  {
          className: 'role',
          propertyName: 'role',
          component: 'users/user-role',
          title: i18n.t('users.list.role'),
          useFilter: false,
        },*/
      {
        className: 'action-menu',
        component: 'users/users-action-menu',
        refreshList: this.refreshList
      }];
    }).readOnly(),
    actions: {
      /**
       * @method actions.deletePost
       * @param {Models.Post} post post to delete
       * @return {RSVP.Promise} delete result or nothing
       */

      /*
      deletePost (post) {
        const i18n = get(this, 'i18n');
        const modalsManager = get(this, 'modalsManager');
        return modalsManager
          .confirm({
            title: i18n.t('modals.defaultConfirmOptions.title'),
            body: i18n.t('modals.defaultConfirmOptions.body'),
            confirm: i18n.t('modals.defaultConfirmOptions.confirm')
          })
          .then(() => post.destroyRecord())
            .catch(e => modalsManager.detailedAjaxErrorHandler(e));
      },*/

      /**
       * @method actions.copyIconClicked
       * @param {Models.Post} record post to copy
       */

      /*
      copyIconClicked (record) {
        const copyIconClicked = get(this, 'copyIconClicked');
        if (copyIconClicked) {
          copyIconClicked(record);
        }
      },*/
      deleteUser(user) {
        const modalsManager = Ember.get(this, 'modalsManager');
        const i18n = Ember.get(this, 'i18n');
        return modalsManager.confirm({
          title: i18n.t('modals.defaultConfirmOptions.title'),
          body: i18n.t('modals.defaultConfirmOptions.body'),
          confirm: i18n.t('modals.defaultConfirmOptions.confirm')
        }).then(() => {
          const data = {
            userId: Ember.get(this, 'currentUser.user.id'),
            userToRemove: user.id,
            portalId: Ember.get(this, 'currentPortal.portal.id'),
            email: user.email
          }; //console.log('User to delete: ', user);

          return Ember.get(this, 'ajax').post('/removeInvitedUser', {
            data
          }).then(r => {
            if (r.status === 'success') {
              modalsManager.alert({
                title: 'Removed',
                confirm: 'Close',
                body: r.message
              }).then(() => Ember.get(this, 'refreshingRoute')());
            }
          }).catch(e => {
            const data = {
              title: 'Error',
              body: 'You do not have permission to delete this user'
            };
            return modalsManager.errorHandler(data); //modalsManager.detailedAjaxErrorHandler(e)
          });
        } // user.destroyRecord()
        // .catch(e => modalsManager.detailedAjaxErrorHandler(e))
        );
      }

    }
  });

  _exports.default = _default;
});