define("campaign-warrior-app/components/select-portal-form-internal", ["exports", "campaign-warrior-app/components/select-portal-form"], function (_exports, _selectPortalForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * "Select-Portal" form used in the portal-details pages
   *
   * If current portal is provided it'll be marked as active
   *
   * Usage
   *
   * ```hbs
   * {{select-portal-form-internal currentPortal=currentPortal portals=portals filteringIgnoreCase=false}}
   * ```
   *
   * @namespace Components
   * @class SelectPortalFormInternal
   * @extends Components.SelectPortalForm
   */
  var _default = _selectPortalForm.default.extend({
    classNames: ['clearfix', 'select-portal-form-internal'],

    /**
     * Current portal. It used to mark active portal in the list
     *
     * @type object
     * @default null
     * @property currentPortal
     */
    currentPortal: null
  });

  _exports.default = _default;
});