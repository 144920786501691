define("campaign-warrior-app/components/login-form", ["exports", "campaign-warrior-app/components/floating-form", "ember-cp-validations"], function (_exports, _floatingForm, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage
   *
   * ```hbs
   * {{login-form
   *   onSubmit=(action "loginHandler")
   *   fbLogin=(action "fbLoginHandler")
   *   twitterLogin=(action "twitterLoginHandler")
   *   linkedinLogin=(action "linkedinLoginHandler")
   *   googlePlusLogin=(action "googlePlusLoginHandler")
   * }}
   * ```
   *
   * @namespace Components
   * @class LoginForm
   * @extends Components.FloatingForm
   */
  var _default = _floatingForm.default.extend({
    classNames: ['login-form'],

    /**
     * @event fbLogin
     */
    fbLogin: null,

    /**
     * @event twitterLogin
     */
    twitterLogin: null,

    /**
     * @event linkedinLogin
     */
    linkedinLogin: null,

    /**
     * @event googlePlusLogin
     */
    googlePlusLogin: null,
    submitInProgress: false,

    /**
     * @property formData
     * @type object
     * @private
     */
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        username: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
          allowBlank: true,
          type: 'email'
        })],
        password: [(0, _emberCpValidations.validator)('presence', true)]
      });
      return Ember.Object.extend(Validations, {}).create(Ember.getOwner(this).ownerInjection());
    }),
    actions: {
      /**
       * @method actions.onSubmit
       * @return {RSVP.Promise} auth result
       */
      onSubmit() {
        let _this = this;

        Ember.set(this, 'submitInProgress', true);
        return this.authWrapper(() => Ember.get(this, 'onSubmit')(Ember.get(this, 'formData')).then(function () {
          Ember.set(_this, 'submitInProgress', false);
        }));
      },

      /**
       * @method actions.authenticateWithFacebook
       * @return {RSVP.Promise} auth result
       */
      authenticateWithFacebook() {
        return this.authWrapper(() => this.fbLogin());
      },

      /**
       * @method actions.authenticateWithGoogle
       * @return {RSVP.Promise} auth result
       */
      authenticateWithGoogle() {
        return this.authWrapper(() => this.googleLogin());
      }

    },

    /**
     * @method authWrapper
     * @param {Function} clb login function itself
     * @private
     */
    authWrapper(clb) {
      clb();
    }

  });

  _exports.default = _default;
});