define("campaign-warrior-app/templates/components/locked-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AhSIs9cV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"locked-feature-inner\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"icon-wrapper\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fad fa-lock-alt\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content-wrapper\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[34,0]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"desc\"],[12],[2,\"\\n      \"],[1,[34,1]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"controls\"],[12],[2,\"\\n      \"],[6,[37,2],null,[[\"class\",\"route\"],[\"btn btn-primary\",\"billing\"]],[[\"default\"],[{\"statements\":[[2,\"Upgrade Now\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sales\"],[12],[2,\"\\n      Or talk to our \"],[10,\"a\"],[14,6,\"https://www.campaignwarrior.com/sales\"],[14,\"target\",\"_blank\"],[12],[2,\"sales team\"],[13],[2,\" to get your\\n      questions answered right now\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"heading\",\"description\",\"link-to\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/locked-feature.hbs"
    }
  });

  _exports.default = _default;
});