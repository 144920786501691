define("campaign-warrior-app/components/pages/page-edit-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PageEditNotes = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class PageEditNotes extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "currentPortal", _descriptor2, this);

      _defineProperty(this, "newNoteText", '');

      _defineProperty(this, "isEdit", false);

      _defineProperty(this, "noteToEdit", null);

      _defineProperty(this, "creatingNote", false);
    }

    async createNote() {
      if (this.isEdit) {
        Ember.set(this.noteToEdit, 'description', this.noteText);
        await this.noteToEdit.save();
        Ember.set(this, 'isEdit', false);
        Ember.set(this, 'noteToEdit', null);
      } else {
        let notePayload = {
          page: this.page,
          portal: this.currentPortal.portal,
          description: this.noteText
        };
        let newNote = this.store.createRecord('note', notePayload);
        await newNote.save();
        Ember.set(this, 'noteText', '');
      }
    }

    noteChanged(change) {
      Ember.set(this, 'newNoteText', change);
    }

    editNote(note) {
      console.log("Edit note", note);
      /*set(this, 'noteToEdit', note);
      set(this, 'noteText', note.description);
      set(this, 'isEdit', true);*/

      note.editing = true;
    }

    deleteNote(note) {
      console.log("Delete note", note);
      note.destroyRecord();
    }

    saveChanges(note) {
      note.editing = false;
      note.save();
    }

    triggerCreateNote() {
      Ember.set(this, 'creatingNote', true);
    }

    async createNewNote() {
      console.log("Create new note", this.newNoteText);
      let notePayload = {
        page: this.page,
        portal: this.currentPortal.portal,
        description: this.newNoteText
      };
      let newNote = this.store.createRecord('note', notePayload);
      await newNote.save();
      Ember.set(this, 'newNoteText', '');
      Ember.set(this, 'creatingNote', false);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createNote", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "createNote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noteChanged", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "noteChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editNote", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "editNote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteNote", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "deleteNote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveChanges", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "saveChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "triggerCreateNote", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "triggerCreateNote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createNewNote", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "createNewNote"), _class.prototype)), _class));
  _exports.default = PageEditNotes;
});