define("campaign-warrior-app/components/portal-styling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Style overrides for current portal. Style settings are stored in the `styleSettings`-property for each portal.
   *
   * Style-block is created here and inserted to the page as `style`-tag
   *
   * Usage:
   *
   * ```hbs
   * {{portal-styling}}
   * ```
   *
   * @class PortalStyling
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),
    tagName: 'style',

    /**
     * @property portal
     * @type Models.Portal
     * @default null
     */
    portal: Ember.computed.reads('currentPortal.portal'),

    /**
     * @property menuBarStyle
     * @type string
     * @private
     * @readonly
     */
    menuBarStyle: Ember.computed('portal.{styleSettings,hasDirtyAttributes}', function () {
      const id = Ember.get(this, 'portal.id');
      const styleSettings = Ember.get(this, 'portal.styleSettings');

      if (!styleSettings) {
        return '';
      }

      const menuBarBackground = Ember.get(styleSettings, 'menuBarBackground');
      const menuDropBackground = Ember.get(styleSettings, 'menuDropBackground');
      const menuHighlight = Ember.get(styleSettings, 'menuHighlight');
      const menuFont = Ember.get(styleSettings, 'menuFont');
      const baseSelector = `.portal-${id} header .navbar`;
      const navBarNav = `${baseSelector} .navbar-nav`; // Color for `header .navbar .navbar-nav a` states

      const fontASelector = ['', ':hover', ':focus', ':active', '.active'].map(suffix => `${navBarNav} a${suffix}`).join(',\n'); // Background-color for

      const backgroundMenuSelector = [// `header .navbar .navbar-nav > li > a.active` states
      ['', ':hover', ':focus'].map(suffix => `${navBarNav} > li > a.active${suffix}`).join(',\n'), // `header .navbar .navbar-nav` active item and dropdown-menu
      ['.open > a', '.dropdown-menu'].map(suffix => `${navBarNav} ${suffix}`).join(',\n')].join(',\n'); // Background-color for

      const activeItemsSelector = [// `header .navbar .navbar-nav .dropdown-menu li a` states
      ['.active', ':active', ':focus', ':hover'].map(suffix => `${navBarNav} .dropdown-menu li a${suffix}`).join(',\n'), // `header .navbar .navbar-nav .navbar-toggle` states
      [':active', ':focus', ':hover'].map(suffix => `${navBarNav} .navbar-toggle${suffix}`).join(',\n')].join(',\n');
      return '';
      return Ember.String.htmlSafe(`
      ${baseSelector} {
        background: ${menuBarBackground};
      }
      ${fontASelector} {
        color: ${menuFont} !important;
      }
      ${backgroundMenuSelector} {
        background: ${menuDropBackground} !important;
      }
      ${activeItemsSelector} {
        background: ${menuHighlight} !important;
      }`);
    }),

    /**
     * @property panelStyle
     * @type string
     * @private
     * @readonly
     */
    panelStyle: Ember.computed('portal.{styleSettings,hasDirtyAttributes}', function () {
      const id = Ember.get(this, 'portal.id');
      const styleSettings = Ember.get(this, 'portal.styleSettings');

      if (!styleSettings) {
        return '';
      }

      const menuBarBackground = Ember.get(styleSettings, 'menuBarBackground');
      const menuFont = Ember.get(styleSettings, 'menuFont');
      const selector = `.portal-${id} .cw-panel.branding>.panel`; //return htmlSafe(`${selector} {border-top-color: ${menuBarBackground};}\n
      //  ${selector} a {color: ${menuFont} !important;}`);

      return Ember.String.htmlSafe(`${selector} {border-top-color: ${menuBarBackground};}`);
    })
  });

  _exports.default = _default;
});