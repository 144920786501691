define("campaign-warrior-app/templates/components/mentions/create-mention-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "91XNlQYU",
    "block": "{\"symbols\":[\"form\",\"el\",\"platform\",\"&default\"],\"statements\":[[6,[37,8],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,7],true,[30,[36,1],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],[[30,[36,5],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/mentions/create-mention-form.hbs' @ L2:C4) \"],null]],[[\"controlType\",\"label\",\"property\"],[\"input\",[30,[36,4],[\"mentionables.create.alias\"],null],\"alias\"]]]],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/mentions/create-mention-form.hbs' @ L8:C5) \"],null]],[[\"class\",\"property\",\"label\"],[\"select-mention-platform\",\"platform\",[30,[36,4],[\"mentionables.create.platform\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"id\",\"options\",\"onChange\",\"selected\"],[[32,2,[\"id\"]],[35,2],[30,[36,1],[[32,0],\"setPlatform\"],null],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,3,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[2]}]]],[2,\"\\n  \"],[1,[30,[36,6],[[30,[36,5],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/mentions/create-mention-form.hbs' @ L21:C4) \"],null]],[[\"controlType\",\"label\",\"property\",\"rows\"],[\"textarea\",[30,[36,4],[\"mentionables.create.note\"],null],\"note\",2]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,4,null]],\"hasEval\":false,\"upvars\":[\"platform\",\"action\",\"platformOptions\",\"power-select\",\"t\",\"-assert-implicit-component-helper-argument\",\"component\",\"record\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/mentions/create-mention-form.hbs"
    }
  });

  _exports.default = _default;
});