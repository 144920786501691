define("campaign-warrior-app/components/show-portals-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShowPortalsDetails = (_dec = Ember.inject.service, _dec2 = Ember.computed('portals'), _dec3 = Ember.computed('portals'), (_class = (_temp = class ShowPortalsDetails extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);
    }

    get columns() {
      return [{
        title: 'Portal',
        propertyName: 'portalName',
        className: 'portal-cell'
      }, {
        title: 'Subscription plan',
        propertyName: 'subscription',
        className: 'subscription-cell'
      },
      /*{
        title: 'Additionals',
        className: 'additional-cell',
        propertyName: 'additional',
      },*/
      {
        title: 'Total',
        propertyName: 'total'
      }];
    }

    get portalDetails() {
      const portalsData = [];
      const portals = Ember.get(this, 'portals');
      portals.forEach(portal => {
        const data = {
          portalName: portal.name
        };
        let extraBlocks = portal.chargeableBlocks || 0; //console.log("portal subscription", get(portal, 'portalSubscription'));

        const total = 19 + extraBlocks * 5;
        data.extraBlocks = extraBlocks || 0;
        data.additionalImpressions = extraBlocks * 1000;
        data.additionalPages = extraBlocks * 250;
        data.total = total.toFixed(2);
        data.company = portal.companyId;
        portalsData.push(data);
      });
      return portalsData;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "columns", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "portalDetails", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "portalDetails"), _class.prototype)), _class));
  _exports.default = ShowPortalsDetails;
});