define("campaign-warrior-app/services/telemetry-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    socketIOService: Ember.inject.service('socket-io'),
    socket: Ember.computed(function () {
      return this.socketIOService.socketFor('https://cloud.campaignwarrior.com');
    }),
    portal: Ember.computed.reads('currentPortal.portal'),
    clientVersion: Object.freeze('0.9a'),

    pageNotFound() {
      /*get(this, 'socket').emit('telemetry.client.errors.pageNotFound', {
        clientVersion: get(this,'clientVersion'),
        portal: get(this, 'portal.id'),
        url: window.location.href,
        user: get(this, 'currentUser.user.id')
      });*/
    },

    ajaxError(status, error) {
      /*get(this, 'socket').emit('telemetry.client.errors.ajaxError', {
        clientVersion: get(this, 'clientVersion'),
        portal: get(this, 'portal.id'),
        error,
        user: get(this, 'currentUser.user.id')
      })*/
    }

  });

  _exports.default = _default;
});