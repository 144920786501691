define("campaign-warrior-app/controllers/portals/portal/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PortalIndex
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    actions: {
      openEditPortalModal() {
        const topNav = Ember.getOwner(this).lookup('component:top-nav');

        if (topNav) {
          topNav.send('editPortal');
        }
      },

      goToTutorial() {
        window.scrollTo(0, document.querySelector('.tutorial').offsetTop);
      }

    }
  });

  _exports.default = _default;
});