define("campaign-warrior-app/controllers/portals/portal/tags", ["exports", "campaign-warrior-app/models/tag"], function (_exports, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Tags
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property store
     * @type Ember.Service
     */
    store: Ember.inject.service(),
    can: Ember.inject.service(),
    ajax: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),

    /**
     * @property model
     * @type Models.Tag[]
     * @default null
     */
    model: null,

    /**
     * @property latestTags
     * @type Models.Tag[]
     * @default null
     */
    latestTags: null,

    /**
     * @property popularTags
     * @type Models.Tag[]
     * @default null
     */
    popularTags: null,

    /**
     * @property newTag
     * @type Models.Tag
     * @default null
     */
    newTag: null,

    /**
     * @property tagToEdit
     * @type Models.Tag
     * @default null
     */
    tagToEdit: null,

    /**
     * @type object[]
     * @default null
     * @property columnsForAll
     */
    columnsForAll: null,

    /**
     * @type object[]
     * @default null
     * @property columnsForRecent
     */
    columnsForRecent: null,

    /**
     * @type object[]
     * @default null
     * @property columnsForPopular
     */
    columnsForPopular: null,

    init() {
      this._super(...arguments);

      this._initColumnsForTables();
    },

    /**
     * @property portal
     * @type Models.Portal
     */
    portal: Ember.computed.reads('currentPortal.portal'),
    hashtags: Ember.computed(function () {
      return [];
    }),
    snippets: Ember.computed(function () {
      return [];
    }),
    actions: {
      /**
       * Save new Tag to the BE and set `newTag` to new placeholder-value
       *
       * @method actions.save
       * @param {Models.Tag} tag data about new Tag
       * @return {Promise} promise is resolved after save-request is completed
       */
      save(tag) {
        Ember.set(tag, 'createDate', new Date().getTime());
        return tag.save().then(() => this.send('refreshRoute')).catch(e => Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e));
      },

      /**
       * Delete tag from the BE
       *
       * @method actions.deleteTag
       * @param {Models.Tag} tag tag to delete
       */
      deleteTag(tag) {
        const i18n = Ember.get(this, 'i18n');
        Ember.get(this, 'modalsManager').confirm({
          title: i18n.t('modals.defaultConfirmOptions.title'),
          body: i18n.t('modals.defaultConfirmOptions.body')
        }).then(() => tag.destroyRecord()).then(() => this.send('refreshRoute'));
      },

      /**
       * Edit tag
       *
       * @method actions.editTag
       * @param {Models.Tag} tag tag to edit
       */
      editTag(tag) {
        const modalsManager = Ember.get(this, 'modalsManager');
        modalsManager.show('tags/manage-tag-modal', {
          tag
        }).then(() => {
          tag.save();
        }).catch(e => {
          tag.rollbackAttributes();
          return modalsManager.detailedAjaxErrorHandler(e);
        });
      },

      createTag() {
        const portal = Ember.get(this, 'portal');
        const store = Ember.get(this, 'store');
        const modalsManager = Ember.get(this, 'modalsManager');

        const _this = this;

        modalsManager.show('tags/create-tag-modal').then(res => {
          let tagName = res.name;
          const newTag = store.createRecord('tag', {
            name: tagName,
            description: res.description,
            color: _tag.default.getRandColor(),
            portal
          });
          newTag.save().then(() => {
            _this.send('refreshRoute');
          });
        });
      },

      /**
       * @method actions.createTags
       * @param {{tag: string}[]} tags names for new tags
       * @returns {RSVP.Promise} saving result
       */
      createTags(tags) {
        const portal = Ember.get(this, 'portal');
        const store = Ember.get(this, 'store');
        const i18n = Ember.get(this, 'i18n');
        const modalsManager = Ember.get(this, 'modalsManager');
        const errors = [];
        const promises = tags.map(tag => {
          const newTag = store.createRecord('tag', {
            name: tag.tag,
            color: _tag.default.getRandColor(),
            portal
          });
          return () => newTag.save().catch(error => {
            errors.push({
              error,
              tag: tag.tag
            });
            newTag.destroyRecord();
          });
        });
        return modalsManager.progress({
          title: '',
          body: '',
          bodyComponent: 'tags/add-many-tags-form/progress-body',
          tags,
          striped: true,
          animate: true,
          promises
        }).then(() => {
          if (errors.length) {
            const firstError = errors[0];
            return modalsManager.alert({
              title: i18n.t('modals.defaultAlertOptions.title'),
              body: firstError.error.payload.errors[0].details,
              confirm: i18n.t('modals.defaultAlertOptions.confirm')
            });
          }

          this.send('refreshRoute');
        });
      },

      createHashtag() {
        const portal = Ember.get(this, 'portal');
        const newHashtag = Ember.get(this, 'store').createRecord('hashtag', {
          name: '',
          portal,
          type: 'hash'
        });
        const modalsManager = Ember.get(this, 'modalsManager');
        modalsManager.show('hashtags/create-hashtag-modal', {
          hashtag: newHashtag
        }).then(() => newHashtag.save().then(() => {
          const portal = Ember.get(this, 'portal');
          const portalId = Ember.get(portal, 'id');
          const store = Ember.get(this, 'store');
          const hashtags = store.query('hashtag', {
            portal_id: portalId
          });
          Ember.set(this, 'hashtags', hashtags);
        })).catch(e => {
          newHashtag.destroyRecord();
          return modalsManager.detailedAjaxErrorHandler(e);
        });
      },

      createSnippet() {
        const portal = Ember.get(this, 'portal');
        const newSnippet = Ember.get(this, 'store').createRecord('snippet', {
          message: '',
          portal
        });
        const modalsManager = Ember.get(this, 'modalsManager');
        modalsManager.show('text-snippets/create-snippet-modal', {
          snippet: newSnippet
        }).then(() => {
          newSnippet.save().then(() => {
            const portal = Ember.get(this, 'portal');
            const portalId = Ember.get(portal, 'id');
            const store = Ember.get(this, 'store');
            store.query('snippet', {
              portal_id: portalId
            }).then(snippets => {
              Ember.set(this, 'snippets', snippets);
            });
          });
        }).catch(e => {
          newSnippet.destroyRecord();
          return modalsManager.detailedAjaxErrorHandler(e);
        });
      }

    },

    /**
     * @method setupNewTag
     * @returns {DS.Model} created Tag
     */
    setupNewTag() {
      return Ember.get(this, 'store').createRecord('tag', {
        portal: Ember.get(this, 'portal'),
        color: _tag.default.getRandColor()
      });
    },

    /**
     * @private
     */
    _initColumnsForTables() {
      const i18n = Ember.get(this, 'i18n');
      const tagTitle = i18n.t('tags.tag');
      const tagComponentColumn = {
        component: 'cw-tag',
        title: tagTitle
      };
      const deleteTagColumn = {
        className: 'delete',
        component: 'delete-record',
        title: i18n.t('tags.all.table.titles.delete')
      };
      const editTagColumn = {
        className: 'edit',
        component: 'edit-record',
        title: i18n.t('tags.all.table.titles.edit')
      };
      Ember.set(this, 'columnsForAll', [tagComponentColumn, editTagColumn, deleteTagColumn]);
      Ember.set(this, 'columnsForRecent', [tagComponentColumn, {
        component: 'cw-table/moment-date',
        title: i18n.t('tags.recent.createdAt'),
        propertyName: 'createDate',
        disableSorting: true
      }, editTagColumn, deleteTagColumn]);
      Ember.set(this, 'columnsForPopular', [tagComponentColumn, {
        title: i18n.t('tags.popular.usageCount'),
        propertyName: 'usageCount',
        disableSorting: true
      }, editTagColumn, deleteTagColumn]);
    }

  });

  _exports.default = _default;
});