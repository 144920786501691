define("campaign-warrior-app/torii-providers/google", ["exports", "torii/providers/google-oauth2"], function (_exports, _googleOauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Override original Google OAuth2 provider from [Torii](https://github.com/Vestorly/torii)
   *
   * @class GoogleOauth2
   * @namespace ToriiProviders
   * @extends Torii.Providers.GoogleOauth2
   */
  var _default = _googleOauth.default.extend({
    optionalUrlParams: Object.freeze(['include_granted_scopes']),
    includeGrantedScopes: true,
    redirectUri: Ember.computed(function () {
      const {
        protocol,
        host
      } = window.location;
      return [protocol, '//', host, '/torii/redirect.html'].join('');
    })
  });

  _exports.default = _default;
});