define("campaign-warrior-app/controllers/portals/create/step3", ["exports", "campaign-warrior-app/controllers/portals/create/step", "campaign-warrior-app/macros/get-props-from"], function (_exports, _step, _getPropsFrom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = ['trackingCode', 'activePortal'];
  /**
   * First step for Create-a-Portal wizard. Here user provides a name and description for the new portal
   *
   * @namespace Controllers.CreatePortal
   * @class Step1
   * @extends Controllers.CreatePortal.WizardStep
   */

  var _default = _step.default.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property notify
     * @type Ember.Service
     */
    notify: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    activePortal: null,
    createdDomain: null,
    gotFocus: false,

    /**
     * Properties `slug` and `description` are taken from the LS
     *
     * @property initialData
     */
    initialData: (0, _getPropsFrom.default)('portal', fields),
    actions: {
      finish() {
        const portal = Ember.get(this, 'initialData.activePortal');
        const notify = Ember.get(this, 'notify');
        const i18n = Ember.get(this, 'i18n');
        const modalsManager = Ember.get(this, 'modalsManager');
        modalsManager.show('edit-portal-modal', {
          record: portal,
          backDrop: false
        }).then(() => portal.save().then(() => notify.success(i18n.t('portals.portal.edit.form.success'))).then(() => Ember.get(this, 'router').transitionTo('portals.portal', portal)).catch(e => modalsManager.detailedAjaxErrorHandler(e))).catch(e => {
          if (e === 'delete-portal') {
            return this.send('deletePortal');
          }

          portal.rollback();
          Ember.get(this, 'router').transitionTo('portals.portal', portal);
          return modalsManager.detailedAjaxErrorHandler(e);
        });
      },

      /**
       * @method actions.deletePortal
       * @returns {RSVP.Promise} request result
       */
      deletePortal() {
        const portal = Ember.get(this, 'initialData.activePortal');
        const modalsManager = Ember.get(this, 'modalsManager');
        const i18n = Ember.get(this, 'i18n');
        return modalsManager.promptConfirm({
          title: i18n.t('portals.portal.edit.delete.confirm.title'),
          bodyComponent: 'portals/delete-confirm/body',
          confirmButtonType: 'danger',
          confirm: i18n.t('portals.portal.edit.delete.confirm.confirm'),
          decline: i18n.t('portals.portal.edit.delete.confirm.decline'),
          promptValue: Ember.get(portal, 'name')
        }).then(() => {
          modalsManager.process({
            body: '',
            iconClass: 'text-center fal fa-spinner fa-spin fa-3x fa-fw',
            title: 'Deleting portal, please wait',
            process: () => new Promise(resolve => resolve(portal.destroyRecord()))
          }).then(() => Ember.get(this, 'router').transitionTo('portals')).then(() => Ember.get(this, 'currentUser').load()).catch(e => modalsManager.detailedAjaxErrorHandler(e));
        }).catch(() => portal.rollback());
      }

    },

    setFocus() {
      Ember.set(this, 'gotFocus', false);
      Ember.run.later(() => {
        Ember.set(this, 'gotFocus', true);
      }, 300);
    }

  });

  _exports.default = _default;
});