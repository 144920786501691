define("campaign-warrior-app/models/portal-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    domains: _emberData.default.hasMany('domain'),
    billingInfo: _emberData.default.belongsTo('billing-info'),
    portal: _emberData.default.belongsTo('portal')
  });

  _exports.default = _default;
});