define("campaign-warrior-app/validators/begin-date", ["exports", "ember-cp-validations/validators/base", "moment"], function (_exports, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Validator for date-property which must be before another date-property. Must be combined with [end-date](Validators.EndDate.html)
   *
   * It's a custom validator for [ember-cp-validations](https://github.com/offirgolan/ember-cp-validations)
   *
   * Usage:
   * ```js
   * buildValidations({
   *   start: validator('begin-date', {
   *     end: 'end' // date-property with end-date
   *   })
   * });
   * ```
   *
   * @class BeginDate
   * @extends BaseValidator
   * @namespace Validators
   */
  const BeginDate = _base.default.extend({
    i18n: Ember.inject.service(),

    validate(value, options, model, attribute) {
      const endAttr = options.end;
      let end = Ember.get(model, endAttr);

      if (!end) {
        return true;
      }

      let start = (0, _moment.default)(value).valueOf();
      end = (0, _moment.default)(end).valueOf();
      const descriptionKey = options.descriptionKey || 'errors.before';
      return end > start ? true : Ember.get(this, 'i18n').t(descriptionKey, {
        description: attribute,
        before: (0, _moment.default)(end).format('YYYY-MM-DD')
      }).toString();
    }

  });

  BeginDate.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     *  `model.array.@each.${attribute}` --> Dependent is created on the model's context
     *  `${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array} deps
     */
    getDependentsFor(attribute, options) {
      return [options.end];
    }

  });
  var _default = BeginDate;
  _exports.default = _default;
});