define("campaign-warrior-app/components/integrations-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    torii: Ember.inject.service(),
    ajax: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    classNames: ['integrations-list'],
    integrations: null,
    connectHubspot: null,
    connectGa: null,
    gaAccounts: null,
    portal: Ember.computed.reads('currentPortal.portal'),
    hubspot: Ember.computed('integrations.@each.type', function () {
      return Ember.get(this, 'integrations').filter(s => Ember.get(s, 'type') === 'hubspot')[0];
    }),
    googleAnalytics: Ember.computed('integrations.@each.type', function () {
      return Ember.get(this, 'integrations').filter(s => Ember.get(s, 'type') === 'googleAnalytics')[0];
    }),
    actions: {
      connectHubspot() {
        const i18n = Ember.get(this, 'i18n');
        const ajax = Ember.get(this, 'ajax');

        const _this = this;

        const portalId = Ember.get(this, 'currentPortal.portal.id');
        const integrationId = Ember.get(this, 'hubspot.id'); //console.log('Hubspot Integration Id', integrationId);

        if (Ember.get(this, 'currentPortal.portal.company.featureLevel') === 'free') {
          return Ember.get(this, 'modalsManager').show('trial-modal', {
            freeAccount: true
          }).then(() => {
            Ember.get(this, 'router').transitionTo('billing');
          }).catch(() => {
            console.log('Going back');
          });
        }

        Ember.get(this, 'torii').open('hubspot').then(function (data) {
          ajax.post('/hubspot/authorize', {
            data: {
              portalId,
              data,
              integrationId
            }
          }).then(function (socials) {
            //console.log('Hubspot Socials', socials);
            Ember.get(_this, 'refreshThisRoute')(); // set(_this, 'hsSocials', socials.data);
            //_this.send('openHsSocialsModal');
          });
        }).catch(err => Ember.get(this, 'modalsManager').alert({
          title: i18n.t('publishing.sources.cantLoadSources.title'),
          body: err,
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }).then(() => Ember.get(_this, 'refreshThisRoute')()));
      },

      openHsSocialsModal() {
        console.log('Hubspot sources', Ember.get(this, 'hsSocials'));
        /*get(this, 'modalsManager')
          .show('select-publish-sources-modal', {
            type: 'hubspot',
            newSources: get(this, 'hsSocials'),
            existingSources: get(this, 'model.publishingSources'),
            columns: get(this, 'hsColumns')
          })
          .then(sources => this.saveSelectedHsSources(sources));*/
      },

      connectGoogleAnalytics() {
        const i18n = Ember.get(this, 'i18n');
        const ajax = Ember.get(this, 'ajax');

        const _this = this;

        const portalId = Ember.get(this, 'currentPortal.portal.id');
        const integrationId = Ember.get(this, 'googleAnalytics.id');

        if (Ember.get(this, 'currentPortal.portal.company.featureLevel') === 'free') {
          return Ember.get(this, 'modalsManager').show('trial-modal', {
            freeAccount: true
          }).then(() => {
            Ember.get(this, 'router').transitionTo('billing');
          }).catch(() => {
            console.log('Going back');
          });
        }

        Ember.get(this, 'torii').open('google-analytics').then(function (data) {
          ajax.post('/ga/authorize', {
            data: {
              portalId,
              data,
              integrationId
            }
          }).then(function (accounts) {
            //console.log('Google Analytics Accounts', accounts);
            Ember.set(_this, 'gaAccounts', accounts.data);

            _this.send('openGaAccountsModal');

            Ember.get(_this, 'refreshThisRoute')();
          });
        }).catch(err => Ember.get(this, 'modalsManager').alert({
          title: i18n.t('publishing.sources.cantLoadSources.title'),
          body: err,
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }).then(() => Ember.get(_this, 'refreshThisRoute')()));
      },

      openGaAccountsModal() {
        //console.log('Open ga accounts modal data', get(this, 'gaAccounts'));
        Ember.get(this, 'modalsManager').show('integrations/select-ga-account-modal', {
          type: 'googleAnalytics',
          newSources: Ember.get(this, 'gaAccounts')
        }).then(async source => {
          //console.log('Selected GA source', source);
          const externalName = Ember.get(source, 'attributes.name');
          const externalId = Ember.get(source, 'attributes.gaId');
          const integration = Ember.get(this, 'googleAnalytics');
          Ember.set(integration, 'external-name', externalName);
          Ember.set(integration, 'external-id', externalId);
          Ember.set(integration, 'connected', true);
          await integration.save(); //console.log('GA Integration', integration);
        });
      }

    }
  });

  _exports.default = _default;
});