define("campaign-warrior-app/adapters/file", ["exports", "campaign-warrior-app/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class File
   * @namespace Adapters
   * @extends Adapter.Application
   */
  var _default = _application.default.extend({
    /**
     * Update a single file
     *
     * Request to the `/api/v1/files/:id/tags` will be done if `adapterOptions.saveTags` is provided. This request will
     * update Tags-list for File
     *
     * @method updateRecord
     * @param {DS.Store} store store itself
     * @param {DS.Model} type model class
     * @param {DS.Snapshot} snapshot record snapshot
     * @returns {RSVP.Promise} save result
     */
    updateRecord(store, type, snapshot) {
      if (Ember.get(snapshot, 'adapterOptions.saveTags')) {
        const url = `${this.urlForUpdateRecord(snapshot.id, type.modelName, snapshot)}/tags`;
        return this.ajax(url, 'POST', {
          data: {
            data: snapshot.serialize().data.relationships.tags.data
          }
        });
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});