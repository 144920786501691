define("campaign-warrior-app/services/wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Wizard
   * @namespace Services
   * @extends Ember.Service
   *
   * This is used to determine whether a user meets the requirements to start a wizard.
   */
  var _default = Ember.Service.extend({
    /**
     * @property token
     * @type string
     * @default null
     */
    currentUser: Ember.inject.service(),

    /**
     * @method checkRequirements
     * @param {object} billingInfo payment info
     * @return {RSVP.Promise} token for payment
     */
    checkRequirements(requirements) {
      const sampleRequirements = {
        permissionTypes: ['posting'],
        featureLevel: 'free'
      };
    }

  });

  _exports.default = _default;
});