define("campaign-warrior-app/routes/portals/portal/pages/page/edit", ["exports", "campaign-warrior-app/mixins/portal-related-manage-record-route", "campaign-warrior-app/utils/consts"], function (_exports, _portalRelatedManageRecordRoute, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class EditPage
   * @namespace Routes
   * @extends Ember.Route
   * @uses Mixins.PortalRelatedManageRecordRoute
   */
  var _default = Ember.Route.extend(_portalRelatedManageRecordRoute.default, {
    modelClassName: 'page',
    rootElementClasses: Object.freeze(['edit-page-page']),

    async model() {
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      const store = Ember.get(this, 'store');
      const page = this.modelFor('portals.portal.pages.page');
      const tags = store.query('tag', {
        portal_id
      });
      const notes = store.query('note', {
        portal_id,
        filter: {
          page: Ember.get(page, 'id')
        },
        ...(0, _consts.defaultPaginationSettings)()
      });
      const users = store.query('user', {
        portal_id,
        filter: {
          portal: portal_id
        },
        ...(0, _consts.defaultPaginationSettings)()
      });
      return Ember.RSVP.hash({
        model: page,
        notes,
        users,
        tags
      });
    },

    setupController(controller, modelHash) {
      Ember.setProperties(controller, {
        predefinedFilters: {
          page: Ember.get(modelHash.model, 'id')
        },
        model: modelHash.model,
        notes: modelHash.notes,
        users: modelHash.users,
        tags: modelHash.tags
      });
    }

  });

  _exports.default = _default;
});