define("campaign-warrior-app/components/domains/domains-table", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * @class DomainsTable
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    classNames: ['domains-table'],
    domainDeleted: null,
    // /**
    //  * @property domains
    //  * @type Models.Domain[]
    //  * @default null
    //  */
    // domains: null,

    /**
     * @property deleteRecordColumnIsHidden
     * @type boolean
     * @default false
     */
    deleteRecordColumnIsHidden: false,
    columns: Ember.computed(function () {
      const i18n = Ember.get(this, 'i18n');
      const tStatsPrefix = 'domains.stats.labels.';
      return [{
        title: i18n.t(`${tStatsPrefix}url`),
        propertyName: 'url',
        route: 'portals.portal.pages.list',
        component: 'cw-table/link-to',
        withoutCurrent: true,
        domainLink: true
      }, {
        title: 'Found Pages',
        propertyName: 'processedPages'
      }, {
        title: 'Status',
        propertyName: 'sitemapError',
        component: 'domains/domain-status'
      },
      /*
      {
        title: '',
        component: 'domains/refresh-domain',
        className: 'refresh-domain'
      },*/
      // {
      //   title: 'Last Updated',
      //   component: 'domains/domain-time',
      //   propertyName: 'updated'
      // },
      {
        title: 'Source',
        propertyName: 'source'
      }, {
        title: 'Last Updated',
        propertyName: 'update'
      }, {
        component: 'domains/table-controls'
      }
      /*
      {
        isHidden: get(this, 'deleteRecordColumnIsHidden'),
        component: 'delete-record'
      }*/
      ];
    }),
    actions: {
      refresh(domain) {},

      deleteDomain(domain) {
        const i18n = Ember.get(this, 'i18n');
        const modalsManager = Ember.get(this, 'modalsManager');
        return modalsManager.confirm({
          title: i18n.t('modals.defaultConfirmOptions.title'),
          body: i18n.t('modals.defaultConfirmOptions.body'),
          confirm: i18n.t('modals.defaultConfirmOptions.confirm')
        }).then(() => {
          if (domain) {
            domain.destroyRecord().then(() => {
              Ember.get(this, 'domainDeleted')(true);
            });
          }
        }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      }

    },

    refreshDomain(domain) {}

  });

  _exports.default = _default;
});