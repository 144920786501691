define("campaign-warrior-app/helpers/prettycron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Convert cron-expression to the human-readable string using [cronstrue](https://www.npmjs.com/package/cronstrue)
   *
   * Locale is taken from `ember-i18n`
   *
   * Usage:
   *
   * ```hbs
   * {{prettycron someCronValue}}
   * ```
   *
   * @namespace Helpers
   * @class PrettyCron
   *
   * @param {string}
   * @returns {string}
   */
  var _default = Ember.Helper.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    compute([str]) {
      const locale = Ember.get(this, 'i18n.locale');
      return cronstrue.toString(str, {
        locale
      });
    },

    onLocaleChange: Ember.observer('i18n.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});