define("campaign-warrior-app/components/billing/update-card-modal", ["exports", "campaign-warrior-app/components/modals-container/base", "ember-cp-validations"], function (_exports, _base, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   * @class UpdateCardModal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    classNames: ['update-card-modal'],
    formData: Ember.computed(function () {
      const user = Ember.get(this, 'options.currentUser');
      const Validations = (0, _emberCpValidations.buildValidations)({
        country: (0, _emberCpValidations.validator)('presence', true),
        'state.name': (0, _emberCpValidations.validator)('presence', true),
        city: (0, _emberCpValidations.validator)('presence', true),
        address1: (0, _emberCpValidations.validator)('presence', true),
        postal_code: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
          allowString: true,
          integer: true
        })],
        firstName: (0, _emberCpValidations.validator)('presence', true),
        lastName: (0, _emberCpValidations.validator)('presence', true)
      });
      return Ember.Object.extend(Validations, {
        firstName: Ember.computed(function () {
          const name = Ember.get(user, 'user.company.billingInfo.firstName');

          if (name) {
            return name;
          }

          return '';
        }),
        lastName: Ember.computed(function () {
          const name = Ember.get(user, 'user.company.billingInfo.lastName');

          if (name) {
            return name;
          }

          return '';
        }),
        address1: Ember.computed(function () {
          const address = Ember.get(user, 'user.company.billingInfo.address1');

          if (address) {
            return address;
          }

          return '';
        }),
        city: Ember.computed(function () {
          const city = Ember.get(user, 'user.company.billingInfo.city');

          if (city) {
            return city;
          }

          return '';
        }),
        state: Ember.computed(function () {
          const savedState = Ember.get(user, 'user.company.billingInfo.state');

          if (savedState) {
            return {
              name: savedState
            };
          }

          return {
            name: ''
          };
        }),
        postal_code: Ember.computed(function () {
          const zipCode = Ember.get(user, 'user.company.billingInfo.postcode');

          if (zipCode) {
            return zipCode;
          }

          return '';
        }),
        country: Ember.computed(function () {
          const countryCode = Ember.get(user, 'user.company.billingInfo.country');
          const countryName = Ember.get(user, 'user.country');

          if (countryCode && countryName) {
            return {
              name: countryName,
              code: countryCode.toLowerCase()
            };
          }

          return {
            name: '',
            code: ''
          };
        }),
        showStatesAsDropdown: Ember.computed('country.code', function () {
          //console.log('COUNTRY CODE: ', get(user, 'user.company.billingInfo.country'));
          return Ember.get(this, 'country.code') in states;
        }),
        countryChanged: Ember.observer('country.code', function () {
          Ember.set(this, 'state', {
            name: ''
          });
        })
      }).create(Ember.getOwner(this).ownerInjection());
    })
  });

  _exports.default = _default;
});