define("campaign-warrior-app/routes/portals/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortalsErrorRoute extends Ember.Route {
    model() {
      console.log("Portals Error Route Model");
    }

    setupController(controller, model) {
      console.log("Portals Error route setup controller");
      Ember.setProperties(controller, {
        errorDetail: model.message
      });

      this._super(...arguments);
    }

  }

  _exports.default = PortalsErrorRoute;
});