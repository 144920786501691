define("campaign-warrior-app/controllers/trial-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Register
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property login
     * @type Ember.Controller
     */
    login: Ember.inject.controller(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),
    actions: {
      /**
       * Send register request
       *
       * @param {object} data user registration data
       * @method actions.doRegister
       */
      doRegister(data) {
        Ember.get(this, 'ajax').post('/signin', {
          data
        })
        /*
         * Login user after registration.
         * Technically user is already logged in,
         * however it's a simple way to init session on the UI
         */
        .then(() => Ember.get(this, 'login').send('doLogin', data)).catch(e => Ember.get(this, 'modalsManager').userFriendlyErrorHandler(e));
      }

    }
  });

  _exports.default = _default;
});