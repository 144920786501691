define("campaign-warrior-app/templates/components/manage-domains-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "odYfTFr2",
    "block": "{\"symbols\":[\"form\",\"&default\"],\"statements\":[[6,[37,5],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,4],true,[30,[36,3],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[3,\"<div class=\\\"row heading\\\">\"],[2,\"\\n  \"],[3,\"<div class=\\\"col-xs-12\\\">\"],[2,\"\\n  \"],[3,\"<div class=\\\"inline-display\\\" style=\\\"display: inline-block\\\">\"],[2,\"\\n  \"],[3,\"<h4>Connected Domains</h4>\"],[2,\"\\n  \"],[3,\"</div>\"],[2,\"\\n  \"],[3,\"<div class=\\\"inline-display\\\" style=\\\"float: right; display: inline-block\\\">\"],[2,\"\\n  \"],[3,\"#unless hideControls}}\"],[2,\"\\n  \"],[3,\"<div class=\\\"btn-group\\\">\"],[2,\"\\n  \"],[3,\"bs-button\"],[2,\"\\n  \"],[3,\"class=\\\"btn btn-primary\\\"\"],[2,\"\\n  \"],[3,\"defaultText='Connect'\"],[2,\"\\n  \"],[3,\"type=\\\"primary\\\"\"],[2,\"\\n  \"],[3,\"buttonType=\\\"submit\\\"\"],[2,\"\\n  \"],[3,\"}}\"],[2,\"\\n  \"],[3,\"</div>\"],[2,\"\\n  \"],[3,\"/unless}}\"],[2,\"\\n  \"],[3,\"</div>\"],[2,\"\\n  \"],[3,\"</div>\"],[2,\"\\n  \"],[3,\"</div>\"],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[3,\"<h4>Connect a New Domain and Scrape the Web Pages</h4>\"],[2,\"\\n\\n      \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/manage-domains-form.hbs' @ L25:C8) \"],null]],[[\"property\",\"label\",\"placeholder\"],[\"url\",[30,[36,0],[\"domains.create.form.labels.url\"],null],\"www.insertdomainhere.com\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"t\",\"-assert-implicit-component-helper-argument\",\"component\",\"action\",\"record\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/manage-domains-form.hbs"
    }
  });

  _exports.default = _default;
});