define("campaign-warrior-app/mixins/portal-internal-route", ["exports", "campaign-warrior-app/utils/routing"], function (_exports, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for portal's top-level routes
   *
   * It contains info about their breadcrumbs
   *
   * Requires `breadCrumbRoutingKey` to be set
   *
   * @class PortalInternalRoute
   * @namespace Mixins
   */
  var _default = Ember.Mixin.create({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * Must be one of the `utils/routing` portalRoutes
     *
     * @type string
     * @default ''
     * @property breadCrumbRoutingKey
     */
    breadCrumbRoutingKey: '',
    breadCrumb: Ember.computed('breadCrumbRoutingKey', function () {
      const k = Ember.get(this, 'breadCrumbRoutingKey');
      return k ? {
        icon: _routing.portalRoutes[k].icon,
        title: Ember.get(this, 'i18n').t(_routing.portalRoutes[k].title)
      } : {};
    })
  });

  _exports.default = _default;
});