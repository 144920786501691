define("campaign-warrior-app/components/page-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{page-helper
   *   iconSrc="some_path"
   *   description=(t "tDescriptionKey")
   *   title=(t "tTitleKey")
   * }}
   * ```
   *
   * OR
   *
   * ```hbs
   * {{page-helper
   *   iconSrc="some_path"
   *   description="tDescriptionKey"
   *   title="tTitleKey"
   * }}
   * ```
   *
   * @class PageHelper
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['page-helper'],

    /**
     * @property iconSrc
     * @type string
     * @default null
     */
    iconSrc: null,

    /**
     * @property description
     * @type string
     * @default null
     */
    description: null,

    /**
     * @property title
     * @type string
     * @default null
     */
    title: null
  });

  _exports.default = _default;
});