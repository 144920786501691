define("campaign-warrior-app/models/post-job-instance", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PostJobInstance
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    /**
     * @type DS.StringAttribute
     * @default null
     * @property agendaJobId
     */
    agendaJobId: _emberData.default.attr('string'),

    /**
     * @type DS.StringAttribute
     * @default null
     * @property interval
     */
    interval: _emberData.default.attr('string'),

    /**
     * @type DS.NumberAttribute
     * @default null
     * @property time
     */
    time: _emberData.default.attr('number'),

    /**
     * @type DS.StringAttribute
     * @default null
     * @property type
     */
    type: _emberData.default.attr('string'),

    /**
     * {{#crossLink "Models.PostJob"}}Models.PostJob{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property postJob
     */
    postJob: _emberData.default.belongsTo('post-job'),

    /**
     * Rounded to day `time`-value
     *
     * @property timeToDay
     * @type number
     * @default null
     */
    timeToDay: Ember.computed('time', function () {
      const day = 1000 * 60 * 60 * 24;
      const time = Ember.get(this, 'time');
      const d = time % day;
      return time - d;
    }),

    /**
     * @property isToday
     * @type boolean
     * @default false
     */
    isToday: Ember.computed('time', function () {
      return (0, _moment.default)(Ember.get(this, 'time')).isSame((0, _moment.default)(), 'day');
    })
  });

  _exports.default = _default;
});