define("campaign-warrior-app/mixins/expandable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * General mixin for components that may collapsed or expanded. Usually it's used for panels
   *
   * @class Expandable
   * @namespace Mixins
   */
  var _default = Ember.Mixin.create({
    /**
     * Determines if element is expanded or collapsed
     *
     * May be set initially
     *
     * @property expanded
     * @type boolean
     * @default true
     */
    expanded: true,

    /**
     * @property expandable
     * @type boolean
     * @default true
     */
    expandable: true,
    actions: {
      /**
       * Click-handler for panel-heading
       *
       * Toggles `expanded`-flag
       *
       * @method actions.togglePanel
       */
      togglePanel() {
        if (Ember.get(this, 'expandable')) {
          this.toggleProperty('expanded');
        }
      }

    }
  });

  _exports.default = _default;
});