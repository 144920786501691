define("campaign-warrior-app/mixins/abilities/scraping", ["exports", "campaign-warrior-app/utils/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ScrapingAbilityMixin
   * @namespace Abilities
   */
  var _default = Ember.Mixin.create({
    /**
     * @property canCreate
     * @type boolean
     */
    canCreate: (0, _ability.portalRelatedAbility)('admin_scraping'),

    /**
     * @property canEdit
     * @type boolean
     */
    canEdit: (0, _ability.portalRelatedAbility)('scraping'),

    /**
     * @property canDelete
     * @type boolean
     */
    canDelete: Ember.computed.reads('canCreate')
  });

  _exports.default = _default;
});