define("campaign-warrior-app/controllers/support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Login
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property session
     * @type Ember.Service
     */
    session: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),
    token: null,
    tokenStatus: null,
    supportCompany: null,
    supportPortalName: null,
    supportPortalId: null,
    authInProgress: null,
    errorMessage: null,
    actions: {
      /**
       * Send login request
       *
       * @param {{username: string, password: string}} data user credentials
       * @method actions.doLogin
       * @return {RSVP.Promise} auth result
       */
      authorize(data) {
        const _this = this;

        const portalId = Ember.get(data, 'portalId');
        const ajax = Ember.get(this, 'ajax'); // ajax.post('/support/request', {data: {portalId}});

        ajax.post('/support/request_portal', {
          data: {
            portalId
          }
        }).then(result => {
          if (result.token) {
            Ember.set(_this, 'token', result.token);
            Ember.set(_this, 'tokenStatus', 'Authorized');
            Ember.set(_this, 'supportPortalId', portalId);
            Ember.set(_this, 'supportCompany', result.company);
            Ember.set(_this, 'supportPortalName', result.portalName);
          } else {
            Ember.set(_this, 'tokenStatus', 'Unauthorized');
          }
        }).catch(err => {
          Ember.set(_this, 'errorMessage', 'No support ticket exists to grant access to this portal, please create a support ticket in hubspot');
          Ember.set(_this, 'tokenStatus', 'Unauthorized');
        });
      },

      supportLogin() {
        const email = Ember.get(this, 'currentUser.user.email');
        const token = Ember.get(this, 'token');
        const remember = false;
        return this.authWrapper(() => Ember.get(this, 'session').authenticate('authenticator:support', {
          token,
          identification: email
        }, {
          remember
        }), 'login.emailLogin.error');
      }

    },

    authWrapper(auth, errorBody) {
      const i18n = Ember.get(this, 'i18n'); //const modalsManager = get(this, 'modalsManager');

      const currentUser = Ember.get(this, 'currentUser');
      Ember.set(this, 'authInProgress', true);
      return auth().then(() => currentUser.load()).then(async () => {
        const router = Ember.get(this, 'router');
        return router.transitionTo('portals');
      }).catch(e => {// `TransitionAborted` happens when user doesn't have created portals and forced from `portals` to `portals.create.step1`
        // It's not an error in this case

        /*
         if (e.name !== 'TransitionAborted') {
           if (!get(e, 'payload.reason')) {
             e.payload = {reason: i18n.t(errorBody)};
           }
           get(this, 'modalsManager')
             .userFriendlyErrorHandler(e);
         }
         */
      }).finally(() => Ember.set(this, 'authInProgress', false));
    }

  });

  _exports.default = _default;
});