define("campaign-warrior-app/templates/components/users/user-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aHB5Lern",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[[35,0],\"Active\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"statusSuccess\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"statusPending\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"displayValue\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/users/user-status.hbs"
    }
  });

  _exports.default = _default;
});