define("campaign-warrior-app/templates/components/hashtags/create-atsign-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ps34PrD6",
    "block": "{\"symbols\":[\"form\",\"&default\"],\"statements\":[[6,[37,5],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,4],true,[30,[36,3],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/hashtags/create-atsign-form.hbs' @ L2:C4) \"],null]],[[\"controlType\",\"label\",\"property\",\"placeholder\"],[\"input\",[30,[36,0],[\"hashtags.atsigns.create.name\"],null],\"name\",\"@atsign\"]]]],[2,\"\\n\\n  \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/hashtags/create-atsign-form.hbs' @ L9:C4) \"],null]],[[\"controlType\",\"label\",\"property\",\"rows\"],[\"textarea\",[30,[36,0],[\"hashtags.atsigns.create.note\"],null],\"note\",2]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\\n\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"t\",\"-assert-implicit-component-helper-argument\",\"component\",\"action\",\"record\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/hashtags/create-atsign-form.hbs"
    }
  });

  _exports.default = _default;
});