define("campaign-warrior-app/components/tags/tags-filter", ["exports", "campaign-warrior-app/components/cw-table/related-model-filter"], function (_exports, _relatedModelFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class TagsFilter
   * @namespace Components
   * @extends Components.CwTableRelatedModelFilter
   */
  var _default = _relatedModelFilter.default.extend({
    classNames: ['tags-filter'],

    /**
     * @property instanceClassName
     * @type string
     * @default 'tag'
     */
    instanceClassName: 'tag',

    /**
     * @property searchField
     * @type string
     * @default 'name'
     */
    searchField: 'name'
  });

  _exports.default = _default;
});