define("campaign-warrior-app/routes/portals/create/step", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WizardStep
   * @namespace Routes.CreatePortal
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * Current step number. Must be set in the every child-routes
     *
     * @property step
     * @type number
     * @default null
     */
    step: null,

    /**
     * Data from the local storage for new the portal
     *
     * It's updated in the LS when user is moved from one step to another
     *
     * @property portal
     * @type object
     * @default {}
     */
    portal: (0, _emberLocalStorage.storageFor)('create-portal'),

    /**
     * Save user's current step to the LS
     *
     * Check if user tries to "jump" over some steps and move him back to the proper step
     *
     * @returns {*} parent `beforeModel`
     */
    beforeModel() {
      const step = parseInt(Ember.get(this, 'step'), 10);
      const lsStep = parseInt(Ember.get(this, 'portal.step'), 10);

      if (step > lsStep) {
        return Ember.get(this, 'router').transitionTo(`portals.create.step${lsStep}`);
      }

      Ember.set(this, 'portal.step', step);
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});