define("campaign-warrior-app/templates/components/tags/add-many-tags-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5api2KTY",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[6,[37,7],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,6],true,[30,[36,4],[[32,0],\"onSubmit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/tags/add-many-tags-form.hbs' @ L2:C4) \"],null]],[[\"controlType\",\"label\",\"placeholder\",\"property\",\"rows\"],[\"textarea\",[30,[36,0],[\"tags.addMany.form.labels.tags\"],null],[30,[36,0],[\"tags.addMany.form.placeholders.tags\"],null],\"tags\",7]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tags-controls-group\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"class\",\"defaultText\",\"type\"],[\"btn btn-primary\",[30,[36,0],[\"tags.addMany.submit\"],null],\"primary\"]]]],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"class\",\"disabled\",\"readFileContent\",\"accept\",\"readType\",\"text\",\"onLoad\"],[\"csv-upload-button\",false,true,\".csv,.txt\",\"readAsText\",[30,[36,0],[\"tags.addMany.upload\"],null],[30,[36,4],[[32,0],\"uploadCSVFile\"],null]]]]],[2,\" \\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-assert-implicit-component-helper-argument\",\"component\",\"bs-button\",\"action\",\"media-manager/upload-file\",\"formData\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/tags/add-many-tags-form.hbs"
    }
  });

  _exports.default = _default;
});