define("campaign-warrior-app/models/page-rating", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PageRating
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    date: _emberData.default.attr('number'),
    userTechRating: _emberData.default.attr('string'),
    userDesignRating: _emberData.default.attr('string'),
    userJourneyRating: _emberData.default.attr('string'),
    userSalesRating: _emberData.default.attr('string'),
    portal: _emberData.default.belongsTo('portal'),
    page: _emberData.default.belongsTo('page'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});