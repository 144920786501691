define("campaign-warrior-app/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Company
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    /**
     * @property status
     * @type DS.StringAttribute
     * @default null
     */
    status: _emberData.default.attr('string'),

    /**
     * @property name
     * @type DS.StringAttribute
     * @default null
     */
    name: _emberData.default.attr('string'),
    featureLevel: _emberData.default.attr('string'),
    inTrial: _emberData.default.attr('boolean'),
    incomplete: _emberData.default.attr('boolean'),

    /**
     * {{#crossLink "Models.User"}}Models.User{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property owner
     */
    owner: _emberData.default.belongsTo('user'),

    /**
     * {{#crossLink "Models.User"}}Models.User{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property users
     */
    users: _emberData.default.hasMany('user'),

    /**
     * {{#crossLink "Models.BillingInfo"}}Models.BillingInfo{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property billingInfo
     */
    billingInfo: _emberData.default.belongsTo('billing-info'),
    freeAccount: Ember.computed(function () {
      return Ember.get(this, 'featureLevel') === 'free';
    })
  });

  _exports.default = _default;
});