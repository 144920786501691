define("campaign-warrior-app/components/portals/delete-confirm/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Body for `prompt-confirm` used to delete portal
   *
   * @class ConfirmDeletePortalBody
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({});

  _exports.default = _default;
});