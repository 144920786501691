define("campaign-warrior-app/components/billing/update-card-form", ["exports", "campaign-warrior-app/data/countries", "campaign-warrior-app/data/states", "ember-cp-validations"], function (_exports, _countries, _states, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class UpdateCardForm
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    recurly: Ember.inject.service(),
    ajax: Ember.inject.service(),

    /**
     * @property notify
     * @type Ember.Service
     */
    notify: Ember.inject.service(),
    classNames: ['update-card-form'],

    /**
     * @property countries
     * @private
     * @readonly
     * @type {{name: string, code: string}[]}
     */
    countries: _countries.default,

    /**
     * @property states
     * @private
     * @readonly
     */
    states: _states.default,

    /**
     * @event onSubmit
     */
    onSubmit: null,

    /**
     * @property formData
     * @type object
     * @private
     * @readonly
     */
    cardIssue: null,

    didInsertElement() {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      recurly.configure(config.recurly);
    },

    actions: {
      save() {
        let _this = this;

        try {
          const form = this.$('form:eq(0)');
          return Ember.get(this, 'recurly').getToken(form).then(t => {
            Ember.set(_this, "cardIssue", null);
            return Ember.get(this, 'save')(t);
          }).catch(err => {
            Ember.set(_this, "cardIssue", err.message);
            console.error('Recurly error', err);
          });
        } catch (err) {
          console.error(err);
        }
      }

    }
  });

  _exports.default = _default;
});