define("campaign-warrior-app/components/select-portal-form-external", ["exports", "campaign-warrior-app/components/select-portal-form"], function (_exports, _selectPortalForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * "Select-Portal" form used in the portals index page
   *
   * Usage
   *
   * ```hbs
   * {{select-portal-form-external portals=portals filteringIgnoreCase=false logout=(action "logout")}}
   * ```
   *
   * @namespace Components
   * @class SelectPortalFormExternal
   * @extends Components.SelectPortalForm
   */
  var _default = _selectPortalForm.default.extend((_dec = Ember.computed('portalsPage'), _dec2 = Ember.computed('portalsPage'), (_obj = {
    classNames: ['select-portal-form-external', 'floating-form-wrapper'],

    get portalSize() {
      return this.portalsPage.length;
    },

    get columns() {
      return [{
        title: '',
        className: 'title-icon',
        propertyName: 'image'
      }, {
        title: 'Name',
        className: 'title-name',
        propertyName: 'name'
      }, {
        title: '',
        className: 'title-link',
        propertyName: 'link'
      }];
    },

    actions: {
      logout() {
        const logout = Ember.get(this, 'logout');

        if (logout) {
          logout();
        }
      },

      clearSearch() {
        Ember.set(this, 'filter', '');
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "portalSize", [_dec], Object.getOwnPropertyDescriptor(_obj, "portalSize"), _obj), _applyDecoratedDescriptor(_obj, "columns", [_dec2], Object.getOwnPropertyDescriptor(_obj, "columns"), _obj)), _obj)));

  _exports.default = _default;
});