define("campaign-warrior-app/components/pages/page-listing-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    url: null,
    relativeUrl: Ember.computed(function () {
      const url = Ember.get(this, 'record.url');
      const indexOf = url.indexOf('/');

      if (indexOf > 0) {
        return url.substring(indexOf);
      } else {
        return '/';
      }
    }),
    relativeShortUrl: Ember.computed(function () {
      const url = Ember.get(this, 'record.url');
      const indexOf = url.indexOf('/');

      if (indexOf > 0) {
        let shortened = url.substring(indexOf);
        let origLength = shortened.length;
        shortened = shortened.substr(0, 30);

        if (origLength > 30) {
          shortened += '...';
        }

        return shortened;
      } else {
        return '/';
      }
    }),
    actions: {
      copyLink(link, httpIncluded = false) {
        const el = document.createElement('textarea');

        if (httpIncluded) {
          el.value = '<a href="' + link + '"></a><br/>';
        } else {
          el.value = 'https://' + link;
        }

        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      },

      copyHtmlLink(record) {
        let url = Ember.get(record, 'url');
        const title = Ember.get(record, 'title');
        const el = document.createElement('textarea');

        if (!url.includes('https://') && !url.includes('http://') && !url.includes('//')) {
          url = 'https://' + url;
        }

        el.value = `<a href="${url}">${title}</a><br/>`;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }

    }
  });

  _exports.default = _default;
});