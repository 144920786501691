define("campaign-warrior-app/components/tags/add-many-tags-form/progress-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AddManyTagsProgressBody
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property currentTag
     * @type string
     * @default null
     * @private
     * @readonly
     */
    currentTag: Ember.computed('done', 'options.tags.[]', function () {
      const tags = Ember.get(this, 'options.tags') || [];
      const done = Ember.get(this, 'done');
      const tag = tags[done];
      return tag ? tag.tag : null;
    }).readOnly()
  });

  _exports.default = _default;
});