define("campaign-warrior-app/controllers/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Billing
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    i18n: Ember.inject.service(),

    /**
     * @property recurly
     * @type Ember.Service
     */
    recurly: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property notify
     * @type Ember.Service
     */
    notify: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property portals
     * @type Models.Portal[]
     * @default null
     */
    portals: null,

    /**
     * @property requestInProgress
     * @type boolean
     * @default false
     */
    requestInProgress: false,

    /**
     * @property billingInfo
     * @type Models.BillingInfo
     * @readonly
     */
    billingInfo: Ember.computed.reads('currentUser.user.company.billingInfo'),
    actions: {
      cancelPlan() {
        return this.planWrapper(Ember.get(this, 'ajax').put('cancel/plan'));
      },

      reactivatePlan() {
        return this.planWrapper(Ember.get(this, 'ajax').put('reactivate/plan'));
      },

      /**
       * @method actions.updateBillingPlan
       * @param {object} form billing form itself
       * @param {object} formData manage-billing-form data
       * @param {string} errorBody i18n-key for error-modal
       * @return {RSVP.Promise} update result
       */
      updateBillingPlan(form, formData, errorBody) {
        const modalsManager = Ember.get(this, 'modalsManager'); //const i18n = get(this, 'i18n');

        modalsManager.process({
          body: '',
          iconClass: 'text-center fal fa-spinner fa-spin fa-3x fa-fw',
          title: 'Processing request, please wait',
          process: () => new Promise(resolve => resolve(Ember.get(this, 'recurly').getToken(form).then(token => {
            return Ember.get(this, 'ajax').put('/update/plan', {
              data: {
                'recurly-token': token,
                'coupon-code': Ember.get(formData, 'couponCode')
              }
            }).then(response => Ember.get(this, 'notify').success(response.message));
          })))
        }).then(() => Ember.get(this, 'currentUser').load()).then(() => Ember.get(this, 'router').transitionTo('portals')).catch(e => {
          if (e.name === 'validation') {
            modalsManager.recurlyErrorHandler(e);
          } else if (e.name !== 'TransitionAborted') {
            if (!e.payload) {
              e.payload = {};
            }

            if (e.payload && e.payload.errors) {
              e.payload.userMessage = e.payload.errors[0].details;
            } else if (e.code) {
              //error codes from recurly
              e.payload.userMessage = e.message;
            }

            Ember.get(this, 'modalsManager').billingErrorHandler(e);
          } else {
            Ember.get(this, 'modalsManager').billingErrorHandler(e);
          }
        }); // return get(this, 'recurly')
        //   .getToken(form)
        //   .then(token => {
        //     return get(this, 'ajax')
        //       .put('/update/plan', {
        //         data: {
        //           'recurly-token': token,
        //           'coupon-code': get(formData, 'couponCode'),
        //         }
        //       })
        //       .then(response => get(this, 'notify').success(response.message))
        //       .then(() => get(this, 'currentUser').load())
        //       .then(() => get(this, 'router').transitionTo('portals'))
        //       // .catch(e => modalsManager.detailedAjaxErrorHandler(e));
        //
        //       .catch(e => {
        //         // `TransitionAborted` happens when user doesn't have created portals and forced from `portals` to `portals.create.step1`
        //         // It's not an error in this case
        //         if (e.name !== 'TransitionAborted') {
        //           if (!get(e, 'payload.reason')) {
        //             e.payload = {reason: i18n.t(errorBody)};
        //           }
        //           get(this, 'modalsManager')
        //             .detailedAjaxErrorHandler(e);
        //         }
        //       })
        //
        //   })
        //   .catch(e => modalsManager.recurlyErrorHandler(e));
      }

    },

    /**
     * @method planWrapper
     * @param {RSVP.Promise} request request to change billing plan or status
     * @return {RSVP.Promise} request result handler
     */
    planWrapper(request) {
      const notify = Ember.get(this, 'notify');
      const billingId = Ember.get(this, 'currentUser.user.company.billingInfo.id');
      Ember.set(this, 'requestInProgress', true);
      return request.then(response => {
        Ember.get(this, 'store').findRecord('billing-info', billingId);
        return notify.success(response.message);
      }).catch(e => notify.error(e.message)).finally(() => Ember.set(this, 'requestInProgress', false));
    }

  });

  _exports.default = _default;
});