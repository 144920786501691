define("campaign-warrior-app/routes/portals/portal/setup/tracking-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TrackingCodeRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "title", 'Setup: Insert Tracking Code | Campaign Warrior');
    }

    model() {
      const portal = this.modelFor('portals.portal');
      const trackingId = portal.trackingId;
      const primaryDomain = this.store.findRecord('domain', trackingId);
      return primaryDomain;
    }

  }

  _exports.default = TrackingCodeRoute;
});