define("campaign-warrior-app/models/file", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^([a-z0-9_-]|\.)+$/i
    }), (0, _emberCpValidations.validator)('uniq-fs-item', {
      type: 'files'
    })]
  });
  /**
   * @class File
   * @namespace Models
   * @extends DS.Model
   */

  var _default = _emberData.default.Model.extend(Validations, {
    /**
     * @type DS.StringAttribute
     * @default null
     * @property name
     */
    name: _emberData.default.attr('string'),

    /**
     * @type DS.StringAttribute
     * @default null
     * @property preview
     */
    preview: _emberData.default.attr('string'),

    /**
     * {{#crossLink "Models.Dir"}}Models.Dir{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property parent
     */
    parent: _emberData.default.belongsTo('dir'),

    /**
     * {{#crossLink "Models.Tag"}}Models.Tag{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property tags
     */
    tags: _emberData.default.hasMany('tag')
    /**
     * @property originalName
     * @type string
     * @default null
     */

  });

  _exports.default = _default;
});