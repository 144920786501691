define("campaign-warrior-app/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UfZ9l0Kk",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[6,[37,7],null,[[\"keyboard\",\"backdropClose\",\"size\",\"open\",\"onSubmit\",\"onHide\",\"class\"],[false,false,\"lg\",[35,6],[30,[36,1],[[32,0],\"confirm\"],null],[30,[36,1],[[32,0],\"decline\"],null],\"modals-branding trial-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('campaign-warrior-app/templates/components/trial-modal.hbs' @ L4:C5) \"],null]],[[\"class\"],[\"text-center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"You are using a trial account from Campaign Warrior.\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"To use these features, please upgrade to Campaign Warrior Full Plan\"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"modal-controls\"],[12],[2,\"\\n      \"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-primary btn-round\",\"billing\"]],[[\"default\"],[{\"statements\":[[2,\"Upgrade Now\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[2,\"      \"],[1,[30,[36,3],null,[[\"type\",\"class\",\"disabled\",\"defaultText\",\"onClick\"],[\"link\",\"subscribe\",[35,2],\"Cancel\",[30,[36,1],[[32,0],\"decline\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"link-to\",\"action\",\"trialExpired\",\"bs-button\",\"-assert-implicit-component-helper-argument\",\"component\",\"modalIsOpened\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/trial-modal.hbs"
    }
  });

  _exports.default = _default;
});