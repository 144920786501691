define("campaign-warrior-app/utils/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addPrefix = addPrefix;

  /**
   * Add a `http://` to the url if doesn't have a prefix
   *
   * @param {string} url url to check
   * @returns {string} updated url
   */
  function addPrefix(url) {
    url = url || '';
    return /^https?:\/\//i.test(url) ? url : 'http://' + url;
  }
});