define("campaign-warrior-app/models/mentionable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Mentionable
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    alias: _emberData.default.attr('string'),
    'internal-alias': _emberData.default.attr('string'),
    'entity-id': _emberData.default.attr('string'),
    platform: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    portal: _emberData.default.belongsTo('portal')
  });

  _exports.default = _default;
});