define("campaign-warrior-app/validators/end-date", ["exports", "ember-cp-validations/validators/base", "moment"], function (_exports, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Validator for date-property which must be after another date-property. Must be combined with [begin-date](Validators.BeginDate.html)
   *
   * It's a custom validator for [ember-cp-validations](https://github.com/offirgolan/ember-cp-validations)
   *
   * Usage:
   * ```js
   * buildValidations({
   *   end: validator('end-date', {
   *     begin: 'start'
   *   })
   * });
   * ```
   *
   * @class EndDate
   * @extends BaseValidator
   * @namespace Validators
   */
  const EndDate = _base.default.extend({
    i18n: Ember.inject.service(),

    validate(value, options, model, attribute) {
      const startAttr = options.begin;
      let start = Ember.get(model, startAttr);

      if (!start) {
        return true;
      }

      let end = (0, _moment.default)(value).valueOf();
      start = (0, _moment.default)(start).valueOf();
      const descriptionKey = options.descriptionKey || 'errors.after';
      return end > start ? true : Ember.get(this, 'i18n').t(descriptionKey, {
        description: attribute,
        after: (0, _moment.default)(start).format('YYYY-MM-DD')
      }).toString();
    }

  });

  EndDate.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     *  `model.array.@each.${attribute}` --> Dependent is created on the model's context
     *  `${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array} deps
     */
    getDependentsFor(attribute, options) {
      return [options.begin];
    }

  });
  var _default = EndDate;
  _exports.default = _default;
});