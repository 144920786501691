define("campaign-warrior-app/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jsv7Oes2",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"portals\"],[[35,0]]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container-internal-wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content billing-wrapper-inner\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"internal-content\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"page-controls\"],[12],[2,\"\\n        \"],[10,\"h2\"],[12],[2,\"Billing Settings\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"control-group\"],[12],[2,\"\\n          \"],[8,\"bs-button\",[[24,0,\"btn btn-submenu\"]],[[\"@defaultText\",\"@onClick\"],[\"Return Home\",[30,[36,2],[[32,0],\"returnHome\"],null]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n       \"],[1,[30,[36,5],null,[[\"billingInfo\",\"company\",\"reload\"],[[35,4,[\"user\",\"company\",\"billingInfo\"]],[35,4,[\"user\",\"company\"]],[35,3]]]]],[2,\"\\n       \"],[1,[30,[36,8],null,[[\"billingInfo\",\"portals\",\"isTrial\"],[[35,7],[35,0],[35,6]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"portals\",\"top-nav\",\"action\",\"reload\",\"currentUser\",\"show-billing-details\",\"isTrial\",\"billingDetails\",\"show-portals-details\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/account.hbs"
    }
  });

  _exports.default = _default;
});