define("campaign-warrior-app/components/cw-panel-promo", ["exports", "campaign-warrior-app/components/cw-panel"], function (_exports, _cwPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cwPanel.default.extend({
    classNames: ['cw-panel-promo'],
    expandable: false
  });

  _exports.default = _default;
});