define("campaign-warrior-app/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    common: {
      or: 'or'
    },
    copyright: '&copy; Campaign Warrior 2021',
    services: {
      twitter: 'Twitter',
      facebook: 'Facebook',
      linkedin: 'LinkedIn',
      hubspot: 'HubSpot',
      googleAnalytics: 'Google Analytics'
    },
    logo: {
      alt: 'Campaign Warrior'
    },
    manageRecords: {
      savedSuccessfully: 'Saved Successfully'
    },
    dashboard: {
      title: 'Welcome to the Dashboard',
      description: 'The Dashboard will grow with power as your content expands. Campaign Warrior puts as much information as possible at your fingertips. Giving you full control. ',
      tables: {
        pages: {
          titles: {
            metaSize: 'Size'
          }
        }
      },
      gettingStarted: {
        title: 'Getting Started Tutorial',
        content: {
          subTitle: 'Welcome to Campaign Warrior',
          subText: 'Now you are here, let\'s get started with this checklist',
          list: {
            importPages: 'Import Your Web Pages',
            tags: 'Create or Import Tags',
            subscribe: 'Subscribe',
            home: 'Home Page'
          },
          descriptions: {
            why: {
              title: 'The Why',
              message: `<p>Campaign Warrior is built by <strong>The Kingdom</strong>. The Kingdom was a digital advertising agency, before becoming one of the fastest growing HubSpot Partners in 2015. We were the International HubSpot Partner of the Year in the 2015.</p>
<p>We successfully proved the value of posting  evergreen content web page links by social media to our clients, but quickly got sick of the amount of time it takes to publish.</p>
<p>So in 2016, we invented the first edition of the software that is now Campaign Warrior. After 3 years of refinement and hundreds of hours of development we bring you this powerful tool that will become the backbone of your social media traffic generation.</p>`
            },
            traffic: {
              title: 'Posting Links To Your Website Drives Traffic',
              message: '<p>Using social media to reveal and encourage your audience to learn from your web page drives traffic.</p>'
            }
          }
        }
      }
    },
    breadcrumbs: {
      portals: 'Portals',
      portalsWizard: 'Create'
    },
    register: {
      title: 'Activate Your Free Account',
      form: {
        submit: 'Activate Free Account',
        goToLogin: 'Back to Login',
        placeholders: {
          name: 'First Name*',
          surname: 'Last Name*',
          email: 'Email*',
          password: 'Password*',
          confirmPassword: 'Confirm Password*',
          company: 'Company*',
          country: 'Country*'
        },
        labels: {
          createPassword: 'Create Password*',
          verifyPassword: 'Verify Password*'
        }
      }
    },
    reset: {
      verify: {
        title: 'Receive new password',
        description: 'Reset password token is sent to your email. Please, enter it here:',
        form: {
          placeholders: {
            token: 'Token'
          }
        },
        submit: 'Send',
        back: 'Back to Login',
        newPassword: 'Your new password: '
      },
      pass: {
        title: 'Request New Password',
        description: 'Enter your email and we will send a reset link',
        form: {
          placeholders: {
            email: 'Email'
          }
        },
        successModal: {
          body: 'Please check your email inbox'
        },
        back: 'Back to Login',
        submit: 'Send'
      }
    },
    login: {
      registerBtn: 'Sign Up For A 14 Day Free Trial',
      googleLogin: {
        error: 'There was a problem logging in with Google'
      },
      fbLogin: {
        error: 'There was a problem logging in with Facebook'
      },
      form: {
        passwordReset: 'Forgot Password?',
        submit: 'Sign In',
        fbLogin: {
          name: 'Facebook',
          error: 'There was a problem logging in with Facebook'
        },
        linkinedLogin: 'Linkedin',
        twitterLogin: 'Twitter',
        googleLogin: {
          name: 'Google',
          error: 'There was a problem logging in with Google'
        },
        placeholders: {
          email: 'Email',
          password: 'Password',
          remember: 'Remember me'
        },
        label: {
          email: 'Email',
          password: 'Password',
          remember: 'Remember me'
        }
      },
      promo: {
        title: 'Get control of your Social Media, fast.',
        description: `<p>Campaign Warrior is a powerful multi-location, digital and social organizing hero.</p>
      <p>Manage social, repurpose quickly, drive organic clicks to your website, handle multiple locations.</p>
      <p>Create a Database from all of your web pages.</p>
      <p>Auto Tag your content.</p>
      <p>Connect your social media accounts.</p>
      <p>Publish social messages quickly to multiple platforms.</p>
      <p>Show your success in a report.</p>`,
        trial: 'Free 14 Days Trial',
        demo: 'Book a Demo'
      },
      trial: {
        title: 'Welcome to your Campaign Warrior 14 day trial',
        description: `<p>Your trial expires on <strong>{{expireDate}}</strong>. You have <strong>{{daysToExpire}}</strong> to go.<p>
      <p>You get 50 free social media messages to send.`,
        descriptionShort: '<p>Your trial expires on <strong>{{expireDate}}</strong></p><p>You get 50 free social media messages to send.</p>',
        subscribe: 'Sign Up For A Paid Account',
        doNotSubscribe: 'Continue'
      },
      trialExpired: {
        title: 'Your Free Trial Has Expired',
        subscribe: 'Sign Up Now For a Paid Account',
        doNotSubscribe: 'Contact Sales'
      },
      bfLogin: {
        error: 'Can\'t login with Facebook. '
      },
      twitterLogin: {
        error: 'Can\'t login with Twitter. '
      },
      linkedinLogin: {
        error: 'Can\'t login with Linkedin. '
      },
      googlePlusLogin: {
        error: 'Can\'t login with Google Plus. '
      },
      emailLogin: {
        error: 'Your email or password is incorrect. If you have forgotten your password please <a href="https://cloud.campaignwarrior.com/reset/pass">reset it here</a>.'
      }
    },
    selectPortal: {
      title: 'Select a Portal',
      openBtnTitle: 'Open',
      logout: 'Sign Out',
      createNewPortal: 'Add New'
    },
    portals: {
      noPortals: 'Please Create A Portal',
      create: {
        step1: {
          title: 'Add a New Portal',
          subTitle: 'Step 1',
          description: 'Adding a new portal will give you a whole new workspace for a different domain. It’s perfect for agencies and businesses that have multiple websites.',
          form: {
            submit: 'Add New',
            cancel: 'Cancel',
            labels: {
              title: 'Create New Portal Name*',
              description: 'Describe Your Portal'
            }
          },
          confirmModal: {
            title: 'Add Another Client Portal?',
            subtitle: '$19 USD Per Month',
            description: 'Your new portal will get added to your monthly charged subscription.',
            tos: {
              agreement: 'I Agree to the',
              self: 'Terms and Conditions'
            },
            cancelCreate: 'Cancel',
            confirmCreate: 'Add Portal'
          }
        },
        step2: {
          title: 'Add a Domain',
          subTitle: 'Step 2',
          description: 'Connect Campaign Warrior to your website to import your existing web pages',
          form: {
            submit: 'Next Step',
            cancel: 'Cancel',
            placeholders: {
              url: 'www.example.com'
            },
            labels: {
              title: 'Website Url'
            }
          }
        },
        back: 'Back',
        wizard: {
          summary: {
            title: 'Great! Your portal is being set up',
            desc: 'Click below to get started',
            stepName: 'Confirm',
            stepIcon: 'fa-user-check',
            portalName: 'Portal Name',
            emptyValue: {
              blackoutTimes: 'No blackout times chosen'
            }
          },
          tracking: {
            title: 'Insert Tracking Code',
            desc: 'The website scraper gives you a list of your website pages, so you can post to social quickly. ' + 'The tracking pixel adds your new pages each time a page is visited, it will automatically be added to your web page list.' + 'Place this tracking code below in the html header or footer of your site, and it will update your list automatically.' + 'If you have any problems, click on the chat box and let us know, as we would be delighted to help.'
          },
          setName: {
            title: 'Let\'s Get Started',
            desc: 'Enter a new for your new Portal',
            stepName: 'Setup your Portal Name',
            stepIcon: 'fa-font',
            errorMessage: 'Please Name Your Portal'
          },
          setDomain: {
            stepName: 'Connect your Website',
            title: 'Connect a Domain',
            desc: 'Enter your website address to find your pages',
            stepIcon: 'fa-sitemap',
            errorMessage: 'Please enter your website address'
          },
          setLogo: {
            title: 'Make it Yours',
            desc: 'Include a logo of your business',
            stepName: 'Upload your Portal Logo',
            stepIcon: 'fa-file-upload',
            errorMessage: 'Your Portal must have a logo'
          }
        }
      },
      portal: {
        topNav: {
          updateStats: {
            success: 'Update done'
          }
        },
        sandboxWarning: {
          message: 'This portal belongs to a sandboxed account, it may be terminated without notice.'
        },
        home: {
          new: {
            sections: {
              gettingStarted: 'Getting Started',
              everyDayActions: 'Every Day Actions',
              settings: 'Settings'
            },
            gettingStarted: {
              titles: {
                subscribeNow: 'Subscribe Now',
                addPortals: 'Add Portals',
                importWebPages: 'Import Web Pages',
                scrapeWebPages: 'Scrape Web Pages',
                addSocialAccount: 'Add Social Account',
                tutorial: 'View Tutorial'
              },
              descriptions: {
                subscribeNow: 'Sign up for a real unlimited portal. $19 USD a month per portal subscription.',
                addPortals: 'Add another portal for $19 USD a Month. Added to your monthly subscription.',
                importWebPages: 'Upload a list of URLs to be loaded ready for social publishing.',
                scrapeWebPages: 'Enter a domain and we find all the web pages in your page index.',
                addSocialAccount: 'Connect Twitter, Facebook or LinkedIn accounts to your publishing arsenal.',
                tutorial: 'See a step by step tutorial on the key steps to get going with Campaign Warrior.'
              },
              buttons: {
                subscribeNow: 'Sign Up',
                addPortals: 'Add Portal',
                importWebPages: 'Upload Web Pages',
                scrapeWebPages: 'Scrape Domain',
                addSocialAccount: 'Add Accounts',
                tutorial: 'Show Tutorial'
              }
            },
            everyDayActions: {
              titles: {
                listWebPages: 'List Web Pages',
                addWebPage: 'Add Web Page'
              },
              descriptions: {
                listWebPages: 'See the List of all of your web pages. '
              },
              buttons: {
                listWebPages: 'List Web Pages',
                addWebPage: 'Add Web Page'
              }
            },
            settings: {
              titles: {
                manageTags: 'Manage Tags',
                addUsers: 'Add Users',
                viewBilling: 'View Billing',
                portalSettings: 'Portal Settings'
              },
              descriptions: {
                manageTags: 'Tags are used to filter your content. Create and Edit Tags as you need to.',
                addUsers: 'Add User to your portal at no extra charge. Set up their permissions.',
                viewBilling: 'View your account details. Change the billing details, upgrade or downgrade your account.',
                portalSettings: 'Change the colors of your portal. Add your own icon. Make each brand obvious.'
              },
              buttons: {
                manageTags: 'Edit Tags',
                addUsers: 'Add Users',
                viewBilling: 'View Billing',
                portalSettings: 'Change Settings'
              }
            }
          },
          headers: {
            actions: 'Actions',
            settings: 'Settings'
          },
          actions: {
            pages: 'View Web Pages',
            tags: 'Manage Tags'
          },
          settings: {
            settings: 'Settings',
            importPages: 'Import Web Pages',
            accounts: 'Connect Accounts'
          }
        },
        edit: {
          title: 'Portal Settings',
          form: {
            submit: 'Save',
            cancel: 'Cancel',
            delete: 'Delete Portal',
            labels: {
              title: 'Portal title',
              description: 'Describe Your Portal',
              contactEmail: 'Contact Email',
              contactName: 'Contact Name',
              logo: 'Logo',
              currentLogo: 'Current Logo',
              currentlyUsedLogo: 'It\'s already used',
              portalLogo: 'Portal Logo',
              customerLogo: 'Customer Logo',
              agencyLogo: 'Your Logo',
              picture: 'Picture',
              menuBarBackground: 'Menu Background',
              menuDropBackground: 'Menu Dropdown',
              menuHighlight: 'Menu Highlight',
              menuFont: 'Menu Font Colour',
              upload: 'Change'
            },
            success: 'Portal is updated',
            selectedNewLogo: 'Select another logo from your media-library',
            selectedNewPicture: 'Select another picture from your media-library'
          },
          delete: {
            confirm: {
              title: 'Delete Portal Confirmation',
              body: `You are about to delete a portal. You will lose all related to this portal data.<br>
            Please enter a portal-name you want to delete:`,
              placeholder: 'Enter portal name here',
              confirm: 'Delete Portal',
              decline: 'Cancel'
            }
          }
        },
        nav: {
          titles: {
            portals: 'Portals',
            home: 'Home',
            stats: 'Stats',
            webpages: 'Web Pages',
            tags: 'Tags',
            accounts: 'Accounts and Integrations',
            settings: 'Settings'
          },
          accounts: {
            update: 'Update Stats'
          },
          pages: {
            list: 'List',
            addPage: 'Add Page',
            addPages: 'Import URLs',
            scraping: 'Site Scraping'
          },
          settings: {
            users: 'Users',
            addUser: 'Add User'
          },
          portals: {
            noPortals: 'No Portals'
          },
          refresh: 'Refresh Stats',
          help: 'Help',
          domains: {
            manage: 'Domains'
          }
        }
      }
    },
    domains: {
      title: 'Manage Connected Domains',
      description: 'Connect your domain to Campaign Warrior to import your existing content.',
      list: {
        title: 'All Domains'
      },
      stats: {
        title: 'Domain Summary',
        labels: {
          url: 'URL',
          totalPages: 'Pages in Sitemap',
          processedPages: 'Processed Pages'
        }
      },
      create: {
        form: {
          title: 'Connect a New Domain',
          labels: {
            url: 'URL '
          },
          create: 'Connect'
        },
        step: {
          confirmModal: {
            title: 'Add Another Domain?',
            subtitle: '$6.99 Per Month',
            description: 'Your new domain will get added to your monthly charged subscription.',
            tos: {
              agreement: 'I Agree to the',
              self: 'Terms and Conditions'
            },
            cancelCreate: 'Cancel',
            confirmCreate: 'Add Domain'
          }
        }
      }
    },
    hashtags: {
      addList: {
        confirm: {
          yes: 'Save',
          no: 'Cancel'
        }
      },
      edit: {
        title: 'Edit Hashtag',
        save: 'Save',
        note: 'Note',
        name: 'Name'
      },
      create: {
        title: 'Create a New Hashtag',
        name: 'New Hashtag',
        note: 'Describe your Hashtag',
        save: 'Save Hashtag',
        cancel: 'Cancel'
      },
      atsigns: {
        create: {
          title: 'Create a New At Sign',
          name: 'New At Sign',
          note: 'Describe your At Sign',
          save: 'Save At Sign',
          cancel: 'Cancel'
        },
        edit: {
          title: 'Edit At Sign',
          save: 'Save',
          note: 'Note',
          name: 'Name'
        }
      }
    },
    pages: {
      best: 'Best',
      trending: 'Trending',
      favorites: 'Favorites',
      recentCreated: 'Recently Created Pages',
      noPages: 'No Pages to show',
      goToWebPage: 'Go to Web Page',
      deletePage: 'Delete',
      helperTitles: {
        webPages: 'Web Pages List',
        filters: 'Filters'
      },
      list: {
        title: 'List of Web Pages'
      },
      refreshPageMeta: 'Refresh Metadata',
      helpers: {
        webPages: 'This is where you have full control of your website pages. The approved and published web pages are a fantastic source of content. You can apply powerful filters to quickly find your web pages, then tag them so they can be grouped.',
        filters: 'Use the filters to find a set of pages. The statistics update for the found set of pages. You can quickly apply tags to the pages so you can easily recall the pages your have isolated, for future reference.'
      },
      filters: {
        title: 'Filters',
        tags: 'Show Tags',
        omitTags: 'Omit Tags',
        name: 'Title',
        url: 'Url',
        domain: 'Domain',
        metaCount: 'Meta Description Count',
        metaDescriptions: 'Meta description',
        metricsFilter: {
          viewsCount: 'Pages with <strong>Views</strong> count greater than 0 are shown'
        },
        submit: 'Submit',
        reset: 'Reset'
      },
      summary: {
        columns: {
          title: 'Title',
          shortUrl: 'Short URL'
        },
        title: 'Web Pages Summary',
        views: 'Web Page Views',
        pages: 'Count of Pages',
        onlyForFiltered: 'Only for Filtered Pages'
      },
      addTags: {
        title: 'Apply Tags for filtered Pages',
        subTitle: 'Add Tags',
        description: 'Auto Add Tags based on the filtered set you have found. This allows you to create sets of website pages and report based on the performance of the sets.',
        submit: 'Apply Tags',
        confirmation: {
          one: 'Tags "{{tags}}" will be added to the page. Proceed?',
          other: 'Tags "{{tags}}" will be added to the {{count}} pages. Proceed?'
        },
        complete: {
          one: 'One page is updated',
          other: '{{count}} pages are updated'
        }
      },
      webView: {
        title: 'Web View',
        pageNotLoaded: 'Page may be not loaded. There are few possible reasons - URL is invalid, page does not respond or it\'s not allowed to load it in the iframe.'
      },
      metaImage: {
        title: 'Page meta image',
        imageNotLoaded: 'Image may be not loaded. There are few possible reasons - URL is invalid or page does not respond.'
      },
      create: {
        title: 'Add a Page',
        form: {
          pageInfoIsNotLoaded: 'Page title and description can\'t be loaded',
          emptyDescription: 'Description is empty for this page',
          notProvidedDescription: 'Description is missing for this page',
          emptyTitle: 'Title is empty for this page',
          notProvidedTitle: 'Title is missing for this page',
          addPageName: 'Add This Page Name',
          addMetaDescription: 'Add This Meta Description',
          labels: {
            title: 'Page Name',
            url: 'URL',
            metaPicture: 'Meta Picture',
            metaDescription: 'Meta Description',
            tags: 'Tags',
            otherImages: 'Other Images and Alt Tags'
          },
          create: 'Add Web Page',
          edit: 'Save'
        }
      },
      edit: {
        title: 'Single Web Page',
        description: 'View the chose web page. See the meta picture and the meta data. Tag your pages indvidually. This view lets you see the past and future social posts for this website page as well as the page itself, in a static iFrame.',
        previewTitle: 'Meta Picture',
        buttons: {
          deletePage: 'Delete',
          refreshMeta: 'Refresh Meta',
          viewWebPage: 'View Web Page',
          addNote: 'Add Note',
          backToList: 'Web Page List'
        },
        stats: {
          title: 'Statistics',
          webPageViews: 'Web Page Views',
          pageViews: 'Page Views',
          notes: 'Notes'
        }
      },
      delete: {
        confirm: {
          title: 'Delete Page Confirmation',
          body: `You are about to delete a page. You will lose all related to this page data.<br>
           Please enter a DELETE to delete a web page:`,
          placeholder: 'Enter page name here',
          confirm: 'Delete Page',
          decline: 'Cancel'
        }
      },
      addList: {
        noUrlsModal: {
          body: 'There are no URLs. Did you provide URLs one per line and without any delimiters?'
        },
        form: {
          labels: {
            links: 'Paste in Links You Wish to Import'
          },
          placeholders: {
            links: 'Enter links you want to add one per line'
          },
          submit: 'Import',
          reset: 'Reset',
          uploadCSVFile: 'Upload CSV File for Import'
        },
        confirm: {
          title: 'Confirm selected pages',
          yes: 'Confirm',
          no: 'Decline'
        },
        results: {
          addedPages: {
            one: 'One page is added.',
            other: '{{count}} pages are added.'
          }
        }
      },
      scraping: {
        scrapeModal: {
          title: 'Scraping your web page index'
        },
        jobs: {
          title: 'Scrape Jobs',
          table: {
            titles: {
              domain: 'Domain',
              type: 'Type',
              status: 'Status',
              startDate: 'Start Date',
              endDate: 'End Date',
              urlsCount: 'URLs Count'
            },
            addPagesCell: {
              tooltip: 'Add mapped URLs to the Portal\'s Web Pages',
              btnText: 'Add'
            }
          }
        },
        manageScrapeJob: {
          sitemapTitle: 'Import Pages From Your Web Index',
          form: {
            create: 'Import',
            edit: 'Update Scrape Job',
            labels: {
              type: 'Type',
              url: 'URL'
            },
            description: {
              url: 'e.g. https://yourdomain.com'
            }
          }
        }
      },
      imageList: {
        picture: 'Picture',
        altTag: 'Alt Tag',
        noAltTag: 'Alt Tag Missing',
        addToLibraryButton: '+ Add To Library'
      }
    },
    tags: {
      tag: 'Tag',
      all: {
        title: 'All',
        table: {
          titles: {
            edit: 'Edit',
            delete: 'Delete'
          }
        }
      },
      recent: {
        title: 'Recent',
        createdAt: 'Created At'
      },
      popular: {
        title: 'Popular',
        usageCount: 'Usage Count'
      },
      create: {
        title: 'Add Tag',
        form: {
          labels: {
            name: 'Tag',
            color: 'Color'
          },
          create: 'Create',
          edit: 'Save'
        },
        modal: {
          titles: {
            create: 'Create Tag',
            edit: 'Edit Tag'
          }
        }
      },
      addMany: {
        title: 'Add Many Tags',
        submit: 'Add Tags',
        upload: 'Upload CSV File',
        form: {
          labels: {
            tags: 'Tags'
          },
          placeholders: {
            tags: 'Enter Tags you want to add one per line'
          }
        },
        confirm: {
          title: 'Confirm selected Tags',
          yes: 'Confirm',
          no: 'Decline'
        }
      },
      delete: {
        form: {
          delete: 'Delete'
        }
      },
      edit: {
        form: {
          edit: 'Edit'
        }
      },
      manageRelatedTags: {
        suggestion: 'Create a new tag "{{tagName}}" and add it'
      }
    },
    user: {
      topMenu: {
        profile: 'Your Profile',
        billing: 'Billing Settings',
        portalSetup: 'Portal Setup',
        userManagement: 'User Management',
        addPortal: 'New Portal',
        help: 'Help',
        docs: 'Knowledge Base',
        support: 'Contact Support',
        logout: 'Sign Out'
      },
      form: {
        title: 'Profile Info',
        titlePasswordChange: 'Change Password',
        labels: {
          firstName: 'First Name',
          lastName: 'Last Name',
          company: 'Company Name',
          username: 'Email',
          password: 'Current Password',
          newPassword: 'New password',
          verifyPassword: 'Confirm password',
          country: 'Country'
        },
        uploadAvatar: 'Change profile picture',
        currentAvatar: 'Profile Picture',
        edit: 'Save',
        create: 'Create',
        change: 'Change'
      },
      uploadProcess: {
        title: 'Uploading Photo'
      },
      save: {
        success: 'Your information is updated'
      }
    },
    users: {
      list: {
        title: 'Portal Users',
        email: 'Email',
        firstName: 'First Name',
        lastName: 'Last Name',
        role: 'Role'
      },
      manage: {
        role: 'Role'
      },
      invite: {
        title: 'Invite User to Portal',
        role: 'Role'
      }
    },
    accounts: {
      nav: {
        titles: {
          all: 'List All',
          linkedin: 'Linkedin',
          facebook: 'Facebook',
          twitter: 'Twitter',
          add: 'Add New Account'
        }
      },
      add: {
        title: 'Add New Account',
        fbTable: {
          titles: {
            id: 'ID',
            name: 'Name',
            description: 'Description',
            about: 'About',
            category: 'Category'
          }
        },
        linkedInTable: {
          titles: {
            id: 'ID',
            logo: 'Logo',
            name: 'Name'
          }
        },
        hubspotTable: {
          titles: {
            id: 'ID',
            name: 'Name',
            description: 'Description',
            about: 'About',
            category: 'Category',
            logo: 'Logo',
            hubspot: 'Hubspot',
            accountType: 'Type'
          }
        },
        gaTable: {
          titles: {
            name: 'Name',
            gaId: 'Google Analytics ID',
            websiteUrl: 'Website URL'
          }
        }
      }
    },
    snippets: {
      create: {
        title: 'Create Snippet',
        message: 'Snippet',
        cancel: 'Cancel',
        save: 'Save'
      }
    },
    notes: {
      create: {
        description: 'Note',
        save: 'Save',
        cancel: 'Cancel',
        title: 'Add a Note',
        assignTo: 'Assign To'
      }
    },
    errors: {
      description: 'This field',
      inclusion: '{{description}} is not included in the list',
      exclusion: '{{description}} is reserved',
      invalid: '{{description}} is invalid',
      confirmation: '{{description}} doesn\'t match {{on}}',
      accepted: '{{description}} must be accepted',
      empty: '{{description}} can\'t be empty',
      blank: '{{description}} can\'t be blank',
      present: '{{description}} must be blank',
      collection: '{{description}} must be a collection',
      singular: '{{description}} can\'t be a collection',
      tooLong: '{{description}} is too long (maximum is {{max}} characters)',
      tooShort: '{{description}} is too short (minimum is {{min}} characters)',
      before: '{{description}} must be before {{before}}',
      after: '{{description}} must be after {{after}}',
      wrongDateFormat: '{{description}} must be in the format of {{format}}',
      wrongLength: '{{description}} is the wrong length (should be {{is}} characters)',
      notANumber: '{{description}} must be a number',
      notAnInteger: '{{description}} must be an integer',
      greaterThan: '{{description}} must be greater than {{gt}}',
      greaterThanOrEqualTo: '{{description}} must be greater than or equal to {{gte}}',
      equalTo: '{{description}} must be equal to {{is}}',
      lessThan: '{{description}} must be less than {{lt}}',
      lessThanOrEqualTo: '{{description}} must be less than or equal to {{lte}}',
      otherThan: '{{description}} must be other than {{value}}',
      odd: '{{description}} must be odd',
      even: '{{description}} must be even',
      positive: '{{description}} must be positive',
      date: '{{description}} must be a valid date',
      onOrAfter: '{{description}} must be on or after {{onOrAfter}}',
      onOrBefore: '{{description} must be on or before {{onOrBefore}}',
      email: '{{description}} must be a valid email address',
      phone: '{{description}} must be a valid phone number',
      url: '{{description}} must be a valid url',
      uniqBy: '{{description}} with value "{{value}}" already exists',
      uniqPortalName: 'Portal with name "{{value}}" already exists',
      uniqFsItem: {
        dir: 'Directory with name "{{value}}" already exists',
        file: 'File with name "{{value}}" already exists'
      },
      arraySize: {
        is: '{{description}} must have {{is}} items',
        min: '{{description}} must have at least {{min}} items',
        max: '{{description}} must have less than {{max}} items'
      },
      complexDate: {
        lowerThanCurrent: 'Date and time are less than current'
      }
    },
    settings: {
      users: {
        title: 'Users',
        addUser: 'Add User',
        table: {
          titles: {
            email: 'Email',
            firstName: 'First Name',
            lastName: 'Last Name',
            country: 'Country'
          }
        },
        user: {
          title: '{{firstName}} {{lastName}} ({{email}})',
          updatePermissions: {
            submit: 'Update Permissions',
            success: 'Permissions for {{user}} are updated',
            noPermissionsPerPortal: 'No Permissions',
            noPortals: 'You didn\'t create any Portal yet'
          }
        }
      },
      addUser: {
        title: 'Add User',
        inviteSentModal: {
          body: 'Invite successfully sent to {{email}}'
        },
        form: {
          titles: {
            globalPermissions: 'Global Permissions',
            permissionsPerPortal: 'Permissions per Portal'
          },
          labels: {
            email: 'Email',
            submit: 'Send Invite'
          },
          descriptions: {
            invite_user: 'Allows to invite users into system',
            grant_invite_user: 'User will be able to allow invited users to invite another users into system',
            create_portal: 'Allows to create portal inside company',
            grant_create_portal: 'User will be able to allow invited users to create portal inside company',
            view_portal: 'Allows to view portal and all related to portal resources',
            grant_view_portal: 'User will be able to allow invited users to view portal and all related to portal resources',
            media: 'Allows to update media entities - files and dirs',
            grant_media: 'User will be able to allow invited users to update media entities - files and dirs',
            admin_media: 'Allows to create/delete media files and dirs',
            grant_admin_media: 'User will be able to allow invited users to create/delete media files and dirs',
            scraping: 'Allows to update options of existing scrape-jobs',
            grant_scraping: 'User will be able to allow invited users to update options of existing scrape-jobs',
            admin_scraping: 'Allows to create/delete scrape-jobs',
            grant_admin_scraping: 'User will be able to allow invited users to create/delete scrape-jobs'
          }
        }
      }
    },
    modals: {
      defaultConfirmOptions: {
        title: 'Confirm',
        body: 'Are you sure?',
        confirm: 'Yes',
        decline: 'No'
      },
      defaultAlertOptions: {
        title: 'Info',
        confirm: 'Ok'
      },
      defaultErrorOptions: {
        title: 'Error'
      },
      process: {
        cancel: 'Cancel Process'
      }
    },
    invite: {
      confirmation: {
        title: 'Confirm Your Invite',
        description: 'Please, fill the form bellow to complete your registration. <br> After it\'s completed you will be able to login with your credentials',
        form: {
          labels: {
            name: 'First Name',
            surname: 'Last Name',
            password: 'Password',
            confirmPassword: 'Confirm Password',
            country: 'Country'
          },
          submit: 'Create Account'
        }
      }
    },
    ajaxErrors: {
      goBack: 'Back'
    },
    billing: {
      accountInfo: {
        title: 'Subscriptions & Billing',
        subscriptionInfo: {
          title: 'Subscription Information',
          costPerPortal: 'Cost Per Portal',
          costPerPortalPeriod: 'Month',
          numberOfPortals: 'Number of Portals',
          monthlyCost: 'Monthly Total',
          renewalDate: 'Renewal Date',
          planName: 'Plan Name',
          additionalDomains: 'Additional Domains',
          additionalDomainsCostEach: 'Cost Per Additional Domain',
          perMonth: '/ Month',
          additionalImpressionsBlocks: 'Additional Impressions',
          additionalPages: 'Additional Pages',
          additionalBlocksCostEach: 'Cost Per Additional Impressions Block'
        },
        cardAndBilling: {
          billingTitle: 'Billing Information',
          company: 'Company',
          name: 'Name',
          address: 'Address',
          city: 'City',
          state: 'State',
          postcode: 'Postcode',
          country: 'Country',
          phone: 'Phone',
          cardDetails: {
            label: 'Credit Card Information',
            cardNumber: 'Card Number',
            expiry: 'Expiry'
          }
        }
      },
      title: 'Subscription Information',
      price: '$49 Per Month',
      current: 'Your current plan is <strong>{{plan}}</strong>',
      status: 'Your plan\'s current status is <strong>{{status}}</strong>',
      plans: {
        base: 'Return to Base',
        premium: 'Get Premium'
      },
      actions: {
        cancelPlan: 'Cancel Plan',
        reactivatePlan: 'Reactivate Plan'
      },
      securedBy: {
        self: 'Secured by',
        brainTree: 'Braintree'
      },
      form: {
        title: 'Payment Information',
        trialExpired: 'Your trial period is expired. Please, upgrade your plan.',
        labels: {
          firstName: 'First Name',
          lastName: 'Last Name',
          postalCode: 'Zip Code',
          address: 'Address',
          city: 'City',
          state: 'State',
          country: 'Country',
          coupon: 'Coupon Code',
          cardInfo: 'Credit Card Info'
        },
        recurlyError: {
          code: 'Code',
          fields: 'Fields'
        },
        submit: 'Let\'s Get Started'
      },
      updateAccountForm: {
        title: 'Update Account Info',
        cancel: 'Cancel',
        update: 'Update'
      },
      updateCardForm: {
        title: 'Update Credit Card Details',
        cancel: 'Cancel',
        update: 'Update'
      }
    },
    cronInput: {
      every: 'Every',
      at: 'at',
      on: 'on',
      minutesForHour: 'minutes past the hour'
    },
    errorCodes: {
      undefined: {
        message: 'An internal error occurred, please try again later.',
        severity: 1
      },
      register: {
        accountExists: {
          message: 'This email address is already in use. Please log in',
          severity: -1
        },
        unknownError: {
          message: 'An Error Occurred.',
          severity: 0
        },
        inviterNotExists: {
          message: 'This Invitation is invalid.',
          severity: 0
        }
      },
      login: {
        loginFailed: {
          message: 'Failed to login.',
          severity: -1
        },
        wrongAuthType: {
          message: 'Try logging in with a social channel.',
          severity: -1
        },
        tokenInvalid: {
          message: 'Failed to exchange security token.',
          severity: 0
        }
      },
      resetPw: {
        invalid: {
          message: 'This reset token is not valid for this account',
          severity: -1
        },
        passwordMismatch: {
          message: 'Your password and confirm password don\'t match',
          severity: -1
        }
      },
      api: {
        resourceNotFound: {
          message: 'A Required Resource Not Found',
          severity: 1
        },
        missingRequiredRelationship: {
          message: 'A required relationship was missing between entities',
          severity: 1
        },
        getAllRestricted: {
          message: 'You are not authorized to perform take this action.',
          severity: 1
        },
        unknownFilterProperty: {
          message: 'You cannot filter based on this property.',
          severity: 1
        },
        keyNotString: {
          message: 'Cannot search on non-text fields',
          severity: 1
        },
        keyInvalidRef: {
          message: 'Contained reference to an invalid object',
          severity: 1
        },
        cannotSearch: {
          message: 'Tried to search the unsearchable',
          severity: 1
        },
        invalidRef: {
          message: 'Reference to invalid object',
          severity: 1
        },
        invalidPattern: {
          message: 'Invalid Number Pattern',
          severity: 1
        }
      }
    },
    tooltips: {
      pages: {
        list: {
          magicCopy: 'Copy the link to your clipboard',
          domains: 'Manage your connected domains',
          refreshList: 'Refresh your list of web pages',
          mostViewed: 'Your most viewed website pages',
          oversizeMetaAudit: 'View your pages with oversized Meta count',
          undersizeMetaAudit: 'View your pages with undersized Meta count',
          export: 'Export your filtered web pages as HTML or CSV',
          resetFilters: 'Reset filters',
          submitFilters: 'Submit filters'
        }
      }
    }
  };
  _exports.default = _default;
});