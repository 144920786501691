define("campaign-warrior-app/utils/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nmbrs = nmbrs;

  /**
   * @param {number} count how many items should be in the result
   * @param {boolean} zeroFirst should `0` be added to the `0` - `9`
   * @param {boolean} skipZero should `0` be added to the list
   * @returns {Ember.Computed} computed property
   */
  function nmbrs(count, zeroFirst = true, skipZero = false) {
    return Ember.computed(function () {
      let ret = [];
      const c = Number(count);

      for (let i = 0; i < c; i++) {
        ret.push(i < 10 && zeroFirst ? `0${i}` : `${i}`);
      }

      if (skipZero) {
        ret = ret.slice(1);
        ret.push(`${c}`);
      }

      return ret;
    }).readOnly();
  }
});