define("campaign-warrior-app/components/media/upload-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UploadLogo = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class UploadLogo extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modalsManager", _descriptor, this);

      _defineProperty(this, "readType", 'readAsDataURL');

      _defineProperty(this, "accept", '.jpg, .png, .jpeg, .gif');

      _defineProperty(this, "text", '');

      _defineProperty(this, "upload", null);

      _defineProperty(this, "onLoad", null);

      _defineProperty(this, "previewSrc", null);
    }

    openFileBrowser() {
      this.$('input[type="file"]').click();
    }

    async startUpload() {
      const input = this.$('input[type="file"]');

      if (input[0] && input[0].files && input[0].files.length) {
        let file = input[0].files[0];
        this.readFile(file);

        const clearFile = () => {
          const fileInput = this.$('.file-input');

          if (fileInput) {
            fileInput.val('');
          }
        };

        if (file && file.size >= 1048576) {
          // 1 megabyte limit
          clearFile();
          this.modalsManager.ajaxErrorHandler({
            message: 'File is too large, there is a 1MB limit'
          });
          file = null;
          Ember.set(this, 'previewSrc', null);
          return;
        } else {
          this.updatePreview(file);
        }

        const upload = Ember.get(this, 'upload');
        let r;

        if (upload) {
          r = upload(file).then(() => input.val(''));
        }

        if (r && r.then && r.catch) {
          r.then(clearFile).catch(clearFile);
        } else {
          clearFile();
        }
      }
    }

    readFile(input) {
      const reader = new FileReader();

      reader.onload = e => {
        const fileContent = e.target.result;

        if (this.onLoad) {
          this.onLoad(fileContent);
        }
      };

      Ember.tryInvoke(reader, this.readType, [input]);
    }

    updatePreview(input) {
      const reader = new FileReader();

      reader.onload = e => {
        const fileContent = e.target.result;
        Ember.set(this, 'previewSrc', fileContent);

        if (this.onLoad) {
          this.onLoad(fileContent);
        }
      };

      Ember.tryInvoke(reader, this.readType, [input]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openFileBrowser", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "openFileBrowser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startUpload", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "startUpload"), _class.prototype)), _class));
  _exports.default = UploadLogo;
});