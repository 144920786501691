define("campaign-warrior-app/templates/components/notification-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dTN/PMai",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"notification-area\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"notification-display \",[34,0,[\"type\"]]]]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"notification-controls\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,0,\"dismiss btn btn-primary btn-xs\"],[4,[38,1],[[32,0],\"dismissNotification\",[35,0]],null],[12],[10,\"i\"],[14,0,\"fa fa-times\"],[12],[13],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"message-wrapper\"],[12],[2,\"\\n        \"],[1,[35,0,[\"message\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"notification\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/notification-display.hbs"
    }
  });

  _exports.default = _default;
});