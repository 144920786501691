define("campaign-warrior-app/helpers/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleize = titleize;
  _exports.default = void 0;

  /**
   * Capitalize first letter in the every word
   *
   * Usage:
   *
   * ```hbs
   * {{titleize someVal}}
   * ```
   *
   * Input - `some value`
   *
   * Output - `Some Value`
   *
   * @namespace Helpers
   * @class Titleize
   *
   * @param {string} str string to titleize
   * @returns {string} titelized string
   */
  function titleize([str]
  /*, hash*/
  ) {
    if (Ember.String.isHTMLSafe(str)) {
      str = str.string;
    }

    if (!str) {
      return '';
    }

    return str.toLowerCase().replace(/(?:^|\s|-|\/)\S/g, m => m.toUpperCase());
  }

  var _default = Ember.Helper.helper(titleize);

  _exports.default = _default;
});