define("campaign-warrior-app/templates/components/page-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HqT8LUVT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"logo-wrapper text-center\"],[12],[10,\"img\"],[14,0,\"cw-logo\"],[14,\"src\",\"https://d25xioqhg7be2.cloudfront.net/static-assets/cw-logo.png\"],[15,\"alt\",[30,[36,0],[\"logo.alt\"],null]],[12],[13],[13],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[34,1]],[2,\" \"],[10,\"strong\"],[12],[1,[34,2]],[13],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"headingOne\",\"headingTwo\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/page-header.hbs"
    }
  });

  _exports.default = _default;
});