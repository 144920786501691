define("campaign-warrior-app/components/create-portal/step1-form", ["exports", "ember-cp-validations", "campaign-warrior-app/components/floating-form", "jquery"], function (_exports, _emberCpValidations, _floatingForm, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * Form for first step in the Create-a-Portal wizard. It contains fields for portal's `name` and description.
   *
   * Validation is used for `name`. It's required and should be not greater than 25 symbols
   *
   * `initialData`-property can be used to set initial form values
   *
   * Usage
   *
   * ```hbs
   * {{create-portal/step1-form initialData=initialData onSubmit=(action "onSubmit")}}
   * ```
   *
   * @namespace Components.CreatePortal
   * @class Step1Form
   * @extends Components.FloatingForm
   */
  let Step1Form = (_dec = Ember.computed(), _dec2 = Ember._action, (_class = (_temp = class Step1Form extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "classNames", ['create-portal-step1-form setup-container']);

      _defineProperty(this, "submitInProgress", false);
    }

    get formData() {
      const Validations = (0, _emberCpValidations.buildValidations)({
        name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
          max: 100
        }), (0, _emberCpValidations.validator)('uniq-by', {
          modelName: 'portal',
          descriptionKey: 'errors.uniqPortalName'
        })]
      });
      return Ember.Object.extend(Validations, {}).create(Ember.getOwner(this).ownerInjection());
    }

    nextStep() {
      const dataToSend = Ember.getProperties(Ember.get(this, 'formData'), ['name']);
      Ember.set(this, 'submitInProgress', true); //console.log('On Submit Handler', dataToSend);

      Ember.get(this, 'onSubmit')(dataToSend);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "formData", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "formData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextStep", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "nextStep"), _class.prototype)), _class));
  _exports.default = Step1Form;
});