define("campaign-warrior-app/templates/portals/portal/setup/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VCQQ8M4/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"setup-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"setup-panel setup-complete\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-heading\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Step 4 \"],[10,\"span\"],[14,0,\"step-name\"],[12],[2,\"Portal Setup Complete\"],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-body\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"setup-complete-tick\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fal fa-check\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Congratulations\"],[13],[2,\"\\n      \"],[10,\"h4\"],[12],[2,\"Your Campaign Warrior Portal is now set up\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"Your website pages should appear within a minute or two\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-controls\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"portals.portal.pages.list\"]],[[\"default\"],[{\"statements\":[[2,\"Get Started\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/setup/complete.hbs"
    }
  });

  _exports.default = _default;
});