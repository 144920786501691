define("campaign-warrior-app/templates/components/tags-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5WLOP1lK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,7],null,[[\"title\",\"class\",\"name\",\"bodyComponent\"],[[35,6],\"branding\",[35,5],[30,[36,4],[\"models-table-server-paginated\"],[[\"data\",\"columns\",\"selectRowOnClick\",\"columnComponents\",\"showColumnsDropdown\",\"showGlobalFilter\",\"showPageSize\",\"showComponentFooter\",\"useFilteringByColumns\"],[[35,3],[35,2],false,[35,1],false,false,false,[35,0],false]]]]]]],[2,\"\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"showTableFooter\",\"columnComponents\",\"columns\",\"tags\",\"component\",\"panelName\",\"title\",\"cw-panel\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/tags-group.hbs"
    }
  });

  _exports.default = _default;
});