define("campaign-warrior-app/components/cw-table/expand-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Custom component to collapse/expand single row. Must used as `component` for cells in the table-body
   *
   * @class CwTableExpandToggle
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    actions: {
      collapseRow(index, record) {
        Ember.get(this, 'collapseRow')(index, record);
      },

      expandRow(index, record) {
        Ember.get(this, 'expandRow')(index, record);
      }

    }
  });

  _exports.default = _default;
});