define("campaign-warrior-app/templates/components/pages/page-image-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "knSuB6Us",
    "block": "{\"symbols\":[\"image\",\"&default\"],\"statements\":[[10,\"table\"],[14,0,\"table page-images-table\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[14,5,\"width: 30%;\"],[14,0,\"image-col\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"pages.imageList.picture\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[14,5,\"width: 60%;\"],[14,0,\"alt-col\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"pages.imageList.altTag\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[14,5,\"width:10%;\"],[14,0,\"magic-col\"],[12],[2,\"\\n\\n      \"],[13],[2,\"\\n      \"],[3,\"<th>\"],[2,\"\\n\\n      \"],[3,\"</th>\"],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[14,5,\"width: 30%;\"],[14,0,\"image-col\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"page-image\"],[15,5,[31,[\"background-image: url('\",[32,1,[\"src\"]],\"');\"]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[14,5,\"width: 60%;\"],[14,0,\"alt-col\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"alt\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[32,1,[\"alt\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"pages.imageList.noAltTag\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"td\"],[14,5,\"width: 10%;\"],[14,0,\"magic-col\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,0,\"btn btn-link\"],[4,[38,2],[[32,0],\"imageCopy\",[32,1]],null],[12],[2,\"Magic Copy\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\",\"images\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/page-image-list.hbs"
    }
  });

  _exports.default = _default;
});