define("campaign-warrior-app/templates/components/pages/delete-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "at2ofhfy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"a\"],[4,[38,0],[[32,0],\"hidePage\"],null],[12],[10,\"i\"],[14,0,\"fad fa-eye-slash\"],[14,5,\"cursor: pointer; margin-right: 5px;\"],[12],[13],[13],[2,\"\\n\"],[11,\"a\"],[4,[38,0],[[32,0],\"openModel\"],null],[12],[10,\"i\"],[14,0,\"fad fa-trash\"],[14,5,\"cursor: pointer;\"],[12],[13],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/delete-page.hbs"
    }
  });

  _exports.default = _default;
});