define("campaign-warrior-app/templates/components/action-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cS/lGMba",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"action-card \",[34,5]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"action-card-inner\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-col\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card-title\"],[12],[2,\"\\n        \"],[1,[34,6]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"subtext-wrapper\"],[12],[2,\"\\n\"],[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[14,0,\"card-icon\"],[12],[10,\"i\"],[15,0,[31,[[34,4]]]],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[14,0,\"subtext\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"              \"],[10,\"a\"],[15,6,[31,[[34,2]]]],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"              \"],[10,\"span\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"stat-col\"],[12],[2,\"\\n\"],[6,[37,3],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"stat-counter\"],[12],[2,\"\\n          \"],[1,[34,0]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"stat-value\"],[12],[2,\"\\n          \"],[1,[34,0]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"statValue\",\"subtext\",\"subtextLink\",\"if\",\"icon\",\"cardStyle\",\"title\",\"statCounter\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/action-card.hbs"
    }
  });

  _exports.default = _default;
});