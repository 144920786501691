define("campaign-warrior-app/templates/components/column-sort-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t45UoLWy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"column-sort \",[34,6],\" \",[30,[36,4],[[35,5],\"sort-active\"],null]]]],[12],[2,\"\\n\"],[6,[37,4],[[35,7,[\"useSorting\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[15,\"onclick\",[30,[36,3],[[32,0],\"sortProp\",[35,2]],null]],[12],[1,[34,0]],[2,\" \"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[15,0,[31,[[34,1]]]],[12],[13]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"sortIcon\",\"propertyName\",\"action\",\"if\",\"sortActive\",\"wrapperClass\",\"column\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/column-sort-header.hbs"
    }
  });

  _exports.default = _default;
});