define("campaign-warrior-app/adapters/post-job-instance", ["exports", "campaign-warrior-app/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PostJobInstance
   * @namespace Adapters
   * @extends Adapters.Application
   */
  var _default = _application.default.extend({
    /**
     * Send only `agendaJobId` and `postJobId` to cancel single post-job instance
     *
     * @method deleteRecord
     * @param {DS.Store} store store itself
     * @param {DS.Model} type 'post-job'
     * @param {DS.Snapshot} snapshot snapshot for record
     * @return {RSVP.Promise} request result
     */
    deleteRecord(store, type, snapshot) {
      return this.ajax('/schedule/cancel-item', 'POST', {
        data: {
          agendaJobId: snapshot.attr('agendaJobId'),
          postJobId: snapshot.belongsTo('postJob').id
        }
      });
    }

  });

  _exports.default = _default;
});