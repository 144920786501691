define("campaign-warrior-app/components/pages/pages-export-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    classNames: ['pages-export-modal'],
    page: null,
    actions: {
      exportHtml(modal) {
        this.ajax.post('/export/html', {
          data: {
            portal: this.currentPortal.portal.id,
            filters: this.options.filters ? this.options.filters : {}
          }
        }).then(function (response) {
          const htmlContent = response.htmlContent;
          const encodedUri = encodeURI(htmlContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', 'cw_pages_export_html.txt');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          modal.close();
        });
      },

      exportCSV(modal) {
        this.ajax.post('/export/csv', {
          data: {
            portal: this.currentPortal.portal.id,
            filters: this.options.filters ? this.options.filters : {}
          }
        }).then(function (response) {
          let csvContent = response.csvContent;
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', 'cw_pages_export.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          modal.close();
        });
      }

    }
  });

  _exports.default = _default;
});