define("campaign-warrior-app/templates/reset/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zfoppFMt",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"onSubmit\",\"token\"],[[30,[36,1],[[32,0],\"getNewPassword\"],null],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"token\",\"action\",\"verify-token-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/reset/verify.hbs"
    }
  });

  _exports.default = _default;
});