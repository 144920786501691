define("campaign-warrior-app/routes/portals/portal/accounts", ["exports", "campaign-warrior-app/mixins/portal-internal-route"], function (_exports, _portalInternalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PortalAccounts
   * @uses Mixins.PortalInternalRoute
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_portalInternalRoute.default, {
    breadCrumbRoutingKey: 'accounts'
  });

  _exports.default = _default;
});