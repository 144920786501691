define("campaign-warrior-app/templates/settings/users/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ovkNZmFX",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"expandable\",\"title\",\"class\",\"bodyComponent\"],[false,[30,[36,4],[\"settings.users.user.title\",[35,1]],null],\"branding\",[30,[36,3],[\"manage-permissions-form\"],[[\"portals\",\"userToManage\",\"onSubmit\"],[[35,2],[35,1],[30,[36,0],[[32,0],\"updatePermissions\"],null]]]]]]]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,6],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"portals\",\"component\",\"t\",\"cw-panel\",\"-outlet\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/settings/users/user.hbs"
    }
  });

  _exports.default = _default;
});