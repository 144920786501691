define("campaign-warrior-app/components/cw-table/tags-list", ["exports", "campaign-warrior-app/models/tag"], function (_exports, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```js
   * columns: [
   *   {
   *     component: 'cw-table/tags-list',
   *     propertyName: 'someName'
   *   }
   * ]
   * ```
   *
   * Component shows message from `service.SERVICE_NAME`, where `SERVICE_NAME` is a result of `get(record column.propertyName)`.
   * Both `record` and `column` are bound from `models-table`.
   *
   * @class CwTable.TagsList
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    tagsList: Ember.computed(function () {
      return Ember.get(this, 'column.tags');
    }),
    page: Ember.computed(function () {
      return Ember.get(this, 'record');
    }),
    actions: {
      openModel() {
        const tagsList = Ember.get(this, 'tagsList');
        const page = Ember.get(this, 'page');
        const tagsToAdd = [];
        tagsList.forEach(tag => {
          if (Ember.get(page, 'tags').indexOf(tag) === -1) {
            tagsToAdd.push(tag);
          }
        });
        /*
        let modalsManager = get(this, 'modalsManager');
        return modalsManager
          .show('create-tag-modal', {
            domains: '',
            record: '',
            tagsList: tagsToAdd,
            page: page,
          })
          .then(model => {})
        */
      },

      removeTag(tag) {
        // let modalsManager = get(this, 'modalsManager');
        // return modalsManager
        //   .confirm({
        //     title: get(this, 'i18n').t('modals.defaultConfirmOptions.title'),
        //     body: get(this, 'i18n').t('modals.defaultConfirmOptions.body'),
        //     confirm: get(this, 'i18n').t('modals.defaultConfirmOptions.confirm')
        //   })
        //   .then(() => {
        const page = Ember.get(this, 'page');

        if (Ember.isEmpty(tag) || Ember.isEmpty(page)) {
          return false;
        }

        let pageTags = Ember.get(page, 'tags');
        pageTags.removeObject(tag);
        page.save(); // })
        // .catch(e => modalsManager.detailedAjaxErrorHandler(e));
      },

      createTag() {
        const portal = Ember.get(this, 'portal');
        const store = Ember.get(this, 'store');
        const modalsManager = Ember.get(this, 'modalsManager');

        const _this = this;

        modalsManager.show('tags/create-tag-modal').then(res => {
          let tagName = res.name;
          const newTag = store.createRecord('tag', {
            name: tagName,
            color: _tag.default.getRandColor(),
            portal
          });
          newTag.save();

          _this.tagsList.addObject(newTag);

          _this.record.tags.addObject(newTag);
        }).then(() => {
          _this.send('refreshRoute');
        });
      }

    }
  });

  _exports.default = _default;
});