define("campaign-warrior-app/components/modals-container/base", ["exports", "@ember-decorators/component", "ember-modals-manager-internal/components/emmi-modals-container/base", "ember-bootstrap-modals-manager/templates/components/ebmm-modals-container/base"], function (_exports, _component, _base, _base2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let _BaseModal = (_dec = (0, _component.layout)(_base2.default), _dec(_class = class _BaseModal extends _base.default {}) || _class);

  _exports.default = _BaseModal;
});