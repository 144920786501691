define("campaign-warrior-app/controllers/settings/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class UsersSettings
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property userColumns
     * @type object[]
     * @readonly
     */
    userColumns: Ember.computed(function () {
      const i18n = Ember.get(this, 'i18n');
      return [{
        propertyName: 'picture.preview',
        component: 'cw-table/image-cell',
        useFilter: false,
        useSorting: false,
        title: '',
        style: {
          'max-width': '30px',
          'max-height': '30px'
        }
      }, {
        title: i18n.t('settings.users.table.titles.email'),
        propertyName: 'email',
        routeName: 'settings.users.user'
      }, {
        title: i18n.t('settings.users.table.titles.firstName'),
        propertyName: 'firstName'
      }, {
        title: i18n.t('settings.users.table.titles.lastName'),
        propertyName: 'lastName'
      }, {
        title: i18n.t('settings.users.table.titles.country'),
        propertyName: 'country'
      }];
    })
  });

  _exports.default = _default;
});