define("campaign-warrior-app/templates/components/wizard-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jmeRzgrE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"wizard-page step-\",[34,0,[\"stepnum\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"wizard-page-title\"],[12],[2,\"\\n    \"],[1,[35,0,[\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"step\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/wizard-page.hbs"
    }
  });

  _exports.default = _default;
});