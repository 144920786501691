define("campaign-warrior-app/routes/portals/create/setup-wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class SetupWizard
   * @namespace Routes.CreatePortal
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    can: Ember.inject.service(),

    beforeModel() {
      if (Ember.get(this, 'can').cannot('create portal')) {
        Ember.get(this, 'router').transitionTo('portals');
      }

      return this._super(...arguments);
    },

    model() {
      return Ember.get(this, 'store').findAll('portal');
    },

    activate() {
      console.log('Activate');
      const portalsCount = Ember.get(this, 'currentUser.user.company.billingInfo.portalsCount');
      const modalsManager = Ember.get(this, 'modalsManager');
      console.log('Portal Count', portalsCount);

      if (portalsCount >= 1) {
        return modalsManager.show('portals/confirm-create')
        /*.then(() => {
          console.log('setting focus...');
          set(get(this, 'controller'), 'focux', get(this, 'controller').setFocus());
        })*/
        .catch(() => Ember.get(this, 'router').transitionTo('portals'));
      }
    }

  });

  _exports.default = _default;
});