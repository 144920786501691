define("campaign-warrior-app/routes/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PortalSettings
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    model() {
      return Ember.get(this, 'store').findAll('portal');
    }

  });

  _exports.default = _default;
});