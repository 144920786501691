define("campaign-warrior-app/components/hashtags/create-hashtag-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['create-hashtag-form'],
    actions: {
      save() {
        try {
          let name = Ember.get(this, 'record.name').replace('#', '');
          Ember.set(this, 'record.name', name);
          return Ember.get(this, 'save')(Ember.get(this, 'record'));
        } catch (err) {
          console.error(err);
        }
      }

    }
  });

  _exports.default = _default;
});