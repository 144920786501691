define("campaign-warrior-app/models/post-job", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PostJob
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    /**
     * @property postOptions
     * @type object
     * @default null
     */
    postOptions: _emberData.default.attr(),

    /**
     * @property scheduleJobs
     * @type array
     * @default null
     */
    scheduleJobs: _emberData.default.attr(),

    /**
     * {{#crossLink "Models.PostJobInstance"}}Models.PostJobInstance{{/crossLink}}[]
     * @property postJobInstances
     * @type DS.HasManyRelationship
     */
    postJobInstances: _emberData.default.hasMany('post-job-instance'),

    /**
     * {{#crossLink "Models.Portal"}}Models.Portal{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property portal
     */
    portal: _emberData.default.belongsTo('portal'),

    /**
     * {{#crossLink "Models.PublishSource"}}Models.PublishSource{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property publishSources
     */
    publishSources: _emberData.default.hasMany('publish-source'),

    /**
     * {{#crossLink "Models.Tag"}}Models.Tag{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property tags
     */
    tags: _emberData.default.hasMany('tag'),

    /**
     * {{#crossLink "Models.Campaign"}}Models.Campaign{{/crossLink}}[]
     * @type DS.BelongsToRelationship
     * @property campaign
     */
    campaign: _emberData.default.belongsTo('campaign'),

    /**
     * {{#crossLink "Models.Page"}}Models.Page{{/crossLink}}[]
     * @type DS.BelongsToRelationship
     * @property page
     */
    page: _emberData.default.belongsTo('page'),

    /**
     * {{#crossLink "Models.File"}}Models.File{{/crossLink}}[]
     * @type DS.BelongsToRelationship
     * @property file
     */
    file: _emberData.default.belongsTo('file'),

    /**
     * @property scheduledOnce
     * @type boolean
     * @default false
     */
    scheduledOnce: Ember.computed.equal('scheduleJobs.firstObject.type', 'at'),

    /**
     * @property scheduledAt
     * @type string
     */
    scheduledAt: Ember.computed.alias('scheduleJobs.firstObject.time'),

    /**
     * @property scheduledPeriodically
     * @type boolean
     * @default false
     */
    scheduledPeriodically: Ember.computed.equal('scheduleJobs.firstObject.type', 'every'),

    /**
     * @property scheduledEvery
     * @type string
     */
    scheduledEvery: Ember.computed.alias('scheduleJobs.firstObject.interval'),

    /**
     * @property scheduledUntil
     * @type string
     */
    scheduledUntil: Ember.computed.alias('scheduleJobs.firstObject.expireDate'),

    /**
     * @property scheduledOnce
     * @type boolean
     * @default false
     */
    scheduledLinear: Ember.computed.equal('scheduleJobs.firstObject.type', 'linear'),

    /**
     * @property hasScheduleForToday
     * @type boolean
     * @default false
     */
    isScheduledForToday: Ember.computed('postJobInstances.@each.isToday', function () {
      return Ember.get(this, 'postJobInstances').isAny('isToday');
    }),

    /**
     * `scheduledAt / 1000`
     *
     * @property scheduledAtUnix
     * @type number
     * @default
     */
    scheduledAtUnix: Ember.computed('scheduledAt', function () {
      return Math.round(Number(Ember.get(this, 'scheduledAt')) / 1000);
    })
  });

  _exports.default = _default;
});