define("campaign-warrior-app/routes/portals/portal/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PortalPostsList
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    title: 'Users | Campaign Warrior',

    model() {
      const store = Ember.get(this, 'store');
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      return store.query('user', {
        filter: {
          portal: portal_id
        }
      });
    },

    actions: {
      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});