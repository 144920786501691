define("campaign-warrior-app/components/pages/panel-title", ["exports", "campaign-warrior-app/components/cw-panel/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _title.default.extend({
    /**
     * @type Models.Page[]
     * @default null
     * @property pages
     */
    pages: null,

    /**
     * @property filterForAll
     * @type boolean
     * @default null
     */
    filterForAll: true,

    /**
     * @property filterForNew
     * @type boolean
     * @default null
     */
    filterForNew: null,

    /**
     * @property filterForFavorite
     * @type boolean
     * @default null
     */
    filterForFavorite: null,

    /**
     * @property filterForHighest
     * @type boolean
     * @default null
     */
    filterForHighest: null,

    /**
     * @property filterForLowest
     * @type boolean
     * @default null
     */
    filterForLowest: null,
    filterForHidden: null
    /*
    actions: {
      updateFilters (el) {
        set(this, 'filterForAll', null);
        set(this, 'filterForNew', null);
        set(this, 'filterForFavorite', null);
        set(this, 'filterForHighest', null);
        set(this, 'filterForLowest', null);
        set(this, 'filterForHidden', null);
          const all = get(this, 'filterForAll');
        const new1 = get(this, 'filterForNew');
        const fvrt = get(this, 'filterForFavorite');
        const high = get(this, 'filterForHighest');
        const low = get(this, 'filterForLowest');
        const hidden = get(this, 'filterForHidden');
        if (el === 'all' && !all) {
          set(this, 'filterForAll', true);
          get(this, 'updateData')('all');
        } else if (el === 'new' && !new1) {
          set(this, 'filterForNew', true);
          get(this, 'updateData')('new');
        } else if (el === 'favorite' && !fvrt) {
          set(this, 'filterForFavorite', true);
          get(this, 'updateData')('fvrt');
        } else if (el === 'highest' && !high) {
          set(this, 'filterForHighest', true);
          get(this, 'updateData')('high');
        } else if (el === 'lowest' && !low) {
          set(this, 'filterForLowest', true);
          get(this, 'updateData')('low');
        } else if (el === 'hidden' && !hidden){
          set(this, 'filterForHidden', true);
          get(this, 'updateData')('hidden')
        }
        //console.log('Actions in panel title: ', el);
      }
    }*/

  });

  _exports.default = _default;
});