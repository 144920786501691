define("campaign-warrior-app/templates/components/pages/page-listing-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5LyxU5JR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-url\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"long-url\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[\"https://\",[34,0,[\"url\"]]]]],[14,\"target\",\"_blank\"],[12],[2,\" \"],[1,[34,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"short-url link-color\"],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"record\",\"relativeUrl\",\"relativeShortUrl\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/page-listing-url.hbs"
    }
  });

  _exports.default = _default;
});