define("campaign-warrior-app/components/pages/add-many-tags/progress-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AddManyTagsProgressBody
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property currentUrl
     * @type string
     * @default null
     * @private
     * @readonly
     */
    currentUrl: Ember.computed('done', 'options.pages.@each.url', function () {
      const pages = Ember.get(this, 'options.pages') || [];
      const done = Ember.get(this, 'done');
      const currentPage = pages.objectAt(done);
      return currentPage ? Ember.get(currentPage, 'url') : null;
    }).readOnly()
  });

  _exports.default = _default;
});