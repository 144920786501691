define("campaign-warrior-app/components/hashtags/edit-atsign-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    classNames: ['edit-atsign-form'],
    actions: {
      /**
       * @method actions.updateAttr
       * @param {string} attrName attribute name to update
       * @param {string} val new value
       */
      updateAttr(attrName, val) {
        Ember.set(this, `record.${attrName}`, val);
      },

      save(hashtag) {
        //console.log('editAtsignForm.save()', hashtag);
        try {
          return Ember.get(this, 'save')(Ember.get(this, 'record'));
        } catch (err) {
          console.error('edit-atsign-form error', err);
        }
      }

    },

    /**
     * @method updatePageInfo
     * @private
     */
    updateHashtagInfo() {
      Ember.run.debounce(this, 'updateHashtagInfoOnce', 250);
    },

    /**
     * @method updateHashtagInfoOnce
     * @private
     */
    updateHashtagInfoOnce() {// console.log('Update Hashtag Info', get(this, 'hashtag'));
    }

  });

  _exports.default = _default;
});