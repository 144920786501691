define("campaign-warrior-app/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service with information about current user. It may be used anywhere after user is authorized
   *
   * Usage:
   *
   * ```js
   * import Component from '@ember/component';
   * import {inject as service} from '@ember/service';
   * import {get} from '@ember/object';
   *
   * export default Component.extend({
   *   currentUser: service('current-user'),
   *
   *   someMethod() {
   *     get(this, 'currentUser.user.firstName');
   *   }
   * });
   * ```
   *
   * @class CurrentUser
   * @namespace Services
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    /**
     * @property session
     * @type Ember.Service
     */
    session: Ember.inject.service(),

    /**
     * @property store
     * @type Ember.Service
     */
    store: Ember.inject.service(),
    router: Ember.inject.service(),

    /**
     * Info about current user. Check [models/user](Models.User.html) for list of attributes
     *
     * @property user
     * @type Models.User
     * @default null
     */
    user: null,

    /**
     * Load info about current user (must be called only after user authorization)
     *
     * This method is already called on [routes/application](Routes.Application.html)
     *
     * @method load
     * @returns {Promise} request for current user
     */
    load() {
      const _this = this;

      if (Ember.get(this, 'session.isAuthenticated')) {
        const store = Ember.get(this, 'store');
        return store.queryRecord('user', {
          current: true
        }).then(user => {
          Ember.set(this, 'user', user);
          return user;
        }).then(user => store.findRecord('company', Ember.get(user, 'company.id'))).then(company => {
          store.findRecord('billing-info', Ember.get(company, 'billingInfo.id'));
          Ember.set(_this, 'company', company);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }

  });

  _exports.default = _default;
});