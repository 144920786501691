define("campaign-warrior-app/initializers/track-active-portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * Reopen Route to keep active Portal
   */
  function initialize() {
    // todo use `activePortalId` or `activePortal` everywhere where active portal is needed
    Ember.Route.reopen({
      /**
       * @for Ember.Route
       * @property activePortalId
       * @type string
       * @default null
       */
      activePortalId: Ember.computed.readOnly('activePortal.id'),

      /**
       * @for Ember.Route
       * @property activePortal
       * @type Models.Portal
       * @default null
       */
      activePortal: Ember.computed('router.currentURL', function () {
        const currentUrl = Ember.get(this, 'router.currentURL') || '';

        if (currentUrl.indexOf('portals/') !== -1) {
          return this.modelFor('portals.portal');
        }

        return null;
      }),

      /**
       * @for Ember.Route
       * @property activePortalQP
       * @type {{portal_id: string}}
       */
      activePortalQP: Ember.computed('activePortalId', function () {
        return {
          portal_id: Ember.get(this, 'activePortalId')
        };
      })
    });
  }

  var _default = {
    name: 'track-active-portal',
    initialize
  };
  _exports.default = _default;
});