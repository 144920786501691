define("campaign-warrior-app/routes/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: 'My Profile | Campaign Warrior',

    model() {
      return Ember.get(this, 'store').findAll('portal');
    }

  });

  _exports.default = _default;
});