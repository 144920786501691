define("campaign-warrior-app/initializers/extra-torii", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  //import FacebookPagesProvider from '../torii-providers/facebook-pages';

  /**
   * Initializer for registration torii-related factories
   *
   * @param {Ember.Application} application current application
   */
  function initialize(application) {//application.register('torii-provider:facebook-pages', FacebookPagesProvider);
  }

  var _default = {
    name: 'extra-torii',
    after: 'torii',
    initialize
  };
  _exports.default = _default;
});