define("campaign-warrior-app/services/modals-manager", ["exports", "campaign-warrior-app/services/modals-manager-solid"], function (_exports, _modalsManagerSolid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import ModalsManagerBootstrap from 'ember-bootstrap-modals-manager/services/modals-manager';

  /**
   * Extend original service to add some extra-modals
   *
   * @class ModalsManager
   * @namespace Services
   * @extends EmberBootstrapModalsManager.Services.ModalsManager
   */
  var _default = _modalsManagerSolid.default.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * Show simple alert-modal with description for ajax-error
     *
     * @method ajaxErrorHandler
     * @param {object} e error-object
     * @returns {RSVP.Promise} modal with error
     */
    ajaxErrorHandler(e) {
      try {
        console.error('Ajax error handler', e);
        const i18n = Ember.get(this, 'i18n');
        return e ? this.alert({
          title: e.name,
          body: e.message,
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }) : Ember.RSVP.resolve();
      } catch (err) {
        console.error("Modals manager error", err);
      }
    },

    /**
     * Show alert-modal with details about ajax-error
     *
     * It uses `payload` to get error details
     *
     * @method detailedAjaxErrorHandler
     * @param {object} error error-object
     * @returns {RSVP.Promise} modal with error details
     */
    detailedAjaxErrorHandler(error) {
      try {
        const i18n = Ember.get(this, 'i18n');
        console.error('Detailed Ajax Error Handler Error', error);
        return error ? this.alert({
          error,
          bodyComponent: 'modals/request-error-body',
          titleComponent: 'modals/request-error-header',
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }) : Ember.RSVP.resolve();
      } catch (err) {
        console.error("Modals manager error", err);
      }
    },

    /**
     * Show alert-modal with details about recurly-request
     *
     * @method recurlyErrorHandler
     * @param {object} error error-object
     * @returns {RSVP.Promise} modal with error details for recurly-request
     */
    recurlyErrorHandler(error) {
      try {
        const i18n = Ember.get(this, 'i18n');
        return error ? this.alert({
          error,
          title: 'A Billing error occurred',
          bodyComponent: 'modals/recurly-error-body',
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }) : Ember.RSVP.resolve();
      } catch (err) {
        console.error("Modals manager error", err);
      }
    },

    registrationErrorHandler(error) {
      try {
        console.error('Handle Registration Error', error);
        if (!error) return;

        if (error.status) {
          console.log("Registration error handler status", error.status);
          console.log("registration error handler payload", error.payload);
          return error ? this.show('modals/registration-error', {
            error
          }) : Ember.RSVP.resolve();
        }
      } catch (err) {
        console.error("Modals manager error", err);
      }
    },

    loginErrorHandler(error) {
      try {
        console.error('Handle Login Error', error);
        if (!error) return;

        if (error.status) {
          console.log("Login error handler status", error.status);
          console.log("Login error handler payload", error.payload);
          this.show('modals/login-error', {
            error
          });
        } else if (error.name === 'TransitionAborted') {
          return error ? this.show('modals/login-error', {
            error
          }) : Ember.RSVP.resolve();
        }
      } catch (err) {
        console.error("Modals manager error", err);
      }
    },

    /**
     * Show success-modal
     *
     * @method successHandler
     * @param {object} success successObject
     * @returns {RSVP.Promise} modal with success details
     */
    successHandler(success) {
      try {
        const i18n = Ember.get(this, 'i18n'); //console.log('success in modal: ', success);

        return success ? this.alert({
          success,
          title: success.title,
          body: success.body,
          confirm: success.btnText || i18n.t('modals.defaultAlertOptions.confirm')
        }) : Ember.RSVP.resolve();
      } catch (err) {
        console.error("Modals manager error", err);
      }
    },

    /**
     * Show success-modal
     *
     * @method successHandler
     * @param {object} success successObject
     * @returns {RSVP.Promise} modal with success details
     */
    errorHandler(error) {
      try {
        const i18n = Ember.get(this, 'i18n');
        return error ? this.alert({
          error,
          title: error.title,
          body: error.body,
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }) : Ember.RSVP.resolve();
      } catch (err) {
        console.error("Modals manager error", err);
      }
    },

    userFriendlyErrorHandler(error) {
      try {
        console.error('Handle User Friendly Error', error);
        const i18n = Ember.get(this, 'i18n');
        if (!error) return;

        if (!error.payload) {
          console.error(error);
          return;
        }

        if (!error.payload.code) {
          error.payload.code = 'unhandled';
        }

        let userMessage = i18n.t(`errorCodes.${error.payload.code}.message`);
        let severity = i18n.t(`errorCodes.${error.payload.code}.severity`);
        console.error('Error', error);
        console.error('User Message', userMessage);
        error.payload.userMessage = userMessage;
        error.payload.severity = severity;

        if (!error.payload.userMessage) {
          error.payload.userMessage = 'This error was unhandled';
        }

        return error ? this.alert({
          error,
          bodyComponent: 'modals/request-user-error-body',
          titleComponent: 'modals/request-user-error-header',
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }) : Ember.RSVP.resolve();
      } catch (err) {
        console.error("Modals manager error", err);
      }
    },

    billingErrorHandler(error) {
      try {
        console.error('Handle Billing Error', error);
        if (!error) return;
        const i18n = Ember.get(this, 'i18n');

        if (error.code) {
          return error ? this.show('modals/billing-error', {
            error
          }) : Ember.RSVP.resolve();
        }
      } catch (err) {
        console.error("Modals manager error", err);
      }
    },

    restrictedFeatureHandler(e) {
      try {
        this.show('trial-modal', {});
      } catch (err) {
        console.error("Modals manager error", err);
      }
    }

  });

  _exports.default = _default;
});