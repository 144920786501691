define("campaign-warrior-app/components/page-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['page-header-module'],
    headingOne: null,
    headingTwo: null
  });

  _exports.default = _default;
});