define("campaign-warrior-app/templates/mobile-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TKxH7jsn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"error-page\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"error-logo-wrapper\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"https://d25xioqhg7be2.cloudfront.net/static-assets/cw-logo.png\"],[14,\"alt\",\"Campaign Warrior\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container info-modal\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"panel mobile-panel\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"error\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,1,\"fire-target\"],[12],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"icon-wrapper\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"far fa-blender-phone\"],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"h1\"],[12],[2,\"Not Designed for Mobile Devices\"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"desc\"],[12],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"Campaign Warrior is designed for exclusive use on desktops and laptops.\"],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"A mobile optimised version is coming next season, until then we recommend a PC with a large screen.\"],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"controls\"],[12],[2,\"\\n            \"],[10,\"a\"],[14,0,\"btn btn-primary\"],[14,6,\"https://www.campaignwarrior.com\"],[12],[2,\"Discover what you're missing\"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/mobile-error.hbs"
    }
  });

  _exports.default = _default;
});