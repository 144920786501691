define("campaign-warrior-app/templates/components/edit-tag-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nRv1ayHj",
    "block": "{\"symbols\":[\"form\",\"&default\"],\"statements\":[[6,[37,4],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,3],true,[30,[36,2],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/edit-tag-form.hbs' @ L2:C4) \"],null]],[[\"property\",\"label\"],[\"name\",\"Name\"]]]],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/edit-tag-form.hbs' @ L6:C4) \"],null]],[[\"property\",\"label\",\"controlType\"],[\"description\",\"Description\",\"textarea\"]]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"action\",\"formData\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/edit-tag-form.hbs"
    }
  });

  _exports.default = _default;
});