define("campaign-warrior-app/services/recurly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Recurly
   * @namespace Services
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    /**
     * @property token
     * @type string
     * @default null
     */
    token: null,

    /**
     * @method getToken
     * @param {object} billingInfo payment info
     * @return {RSVP.Promise} token for payment
     */
    getToken(billingInfo) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        recurly.token(billingInfo, (err, token) => {
          if (err) {
            reject(err);
          } else {
            Ember.set(this, 'token', token);
            resolve(token);
          }
        });
      });
    }

  });

  _exports.default = _default;
});