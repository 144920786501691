define("campaign-warrior-app/templates/partner-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FNQLQX1i",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"register-form-wrapper\"],[12],[2,\"\\n\\n\\n    \"],[10,\"div\"],[14,0,\"panel branding\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-logo\"],[12],[2,\"\\n          \"],[10,\"img\"],[14,0,\"cw-logo\"],[14,\"src\",\"https://d25xioqhg7be2.cloudfront.net/static-assets/cw-logo-colored.png\"],[15,\"alt\",[30,[36,0],[\"logo.alt\"],null]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"body-content\"],[12],[2,\"\\n          \"],[10,\"h1\"],[12],[2,\"Sign up to your Partner portal right now\"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-lg-5 plan\"],[12],[2,\"\\n              \"],[10,\"h2\"],[14,0,\"plan-name\"],[12],[2,\"Partner\"],[13],[2,\"\\n              \"],[10,\"h3\"],[14,0,\"price\"],[12],[2,\"USD $0 Per Month\"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-lg-7\"],[12],[2,\"\\n              \"],[1,[30,[36,2],null,[[\"onSubmit\"],[[30,[36,1],[[32,0],\"doRegister\"],null]]]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n  \"],[10,\"small\"],[12],[1,[30,[36,0],[\"copyright\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"partner-register-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/partner-register.hbs"
    }
  });

  _exports.default = _default;
});