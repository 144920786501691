define("campaign-warrior-app/components/hashtags/edit-hashtag-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import {computed, get, set} from '@ember/object';

  /**
  
   * @class EditHashtagModal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    hashtag: null,
    actions: {
      confirm(updatedData) {
        return this._super(updatedData);
      }

    }
  });

  _exports.default = _default;
});