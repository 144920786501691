define("campaign-warrior-app/components/pages/page-image-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),

    /**
     * @property image
     */
    image: null,

    /**
     * @property portal
     * @type Models.Portal
     * @default null
     */
    portal: Ember.computed.reads('currentPortal.portal'),

    didInsertElement() {
      // setTimeout(() => {
      const rows = (0, _jquery.default)('table')[0].rows;
      let height = 0;
      let col1 = 0;
      let col2 = 0;

      if (rows.length > 1) {
        const cols = (0, _jquery.default)(rows[1]).children();
        col1 = (0, _jquery.default)(cols[0]).width() + 16;
        col2 = (0, _jquery.default)(cols[1]).width() + 16;
      }

      if (rows.length > 6) {
        for (let i = 1; i < rows.length; i++) {
          height += (0, _jquery.default)(rows[i]).height();

          if (i == 5) {
            break;
          }
        }

        const tableHead = (0, _jquery.default)('table > thead')[0];
        const tableBody = (0, _jquery.default)('table > tbody')[0];
        (0, _jquery.default)(tableHead).css({
          display: 'block'
        });
        const tableHeader = (0, _jquery.default)(tableHead).find('th');
        (0, _jquery.default)(tableHeader[0]).css({
          width: col1 + 'px'
        });
        (0, _jquery.default)(tableHeader[1]).css({
          width: col2 + 'px'
        });
        (0, _jquery.default)(tableBody).css({
          display: 'block',
          overflow: 'auto',
          height: height + 'px'
        });
        (0, _jquery.default)(tableBody).find('td:even').css({
          width: col1 + 'px'
        });
        (0, _jquery.default)(tableBody).find('td:odd').css({
          width: col2 + 'px'
        });
      } // }, 1000);

    },

    actions: {
      addToLibrary(image) {
        const i18n = Ember.get(this, 'i18n');

        const process = () => Ember.get(this, 'ajax').post('media/uploadWebPageImage', {
          data: {
            url: image,
            cache: false,
            processData: false,
            contentType: false,
            portalId: Ember.get(this, 'portal.id')
          }
        });

        const modalsManager = Ember.get(this, 'modalsManager');
        return modalsManager.show('modals/single-process', {
          title: '',
          body: i18n.t('media.uploadModal.body', {
            name: image
          }),
          process
        }).then(() => {
          const data = {
            title: 'Saved',
            body: 'image saved to library'
          };
          return Ember.get(this, 'modalsManager').successHandler(data);
        }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      },

      imageCopy(image) {
        if (Ember.get(this, 'currentUser.user.company.featureLevel') === 'free') {
          return Ember.get(this, 'modalsManager').show('trial-modal', {
            exportingList: true
          }).then(() => {
            Ember.get(this, 'router').transitionTo('billing');
          }).catch(() => {
            console.log('Going back');
          });
        } else {
          const el = document.createElement('textarea');
          let htmlContent = `<img src="${image.src}" alt="${image.alt}">`;
          el.value = htmlContent;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
        }
      }

    }
  });

  _exports.default = _default;
});