define("campaign-warrior-app/components/cw-breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Based on `ember-crumbly`
   *
   * Original one can't be used because of bug with block-context, however original idea is not changed - each needed Route
   * must have a property `breadCrumb` with a info about breadcrumb:
   *
   * * `linkable` - show as clickable link
   * * `icon` - custom font-awesome icon
   * * `path` - custom path (by default current route is used)
   * * `title` - breadcrumb title (may be set as some Model field if it's a Route for custom Record)
   *
   * Usage:
   *
   * ```handlebars
   * {{cw-breadcrumb}}
   * ```
   *
   * @class CwBreadcrumbs
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: 'ul',
    classNames: ['breadcrumb'],

    /**
     * Determines if breadcrumbs should be shown as links. It be overridden in the each Route itself
     *
     * @type boolean
     * @default true
     * @property linkable
     */
    linkable: true,

    /**
     * @private
     * @readonly
     * @property routeHierarchy
     * @type object[]
     * @default []
     */
    routeHierarchy: Ember.computed('router.{currentURL,currentRouteName}', 'reverse', {
      get() {
        const currentRouteName = this.router.currentRouteName;

        if (!currentRouteName) {
          return [];
        }

        const routeNames = currentRouteName.split('.');

        const filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);

        return this._lookupBreadCrumb(routeNames, filteredRouteNames);
      }

    }).readOnly(),

    _guessRoutePath(routeNames, name, index) {
      const routes = routeNames.slice(0, index + 1);

      if (routes.length === 1) {
        let path = `${name}.index`;
        return this._lookupRoute(path) ? path : name;
      }

      return routes.join('.');
    },

    _filterIndexAndLoadingRoutes(routeNames) {
      return routeNames.filter(name => !['loading', 'error'].includes(name));
    },

    _lookupRoute(routeName) {
      return Ember.getOwner(this).lookup(`route:${routeName}`);
    },

    _lookupBreadCrumb(routeNames, filteredRouteNames) {
      const defaultLinkable = Ember.get(this, 'linkable');
      const pathLength = filteredRouteNames.length;
      const breadCrumbs = filteredRouteNames.map((name, index) => {
        let path = this._guessRoutePath(routeNames, name, index);

        const route = this._lookupRoute(path);

        const isHead = index === 0;
        const isTail = index === pathLength - 1;
        const crumbLinkable = index === pathLength - 1 ? false : defaultLinkable;
        let breadCrumb = route.breadCrumb || {
          title: Ember.String.classify(name)
        };

        if (Ember.typeOf(breadCrumb) === 'null') {
          return;
        }

        if (breadCrumb.title === 'Index') {
          return;
        }

        if (Ember.isPresent(breadCrumb.path)) {
          path = breadCrumb.path;
        }

        Ember.setProperties(breadCrumb, {
          path,
          isHead,
          isTail,
          linkable: breadCrumb.hasOwnProperty('linkable') ? breadCrumb.linkable : crumbLinkable
        });
        return breadCrumb;
      });
      return Ember.A(breadCrumbs.filter(breadCrumb => Ember.typeOf(breadCrumb) !== 'undefined'));
    }

  });

  _exports.default = _default;
});