define("campaign-warrior-app/components/tags-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Show some tags (`Models.Tag[]`) group. Panel (`cw-panel`) with a table (`models-table`) is shown by default.
   *
   * Usage:
   *
   * ```hbs
   * {{tags-group
   *   columns=columns
   *   tags=(slice 0 10 (sort-by "createDate" shownTags))
   *   title=(t "tags.recent.title")
   *   showTableFooter=false
   * }}
   * ```
   *
   * @class TagsGroup
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['tags-group'],

    /**
     * Tags to show
     *
     * @property tags
     * @type Models.Tag[]
     * @default null
     */
    tags: null,

    /**
     * Panel title
     *
     * @property title
     * @type string
     * @default ''
     */
    title: '',

    /**
     * Column definitions for `models-table`
     *
     * @type object[]
     * @property columns
     * @default null
     */
    columns: null,

    /**
     * Bound value for `models-table.showComponentFooter`
     *
     * @type boolean
     * @default true
     * @property showTableFooter
     */
    showTableFooter: true,

    /**
     * Bound value for `models-table.columnComponents`
     *
     * @type object[]
     * @default null
     * @property columnComponents
     */
    columnComponents: null,

    /**
     * @property panelName
     * @type string
     * @default ''
     */
    panelName: ''
    /**
     * Theme-overrides for `models-table` shown in the panel
     *
     * @property themeInstance
     * @type object
     */

    /*
    themeInstance: Bs3.create({
      paginationWrapperDefault: 'col-xs-7'
    })*/

  });

  _exports.default = _default;
});