define("campaign-warrior-app/templates/components/domains/show-tracking-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qA3cDo4o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"a\"],[4,[38,0],[[32,0],\"copyTrackingCode\"],null],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Copied\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Copy Tracking Code\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"isShowingTrackingCode\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/domains/show-tracking-code.hbs"
    }
  });

  _exports.default = _default;
});