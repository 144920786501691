define("campaign-warrior-app/controllers/portals/portal/accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Accounts
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property torii
     * @type Ember.Service
     */
    torii: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property store
     * @type Ember.Service
     */
    store: Ember.inject.service(),

    /**
     * @property store
     * @type Ember.Service
     */
    can: Ember.inject.service(),

    /**
     * @property model
     * @type Models.PublishSource[]
     * @default null
     */
    model: null,

    /**
     * @property portal
     * @type Models.Portal
     * @default null
     */
    portal: null,

    /**
     * @property title
     * @type string
     * @readOnly
     */
    title: Ember.computed('sourceTypeToFilter', 'i18n', function () {
      const i18n = Ember.get(this, 'i18n');
      const sourceTypeToFilter = Ember.get(this, 'sourceTypeToFilter');
      return sourceTypeToFilter === 'all' ? i18n.t('publishing.sources.titles.all') : i18n.t('publishing.sources.titles.forType', {
        type: Ember.String.capitalize(sourceTypeToFilter)
      });
    }).readOnly(),

    /**
     * @property toriiAuth
     * @type Authenticators.Torii
     */
    toriiAuth: Ember.computed(function () {
      return Ember.getOwner(this).lookup('authenticator:torii');
    }),
    hsColumns: Ember.computed(function () {
      const i18n = Ember.get(this, 'i18n');
      return [{
        propertyName: 'attributes.logo',
        title: i18n.t('accounts.add.hubspotTable.titles.logo'),
        component: 'cw-table/image-cell',
        style: {
          'max-width': '30px',
          'max-height': '30px'
        }
      }, {
        propertyName: 'attributes.name',
        title: i18n.t('accounts.add.hubspotTable.titles.name')
      }, {
        propertyName: 'attributes.category',
        title: i18n.t('accounts.add.hubspotTable.titles.category')
      }, {
        propertyName: 'attributes.accountType',
        title: i18n.t('accounts.add.hubspotTable.titles.accountType')
      }];
    }),
    gaColumns: Ember.computed(function () {
      const i18n = Ember.get(this, 'i18n');
      return [{
        title: i18n.t('accounts.add.gaTable.titles.name'),
        propertyName: 'attributes.name'
      }, {
        title: i18n.t('accounts.add.gaTable.titles.websiteUrl'),
        propertyName: 'attributes.websiteUrl'
      }, {
        title: i18n.t('accounts.add.gaTable.titles.gaId'),
        propertyName: 'attributes.gaId'
      }];
    }),

    /**
     * List of column-definitions for all publish sources
     *
     * @property sourceColumns
     * @type object[]
     */
    sourceColumns: Ember.computed(function () {
      const i18n = Ember.get(this, 'i18n');
      return [{
        title: i18n.t('publishing.sources.table.titles.category'),
        propertyName: 'category'
      }, {
        title: i18n.t('publishing.sources.table.titles.sourceType'),
        propertyName: 'sourceType',
        component: 'cw-table/publish-source-type',
        filterWithSelect: true,
        predefinedFilterOptions: [{
          label: 'Twitter',
          value: 'twitter'
        }, {
          label: 'LinkedIn',
          value: 'linkedin'
        }, {
          label: 'Facebook',
          value: 'facebook'
        }]
      }, {
        title: i18n.t('publishing.sources.table.titles.type'),
        propertyName: 'viaPlatformFormatted',
        className: 'via-platform'
      }, {
        title: i18n.t('publishing.sources.table.titles.toggleActive'),
        propertyName: 'active',
        useFilter: false,
        component: 'toggle-active-state'
      }, {
        component: 'delete-record',
        className: 'delete-ps-cell'
      }];
    }),
    actions: {
      /**
       * @method actions.deletePublishSource
       * @param {Models.PublishSource} record record to delete
       * @return {Promise} delete-response
       */
      deletePublishSource(record) {
        const can = Ember.get(this, 'can');
        const i18n = Ember.get(this, 'i18n');

        if (can.cannot('delete publish-source')) {
          return Ember.get(this, 'modalsManager').alert({
            title: 'Permission Denied',
            body: 'You are not allowed to delete publish sources in trial mode.',
            confirm: i18n.t('modals.defaultAlertOptions.confirm')
          });
        }

        const name = Ember.get(record, 'meta.alias') || Ember.get(record, 'name');
        const modalsManager = Ember.get(this, 'modalsManager');
        return modalsManager.promptConfirm({
          promptValue: name,
          title: i18n.t('modals.defaultConfirmOptions.title'),
          body: i18n.t('publishing.sources.deletePublishSource.confirm.body', {
            name,
            type: Ember.get(record, 'sourceType')
          }),
          confirm: i18n.t('publishing.sources.deletePublishSource.confirm.submit'),
          decline: i18n.t('publishing.sources.deletePublishSource.confirm.cancel')
        }).then(() => record.destroyRecord()).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      },

      loadHubspotAccount() {
        const i18n = Ember.get(this, 'i18n');
        const ajax = Ember.get(this, 'ajax');

        const _this = this;

        Ember.get(this, 'torii').open('hubspot').then(function (data) {
          ajax.post('/hubspot/authorize', {
            data: {
              portalId: Ember.get(_this, 'portal.id'),
              data
            }
          }).then(function (socials) {
            Ember.set(_this, 'hsSocials', socials.data);

            _this.send('openHsSocialsModal');
          });
        }).catch(err => Ember.get(this, 'modalsManager').alert({
          title: i18n.t('publishing.sources.cantLoadSources.title'),
          body: err,
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }));
      },

      openHsSocialsModal() {
        Ember.get(this, 'modalsManager').show('select-publish-sources-modal', {
          type: 'hubspot',
          newSources: Ember.get(this, 'hsSocials'),
          existingSources: Ember.get(this, 'model.publishingSources'),
          columns: Ember.get(this, 'hsColumns')
        }).then(sources => this.saveSelectedHsSources(sources));
      },

      loadGoogleAnalyticsAccounts() {
        const i18n = Ember.get(this, 'i18n');
        const ajax = Ember.get(this, 'ajax');

        const _this = this;

        const portalId = Ember.get(this, 'portal.id');
        Ember.get(this, 'torii').open('google-analytics').then(function (data) {
          ajax.post('/ga/authorize', {
            data: {
              portalId,
              data
            }
          }).then(function (accounts) {
            Ember.set(_this, 'gaAccounts', accounts.data);

            _this.send('openGaAccountsModal');
          });
        }).catch(err => Ember.get(this, 'modalsManager').alert({
          title: i18n.t('publishing.sources.cantLoadSources.title'),
          body: err,
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }));
      },

      openGaAccountsModal() {
        console.log('Open ga accounts modal data', Ember.get(this, 'gaAccounts'));
        Ember.get(this, 'modalsManager').show('select-publish-sources-modal', {
          type: 'googleAnalytics',
          newSources: Ember.get(this, 'gaAccounts'),
          existingSources: Ember.get(this, 'model.publishingSources'),
          columns: Ember.get(this, 'gaColumns')
        }).then(sources => this.saveSelectedGaSources(sources));
      },

      /**
       * @method actions.togglePublishSourceActiveState
       * @param {Models.PublishSource} record record to update
       * @returns {RSVP.Promise} save result
       */
      togglePublishSourceActiveState(record) {
        record.toggleProperty('active');
        return record.save({
          adapterOptions: {
            toggleActiveState: true
          }
        }).catch(e => {
          record.rollbackAttributes();
          return Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e);
        });
      },

      refreshThisRoute() {
        this.send('refreshRoute');
      }

    },

    /**
     * @method transitionToAccountsByType
     * @param {string} type qp type
     * @return {Ember.Transition} transition object
     * @private
     */
    transitionToAccountsByType(type) {
      return Ember.get(this, 'router').transitionTo('portals.portal.accounts', {
        queryParams: {
          type
        }
      });
    },

    /**
     * @method savePublishSourcesCallback
     * @param {object} payload publish sources
     * @returns {RSVP.Promise} alert-modal
     */
    savePublishSourcesCallback(payload) {
      console.log('in saving sources callback function');
      const i18n = Ember.get(this, 'i18n');
      const data = Ember.isArray(payload.data) ? payload.data[0] : payload.data;
      const type = i18n.t(`services.${data.attributes['source-type']}`); // get(this, 'store').findAll('publish-source');

      return Ember.get(this, 'modalsManager').alert({
        body: i18n.t('publishing.sources.addSourcesCallback.body', {
          count: payload.data.length || 1,
          type
        }),
        confirm: i18n.t('modals.defaultAlertOptions.confirm'),
        title: i18n.t('modals.defaultAlertOptions.title')
      }).finally(() => this.send('refreshRoute'));
    },

    saveGoogleAnalyticsSourceCallback(payload) {},

    saveSelectedHsSources(sources) {
      if (!sources.length) {
        return;
      }

      Ember.get(this, 'ajax').post('/hubspot/sources', {
        data: {
          portalId: Ember.get(this, 'portal.id'),
          data: sources
        }
      }).then(payload => this.savePublishSourcesCallback(payload)).catch(e => Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e));
    },

    saveSelectedGaSources(sources) {
      if (!sources.length) {
        return;
      }

      Ember.get(this, 'ajax').post('/ga/sources', {
        data: {
          portalId: Ember.get(this, 'portal.id'),
          data: sources
        }
      }).then(payload => this.saveGoogleAnalyticsSourceCallback(payload)).catch(e => Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e));
    },

    canAddPublishSources() {
      const can = Ember.get(this, 'can');
      const publishSources = Ember.get(this, 'model.publishingSources');
      return can.can('add publish-source', null, {
        publishSources
      });
    }

  });

  _exports.default = _default;
});