define("campaign-warrior-app/routes/portals", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),
    breadCrumb: null,
    title: 'Portals | Campaign Warrior',

    async beforeModel(transition) {
      const user = Ember.get(this, 'currentUser.user');

      if (user) {
        // get(this, 'currentUser').load();
        const company = Ember.get(this, 'currentUser.company');

        if (company.incomplete) {
          this.router.transitionTo('complete-registration');
        } // const companyStatus = get(this, 'currentUser.company.status');


        if (company.status === 'expired') {
          this.router.transitionTo('expired');
        } // const portals = await get(this, 'store').findAll('portal');
        // const portalsExist = portals && portals.length > 0;

        /*if (get(this, 'currentUser.user.company.billingInfo.trialExpired') && !portalsExist) {
          // modalsManager
          //     .show('trial-expired-modal', {
          //       portalsExist: portalsExist
          //     })
          //     .then(() => {
          get(this, 'router').transitionTo('billing');
          //     })
          //   .catch(() => {
          //       console.log('Going to portals');
          // });
          // console.log('currentUSER IN PORTALS: ', get(this, 'currentUser.user'));
          // return get(this, 'router').transitionTo('billing');
        }*/

      } else {
        Ember.get(this, 'router').transitionTo('login');
      }

      return this._super(...arguments);
    },

    /**
     * @returns {Promise} All portals available for current user
     */
    model() {
      const portals = Ember.get(this, 'store').findAll('portal');
      return portals;
    },

    afterModel(model) {
      if (!Ember.get(model, 'length')) {
        return Ember.get(this, 'router').transitionTo('portals.create.step1');
      }
    },

    actions: {
      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});