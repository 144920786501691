define("campaign-warrior-app/controllers/portals/portal/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UsersListController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class UsersListController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "i18n", _descriptor, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor2, this);

      _initializerDefineProperty(this, "currentPortal", _descriptor3, this);

      _initializerDefineProperty(this, "currentUser", _descriptor4, this);

      _initializerDefineProperty(this, "ajax", _descriptor5, this);
    }

    inviteUser() {
      let modalsManager = this.modalsManager;
      let ajax = this.ajax;
      let i18n = this.i18n;
      return modalsManager.show('invite-user-modal', {}).then(model => {
        const data = {
          email: Ember.get(model, 'email'),
          portal: Ember.get(this, 'currentPortal.portal.id'),
          invitedBy: Ember.get(this, 'currentUser.user.id'),
          role: Ember.get(model, 'role')
        };
        return ajax.post('/inviteuser', {
          data
        }).then(r => modalsManager.alert({
          title: "Your invite has been sent",
          confirm: i18n.t('modals.defaultAlertOptions.confirm'),
          body: i18n.t('settings.addUser.inviteSentModal.body', {
            email: r.email
          })
        }).then(() => this.send('refreshRoute'))).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      });
    }

    refreshList() {
      this.send('refreshRoute');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "inviteUser", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "inviteUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshList", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "refreshList"), _class.prototype)), _class));
  _exports.default = UsersListController;
  ;
});