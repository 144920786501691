define("campaign-warrior-app/adapters/billing-info", ["exports", "campaign-warrior-app/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import {get} from '@ember/object';

  /**
   * @class BillingInfo
   * @namespace Adapters
   * @extends Adapters.Application
   */
  var _default = _application.default.extend({
    /**
     * Custom handler for current billing info. Request for billing info trigger an update of this information from recurly
     *
     * @method urlForQueryRecord
     * @param {object} query object with query-data
     * @returns {string} generated url
     */
    urlForQueryRecord(query) {
      //console.log('Using billing info urlForQueryRecord', query);
      if (query.update) {
        delete query.update;
        let url = `${this._super(...arguments)}/${query.id}?update=true`; //console.log('QUERY URL', url);

        return url;
      }

      return this._super(...arguments);
    }
    /**
     * @method urlForFindRecord
     * @returns {string} url for request
     */

    /*
    urlForFindRecord() {
      return `${this._super(...arguments)}?include=permissions,favorites,invited-users`;
    }*/


  });

  _exports.default = _default;
});