define("campaign-warrior-app/components/tags/tags-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * @class TagsList
   * @namespace Components
   * @extend Ember.Component
   */
  let TagList = (_dec = Ember.computed('pagesListForAddTag', 'tagsList'), (_class = class TagList extends Ember.Component {
    get columns() {
      return [{
        title: 'Name',
        component: 'cw-tag',
        className: 'title-cell',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        title: 'Description',
        propertyName: 'description',
        className: 'description-cell',
        useSorting: false
      }, {
        title: 'Usage Count',
        propertyName: 'usageCount',
        className: 'usage-cell',
        useSorting: true,
        sortAsc: true
      }, {
        title: '',
        component: 'tags/tag-action-menu',
        className: 'actions-cell',
        id: 'id',
        useSorting: false,
        sortAsc: false
      }];
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "columns", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype)), _class));
  _exports.default = TagList;
  ;
});