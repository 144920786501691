define("campaign-warrior-app/components/pages/pages-to-show", ["exports", "campaign-warrior-app/components/cw-panel/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _title.default.extend({
    /**
     * @type Models.Page[]
     * @default null
     * @property pages
     */
    pages: null,
    selected: 'all'
  });

  _exports.default = _default;
});