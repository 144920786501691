define("campaign-warrior-app/components/pages/delete-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),
    i18n: Ember.inject.service(),
    page: Ember.computed(function () {
      return Ember.get(this, 'record');
    }),
    actions: {
      openModel() {
        const page = Ember.get(this, 'record');
        const i18n = Ember.get(this, 'i18n');
        const currentRouteName = Ember.get(this, 'router.currentRouteName');
        let modalsManager = Ember.get(this, 'modalsManager');
        return modalsManager.confirm({
          title: i18n.t('modals.defaultConfirmOptions.title'),
          body: 'Are you sure you want to delete the page?',
          confirm: 'Yes',
          decline: 'No'
        }).then(() => {
          //console.log('confirmed');
          page.destroyRecord().then(() => {
            const currentRouteInstance = Ember.getOwner(this).lookup(`route:${currentRouteName}`);
            currentRouteInstance.refresh();
          });
        }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      },

      hidePage() {
        const page = Ember.get(this, 'record');
        const i18n = Ember.get(this, 'i18n');
        const currentRouteName = Ember.get(this, 'router.currentRouteName');
        let modalsManager = Ember.get(this, 'modalsManager');
        return modalsManager.confirm({
          title: i18n.t('modals.defaultConfirmOptions.title'),
          body: 'Are you sure you want to hide this page?',
          confirm: 'Yes',
          decline: 'No'
        }).then(() => {
          //console.log('confirm hide page');
          let isHidden = Ember.get(page, 'hidden');
          Ember.set(page, 'hidden', !isHidden);
          page.save();
          const currentRouteInstance = Ember.getOwner(this).lookup(`route:${currentRouteName}`);
          currentRouteInstance.refresh();
        }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      }

    }
  });

  _exports.default = _default;
});