define("campaign-warrior-app/components/top-nav", ["exports", "campaign-warrior-app/macros/starts-with", "ember-local-storage", "campaign-warrior-app/utils/routing"], function (_exports, _startsWith, _emberLocalStorage, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Top-navigation panel:
   *
   *  - Logo
   *  - Breadcrumbs
   *  - User dropdown
   *  - Logout
   *
   *
   * @class TopNav
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),

    /**
     * @property session
     * @type Ember.Service
     */
    session: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property notify
     * @type Ember.Service
     */
    notify: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    tagName: 'header',
    classNames: ['bg-primary', 'header', 'navbar'],

    /**
     * @property portalRoutes
     * @type object
     * @private
     * @readonly
     */
    portalRoutes: _routing.portalRoutes,

    /**
     * @property portals
     * @type Models.Portal[]
     * @default null
     */
    portals: null,

    /**
     * `true` - show "edit portal" modal
     *
     * @property editPortalModalIsShown
     * @type boolean
     * @default false
     */
    editPortalModalIsShown: false,

    /**
     * @property collapsed
     * @type boolean
     * @default false
     * @private
     */
    collapsed: false,

    /**
     * @property filterForPortals
     * @type string
     * @default ''
     * @private
     */
    filterForPortals: '',

    /**
     * @property refreshInProgress
     * @type boolean
     * @default false
     * @private
     */
    refreshInProgress: false,
    currentPage: null,

    /**
     * @property portalsTraverse
     * @type object
     */
    portalsTraverse: (0, _emberLocalStorage.storageFor)('portals-traverse'),

    /**
     * @property activePortal
     * @type Models.Portal
     * @default null
     * @private
     * @readonly
     */
    activePortal: Ember.computed.reads('currentPortal.portal'),

    /**
     * @property activeUser
     * @type Models.User
     * @default null
     * @private
     * @readonly
     */
    activeUser: Ember.computed.reads('currentUser.user'),

    /**
     * @property userCompany
     * @type Models.Company
     * @default null
     * @private
     * @readonly
     */
    company: Ember.computed.reads('currentUser.user.company'),

    /**
     * Top-Nav is shown on every page under the `portals`-route,
     * however edit-portal is available only under the `portals.portal`-route
     *
     * @property enableEditPortal
     * @type boolean
     * @default false
     * @private
     * @readonly
     */
    enableEditPortal: (0, _startsWith.default)('router.currentRouteName', 'portals.portal').readOnly(),

    /**
     * @property sortedPortalsProps
     * @type string[]
     * @private
     * @readonly
     */
    sortedPortalsProps: Object.freeze(['name']),

    /**
     * @property sortedPortals
     * @type Models.Portal[]
     * @private
     * @readonly
     */
    sortedPortals: Ember.computed.sort('portals', 'sortedPortalsProps'),

    /**
     * @property filteredPortals
     * @type Models.Portal[]
     * @private
     * @readonly
     */
    filteredPortals: Ember.computed('sortedPortals.@each.name', 'filterForPortals', function () {
      const portals = Ember.get(this, 'sortedPortals');
      const filterForPortals = Ember.get(this, 'filterForPortals').toLowerCase();

      if (!Ember.isArray(portals)) {
        return [];
      }

      if (!filterForPortals) {
        return portals;
      }

      return portals.filter(portal => Ember.get(portal, 'name').toLowerCase().includes(filterForPortals));
    }),
    isSupport: Ember.computed('activeUser', function () {
      const internalType = Ember.get(this, 'activeUser.internalType');
      return internalType === 'support' || internalType === 'admin';
    }),
    isSearchActive: Ember.computed('filterForPortals', function () {
      const isTrue = Ember.get(this, 'filterForPortals');
      return isTrue != '';
    }),
    actions: {
      /**
       * Logout user, invalidate its session.
       *
       * Since there is a only one place where user may do logout
       * there is no sense to do `logout` as closure action and bind it from parent
       *
       * @method actions.logout
       */
      logout() {
        Ember.get(this, 'session').invalidate();
      },

      handleDropdownClicked(closeDropdown) {
        closeDropdown();
      },

      /**
       * Open modal to edit current Portal info
       *
       * @method actions.editPortals
       */
      editPortal() {
        const portal = Ember.get(this, 'activePortal');
        const notify = Ember.get(this, 'notify');
        const i18n = Ember.get(this, 'i18n');

        if (Ember.get(this, 'disableEditPortal')) {
          return;
        }

        const modalsManager = Ember.get(this, 'modalsManager');
        modalsManager.show('edit-portal-modal', {
          record: portal,
          backDrop: false
        }).then(() => portal.save().then(() => notify.success(i18n.t('portals.portal.edit.form.success'))).catch(e => modalsManager.detailedAjaxErrorHandler(e))).catch(e => {
          if (e === 'delete-portal') {
            return this.send('deletePortal');
          } else {
            portal.rollbackAttributes();
          }
        });
      },

      /**
       * @method actions.deletePortal
       * @returns {RSVP.Promise} request result
       */
      deletePortal() {
        const portal = Ember.get(this, 'activePortal');
        const modalsManager = Ember.get(this, 'modalsManager');
        const i18n = Ember.get(this, 'i18n');
        return modalsManager.promptConfirm({
          title: i18n.t('portals.portal.edit.delete.confirm.title'),
          bodyComponent: 'portals/delete-confirm/body',
          confirmButtonType: 'cancel',
          declineButtonType: 'primary',
          confirm: i18n.t('portals.portal.edit.delete.confirm.confirm'),
          decline: i18n.t('portals.portal.edit.delete.confirm.decline'),
          promptValue: Ember.get(portal, 'name')
        }).then(() => {
          modalsManager.process({
            body: '',
            iconClass: 'text-center fal fa-spinner fa-spin fa-3x fa-fw',
            title: 'Deleting portal, please wait',
            process: () => new Promise(resolve => resolve(portal.destroyRecord()))
          }).then(() => {
            window.localStorage.setItem('activePortal', null);
          }).then(() => Ember.get(this, 'router').transitionTo('portals')).then(() => Ember.get(this, 'currentUser').load()).catch(e => modalsManager.detailedAjaxErrorHandler(e));
        }).catch();
      },

      /**
       * @method actions.updateStats
       * @returns {RSVP.Promise} request result
       */
      updateStats() {
        /*if (get(this, 'refreshInProgress')) {
          return;
        }*/
        const activePortal = Ember.get(this, 'activePortal');

        if (!activePortal) {
          return;
        }

        const i18n = Ember.get(this, 'i18n');
        const modalsManager = Ember.get(this, 'modalsManager');
        Ember.get(this, 'ajax').post('/update/metrics', {
          data: {
            portalId: activePortal.id
          }
        }).then(() => {
          modalsManager.alert({
            title: 'Web Domain Refresh In Progress',
            body: 'Just checking for updates to your portal... This might take a minute.',
            confirm: 'OK'
          });
        }).catch(() => {
          modalsManager.alert({
            title: 'Web Domain Refresh Already Started',
            body: 'You must wait 5 minutes before you can refresh',
            confirm: 'OK'
          });
        }); // .finally(() => set(this, 'refreshInProgress', false));
      },

      /**
       * @method actions.moveToPortal
       * @param {Models.Portal} portal portal where user is moved
       */
      moveToPortal(portal) {
        const portalsTraverse = Ember.get(this, 'portalsTraverse');
        const moveTo = Ember.get(portalsTraverse, Ember.get(portal, 'id')) || 'portals.portal';
        Ember.get(this, 'router').transitionTo(moveTo, portal); // portals dropdown is not hidden when some its item is clicked
        // this `click` force to close it

        this.$().click();
      },

      checkCurrentPage() {
        const route = Ember.get(this, 'router');
        const path = Ember.get(route, 'currentRouteName');

        if (path.includes('portals.portal.stats')) {
          Ember.set(this, 'currentPage', 'stats');
        } else if (path.includes('portals.portal.pages.list')) {
          Ember.set(this, 'currentPage', 'webpage');
        } else {
          Ember.set(this, 'currentPage', null);
        }
      },

      goToBilling() {
        if (Ember.get(this, 'activePortal')) {
          window.localStorage.setItem('activePortal', JSON.stringify(Ember.get(this, 'activePortal').id));
        }

        Ember.get(this, 'router').transitionTo('account');
      },

      clearSearch() {
        Ember.set(this, 'filterForPortals', '');
      }

    },

    deletingPortal(portal) {
      return new Promise(function (resolve, reject) {
        portal.destroyRecord();
        resolve('');
      });
    }

  });

  _exports.default = _default;
});