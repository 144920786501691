define("campaign-warrior-app/routes/portals/portal/users/user/manage", ["exports", "campaign-warrior-app/mixins/portal-related-manage-record-route"], function (_exports, _portalRelatedManageRecordRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class EditPage
   * @namespace Routes
   * @extends Ember.Route
   * @uses Mixins.PortalRelatedManageRecordRoute
   */
  var _default = Ember.Route.extend(_portalRelatedManageRecordRoute.default, {
    modelClassName: 'user',
    rootElementClasses: Object.freeze(['manage-user-page']),

    async model() {
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      const store = Ember.get(this, 'store');
      const user = this.modelFor('portals.portal.users.user');
      return user;
    }

  });

  _exports.default = _default;
});