define("campaign-warrior-app/templates/components/pages/toggle-favorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uCssO5+s",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"i\"],[15,0,[31,[\"fa-fw \",[30,[36,1],[[35,0,[\"favorite\",\"id\"]],\"fas fa-star\",\"fal fa-star\"],null]]]],[12],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"record\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/toggle-favorite.hbs"
    }
  });

  _exports.default = _default;
});