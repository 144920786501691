define("campaign-warrior-app/controllers/pro-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Register
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property login
     * @type Ember.Controller
     */
    login: Ember.inject.controller(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),
    recurly: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property portals
     * @type Models.Portal[]
     * @default null
     */
    portals: null,

    /**
     * @property requestInProgress
     * @type boolean
     * @default false
     */
    requestInProgress: false,

    /**
     * @property billingInfo
     * @type Models.BillingInfo
     * @readonly
     */
    billingInfo: Ember.computed.reads('currentUser.user.company.billingInfo'),
    actions: {
      /* doRegister(data) {
         get(this, 'ajax')
           .post('/partner', {data})*/

      /*
       * Login user after registration.
       * Technically user is already logged in,
       * however it's a simple way to init session on the UI
       */

      /*
      .then(() => get(this, 'login').send('doLogin', data))
      .catch(e => get(this, 'modalsManager').userFriendlyErrorHandler(e));
      },
      */
      doRegister(form, formData, errorBody) {
        console.log('Submitting form data: ', formData);
        console.log('Submitting form: ', form);
        const modalsManager = Ember.get(this, 'modalsManager');
        const i18n = Ember.get(this, 'i18n');
        modalsManager.process({
          body: '',
          iconClass: 'text-center fa fa-spinner fa-spin fa-3x fa-fw',
          title: 'Processing request, please wait',
          process: () => new Promise(resolve => resolve(Ember.get(this, 'recurly').getToken(form).then(token => {
            console.log('Token from recurly: ', token);
            form['recurly-token'] = token;
            return Ember.get(this, 'ajax').post('/partner', {
              data: form
            }).then(response => Ember.get(this, 'notify').success(response.message));
            /*.catch(err => {
            console.error("Ajax Error", err);
            get(this, 'modalsManager').userFriendlyErrorHandler(err);
            })*/
          })))
        }).then(() => Ember.get(this, 'currentUser').load()).then(() => Ember.get(this, 'login').send('doLogin', form)).catch(e => {
          console.log('Billing error', e); // `TransitionAborted` happens when user doesn't have created portals and forced from `portals` to `portals.create.step1`
          // It's not an error in this case

          /*if (e.name === 'validation') {
            modalsManager.recurlyErrorHandler(e);
          } else */

          if (e.name !== 'TransitionAborted') {
            console.log('Error body', errorBody);
            modalsManager.recurlyErrorHandler(e);
            /*if (!get(e, 'payload.reason')) {
              e.payload = {reason: i18n.t(errorBody)};
            }*/

            if (!e.payload.errors || !e.payload.errors.length) {} else {
              console.log('Error not from recurly', e.payload);
              e.payload.userMessage = e.payload.errors[0].details;
            }

            Ember.get(this, 'modalsManager').billingErrorHandler(e);
          } else {
            Ember.get(this, 'modalsManager').billingErrorHandler(e);
          }
        });
      }

    }
  });

  _exports.default = _default;
});