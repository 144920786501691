define("campaign-warrior-app/controllers/manage-portal-related-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use [EditPortalRelatedRecordController](Controllers.EditPortalRelatedRecord.html) or [CreatePortalRelatedRecordController](Controllers.CreatePortalRelatedRecord.html).
   *
   * @class ManagePortalRelatedRecord
   * @extends Ember.Controller
   * @namespace Controllers
   */
  var _default = Ember.Controller.extend({
    /**
     * @type DS.Record
     * @property model
     */

    /**
     * Router-service. Allows to redirect user to any app-page
     *
     * @type Ember.Service
     * @property router
     */
    router: Ember.inject.service(),

    /**
     * @type Ember.Service
     * @property notify
     */
    notify: Ember.inject.service(),

    /**
     * @type Ember.Service
     * @property i18n
     */
    i18n: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * Route-name where user will be redirected after `model` is successfully saved
     *
     * @type string
     * @property redirectTo
     */
    redirectTo: '',
    actions: {
      /**
       * Save `model` to the BE and navigate user to the Route from `redirectTo`-property
       *
       * @method actions.save
       * @param {DS.Record} model edited record
       * @return {Promise} promise resolved or rejected when `save`-request is completed
       */
      save(model) {
        const i18n = Ember.get(this, 'i18n');
        return model.save().then(() => {
          Ember.get(this, 'notify').success(i18n.t('manageRecords.savedSuccessfully'));
          const redirectTo = Ember.get(this, 'redirectTo');

          if (!redirectTo) {
            return;
          }

          return Ember.get(this, 'router').transitionTo(redirectTo, Ember.get(model, 'portal.id'));
        }).catch(e => Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e));
      }

    }
  });

  _exports.default = _default;
});