define("campaign-warrior-app/templates/portals/portal/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ytryUMOJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-controls users-list-main\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header-ui\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"All users\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Invite new users or remove existing\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"control-group\"],[12],[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"submenu\",[30,[36,0],[[32,0],\"inviteUser\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"far fa-plus-circle\"],[12],[13],[2,\"   Invite user\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row page-panels\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"panel width-80\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"users\",\"refreshList\"],[[35,1],[30,[36,0],[[32,0],\"refreshList\"],null]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"users/users-list\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/users/list.hbs"
    }
  });

  _exports.default = _default;
});