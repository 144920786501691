define("campaign-warrior-app/controllers/reset/pass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ResetPass
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    actions: {
      /**
       * @method actions.doResetPassword
       * @param {object} formData reset pass form data
       * @return {RSVP.Promise} reset pass result
       */
      doResetPassword(formData) {
        const i18n = Ember.get(this, 'i18n');
        return Ember.get(this, 'ajax').post('/reset/pass', {
          data: {
            email: Ember.get(formData, 'email')
          }
        }).then(() => Ember.get(this, 'modalsManager').alert({
          title: 'Password Reset Sent',
          body: i18n.t('reset.pass.successModal.body'),
          confirm: i18n.t('modals.defaultAlertOptions.confirm')
        }).then(() => this.transitionToRoute('login')).catch(() => this.transitionToRoute('login'))).catch(e => {
          console.error('Error occurred', e);
          Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e);
        });
      }

    }
  });

  _exports.default = _default;
});