define("campaign-warrior-app/components/notes/create-note-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CreateNoteModal = (_dec = Ember.computed(), _dec2 = Ember.computed('options.creating'), _dec3 = Ember._action, (_class = (_temp = class CreateNoteModal extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "classNames", ['create-note-modal']);
    }

    get formData() {
      let description = '';

      if (this.options && this.options.description) {
        description = this.options.description;
      }

      return {
        description
      };
    }

    get creating() {
      return this.options.creating;
    }

    cancel() {
      console.log("Cancel note creation");
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "formData", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "formData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "creating", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "creating"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = CreateNoteModal;
});