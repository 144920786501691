define("campaign-warrior-app/abilities/publish-source", ["exports", "campaign-warrior-app/abilities/cw-ability", "campaign-warrior-app/data/limits", "campaign-warrior-app/utils/ability"], function (_exports, _cwAbility, _limits, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PublishSource
   * @namespace Abilities
   * @extends Abilities.CwAbility
   */
  var _default = _cwAbility.default.extend({
    /**
     * @property canEdit
     * @type boolean
     */
    canEdit: Ember.computed.reads('canCreate'),

    /**
     * @property canAdd
     * @type boolean
     */
    canAdd: (0, _ability.portalRelatedAbility)('view_portal'),
    canDelete: (0, _ability.portalRelatedAbility)('view_portal')
  });

  _exports.default = _default;
});