define("campaign-warrior-app/controllers/portals/portal/pages/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * @class PagesList
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend((_dec = Ember._tracked, (_obj = {
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    can: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property notify
     * @type Ember.Service
     */
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: ['page', 'size'],

    /**
     * @property model
     * @type Models.Page[]
     * @default null
     */
    model: null,
    sortOrder: '',
    tagsSortBy: ['name:asc'],

    /**
     * @property tags
     * @type Models.Tag[]
     * @default null
     */
    tags: null,
    currentPage: 0,

    /**
     * @property totalNextPostCount
     * @default null
     */
    totalNextPostCount: null,

    /**
     * @property portal
     * @type Models.Portal
     * @default null
     */
    portal: null,

    /**
     * Hash with filters for pages
     *
     * @property filters
     * @type object
     * @default null
     */
    filters: null,
    componentRef: null,
    tableTab: null,

    /**
     * Determines if summary should be calculated for all pages or only for filtered
     *
     * @property useFilteredContent
     * @type boolean
     * @default true
     */
    useFilteredContent: true,

    /**
     * @property metricNameToFilter
     * @type string
     * @default ''
     */
    metricNameToFilter: '',
    sortedTags: Ember.computed.sort('tags', 'tagsSortBy'),
    tagToApply: null,
    funnelToApply: 'Unknown',
    formData: Ember.computed('filters', 'model', function () {
      const filters = Ember.get(this, 'filters');
      return Ember.Object.extend({
        title: Ember.computed(function () {
          if (filters) {
            const filterName = filters.title;

            if (filterName) {
              return filterName;
            }
          }

          return '';
        }),
        meta: Ember.computed(function () {
          if (filters) {
            const filterMeta = filters.meta;

            if (filterMeta) {
              return filterMeta;
            }
          }

          return '';
        }),
        metaSizeFrom: Ember.computed(function () {
          if (filters) {
            const filterMetaSizeFrom = filters.metaSizeFrom;

            if (filterMetaSizeFrom) {
              return filterMetaSizeFrom;
            }
          }

          return null;
        }),
        metaSizeTo: Ember.computed(function () {
          if (filters) {
            const filterMetaSizeTo = filters.metaSizeTo;

            if (filterMetaSizeTo) {
              return filterMetaSizeTo;
            }
          }

          return null;
        }),
        funnelStage: Ember.computed(function () {
          if (filters) {
            const filterFunnelStage = filters.funnelStage;

            if (filterFunnelStage && filterFunnelStage.length) {
              return filterFunnelStage;
            }
          }

          return 'Any';
        }),
        domain: Ember.computed(function (domain) {
          if (filters) {
            const filterDomain = filters.domain;

            if (filterDomain && filterDomain.length) {
              return filterDomain;
            }
          }

          return 'any';
        }),
        url: Ember.computed(function () {
          if (filters) {
            const filterUrls = filters.urls;

            if (filterUrls) {
              return filterUrls;
            }
          }

          return '';
        }),
        tagsList: Ember.computed(function () {
          if (filters) {
            const filterTags = filters.tags;

            if (filterTags && filterTags.length) {
              return filterTags;
            }
          }

          return [];
        }),
        omitTags: Ember.computed(function () {
          if (filters) {
            const filterOmitTags = filters.omitTags;

            if (filterOmitTags && filterOmitTags.length) {
              return filterOmitTags;
            }
          }

          return [];
        }),
        tagsLogic: filters.tagsLogic ? filters.tagsLogic : 'AND',
        omitTagsLogic: filters.omitTagsLogic ? filters.omitTagsLogic : 'OR',
        omitTags_enabled: false,
        tagList_enabled: false
      }).create(Ember.getOwner(this).ownerInjection());
    }).readOnly(),
    tagData: Ember.computed(function () {
      const filters = Ember.get(this, 'filters');
      return Ember.Object.extend({
        tagsList: Ember.computed(function () {
          if (filters) {
            const filterTags = filters.tagsList;

            if (filterTags && filterTags.length) {
              return filterTags;
            }
          }

          return [];
        }),
        tagsLogic: 'AND',
        omitTags: Ember.computed(function () {
          if (filters) {
            const filterOmitTags = filters.omitTags;

            if (filterOmitTags && filterOmitTags.length) {
              return filterOmitTags;
            }
          }

          return [];
        }),
        omitTagsLogic: 'AND'
      }).create(Ember.getOwner(this).ownerInjection());
    }).readOnly(),

    /**
     * @property metaAuditViewPages
     * @type Models.Page[]
     * @readonly
     */
    //metaAuditViewPages: sort('filteredPages', 'auditMetaSortProps'),
    //metaAuditOverViewPages: sort('filteredPages', 'auditMetaOverSortProps'),

    /*metaAuditViewPages: computed('filteredPages.@each.metaSize', function () {
      const pages =  get(this, 'filteredPages');
      return pages.filter(p => {return p.metaSize < 50}).sortBy('metaSize');
    }),
      metaAuditOverViewPages: computed('filteredPages.@each.metaSize', function () {
      const pages = get(this, 'filteredPages');
      return pages.filter(p => {return p.metaSize > 150}).sortBy('metaSize').reverse()
    }),*/
    //metaAuditOverViewsCountPages: sort('filteredPages', 'auditViewsCountOverSortProps'),

    /**
     * @property recentCreatedPages
     * @type Models.Page[]
     * @readonly
     */
    //recentCreatedPages: sort('filteredPages', 'mostRecentCreatedSortProps'),

    /**
     * @property favoritePages
     * @type Models.Page[]
     * @default []
     * @readonly
     */
    favoritePages: Ember.computed.filterBy('filteredPages', 'isFavorite', true),
    hiddenPages: Ember.computed.filterBy('model', 'hidden', true),

    /**
     * @property mostRecentUsedPages
     * @type Models.Page[]
     * @default []
     * @readonly
     */
    mostRecentUsedPages: Ember.computed('latestPosts.[]', function () {
      return Ember.get(this, 'latestPosts').mapBy('page').filterBy('id').uniqBy('id').slice(0, 10);
    }),

    /**
     * @property mostRecentCreatedPages
     * @type Models.Page[]
     * @default []
     * @readonly
     */
    mostRecentCreatedPages: Ember.computed('recentCreatedPages.[]', function () {
      return Ember.get(this, 'recentCreatedPages').slice(0, 10);
    }),

    /**
     * @property dataToShow
     * @type Models.Page[]
     * @default []
     */
    dataToShow: Ember.computed(function () {
      return Ember.get(this, 'mostViewedPages');
    }),

    /**
     * @property pagesUrls
     * @type string[]
     * @default []
     * @readonly
     */
    pagesUrls: Ember.computed('model', function () {
      const pages = Ember.get(this, 'model');
      return pages.map(page => Ember.get(page, 'url'));
    }).readOnly(),
    pages: Ember.computed('model', function () {
      const pages = Ember.get(this, 'model');
      return pages;
    }).readOnly(),
    domainUrls: Ember.computed('model', function () {
      const pages = Ember.get(this, 'model');
      return [];
    }).readOnly(),
    metaSize: Ember.computed('model', function () {
      const pages = Ember.get(this, 'model');
      let maxSize = 100;
      pages.forEach(function (item) {
        if (item.metaSize > maxSize) {
          maxSize = item.metaSize;
        }
      });
      return maxSize;
    }).readOnly(),

    /**
     * @property tagsToAddForPages
     * @type object
     */
    tagsToAddForPages: Ember.computed(function () {
      return {
        tags: []
      };
    }),

    /**
     * @property filteredPages
     * @type Models.Page[]
     * @default []
     */
    filteredPages: Ember.computed('model.[]', 'filters', 'metricNameToFilter', function () {
      const filters = Ember.get(this, 'filters');
      const metricNameToFilter = this.metricNameToFilter;
      const pages = this.model;

      if (!pages) {
        return [];
      }

      let filteredPages = pages;
      filteredPages = filteredPages.filter(page => {
        return !page.hidden;
      });
      return filteredPages;

      if (metricNameToFilter) {
        filteredPages = filteredPages.filter(page => page[metricNameToFilter] !== 0);
      }

      if (!filters) {
        return filteredPages;
      }

      return filters ? filteredPages.filter(page => {
        const filterUrl = filters.url;

        if (filterUrl) {
          const pageUrl = page.url || '';

          if (!pageUrl.toLowerCase().includes(filterUrl.toLowerCase())) {
            return false;
          }
        }

        const filterDomain = filters.domain;

        if (filterDomain && filterDomain.length) {
          const domainUrl = page.url || '';

          if (!filterDomain.includes(domainUrl.split('/')[0])) {
            return false;
          }
        }

        const filterMeta = filters.meta;

        if (filterMeta) {
          const meta = page.meta || '';

          if (!meta.toLowerCase().includes(filterMeta.toLowerCase())) {
            return false;
          }
        }

        const filterMetaSizeFrom = filters.metaSizeFrom;
        const filterMetaSizeTo = filters.metaSizeTo;
        const metaSize = page.metaSize || 0;

        if (filterMetaSizeFrom && filterMetaSizeFrom > metaSize) {
          return false;
        }

        if (filterMetaSizeTo && filterMetaSizeTo < metaSize) {
          return false;
        }

        const filterName = filters.name;

        if (filterName) {
          const title = page.title || '';

          if (!title.toLowerCase().includes(filterName.toLowerCase())) {
            return false;
          }
        }

        const pageTags = page.tags;
        const tagNames = [];
        pageTags.forEach(tag => {
          if (tag && tag.name) {
            tagNames.push(tag.name.toLowerCase());
          }
        });
        const filterTags = filters.tagsList;

        if (filterTags && filterTags.length) {
          // if (filterTags.some(t => get(pageTags, 'firstObject.name') != t.name)) {
          if (filterTags.some(t => tagNames.indexOf(t) === -1)) {
            return false;
          }
        } // const pageTags = get(page, 'tags');


        const filterOmitTags = filters.omitTags;

        if (filterOmitTags && filterOmitTags.length) {
          // pageTags.forEach((tag) => {
          if (filterOmitTags.some(t => tagNames.indexOf(t) > -1)) {
            return false;
          } // })

        }

        return true;
      }) : filteredPages;
    }),

    getFilteredPage(filters) {
      let queryFilters = {
        omitTagsLogic: "AND",
        tagsLogic: "AND",
        title: null,
        url: null,
        domain: null,
        meta: null,
        metaSizeFrom: null,
        metaSizeTo: null,
        tagsList: null,
        omitTags: null,
        funnelStage: null
      };
      let keys = Object.keys(filters);

      for (let k of keys) {
        if (filters[k] && filters[k].length) {
          queryFilters[k] = filters[k];
        }
      }

      if (this.sortOrder) {
        filters.sortBy = this.sortOrder;
      } else {
        filters.sortBy = '';
      }

      if (filters.tagsList && filters.tagsList.length) {
        filters.tagsList = encodeURIComponent(filters.tagsList);
      }

      if (filters.omitTags && filters.omitTags.length) {
        filters.omitTags = encodeURIComponent(filters.omitTags);
      }

      filters.page = 0;
      this.router.transitionTo('portals.portal.pages.list', this.portal.id, {
        queryParams: filters
      });
    },

    updateControllerFilters(filters) {
      Ember.set(this, 'filters', filters);
    },

    buildFilter() {
      let filters = {};

      if (this.formData) {
        const props = Ember.getProperties(this.formData, ['title', 'domain', 'url', 'meta', 'metaSizeFrom', 'metaSizeTo', 'funnelStage', 'tagsList', 'omitTags', 'tagsLogic', 'omitTagsLogic']);
        const keys = Object.keys(props);

        for (let k of keys) {
          if (k.includes('_enabled')) {
            continue;
          } //only include filters that are enabled and have values


          if (props[k] && props[k].length) {
            if (k === 'tagsList' || k === 'omitTags') {
              filters[k] = props[k].map(t => t.id);
              filters[k] = JSON.stringify(filters[k]);
            } else {
              filters[k] = props[k];
            }
          } else {
            filters[k] = null;
          }
        }

        if (props.metaSize_enabled) {
          if (props.metaSizeFrom) {
            filters.metaSizeFrom = props.metaSizeFrom;
          }

          if (props.metaSizeTo) {
            filters.metaSizeTo = props.metaSizeTo;
          }
        }
      }

      if (this.tagData) {
        const tagProps = Ember.getProperties(this.tagData, ['tagsList', 'omitTags', 'tagsLogic', 'omitTagsLogic']);
        const tagKeys = Object.keys(tagProps);

        for (let key of tagKeys) {
          if (key.includes('_enabled')) {
            continue;
          }

          if (tagProps[key] && tagProps[key].length) {
            let selectedTags = tagProps[key];

            if (typeof tagProps[key] === 'string') {
              //provide tags as array, or logic as string
              filters[key] = tagProps[key];
            } else {
              filters[key] = selectedTags.map(t => t.id);
              filters[key] = JSON.stringify(filters[key]);
            }
          } else {
            filters[key] = [];
          }
        }
      }

      return filters;
    },

    openDialog: false,
    actions: {
      popover() {
        if (this.openDialog == true) {
          Ember.set(this, "openDialog", false);
        } else {
          Ember.set(this, "openDialog", true);
        }
      },

      applyFunnel(stage) {
        this.ajax.post('/bulk/pages/funnel', {
          data: {
            portal: this.portal.id,
            stage: stage,
            filter: this.filter
          }
        }).then(() => {
          this.funnelToApply = null;
          this.send('refreshRoute');
        });
      },

      applyTag(tag) {
        this.ajax.post('/bulk/pages/tagging', {
          data: {
            portal: this.portal.id,
            tag: tag.id,
            filter: this.filter
          }
        }).then(() => {
          this.tagToApply = null;
          this.send('refreshRoute');
        });
      },

      removeTag(tag) {
        this.ajax.post('/bulk/pages/untagging', {
          data: {
            portal: this.portal.id,
            tag: tag.id,
            filter: this.filter
          }
        }).then(() => {
          this.tagToApply = null;
          this.send('refreshRoute');
        });
      },

      updateFilters(filters) {
        this.updateControllerFilters(filters);
      },

      triggerUpdate(clear) {
        if (clear) {
          this.getFilteredPage({
            title: null,
            url: null,
            domain: 'any',
            meta: null,
            metaSizeFrom: null,
            metaSizeTo: null,
            funnelStage: 'Any',
            tagsList: [],
            omitTags: []
          });
          return true;
        }

        let filters = {};

        if (this.formData) {
          const props = Ember.getProperties(this.formData, ['title', 'domain', 'url', 'meta', 'metaSizeFrom', 'metaSizeTo', 'funnelStage', 'tagsList', 'omitTags', 'tagsLogic', 'omitTagsLogic']);
          const keys = Object.keys(props);

          for (let k of keys) {
            if (k.includes('_enabled')) {
              continue;
            } //only include filters that are enabled and have values


            if (props[k] && props[k].length) {
              if (k === 'tagsList' || k === 'omitTags') {
                filters[k] = props[k].map(t => t.id);
                filters[k] = JSON.stringify(filters[k]);
              } else if (k === 'tagsLogic' || k === 'omitTagsLogic') {
                if (k === 'tagsLogic' && props['tagsList'].length > 0) {
                  filters[k] = props[k];
                }

                if (k === 'omitTagsLogic' && props['omitTags'].length > 0) {
                  filters[k] = props[k];
                }
              } else {
                filters[k] = props[k];
              }
            } else {
              filters[k] = null;
            }
          }

          if (props.metaSize_enabled) {
            if (props.metaSizeFrom) {
              filters.metaSizeFrom = props.metaSizeFrom;
            }

            if (props.metaSizeTo) {
              filters.metaSizeTo = props.metaSizeTo;
            }
          }
        }

        this.getFilteredPage(filters);
      },

      /**
       * @method actions.filterByMetric
       * @param {string} metricName metric name to filter pages (page should have value for metric greater than 0)
       */
      filterByMetric(metricName) {
        Ember.set(this, 'metricNameToFilter', metricName);
      },

      /*
       updateFilters(filters) {
         console.log("Update Filters action", filters);
       },
      */

      /**
       * @method actions.updateContentForSummary
       */
      updateContentForSummary() {
        this.toggleProperty('useFilteredContent');
      },

      /**
       * @method actions.toggleFavoritePage
       * @param {Models.Page} page favorite for this page is going to toggle
       * @returns {RSVP.Promise} request result
       */
      toggleFavoritePage(page) {
        const favorite = Ember.get(page, 'favorite');

        if (favorite && Ember.get(favorite, 'isLoaded')) {
          // for some unknown reasons `favorite` is proxy-object for record and not record itself
          return Ember.typeOf(favorite.destroyRecord) === 'function' ? favorite.destroyRecord : favorite.content.destroyRecord();
        }

        return Ember.get(this, 'store').createRecord('favorite', {
          originalType: 'pages',
          originalId: Ember.get(page, 'id'),
          page
        }).save();
      },

      /**
       * @method actions.addTagsForFilteredPages
       * @return {RSVP.Promise} operation result
       */
      addTagsForFilteredPages(tag) {
        // const tags = get(this, 'tagsToAddForPages.tags');
        const filteredPages = Ember.get(this, 'filteredPages');
        /*if (isEmpty(tags) || isEmpty(filteredPages)) {
          return resolve();
        }*/

        const i18n = Ember.get(this, 'i18n');
        return Ember.get(this, 'modalsManager').confirm({
          title: i18n.t('modals.defaultConfirmOptions.title'),
          body: i18n.t('pages.addTags.confirmation', {
            count: Ember.get(this, 'filteredPages.length'),
            tags: tag.name
          })
        }).then(() => this.addTagsForFilteredPages(tag));
      },

      registerComponent(componentRef) {
        Ember.set(this, 'componentRef', componentRef);
      },

      deregisterComponent() {
        Ember.set(this, 'componentRef', null);
      },

      resetForm() {
        const action = Ember.get(this, 'componentRef.actions');
        action.resetForm(Ember.get(this, 'componentRef'));
      },

      submitForm() {
        const action = Ember.get(this, 'componentRef.actions');
        action.submitForm();
      },

      metaAudit() {
        const action = Ember.get(this, 'componentRef.actions');
        const filters = Ember.get(this, 'filters');
        Ember.set(filters, 'metaSize', [0, 150]);
        action.submitForm();
      },

      async exportList() {
        const filteredPages = Ember.get(this, 'filteredPages');
        let csvContent = 'data:text/csv;charset=utf-8,';
        let dataToInclude = ['title', 'url', 'tags', 'domain', 'metaSize', 'meta', 'likesCount', 'funnelStage', 'journeyRating', 'techRating', 'designRating', 'salesRating', 'overallRating', 'id'];

        for (let d of dataToInclude) {
          csvContent += `${d}\t`;
        }

        csvContent += '\n';
        let count = 0;

        for (let pg of filteredPages) {
          let row = '';

          for (let d of dataToInclude) {
            if (d === 'domain') {
              row += Ember.get(pg, 'domain.url') + '\t';
            } else if (d === 'tags') {
              let tags = Ember.get(pg, 'tags');
              let i = 0;
              tags.forEach(tag => {
                i++;
                row += Ember.get(tag, 'name');

                if (i < tags.length - 1) {
                  row += ', ';
                }
              });
              row += '\t';
            } else {
              //remove things that ruin the csv
              row += Ember.get(pg, d).toString().replace(/\r?\n|\r|\t|#|"|'/g, ' ') + '\t';
            }
          }

          row = row.substring(0, row.length - 1);
          csvContent += row + '\n';
        }

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a'); //console.log('Encoded data', encodedUri);

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'cw_pages_export.txt');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },

      async copyHtmlList() {
        this.ajax.post('/export/cluster', {
          data: {
            portal: this.currentPortal.portal.id,
            filters: this.filters
          }
        }).then(function (response) {
          let htmlContent = '';
          const el = document.createElement('textarea');

          for (let pg of response.items) {
            const url = Ember.get(pg, 'url');
            const title = Ember.get(pg, 'title');
            htmlContent += '<a href="https://' + url + '">' + title + '</a>\n<br/>\n';
          }

          el.value = htmlContent;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
        });
      },

      async showExportDialog(formData) {
        Ember.set(this, "openDialog", false);

        if (this.can.can('is trial portal')) {
          return Ember.get(this, 'modalsManager').show('trial-modal', {
            exportingList: true
          }).then(() => {
            Ember.get(this, 'router').transitionTo('account');
          }).catch(() => {
            console.error('Going back');
          });
        } else {
          return Ember.get(this, 'modalsManager').show('pages/pages-export-modal', {
            filters: this.filters
          });
        }
      },

      auditUnderMeta() {
        Ember.set(this, 'formData.metaSizeFrom', null);
        Ember.set(this, 'formData.metaSizeTo', 50);
        let filters = this.buildFilter();
        Ember.set(filters, 'metaSizeFrom', null);
        Ember.set(filters, 'metaSizeTo', 50);
        Ember.set(this, 'currentPage', 0);
        this.getFilteredPage(filters);
      },

      auditOverMeta() {
        Ember.set(this, 'formData.metaSizeFrom', 160);
        Ember.set(this, 'formData.metaSizeTo', null);
        let filters = this.buildFilter();
        Ember.set(filters, 'metaSizeFrom', 160);
        Ember.set(filters, 'metaSizeTo', null);
        Ember.set(this, 'currentPage', 0);
        this.getFilteredPage(filters);
      },

      refreshList() {
        this.send('refreshRoute');
      }

    },

    /**
     * @method addTagsForFilteredPages
     * @return {RSVP.Promise} requests-result
     */
    addTagsForFilteredPages(tag) {
      const filteredPages = this.filteredPages; // const tagsToAdd = this.tagsToAddForPages.tags;

      let pageList = [];
      const portal = this.portal.id;
      filteredPages.forEach(page => {
        page.tags.pushObjects(tag);
        pageList.push({
          id: page.id
        });
      });
      let tagsList = [{
        id: tag.id
      }];
      this.ajax.put('/bulk/pages/tag', {
        data: {
          type: 'pages',
          action: 'applyTag',
          items: pageList,
          value: tagsList,
          portal
        }
      }).then(res => {// console.log('Response', res);
      });
    },

    settingFilters(filters) {
      Ember.set(this, 'tagToApply', null);
      this.updateControllerFilters(filters);
    }

  }, (_applyDecoratedDescriptor(_obj, "currentPage", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "currentPage"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj)), _obj)));

  _exports.default = _default;
});