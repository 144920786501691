define("campaign-warrior-app/helpers/qp-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.qpHash = qpHash;
  _exports.default = void 0;

  /**
   * Helper `query-params` doesn't work with object, so it isn't possible to do `{{query-params obj}}`.
   *
   * Helper `qp-hash` allows this - `{{qp-hash obj}}`.
   *
   * @param {object} obj hash to use as query params
   * @return {object} QP-object
   */
  function qpHash([obj]
  /*, hash*/
  ) {
    const qp = obj || {};
    return Ember.Object.extend({
      isQueryParams: true,
      values: null
    }).create({
      values: qp
    });
  }

  var _default = Ember.Helper.helper(qpHash);

  _exports.default = _default;
});