define("campaign-warrior-app/components/text-snippets/edit-snippet-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['create-snippet-form'],
    actions: {
      save() {
        try {
          return Ember.get(this, 'save')(Ember.get(this, 'record'));
        } catch (err) {
          console.error(err);
        }
      }

    }
  });

  _exports.default = _default;
});