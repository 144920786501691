define("campaign-warrior-app/components/pages/tags-adder-heading", ["exports", "campaign-warrior-app/components/cw-panel/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _title.default.extend({
    pages: null,
    tags: null,
    addTag: null,
    makeDisable: Ember.computed('applyTagsSelection', 'pages', function () {
      if (this.pages.length == 0) {
        return true;
      }

      return this.applyTagsSelection ? false : true;
    }),
    modalsManager: Ember.inject.service(),
    actions: {
      openClusterModal(tag) {
        return this.modalsManager.confirm({
          title: 'Make a Cluster',
          body: 'The Found Set Of Records Are About To All Be Tagged With Your Selected Tag, Building A Powerful Cluster.',
          confirm: 'Continue',
          decline: 'Cancel'
        }).then(() => this.applyTag(tag)).catch(e => this.modalsManager.detailedAjaxErrorHandler(e));
      },

      openUnClusterModal(tag) {
        return this.modalsManager.confirm({
          title: 'Remove a Cluster',
          body: 'The Found Set Of Records Are About To All Be Removed With Your Selected Tag.',
          confirm: 'Continue',
          decline: 'Cancel'
        }).then(() => this.removeTag(tag)).catch(e => this.modalsManager.detailedAjaxErrorHandler(e));
      },

      addTagsForFilteredPages(tag) {
        this.addTag(tag);
      },

      applyFilters() {
        this.triggerUpdate();
      }

    }
  });

  _exports.default = _default;
});