define("campaign-warrior-app/serializers/user", ["exports", "campaign-warrior-app/serializers/application", "ember-inflector"], function (_exports, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class User
   * @namespace Serializers
   * @extends Serializers.Application
   */
  var _default = _application.default.extend({
    /**
     * Permissions and Favorites are loaded for current user. They must be linked correctly with other models
     *
     * @method normalizeQueryRecordResponse
     * @param {DS.Store} store store itself
     * @param {DS.Model} modelClass `user` model
     * @param {object} payload server response
     * @returns {object} normalized payload
     */
    normalizeQueryRecordResponse(store, modelClass, payload) {
      if (!Ember.isArray(payload.included)) {
        payload.included = [];
      }

      const companyId = Ember.get(payload, 'data.relationships.company.data.id');
      payload.included = payload.included.map(item => {
        item.relationships.user = {
          data: {
            id: payload.data.id,
            type: 'users'
          }
        };

        if (item.type === 'permissions') {
          const level = item.attributes['object-id'] === companyId ? 'company' : 'portal';
          item.attributes.level = level;

          if (level === 'portal') {
            item.relationships.portal = {
              data: {
                id: item.attributes['object-id'],
                type: 'portals'
              }
            };
          }
        }

        if (item.type === 'favorites') {
          const type = (0, _emberInflector.singularize)(item.attributes['original-type']);
          item.relationships[type] = {
            data: {
              id: item.attributes['original-id'],
              type: item.attributes['original-type']
            }
          };
        }

        return item;
      });
      return this._super(...arguments);
    },

    /**
     * @method normalizeFindRecordResponse
     * @returns {object} normalized payload
     */
    normalizeFindRecordResponse() {
      return this.normalizeQueryRecordResponse(...arguments);
    },

    /**
     * User can update only its own profile.
     *
     * BE returns real `id` after update, so it must be reverted to `'current'`
     *
     * @param {DS.Store} store ember-data store
     * @param {Models.User} modelClass user-model class
     * @param {object} payload BE-response
     * @param {string} id original id (`'current'`)
     * @returns {object} normalized JSON
     */
    normalizeUpdateRecordResponse(store, modelClass, payload, id) {
      payload.data.id = id;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});