define("campaign-warrior-app/components/mentions/create-mention-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalsManager: Ember.inject.service(),
    classNames: ['create-mention-form'],
    platformOptions: [{
      name: 'Facebook',
      value: 'facebook'
    }, {
      name: 'Twitter',
      value: 'twitter'
    }, {
      name: 'LinkedIn',
      value: 'linkedin'
    }],
    actions: {
      save() {
        try {
          //const record = get(this, 'record');
          //console.log("Try Save", record);
          //record.save();
          //console.log("Close Modal", get(this, 'modalsManager'));
          //modal.close();
          //get(this, 'modalsManager').submit();
          const save = Ember.get(this, 'save');
          const record = Ember.get(this, 'record');
          save(record); //return save(record);
          //return get(this, 'save')(get(this, 'record'));
        } catch (err) {
          console.error(err);
        }
      },

      setPlatform(platform) {
        //console.log("Platform", platform);
        Ember.set(this, 'platform', platform);
        Ember.set(this, 'record.platform', platform.value);
      }

    }
  });

  _exports.default = _default;
});