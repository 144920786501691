define("campaign-warrior-app/routes/portals/portal/pages/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @namespace Routes
   * @class Page
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    title: 'Page Details | Campaign Warrior',

    model({
      page_id
    }) {
      return Ember.get(this, 'store').findRecord('page', page_id);
    },

    afterModel(model) {
      Ember.set(this, 'title', `Page: ${model.title} | Campaign Warrior`);
      Ember.set(this, 'breadCrumb', {
        title: Ember.get(model, 'title')
      });
    }

  });

  _exports.default = _default;
});