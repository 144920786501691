define("campaign-warrior-app/routes/portals/portal/pages/create", ["exports", "campaign-warrior-app/mixins/portal-related-manage-record-route"], function (_exports, _portalRelatedManageRecordRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class CreatePage
   * @namespace Routes
   * @extends Ember.Route
   * @uses Mixins.PortalRelatedManageRecordRoute
   */
  var _default = Ember.Route.extend(_portalRelatedManageRecordRoute.default, {
    modelClassName: 'page',

    model() {
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      const store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        model: this._super(...arguments),
        pages: store.query('page', {
          portal_id
        }),
        tags: store.query('tag', {
          portal_id
        })
      });
    },

    setupController(controller, modelHash) {
      Ember.set(controller, 'model', modelHash.model);
      Ember.set(controller, 'tags', modelHash.tags);
    }

  });

  _exports.default = _default;
});