define("campaign-warrior-app/templates/components/cw-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/boohc3M",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cw-image\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[10,\"em\"],[12],[10,\"small\"],[12],[10,\"i\"],[14,0,\"fa fa-warning\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"pages.metaImage.imageNotLoaded\"],null]],[13],[13],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[14,0,\"img-responsive center-block\"],[15,\"alt\",[30,[36,0],[\"logo.alt\"],null]],[15,\"src\",[34,1]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"src\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-image.hbs"
    }
  });

  _exports.default = _default;
});