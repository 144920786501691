define("campaign-warrior-app/components/modals/recurly-error-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class RequestErrorBody
   * @extends Ember.Component
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    /**
     * @property error
     * @type object
     * @default null
     * @private
     */
    error: Ember.computed.alias('options.error')
  });

  _exports.default = _default;
});