define("campaign-warrior-app/components/edit-tag-form", ["exports", "campaign-warrior-app/components/manage-record-form"], function (_exports, _manageRecordForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _manageRecordForm.default.extend({
    currentPortal: Ember.inject.service(),
    classNames: ['edit-tag-form'],
    portal: Ember.computed.reads('currentPortal.portal')
  });

  _exports.default = _default;
});