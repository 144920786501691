define("campaign-warrior-app/controllers/settings/users/user", ["exports", "campaign-warrior-app/utils/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class UserSettings
   * @namespace Controller
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property notify
     * @type Ember.Service
     */
    notify: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property model
     * @type Models.User
     * @default null
     */
    model: null,

    /**
     * @property portals
     * @type Models.Portal[]
     * @default null
     */
    portals: null,
    actions: {
      /**
       * @method actions.updatePermissions
       * @param {object} formData list of the new permissions for user
       * @returns {RSVP.Promise} save result
       */
      updatePermissions(formData) {
        const ajax = Ember.get(this, 'ajax');
        const i18n = Ember.get(this, 'i18n');
        const modalsManager = Ember.get(this, 'modalsManager');
        const notify = Ember.get(this, 'notify');
        const user = Ember.get(this, 'model');
        const userId = Ember.get(user, 'id');
        const newPermissions = formData.permissions;
        const currentPermissions = Ember.get(user, 'permissions').invoke('getProperties', 'objectId', 'permissionType');
        const permissionsToGrant = (0, _object.diffByKeys)(newPermissions, currentPermissions, ['objectId', 'permissionType']);
        const permissionsToRevoke = (0, _object.diffByKeys)(currentPermissions, newPermissions, ['objectId', 'permissionType']);
        return Ember.RSVP.hash({
          grant: permissionsToGrant.length ? ajax.post('/permission/grant', {
            data: {
              'target-user-id': userId,
              permissions: permissionsToGrant
            }
          }) : Ember.RSVP.resolve(),
          revoke: permissionsToRevoke.length ? ajax.post('/permission/revoke', {
            data: {
              'target-user-id': userId,
              permissions: permissionsToRevoke
            }
          }) : Ember.RSVP.resolve()
        }).then(() => {
          this.send('refreshRoute');
          notify.success(i18n.t('settings.users.user.updatePermissions.success', {
            user: Ember.get(user, 'email')
          }));
        }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      }

    }
  });

  _exports.default = _default;
});