define("campaign-warrior-app/components/cw-table/expand-all-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component to collapse/expand all rows. Must be used as a `componentForSortingCell`
   *
   * @class CwTableExpandAllToggle
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    actions: {
      collapseAllRows() {
        Ember.get(this, 'collapseAllRows')();
      },

      expandAllRows() {
        Ember.get(this, 'expandAllRows')();
      }

    }
  });

  _exports.default = _default;
});