define("campaign-warrior-app/services/emmi-modals-manager", ["exports", "ember-modals-manager-internal/services/modals-manager"], function (_exports, _modalsManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modalsManager.default;
    }
  });
});