define("campaign-warrior-app/templates/portals/portal/setup/website", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "34vPbDDC",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"PanelTitle\",\"formData\",\"redirectUrl\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"redirectUrl\",\"formData\",\"PanelTitle\",\"setup/setup-connect-website-panel\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/setup/website.hbs"
    }
  });

  _exports.default = _default;
});