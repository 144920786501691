define("campaign-warrior-app/templates/components/material/select-power", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fo6K2wVu",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"filter-item material-form-select\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"filter-element-wrapper\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"label-span\"],[12],[1,[34,1]],[13],[2,\"\\n\"],[6,[37,7],null,[[\"placeholder\",\"options\",\"searchField\",\"selected\",\"onChange\"],[\"Show Tags\",[35,6],\"name\",[35,5],[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"tag\",\"selectionView\"],[[32,1],true]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\" \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"script\"],[12],[2,\"\\n  $(document).ready(function(){\\n    $('.material-form-select .ember-power-select-trigger').focus(function(){\\n      $(this).prev().addClass('label-active');\\n      //$('.material-form-select .label-span').addClass('label-active');\\n    })\\n    $('.material-form-select .ember-power-select-trigger').blur(function(){\\n      $(this).prev().removeClass('label-active');\\n    })\\n  })\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"cw-tag\",\"label\",\"property\",\"mut\",\"action\",\"selected\",\"options\",\"power-select-multiple\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/material/select-power.hbs"
    }
  });

  _exports.default = _default;
});