define("campaign-warrior-app/components/source-type-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{source-type-icon sourceType=someType extraClasses="fa-2x"}}
   * ```
   *
   * @class SourceTypeIcon
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: 'span',

    /**
     * @property sourceType
     * @type string
     * @default null
     */
    sourceType: null,

    /**
     * @property extraClasses
     * @type string
     * @default ''
     */
    extraClasses: '',

    /**
     * @property icon
     * @type string
     * @private
     * @readonly
     */
    icon: Ember.computed('sourceType', function () {
      const sourceType = Ember.get(this, 'sourceType');
      return {
        facebook: 'fa-facebook',
        twitter: 'fa-twitter',
        linkedin: 'fa-linkedin',
        gplus: 'fa-google-plus'
      }[sourceType];
    }).readOnly()
  });

  _exports.default = _default;
});