define("campaign-warrior-app/routes/portals/portal/integrations/hubspots/hubspot", ["exports", "campaign-warrior-app/mixins/portal-internal-route"], function (_exports, _portalInternalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @namespace Routes
   * @class Page
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_portalInternalRoute.default, {
    /*
      model() {
        console.log("Hubspot");
        const store = get(this, 'store');
        const portal = this.modelFor('portals.portal');
        const portal_id = get(portal, 'id');
        return store.query('integration', {filter: {
            portal: portal_id,
            type: 'hubspot'
          }});
      },*/
    model({
      integration_id
    }) {
      console.log('in hubspot route');
      const store = Ember.get(this, 'store');
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      const integration = store.findRecord('integration', integration_id);
      let channels = [];
      let connectedChannels = [];
      integration.then(i => {
        /*
        console.log('Publishing Sources', publishingSources);
        publishingSources.then((ps) => {
          console.log('PS', ps);
          ps.forEach((p) => {
            console.log('P', p);
            const sourceId = get(p, 'sourceId');
             for (let ch of get(i, 'channels')){
              const chid = get(ch, 'id');
              if (chid === sourceId){
                const accountSlug = get(ch, 'attributes.accountSlug');
                if (accountSlug === 'twitter'){
                  //hubspot social media api currently doesnt provide a channel name for twitter
                  set(ch, 'attributes.name', p.meta.alias);
                } else if (accountSlug === 'facebook'){
                  set(ch, 'attributes.name', p.displayName);
                } else {
                  console.log('CH Type not handled', ch);
                }
                ch.prospectivePublishingSourceId = get(p, 'id');
                ch.integrationId = get(i, 'id');
                ch.publishingSource = p;
                 const psIntegrationId = get(p, 'viaIntegration');
                if (psIntegrationId){
                  connectedChannels.push(ch);
                } else {
                  channels.push(ch);
                }
              }
            }
          })
        });
        */
      });
      integration.channels = channels;
      integration.connectedChannels = connectedChannels;
      console.log('Route Integration', integration);
      console.log('Channels', channels);
      console.log('Connected Channels', connectedChannels);
      return {
        integration,
        channels,
        connectedChannels
      };
    },

    afterModel(model) {
      Ember.set(this, 'breadCrumb', {
        title: Ember.get(model, 'title')
      });
      console.log('After Model', model);
    },

    actions: {
      refreshRoute() {
        this.refresh();
      }

    },

    getValidChannels(sources, channels) {
      let vc = [];

      for (let s of sources) {
        console.log('Source', s);
      }
    }

  });

  _exports.default = _default;
});