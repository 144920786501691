define("campaign-warrior-app/helpers/t-key-exists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @namespace Helpers
   * @class TKeyExists
   */
  var _default = Ember.Helper.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    compute([tKey]) {
      return Ember.get(this, 'i18n').exists(tKey);
    }

  });

  _exports.default = _default;
});