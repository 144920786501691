define("campaign-warrior-app/templates/components/hashtags/edit-hashtag-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zeRSKovt",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[6,[37,8],null,[[\"open\",\"title\",\"onSubmit\",\"onHide\"],[[35,7],[30,[36,0],[\"hashtags.edit.title\"],null],[30,[36,1],[[32,0],\"confirm\"],null],[30,[36,1],[[32,0],\"decline\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"header\"]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('campaign-warrior-app/templates/components/hashtags/edit-hashtag-modal.hbs' @ L8:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"record\",\"onlyOwnAttrs\",\"hideControls\",\"save\"],[[35,3,[\"hashtag\"]],true,true,[30,[36,1],[[32,0],\"confirm\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[30,[36,5],[[32,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('campaign-warrior-app/templates/components/hashtags/edit-hashtag-modal.hbs' @ L11:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[6,[37,2],null,[[\"onClick\",\"type\"],[[30,[36,1],[[32,0],[32,1,[\"submit\"]]],null],\"primary\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"hashtags.edit.save\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"bs-button\",\"options\",\"hashtags/edit-hashtag-form\",\"-assert-implicit-component-helper-argument\",\"component\",\"modalIsOpened\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/hashtags/edit-hashtag-modal.hbs"
    }
  });

  _exports.default = _default;
});