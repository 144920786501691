define("campaign-warrior-app/controllers/choose-plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import {inject as controller} from '@ember/controller';

  /**
   * @class Register
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    actions: {
      chooseFree() {
        Ember.get(this, 'router').transitionTo('register');
      },

      choosePro() {
        Ember.get(this, 'router').transitionTo('pro-register');
      }

    }
  });

  _exports.default = _default;
});