define("campaign-warrior-app/controllers/portals/portal/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PortalDashboard
   * @namespace Controllers
   * @extends Ember.Controller
   * @uses Mixins.ManagePostJob
   */
  var _default = Ember.Controller.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property campaigns
     * @type Models.Campaign[]
     * @default null
     */
    campaigns: null,

    /**
     * @property newCampaign
     * @type Models.Campaign
     * @default null
     */
    newCampaign: null,

    /**
     * @property pages
     * @type Models.Page[]
     * @default null
     */
    pages: null,

    /**
     * @property posts
     * @type Models.Post[]
     * @default null
     */
    posts: null,

    /**
     * @property postJobs
     * @type Models.PostJob[]
     * @default null
     */
    postJobs: null,

    /**
     * @property publishSources
     * @type PublishSource[]
     * @default null
     */
    publishSources: null,

    /**
     * @property portal
     * @type Models.Portal
     * @default null
     */
    portal: Ember.computed.reads('currentPortal.portal'),

    /**
     * @property pagesColumns
     * @type object[]
     */
    pagesColumns: Ember.computed(function () {
      const i18n = Ember.get(this, 'i18n');
      return [{
        title: i18n.t('pages.summary.columns.title'),
        propertyName: 'title',
        component: 'cw-table/link-to',
        route: 'portals.portal.pages.page.edit',
        parentRecordIds: [Ember.get(this, 'currentPortal.portal.id')],
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        className: 'views-cell',
        propertyName: 'viewsCount',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true,
        title: i18n.t('pages.summary.views'),
        icon: 'fa-eye',
        searchType: 'filter'
      }, {
        className: 'meta-cell',
        propertyName: 'metaSize',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true,
        searchType: 'filter',
        title: i18n.t('dashboard.tables.pages.titles.metaSize')
      }, {
        className: 'delete-cell',
        title: 'delete',
        component: 'delete-record'
      }];
    }),
    actions: {
      /**
       * Delete tag from the BE
       *
       * @method actions.deletePage
       * @param {Models.Page} page page to delete
       */
      deletePage(page) {
        const i18n = Ember.get(this, 'i18n');
        const modalsManager = Ember.get(this, 'modalsManager');
        modalsManager.confirm({
          title: i18n.t('modals.defaultConfirmOptions.title'),
          body: i18n.t('modals.defaultConfirmOptions.body')
        }).then(() => page.destroyRecord()).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      },

      myStartAction(content) {
        console.log('myStartAction: ', content); //Content is the same as the content parameter set above
      },

      myEndAction(content) {
        console.log('myEndAction: ', content); //Content is the same as the content parameter set above
      },

      sortEndAction(content) {
        console.log('sortEndAction: ', content); //Content is the same as the content parameter set above
      },

      increaseRating(obj, ops) {
        console.log('increaseRating obj: ', obj);
        console.log('increaseRating ops: ', ops);
        var amount = parseInt(ops.target.amount);
        obj.incrementProperty('rating', amount); // obj.save();
      }

    }
  });

  _exports.default = _default;
});