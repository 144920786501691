define("campaign-warrior-app/templates/page-size-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mNf/ekEr",
    "block": "{\"symbols\":[\"size\"],\"statements\":[[10,\"select\"],[15,\"onchange\",[30,[36,0],[[32,0],\"selectSize\"],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"option\"],[15,2,[31,[[32,1]]]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"perPageOptions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/page-size-select.hbs"
    }
  });

  _exports.default = _default;
});