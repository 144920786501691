define("campaign-warrior-app/templates/components/cw-table/page-name-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l/gCbhuX",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"route\",\"model\"],[\"portals.portal.pages.page.edit\",[35,1]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\",\"link-to\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/page-name-cell.hbs"
    }
  });

  _exports.default = _default;
});