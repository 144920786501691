define("campaign-warrior-app/components/setup-new-portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WizardPortalSetup
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    router: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['wizard-portal-setup'],
    wizardConfig: {
      steps: [{
        stepName: 'Set Your Portal Name',
        properties: [{
          ref: 'portal.name',
          type: 'text',
          label: 'Create New Portal Name',
          required: true
        }]
      }, {
        stepName: 'Reporting Settings',
        properties: [{
          ref: 'portal.customerLogo',
          type: 'logo-upload',
          label: 'Customer Logo',
          required: false
        }, {
          ref: 'portal.agencyLogo',
          type: 'logo-upload',
          label: 'Agency Logo',
          required: false
        }]
      }]
    },
    activePortal: Ember.computed(function () {}),
    errorMessage: Ember.computed(function () {
      return null;
    }),
    submitted: Ember.computed(function () {
      return false;
    }),
    formData: Ember.Object.extend({
      portal_name: Ember.computed(function () {
        return null;
      }),
      portal_domain: Ember.computed(function () {
        return null;
      })
    }).create(),
    formFilled: Ember.computed(function () {
      return false;
    }),
    completedSteps: Ember.computed(function () {
      return [];
    }),
    trackingId: Ember.computed(function () {
      return null;
    }),
    trackingCode: Ember.computed('trackingId', function () {
      return `<script>
    var p = document.createElement("img");
    p.src = "https://index.campaignwarrior.com/pixel.png?id=${Ember.get(this, 'trackingId')}";
    p.style.display = "none";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(p, s);
  </script>`;
    }),

    init() {
      //console.log('wizard-portal-setup init');
      const formData = Ember.get(this, 'formData'); //console.log('Has formData', formData);

      this._super(...arguments);
    },

    actions: {
      submitForm(data) {
        const submitted = Ember.get(this, 'submitted');

        if (submitted) {
          // console.error("Form already submitted");
          return;
        }

        Ember.set(this, 'submitted', true);
        let fd = Ember.get(this, 'formData');
        const newPortalData = {
          name: Ember.get(fd, 'portal_name'),
          domain: Ember.get(fd, 'portal_domain')
        };
        const store = Ember.get(this, 'store');
        const router = Ember.get(this, 'router');
        store.createRecord('portal', newPortalData).save().then(portal => {
          Ember.set(this, 'activePortal', portal);
          Ember.get(this, 'currentUser').load().then(() => {
            store.createRecord('domain', {
              portal: Ember.get(this, 'activePortal'),
              url: Ember.get(this, 'formData.portal_domain')
            }).save().then(domain => {
              Ember.set(this, 'createdDomain', domain);
              Ember.set(this, 'trackingId', domain.trackingId);
            });
          });
        }).catch(err => {
          console.error("Caught error in portal form submission", err);
        });
      },

      onSubmit(formData) {// console.log('wizard-portal-setup onSubmit()', formData);
      },

      cancelWizard(wizard) {// console.log('Cancel Wizard', wizard)
      },

      getStarted() {
        Ember.set(this, 'finished', true);
      },

      copyTrackingCode() {
        const el = document.createElement('textarea');
        el.value = Ember.get(this, 'trackingCode');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }

    },

    validateStepValues() {
      const formData = Ember.get(this, 'formData');
      Ember.set(this, 'errorMessage', null);

      if (!formData.portal_name || !formData.portal_name.length) {
        Ember.set(this, 'errorMessage', 'Your portal must have a name');
        return false;
      }

      if (!formData.portal_domain || !formData.portal_domain.length || !formData.portal_domain.includes('.')) {
        Ember.set(this, 'errorMessage', 'You must specify a valid URL');
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});