define("campaign-warrior-app/templates/components/tags/tag-action-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UCo8liE9",
    "block": "{\"symbols\":[\"dd\",\"ddm\"],\"statements\":[[8,\"bs-dropdown\",[[24,0,\"tag-action-menu\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"toggle\"]],[[24,0,\"action-menu-toggle\"]],[[],[]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fal fa-ellipsis-v\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"menu\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[11,\"a\"],[4,[38,1],[[32,0],\"editTag\",[35,0]],null],[12],[10,\"i\"],[14,0,\"fal fa-pencil\"],[12],[13],[10,\"span\"],[12],[2,\"Edit Tag\"],[13],[13],[2,\"\\n      \"],[11,\"a\"],[4,[38,1],[[32,0],\"deleteTag\",[35,0]],null],[12],[10,\"i\"],[14,0,\"fal fa-trash\"],[12],[13],[10,\"span\"],[12],[2,\"Delete\"],[13],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"record\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/tags/tag-action-menu.hbs"
    }
  });

  _exports.default = _default;
});