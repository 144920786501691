define("campaign-warrior-app/components/pages/page-domain-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    url: null,
    domainUrl: Ember.computed(function () {
      const url = Ember.get(this, 'record.url');
      return url.split('/')[0];
    })
  });

  _exports.default = _default;
});