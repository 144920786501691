define("campaign-warrior-app/controllers/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Register
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property login
     * @type Ember.Controller
     */
    login: Ember.inject.controller(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),
    recurly: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property portals
     * @type Models.Portal[]
     * @default null
     */
    portals: null,

    /**
     * @property requestInProgress
     * @type boolean
     * @default false
     */
    requestInProgress: false,
    isChoosen: false,
    billingInfo: Ember.computed.reads('currentUser.user.company.billingInfo'),
    isTrial: Ember.computed(function () {
      return true;
    }),
    planName: Ember.computed('isTrial', function () {
      if (this.isTrial) {
        return '14 Day Trial';
      }

      return 'Full Plan';
    }),

    /**
     * @property billingInfo
     * @type Models.BillingInfo
     * @readonly
     */
    actions: {
      async doRegister(form, formData, errorBody) {
        if (this.requestInProgress) return;
        Ember.set(this, 'requestInProgress', true);
        const modalsManager = Ember.get(this, 'modalsManager');
        const ajax = this.ajax;
        form.isTrial = this.isTrial;
        let token;

        try {
          token = await Ember.get(this, 'recurly').getToken(form);
        } catch (err) {
          modalsManager.billingErrorHandler(err);
          return;
        }

        if (token) {
          try {
            form['recurly-token'] = token;
            await ajax.post('/register', {
              data: form
            });
          } catch (err) {
            modalsManager.registrationErrorHandler(err);
            return;
          } finally {
            Ember.set(this, 'requestInProgress', false);
          }

          this.router.transitionTo('verification.required');
        } else {
          modalsManager.billingErrorHandler({});
        }
      },

      selectPlan(planName) {
        Ember.set(this, "isChoosen", true);

        if (planName === 'trial') {
          Ember.set(this, 'isTrial', true);
        } else if (planName === 'full') {
          Ember.set(this, 'isTrial', false);
        }
      }

    }
  });

  _exports.default = _default;
});