define("campaign-warrior-app/routes/portals/portal/index", ["exports", "campaign-warrior-app/utils/consts"], function (_exports, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumbs: null,
    rootElementClasses: Object.freeze(['portal-home-page']),

    model() {
      const store = Ember.get(this, 'store');
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      return Ember.RSVP.hash({
        tiles: store.query('tile', {
          portal_id,
          ...(0, _consts.defaultPaginationSettings)()
        })
      });
    },

    setupController(controller, model) {
      console.log("Portal index route setupController");
      Ember.setProperties(controller, {
        tiles: model.tiles
      });
    }

  });

  _exports.default = _default;
});