define("campaign-warrior-app/components/pages/page-snippet-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   * @class PageSnippetList
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    classNames: ['snippet-table'],
    type: null,
    title: null,
    onCreate: null,
    filteredList: Ember.computed.filter('snippets', function (snippet) {// return hashtag.type === this.type;
      //return snippet.message.includes(filterParams)
    }),
    actions: {
      use: function (el) {
        let rawMessage = Ember.get(this, 'formData.rawMessage');
        if (!rawMessage) rawMessage = '';
        rawMessage += `${el.message}`;
        Ember.set(this, 'formData.rawMessage', rawMessage);
      },
      edit: function (snippet) {
        const modalsManager = Ember.get(this, 'modalsManager');
        return modalsManager.show('text-snippets/edit-snippet-modal', {
          snippet
        }).then(function (snippet) {
          snippet.save();
        });
      },
      delete: function (snippet) {
        const modalsManager = Ember.get(this, 'modalsManager');
        return modalsManager.confirm({
          title: Ember.get(this, 'i18n').t('modals.defaultConfirmOptions.title'),
          body: Ember.get(this, 'i18n').t('modals.defaultConfirmOptions.body'),
          confirm: Ember.get(this, 'i18n').t('modals.defaultConfirmOptions.confirm')
        }).then(() => {
          snippet.destroyRecord();
        }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      }
    }
  });

  _exports.default = _default;
});