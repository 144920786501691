define("campaign-warrior-app/components/home/home-tiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    classNames: ['home-tiles'],
    portal: Ember.computed.reads('currentPortal.portal'),
    tilesSorted: Ember.computed.sort('tiles', 'sortBy'),
    sortBy: Object.freeze(['row:dsc', 'col:dsc']),
    tiles: Ember.computed(function () {
      return [];
    }),
    tilesFiltered: Ember.computed(function () {
      let tiles = this.tilesSorted; //console.log('Tiles', tiles);

      let result = [];

      for (let t of tiles) {
        switch (t.title) {
          case 'Manage Campaigns':
            //manage campaigns
            break;

          case 'Post Social Media':
            //post social media
            break;

          case 'Future Post Schedule':
            //future post schedule
            break;

          case 'Add Social Account':
            //add social account
            break;

          case 'Add Sub Domain':
            //add social account
            break;

          case 'Stats':
            break;

          case 'Add Portal':
            break;

          case 'View Billing':
            if (this.can.can('edit billing')) {
              result.push(t);
              break;
            }

            break;

          default:
            //console.log('Library tile', t);
            result.push(t);
        }
      }

      return result;
    }),
    actions: {
      tileClick(tile, event) {
        const link = Ember.get(tile, 'link');
        const type = Ember.get(tile, 'action-type');

        if (type === 'link' && link.includes('help')) {
          window.open(link, '_blank');
        } else if (type === 'link') {
          if (link === 'account') {
            window.localStorage.setItem('activePortal', JSON.stringify(Ember.get(this, 'currentPortal.portal.id')));
            Ember.get(this, 'router').transitionTo('account');
          } else {
            const router = Ember.get(this, 'router');
            router.transitionTo(link);
          }
        } else if (type === 'modal') {
          if (link === 'portals.portal.setup') {
            const topNav = Ember.getOwner(this).lookup('component:top-nav');

            if (topNav) {
              topNav.send('editPortal');
            }
          } else if (link === 'portals.portal.pages.list') {
            if (this.can.can('is trial portal')) {
              return Ember.get(this, 'modalsManager').show('trial-modal', {
                exportingList: true
              }).catch(() => {
                console.error('Going back');
              });
            } else {
              return Ember.get(this, 'modalsManager').show('pages/pages-export-modal');
            }
          } else {// console.log('Link', link);
          }
        } else {
          console.error(`Tile actionType ${type} not implemented`);
        }
      },

      hardcodedTileClick(tileRef) {
        switch (tileRef) {
          case 'freedemo':
            window.open('https://www.campaignwarrior.com/sign-up-for-a-free-demo-of-campaign-warrior', '_blank');
            break;

          case 'getstarted':
            window.open('https://www.campaignwarrior.com/get-started-video', '_blank');
            break;
        }
      }

    }
  });

  _exports.default = _default;
});