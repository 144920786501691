define("campaign-warrior-app/templates/components/modals/single-process", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cmE2hqFF",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[6,[37,5],null,[[\"open\",\"keyboard\",\"backdropClose\",\"onSubmit\",\"onHide\"],[[35,4],false,false,[30,[36,3],[[32,0],\"confirm\"],null],[30,[36,3],[[32,0],\"decline\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('campaign-warrior-app/templates/components/modals/single-process.hbs' @ L3:C5) \"],null]],[[\"closeButton\"],[false]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('campaign-warrior-app/templates/components/modals/single-process.hbs' @ L6:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[35,0,[\"body\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"text-center\"],[12],[10,\"i\"],[14,0,\"fal fa-spinner fa-spin fa-2x fa-fw\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[32,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('campaign-warrior-app/templates/components/modals/single-process.hbs' @ L10:C5) \"],null]],null,[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"options\",\"-assert-implicit-component-helper-argument\",\"component\",\"action\",\"modalIsOpened\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/single-process.hbs"
    }
  });

  _exports.default = _default;
});