define("campaign-warrior-app/utils/ability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.portalRelatedAbility = portalRelatedAbility;
  _exports.ability = ability;

  /**
   * @param {string|string[]} permissionTypes needed permission name(s) from Models.Permission
   * @returns {Ember.Computed} computed property based on permission for portal
   */
  function portalRelatedAbility(permissionTypes, limitedAccountAction) {
    const neededPermissions = Ember.makeArray(permissionTypes);
    const permissions = Ember.computed('user.permissions.@each.{objectId,permissionType}', 'portal', function () {
      const permissions = Ember.get(this, 'user.permissions');
      const currentPortalId = Ember.get(this, 'portal.id');

      if (Ember.isEmpty(permissions) || Ember.isEmpty(currentPortalId)) {
        return true;
      }

      return neededPermissions.every(neededPermission => permissions.any(p => Ember.get(p, 'objectId') === currentPortalId && Ember.get(p, 'permissionType') === neededPermission));
    });
    return permissions;
  }
  /**
   * @param {string} permissionType permission name from Models.Permission
   * @returns {Ember.Computed} computed property based on permission
   */


  function ability(permissionType) {
    return Ember.computed('user.permissions.@each.permissionType', function () {
      const permissions = Ember.get(this, 'user.permissions');

      if (Ember.isEmpty(permissions)) {
        return true;
      }

      return !!permissions.findBy('permissionType', permissionType);
    });
  }
});