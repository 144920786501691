define("campaign-warrior-app/templates/components/users/delete-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TORHI6re",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[34,2]],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[35,3]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[\"fal fa-fw \",[34,0]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"btnIcon\",\"if\",\"btnText\",\"isAdmin\",\"not\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/users/delete-user.hbs"
    }
  });

  _exports.default = _default;
});