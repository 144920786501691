define("campaign-warrior-app/templates/components/modals/delete-dir-confirm-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2WFHq/G9",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"alert alert-danger\"],[12],[2,\"\\n  \"],[1,[35,0,[\"body\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"input\"],[14,0,\"form-control\"],[15,\"oninput\",[30,[36,2],[[32,0],[35,1]],[[\"value\"],[\"target.value\"]]]],[14,4,\"text\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"options\",\"updatePromptValue\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/delete-dir-confirm-body.hbs"
    }
  });

  _exports.default = _default;
});