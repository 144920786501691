define("campaign-warrior-app/controllers/portals/portal/developer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Developer
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    modalsManager: Ember.inject.service(),

    /*
      beforeModel(model) {
        this._super(model);
        console.log("Current user is developer", get(this, 'currentUser.company.billing.sandboxed'))
      },
    
      afterModel(model){
        const user = get(this, 'currentUser.user');
        this._super(model);
      },*/
    actions: {
      showEditPortal: function () {
        const modalsManager = Ember.get(this, 'modalsManager');
        return modalsManager.show('wizard-portal-setup', {
          model: this.model
        }).then(function (modalCallback) {
          //mt.save();
          console.log('Modal Callback', modalCallback);
        });
      }
    }
  });

  _exports.default = _default;
});