define("campaign-warrior-app/components/models-table", ["exports", "ember-models-table/components/models-table", "ember-models-table/templates/components/models-table", "campaign-warrior-app/utils/models-table/theme"], function (_exports, _modelsTable, _modelsTable2, _theme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extend original `models-table` to do global setup for all tables
   *
   * @class ModelsTable
   * @namespace Components
   * @extends EmberModelsTable.Components.ModelsTable
   */
  var _default = _modelsTable.default.extend({
    layout: _modelsTable2.default,
    themeInstance: _theme.default,
    filteringIgnoreCase: true,
    // eslint-disable-next-line
    pageSizeValues: Ember.computed(function () {
      return [10, 20, 40];
    }),

    // todo remove when new version of ember-models-table is published
    _multiColumnsSorting(column, sortedBy, newSorting) {
      Ember.set(column, 'sorting', newSorting);
      let sortProperties = Ember.get(this, 'sortProperties');
      let sortPropertiesMap = {};
      sortProperties.forEach(p => {
        let [propertyName, order] = p.split(':');
        sortPropertiesMap[propertyName] = order;
      });
      delete sortPropertiesMap[sortedBy];
      let newSortProperties = Ember.A([]);
      let newSortFunctions = Object.create(null);
      Object.keys(sortPropertiesMap).forEach(propertyName => {
        if (propertyName !== sortedBy) {
          newSortProperties.pushObject(`${propertyName}:${sortPropertiesMap[propertyName]}`);
        }

        Ember.set(newSortFunctions, propertyName, Ember.get(column, 'sortFunction'));
      });

      if ('none' !== newSorting) {
        newSortProperties.pushObject(`${sortedBy}:${newSorting}`);
        newSortFunctions[sortedBy] = Ember.get(column, 'sortFunction');
      }

      Ember.set(this, 'sortProperties', newSortProperties);
      Ember.set(this, 'sortFunctions', newSortFunctions);
    }

  });

  _exports.default = _default;
});