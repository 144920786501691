define("campaign-warrior-app/helpers/starts-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startsWith = startsWith;
  _exports.default = void 0;

  /**
   * Check if `source`-string starts with `needed`-value
   *
   * Usage:
   *
   * ```hbs
   * {{#if (starts-with source needed)}}
   * {{/if}}
   * ```
   *
   * @namespace Helpers
   * @class StartsWith
   *
   * @param {string} source source value
   * @param {string} needed needed value
   * @returns {boolean} true - if `source` starts with `needed`
   */
  function startsWith([source, needed]
  /*, hash*/
  ) {
    return String(source).startsWith(String(needed));
  }

  var _default = Ember.Helper.helper(startsWith);

  _exports.default = _default;
});