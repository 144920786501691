define("campaign-warrior-app/components/register-form-partial", ["exports", "campaign-warrior-app/components/floating-form", "ember-cp-validations", "campaign-warrior-app/data/countries", "campaign-warrior-app/data/states", "campaign-warrior-app/utils/object"], function (_exports, _floatingForm, _emberCpValidations, _countries, _states, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage
   *
   * ```hbs
   * {{register-form onSubmit=(action "registerHandler")}}
   * ```
   *
   * @namespace Components
   * @class RegisterForm
   * @extends Components.FloatingForm
   */
  var _default = _floatingForm.default.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['register-form'],
    isTrial: false,

    /**
     * @property countries
     * @private
     * @readonly
     * @type {{name: string, code: string}[]}
     */
    countries: _countries.default,
    states: _states.default,
    submitInProgress: false,
    formData: Ember.computed(function () {
      const _this = this;

      const Validations = (0, _emberCpValidations.buildValidations)({
        first_name: (0, _emberCpValidations.validator)('presence', true),
        last_name: (0, _emberCpValidations.validator)('presence', true),
        company: (0, _emberCpValidations.validator)('presence', true),
        address1: (0, _emberCpValidations.validator)('presence', true),
        postal_code: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
          allowString: true,
          integer: true
        })],
        'state.name': (0, _emberCpValidations.validator)('presence', true),
        city: (0, _emberCpValidations.validator)('presence', true),
        country: (0, _emberCpValidations.validator)('presence', true),
        phone: (0, _emberCpValidations.validator)('presence', true)
      });
      return Ember.Object.extend(Validations, {
        state: Ember.computed(function () {
          return {
            name: ''
          };
        }),
        couponCode: Ember.computed(function () {
          return '';
        }),
        showStatesAsDropdown: Ember.computed('country.code', function () {
          return Ember.get(this, 'country.code') in _states.default;
        }),
        countryChanged: Ember.observer('country.code', function () {
          Ember.set(this, 'state', {
            name: ''
          });
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }),

    didInsertElement() {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (!window.recurly) {
        // just for tests
        return;
      }

      recurly.configure(config.recurly);
      this.preFill();
    },

    actions: {
      /**
       * @method actions.onSubmit
       */
      onSubmit() {
        let _this = this;

        if (Ember.get(this, 'formData').validations.messages.length === 0) {
          Ember.set(this, 'submitInProgress', true);
          const formData = (0, _object.dummyCopy)(Ember.get(this, 'formData'));
          formData.country = formData.country.name;

          if (formData.state.name) {
            formData.state = formData.state.name;
          }

          formData.isTrial = this.isTrial; //formData.state = formData.state.name;

          Ember.get(this, 'onRegister')(formData).then(function () {
            Ember.set(_this, 'submitInProgress', false);
          });
        }
      },

      goToLogin() {
        this.session.invalidate();
      }

    },

    preFill() {
      let country = {
        name: this.userData.country
      };

      if (country.name) {
        for (let c of _countries.default) {
          if (c.name.toLowerCase() === country.name.toLowerCase()) {
            country.code = c.code;
            break;
          }
        }
      }

      if (country && country.name) {
        Ember.set(this, 'formData.country', country);
      }

      if (this.userData.email) {
        Ember.set(this, 'formData.username', this.userData.email);
      }

      if (this.userData.firstName) {
        Ember.set(this, 'formData.first_name', this.userData.firstName);
      }

      if (this.userData.lastName) {
        Ember.set(this, 'formData.last_name', this.userData.lastName);
      }
    }

  });

  _exports.default = _default;
});