define("campaign-warrior-app/torii-providers/hootsuite", ["exports", "torii/providers/oauth2-code"], function (_exports, _oauth2Code) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Hootsuite OAuth2 provider
   *
   * @class HubspotOAuth2
   * @namespace ToriiProviders
   * @extends Torii.Providers.OAuth2CodeProvider
   */
  var _default = _oauth2Code.default.extend({
    baseUrl: 'https://platform.hootsuite.com/oauth2/auth',
    name: 'hootsuite',
    scope: 'offline',
    responseType: 'code',
    responseParams: Ember.computed(function () {
      return ['code'];
    }),
    redirectUri: Ember.computed(function () {
      const {
        protocol,
        host
      } = window.location;
      return [protocol, '//', host, '/torii/redirect.html'].join('');
    }),
    open: function () {
      const clientId = '45a956d8-6f3b-41b0-b394-89b88de9b806';
      const url = `${this.baseUrl}?client_id=${clientId}&scope=${this.scope}&response_type=code&redirect_uri=${'https://app.campaignwarrior.com/torii/redirect.html'}`;
      return Ember.get(this, 'popup').open(url, ['code'], {});
    }
  });

  _exports.default = _default;
});