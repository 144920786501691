define("campaign-warrior-app/mixins/portal-related-manage-record-route", ["exports", "campaign-warrior-app/mixins/manage-record-route"], function (_exports, _manageRecordRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Add `portal` relation for model if it doesn't exist.
   *
   * Mixin must be used for Routes used to create/edit some resources related to `Portal`.
   *
   * Controllers for `edit` and `create` pages must extend
   * [EditPortalRelatedRecordController](Controllers.EditPortalRelatedRecord.html) or [CreatePortalRelatedRecordController](Controllers.CreatePortalRelatedRecord.html).
   *
   * @class PortalRelatedManageRecordRoute
   * @namespace Mixins
   * @uses Mixins.ManageRecordRoute
   */
  var _default = Ember.Mixin.create(_manageRecordRoute.default, {
    model() {
      const model = this._super(...arguments);

      if (!Ember.get(model, 'portal.id')) {
        Ember.set(model, 'portal', this.modelFor('portals.portal'));
      }

      return model;
    }

  });

  _exports.default = _default;
});