define("campaign-warrior-app/components/users/delete-user", ["exports", "campaign-warrior-app/components/cw-table/delete-record", "campaign-warrior-app/templates/components/users/delete-user"], function (_exports, _deleteRecord, _deleteUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class CancelPostJobButton
   * @namespace Components
   * @extends Components.CwTableDeleteRecord
   */
  var _default = _deleteRecord.default.extend({
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    layout: _deleteUser.default,
    isAdmin: Ember.computed(function () {
      //console.log('Is Admin record.id', get(this, 'record.id'));
      return Ember.get(this, 'record.id') === Ember.get(this, 'currentUser.user.id');
    })
  });

  _exports.default = _default;
});