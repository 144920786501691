define("campaign-warrior-app/templates/components/panel-loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mahxprfo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"panel-loading-indicator\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fad fa-spinner fa-spin fa-2x fa-fw\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/panel-loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});