define("campaign-warrior-app/components/pagination-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PaginationDisplay = (_dec = Ember.computed('meta["current-page"]'), _dec2 = Ember.computed('meta["page-count"]'), _dec3 = Ember.computed('meta["total-records"]'), _dec4 = Ember.computed('currentPage'), _dec5 = Ember.computed('lastPage', 'currentPage'), _dec6 = Ember.computed('lastPage', 'currentPage'), _dec7 = Ember.computed('currentPage'), _dec8 = Ember.computed('lastPage', 'currentPage'), _dec9 = Ember.computed('currentPage', 'totalRecords', 'size'), _dec10 = Ember.computed('recordSetStart', 'totalRecords', 'size'), _dec11 = Ember.computed('sortOrder'), _dec12 = Ember._action, (_class = (_temp = class PaginationDisplay extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "perPageOptions", [10, 20, 30, 50, 100]);
    } //sortOrder = null;


    get currentPage() {
      let currentPage = this.meta['current-page'];

      if (currentPage < 1) {
        currentPage = 1;
      }

      return currentPage;
    }

    get lastPage() {
      return this.meta['page-count'];
    }

    get totalRecords() {
      return this.meta['total-records'];
    }

    get pageSequence() {
      let pageCount = parseInt(this.meta['page-count']);
      let limit = 5;
      let remaining = 5;
      let sequence = [];
      let currentPage = this.currentPage;

      if (currentPage < 1) {
        currentPage = 1;
      }

      if (currentPage - 3 > 0) {
        sequence.push('first');
      }

      if (currentPage - 2 > 0) {
        sequence.push(currentPage - 2);
        remaining--;
      }

      if (currentPage - 1 > 0) {
        sequence.push(currentPage - 1);
        remaining--;
      }

      sequence.push(currentPage);
      remaining--;

      if (currentPage + 1 <= this.lastPage) {
        sequence.push(this.currentPage + 1);
      }

      if (currentPage + 2 <= this.lastPage) {
        sequence.push(currentPage + 2);
      }

      if (currentPage + 3 <= this.lastPage) {
        sequence.push('last');
      }

      return sequence;
    }

    get showBeforeDots() {
      return this.currentPage - 3 > 1;
    }

    get showAfterDots() {
      return this.currentPage + 3 < this.lastPage;
    }

    get showPreviousNav() {
      if (this.currentPage - 1 >= 1) {
        return this.currentPage - 1;
      }
    }

    get showNextNav() {
      if (this.currentPage + 1 <= this.lastPage) {
        return this.currentPage + 1;
      }
    }

    get recordSetStart() {
      let size = this.size;
      let startIndex = size * (this.currentPage - 1);
      return startIndex;
    }

    get recordSetEnd() {
      let size = parseInt(this.size);
      let endIndex = this.recordSetStart + size;

      if (endIndex > parseInt(this.meta['total-records'])) {
        endIndex = this.totalRecords;
      }

      this.meta['page-current-size'] = endIndex;
      return endIndex;
    }

    get sortOrderValue() {
      let value = this.sortOrder;

      switch (this.sortOrder) {
        case "-title":
          value = "Title";
          break;

        case "-metaSize":
          value = "Meta Size";
          break;

        case "-tags":
          value = "Tags";
          break;

        case "-url":
          value = "Url";
          break;

        case "-viewsCount":
          value = "Views";
          break;

        case "-source":
          value = "Source";
          break;

        case "-hsPageType":
          value = "Type";
          break;

        case "-overallRating":
          value = "Rating";
          break;

        case "-funnelStage":
          value = "Funnel";
          break;

        case "-hsLastUpdated":
          value = "Updated";
          break;

        case "title":
          value = "Title";
          break;

        case "metaSize":
          value = "Meta Size";
          break;

        case "tags":
          value = "Tags";
          break;

        case "url":
          value = "Url";
          break;

        case "viewsCount":
          value = "Views";
          break;

        case "source":
          value = "Source";
          break;

        case "hsPageType":
          value = "Type";
          break;

        case "overallRating":
          value = "Rating";
          break;

        case "funnelStage":
          value = "Funnel";
          break;

        case "hsLastUpdated":
          value = "Updated";
          break;

        default:
          value = this.sortOrder;
          break;
      }

      return value;
    }

    selectSize(selected) {
      this.size = selected;
      this.triggerUpdate();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "currentPage", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "currentPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lastPage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "lastPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalRecords", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "totalRecords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pageSequence", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "pageSequence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showBeforeDots", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "showBeforeDots"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showAfterDots", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "showAfterDots"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showPreviousNav", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "showPreviousNav"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showNextNav", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showNextNav"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "recordSetStart", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "recordSetStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "recordSetEnd", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "recordSetEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortOrderValue", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "sortOrderValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectSize", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectSize"), _class.prototype)), _class));
  _exports.default = PaginationDisplay;
});