define("campaign-warrior-app/templates/components/pages/tags-adder-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XxhAChCl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"heading-content\"],[12],[2,\"\\n\\n\\n  \"],[10,\"div\"],[14,0,\"apply-buttons flex-columns\"],[12],[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@disabled\",\"@type\",\"@onClick\"],[[34,0],\"cancel\",[30,[36,1],[[32,0],\"openUnClusterModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            Uncluster\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@disabled\",\"@class\",\"@onClick\"],[[34,0],\"btn btn-primary-outline\",[30,[36,1],[[32,0],\"openClusterModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n             Add to cluster\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"makeDisable\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/tags-adder-heading.hbs"
    }
  });

  _exports.default = _default;
});