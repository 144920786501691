define("campaign-warrior-app/templates/components/cw-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gSAoHt3k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"name\"],[12],[1,[35,1,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"remove-tag\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"selfRemove\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"far fa-times\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"tag\",\"removable\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-tag.hbs"
    }
  });

  _exports.default = _default;
});