define("campaign-warrior-app/macros/starts-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = startsWith;

  /**
   *
   * Computed Property
   *
   * Dynamic dependentKey may be set to it
   *
   * Usage:
   *
   * ```javascript
   * import EmberObject from '@ember/object';
   * import {inject as service} from '@ember/service';
   * import startsWith from '../macros/starts-with';
   * export default EmberObject.extend({
   *   router: service(),
   *   obj: null,
   *   isPopoverEnable: startsWith('router.currentRouteName', 'books.index')
   * });
   * ```
   *
   * In this case `isPopoverEnable` will be an boolean value that depends on dynamic currentRouteName changing
   *
   * @param {string} dependentKey dynamic key
   * @param {string} value value to check if dependentKey starts with
   * @returns {Ember.ComputedProperty} new computed property macro
   */
  function startsWith(dependentKey, value) {
    return Ember.computed(dependentKey, function () {
      const currentRoute = Ember.get(this, dependentKey);

      if (currentRoute) {
        return currentRoute.startsWith(value);
      }
    });
  }
});