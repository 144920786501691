define("campaign-warrior-app/components/tags/add-many-tags-form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{tags/add-many-tags-form tags=tags createTags=(action "createTags")}}
   * ```
   *
   * @class AddManyTagsForm
   * @extends Ember.Component
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    can: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    classNames: ['add-many-tags-form'],

    /**
     * @property tags
     * @type Models.Tag[]
     * @default null
     */
    tags: null,

    /**
     * @property existingTagNames
     * @type string[]
     * @default []
     * @private
     * @readonly
     */
    existingTagNames: Ember.computed.mapBy('tags', 'name').readOnly(),

    /**
     * @property formData
     * @type object
     * @private
     * @readonly
     */
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        tags: (0, _emberCpValidations.validator)('presence', true)
      });
      return Ember.Object.extend(Validations, {
        tags: ''
      }).create(Ember.getOwner(this).ownerInjection());
    }),
    actions: {
      /**
       * Show confirmation modal. It contains table with all Tags parsed from the textarea.
       * Not existing Tags are selected and existing are not. User may toggle selection for any Tag.
       *
       * @method actions.onSubmit
       * @returns {RSVP.Promise} confirmation result
       */
      onSubmit() {
        const can = Ember.get(this, 'can');

        if (!can.can('create tag')) {
          return Ember.get(this, 'modalsManager').alert({
            title: 'Limit Exceed',
            body: 'You have reached your free tags limit. Upgrade now for unlimited tags',
            confirm: 'Ok'
          });
        }

        const newTags = Ember.get(this, 'formData.tags').split('\n').uniq().filter(tag => !!tag).compact();
        const modalsManager = Ember.get(this, 'modalsManager');
        const i18n = Ember.get(this, 'i18n');

        if (!newTags.length) {
          return modalsManager.alert({
            title: i18n.t('modals.defaultAlertOptions.title'),
            body: i18n.t('pages.addList.noUrlsModal.body'),
            confirm: i18n.t('modals.defaultAlertOptions.confirm')
          });
        }

        return modalsManager.show('tags/add-many-tags-form/confirmation-modal', {
          newTags,
          existingTagNames: Ember.get(this, 'existingTagNames')
        }).then(urls => Ember.get(this, 'createTags')(urls));
      },

      uploadCSVFile(file) {
        Ember.set(this, 'formData.tags', file);
      }

    }
  });

  _exports.default = _default;
});