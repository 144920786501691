define("campaign-warrior-app/templates/profile/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "883i2Ap9",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"flex-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"info-page\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"header-profile\"],[12],[10,\"div\"],[14,0,\"account-info-main\"],[12],[2,\"Profile Information\"],[13],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"onclick\",[30,[36,0],[[32,0],\"returnHome\"],null]],[12],[2,\"Return Home\"],[13],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-7\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"record\",\"profilePic\",\"save\"],[[32,1],[32,1,[\"picture\",\"preview\"]],[30,[36,0],[[32,0],\"save\"],null]]]]],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"col-xs-5\"],[12],[2,\"\\n          \"],[1,[30,[36,1],null,[[\"save\"],[[30,[36,0],[[32,0],\"changePassword\"],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"manage-user-password\",\"manage-user-form\",\"showPasswordChangeForm\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/profile/info.hbs"
    }
  });

  _exports.default = _default;
});