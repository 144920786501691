define("campaign-warrior-app/components/create-tag-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for edit or create a Domain
   * @class createTagForPage
   * @namespace Components
   * @extends Components.createTagModal
   */
  var _default = _base.default.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property tagsToAddForPages
     * @type object
     */
    tagsToAddForPage: Ember.computed(function () {
      return {
        tags: []
      };
    }),
    actions: {
      addTagsForPage(modal) {
        const tags = Ember.get(this, 'tagsToAddForPage.tags');
        const page = Ember.get(this, 'options.page');

        if (Ember.isEmpty(tags) || Ember.isEmpty(page)) {
          return false;
        }

        let pageTags = Ember.get(page, 'tags');
        pageTags.pushObjects(tags);
        page.save();
        modal.close();
      },

      cancel(modal) {
        modal.close();
      }

    }
  });

  _exports.default = _default;
});