define("campaign-warrior-app/templates/components/pages/funnel-adder-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JEiqG7Da",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"heading-content\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    Funnel Stage\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/funnel-adder-heading.hbs"
    }
  });

  _exports.default = _default;
});