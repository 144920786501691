define("campaign-warrior-app/helpers/make-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeUrl = makeUrl;
  _exports.default = void 0;

  /**
   * Add protocol to the URL if it doesn't have it
   *
   * @namespace Helpers
   * @class makeUrl
   *
   * @param {*} url value to check
   * @returns {string} URL ready to be passed to the `href`-attr
   */
  function makeUrl([url]
  /*, hash*/
  ) {
    url = String(url);
    return url.indexOf('http') === 0 ? url : `http://${url}`;
  }

  var _default = Ember.Helper.helper(makeUrl);

  _exports.default = _default;
});