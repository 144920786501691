define("campaign-warrior-app/components/domains/pages-limit-upgrade-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesLimitUpgradeModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('currentPortal.portal.pagesUsed'), _dec6 = Ember.computed('currentPortal.portal.pagesLimit'), _dec7 = Ember.computed('currentPortal.portal.potentialPages'), _dec8 = Ember.computed('currentPortal.portal.impressionsLimit'), _dec9 = Ember.computed('currentPortal.portal.planName'), _dec10 = Ember._action, (_class = (_temp = class PagesLimitUpgradeModal extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentPortal", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "can", _descriptor3, this);

      _initializerDefineProperty(this, "ajax", _descriptor4, this);
    }

    get usedPages() {
      return this.currentPortal.portal.pagesUsed;
    }

    get pagesLimit() {
      return this.currentPortal.portal.pagesLimit;
    }

    get potentialPages() {
      return this.currentPortal.portal.potentialPages;
    }

    get impressionsLimit() {
      if (!this.currentPortal.portal.impressionsLimit) {
        this.currentPortal.portal.impressionsLimit = 1000;
      }

      return this.currentPortal.portal.impressionsLimit;
    }

    get portalPlan() {
      if (this.currentPortal.portal.planName) {
        return this.currentPortal.portal.planName;
      }

      if (this.can.can('is trial portal')) {
        return 'Trial';
      }

      return 'Professional';
    }

    confirm(modal) {
      const _this = this;

      modal.close();
      this.ajax.post('/billing/upgradetrial', {
        data: {
          portalId: _this.currentPortal.portal.id
        }
      }).then(() => {
        this.router.transitionTo('portals.portal.domains.add-impressions-slider');
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "can", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "usedPages", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "usedPages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pagesLimit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "pagesLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "potentialPages", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "potentialPages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "impressionsLimit", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "impressionsLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "portalPlan", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "portalPlan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirm", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "confirm"), _class.prototype)), _class));
  _exports.default = PagesLimitUpgradeModal;
  ;
});