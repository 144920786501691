define("campaign-warrior-app/components/modals/request-user-error-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class RequestErrorHeader
   * @extends Ember.Component
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    /**
     * @property error
     * @type object
     * @default null
     * @private
     */
    error: Ember.computed.alias('options.error'),

    /**
     * @property title
     * @type string
     * @readonly
     * @private
     */
    title: Ember.computed('error.{message,status,name}', function () {
      /*const status = get(this, 'error.status');
      const message = get(this, 'error.message');
      const name = get(this, 'error.name');
      return status || name ? `${status} ${name}` : message;*/
      return 'Error';
    }).readOnly()
  });

  _exports.default = _default;
});