define("campaign-warrior-app/components/billing/upgrade-account-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UpgradeAccountModal extends _base.default {}

  _exports.default = UpgradeAccountModal;
  ;
});