define("campaign-warrior-app/templates/components/pages/meta-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jTt4vLXC",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],null,[[\"class\",\"url\"],[\"meta-picture\",[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fad fa-spinner fa-spin meta-loading-spinner\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"img\"],[14,0,\"meta-picture meta-picture-missing\"],[14,\"src\",\"/meta-pic-missing.png\"],[14,\"alt\",\"Meta Picture Missing\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\",\"lazy-image\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/meta-picture.hbs"
    }
  });

  _exports.default = _default;
});