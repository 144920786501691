define("campaign-warrior-app/templates/components/source-type-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ChHRcttf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"i\"],[15,0,[31,[\"fab \",[34,1],\" \",[34,0]]]],[12],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"extraClasses\",\"icon\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/source-type-icon.hbs"
    }
  });

  _exports.default = _default;
});