define("campaign-warrior-app/abilities/user", ["exports", "campaign-warrior-app/abilities/cw-ability", "campaign-warrior-app/utils/ability"], function (_exports, _cwAbility, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class User
   * @namespace Abilities
   * @extends Abilities.CwAbility
   */
  var _default = _cwAbility.default.extend({
    /**
     * @property canCreate
     * @type boolean
     * @default false
     */
    canCreate: (0, _ability.ability)('invite_user')
  });

  _exports.default = _default;
});