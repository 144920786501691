define("campaign-warrior-app/templates/portals/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LjRIR8zS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container-internal-wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"internal-content\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"setup-state\"],[12],[2,\"\\n\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"page-content setup-content\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/create.hbs"
    }
  });

  _exports.default = _default;
});