define("campaign-warrior-app/components/pages/page-action-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PageActionMenu = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service(), _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = (_temp = class PageActionMenu extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "currentPortal", _descriptor3, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor4, this);

      _initializerDefineProperty(this, "i18n", _descriptor5, this);

      _defineProperty(this, "record", null);
    }

    addToFavourite(page) {
      const favorite = page.favorite;

      if (favorite && favorite.isLoaded) {
        // for some unknown reasons `favorite` is proxy-object for record and not record itself
        return Ember.typeOf(favorite.destroyRecord) === 'function' ? favorite.destroyRecord : favorite.content.destroyRecord();
      }

      return this.store.createRecord('favorite', {
        originalType: 'pages',
        originalId: page.id,
        page
      }).save();
    }

    hidePage(page) {
      page.hidden = !page.hidden;
      page.save();
    }

    hideFromRelatedContent(page) {
      page.hiddenFromRelated = !page.hiddenFromRelated;
      page.save();
    }

    deletePage(item) {
      const i18n = this.i18n;
      return this.modalsManager.confirm({
        title: 'Delete this page?',
        body: 'This page will be permanently removed from Campaign Warrior',
        confirm: i18n.t('modals.defaultConfirmOptions.confirm')
      }).then(() => item.destroyRecord()).catch(e => this.modalsManager.detailedAjaxErrorHandler(e));
    }

    editPage(item) {
      let args = ['portals.portal.pages.page.edit'];
      args.push(item.id);
      this.router.transitionTo.apply(this.router, args);
    }

    copyUrl(page) {
      const link = page.url;
      const httpIncluded = link.includes('http://') || link.includes('https://');
      const el = document.createElement('textarea');

      if (httpIncluded) {
        el.value = '<a href="' + link + '"></a><br/>';
      } else {
        el.value = 'https://' + link;
      }

      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }

    copyLink(page) {
      let url = page.url;
      const title = page.title;
      const el = document.createElement('textarea');

      if (!url.includes('https://') && !url.includes('http://') && !url.includes('//')) {
        url = 'https://' + url;
      }

      el.value = `<a href="${url}">${title}</a><br/>`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addToFavourite", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "addToFavourite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hidePage", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "hidePage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideFromRelatedContent", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "hideFromRelatedContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deletePage", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "deletePage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editPage", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "editPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyUrl", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "copyUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyLink", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "copyLink"), _class.prototype)), _class));
  _exports.default = PageActionMenu;
});