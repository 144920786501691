define("campaign-warrior-app/torii-providers/linkedin", ["exports", "torii/providers/oauth2-code"], function (_exports, _oauth2Code) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Linkedin OAuth2 provider
   *
   * @class LinkedinOAuth2
   * @namespace ToriiProviders
   * @extends Torii.Providers.OAuth2CodeProvider
   */
  var _default = _oauth2Code.default.extend({
    baseUrl: 'https://www.linkedin.com/uas/oauth2/authorization',
    name: 'linkedin',
    responseType: 'code',
    responseParams: Ember.computed(function () {
      return ['code'];
    }),
    redirectUri: Ember.computed(function () {
      const {
        protocol,
        host
      } = window.location;
      return [protocol, '//', host, '/torii/redirect.html'].join('');
    }),

    fetch(data) {
      return data;
    }

  });

  _exports.default = _default;
});