define("campaign-warrior-app/routes/settings/add-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class InviteUser
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),

    beforeModel() {
      if (Ember.get(this, 'can').cannot('create user')) {
        return Ember.get(this, 'router').transitionTo('portals.portal');
      }
    },

    model() {
      return Ember.get(this, 'store').findAll('portal');
    }

  });

  _exports.default = _default;
});