define("campaign-warrior-app/abilities/cw-ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class CwAbility
   * @namespace Abilities
   * @extends EmberCan.Ability
   */
  var _default = _emberCan.Ability.extend({
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),

    /**
     * @property user
     * @type Models.User
     */
    user: Ember.computed.reads('currentUser.user'),

    /**
     * @property portal
     * @type Models.Portal
     */
    portal: Ember.computed.reads('currentPortal.portal')
  });

  _exports.default = _default;
});