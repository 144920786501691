define("campaign-warrior-app/initializers/root-element-classes", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * @param {Ember.Route} self route to update
   * @param {string} method 'addClass' or 'removeClass'
   */
  function handleClasses(self, method) {
    const classes = Ember.get(self, 'rootElementClasses') || [];
    const config = Ember.getOwner(self).resolveRegistration('config:environment');
    const rootElement = config.APP.rootElement || 'body';

    if (config.environment === 'test') {
      return;
    }

    classes.forEach(cls => (0, _jquery.default)(rootElement)[method](cls));
  }
  /**
   * Reopen `Ember.Route` to handle body css-classes for custom pages
   */


  function initialize() {
    Ember.Route.reopen({
      rootElementClasses: Ember.computed(function () {
        return [];
      }),
      addClasses: Ember.on('activate', function () {
        handleClasses(this, 'addClass');
      }),
      removeClasses: Ember.on('deactivate', function () {
        handleClasses(this, 'removeClass');
      })
    });
  }

  var _default = {
    name: 'root-element-classes',
    initialize
  };
  _exports.default = _default;
});