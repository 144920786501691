define("campaign-warrior-app/templates/components/pages/page-action-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cyxWq+fo",
    "block": "{\"symbols\":[\"dd\",\"ddm\"],\"statements\":[[8,\"bs-dropdown\",[[24,0,\"page-action-menu\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"toggle\"]],[[24,0,\"action-menu-toggle\"]],[[],[]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fal fa-ellipsis-v\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"menu\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[3,\"      <a {{action \\\"addToFavourite\\\" record}}><i class=\\\"fal fa-star\\\"></i><span>Favourite</span></a>\"],[2,\"\\n\"],[3,\"      <a {{action \\\"hidePage\\\" record}}><i class=\\\"fal fa-eye-slash\\\"></i><span>Hide</span></a>\"],[2,\"\\n      \"],[11,\"a\"],[4,[38,1],[[32,0],\"editPage\",[35,0]],null],[12],[10,\"i\"],[14,0,\"fal fa-pencil\"],[12],[13],[10,\"span\"],[12],[2,\"Edit Page\"],[13],[13],[2,\"\\n      \"],[11,\"a\"],[4,[38,1],[[32,0],\"hideFromRelatedContent\",[35,0]],null],[12],[10,\"i\"],[14,0,\"fal fa-eye-slash\"],[12],[13],[10,\"span\"],[12],[2,\"Hide from Related Content\"],[13],[13],[2,\"\\n      \"],[11,\"a\"],[4,[38,1],[[32,0],\"deletePage\",[35,0]],null],[12],[10,\"i\"],[14,0,\"fal fa-trash\"],[12],[13],[10,\"span\"],[12],[2,\"Delete\"],[13],[13],[2,\"\\n      \"],[11,\"a\"],[4,[38,1],[[32,0],\"copyUrl\",[35,0]],null],[12],[10,\"i\"],[14,0,\"fal fa-globe\"],[12],[13],[10,\"span\"],[12],[2,\"Copy Url\"],[13],[13],[2,\"\\n      \"],[11,\"a\"],[4,[38,1],[[32,0],\"copyLink\",[35,0]],null],[12],[10,\"i\"],[14,0,\"fal fa-link\"],[12],[13],[10,\"span\"],[12],[2,\"Copy Link\"],[13],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"record\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/page-action-menu.hbs"
    }
  });

  _exports.default = _default;
});