define("campaign-warrior-app/components/users/resend-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    isAdmin: Ember.computed(function () {
      return Ember.get(this, 'record.id') === Ember.get(this, 'currentPortal.portal.createdby');
    }),
    canResend: Ember.computed(function () {
      return Ember.get(this, 'record.status') == 'pending' || Ember.get(this, 'record.status') == 'rejected';
    }),
    actions: {
      resendInvite() {
        let modalsManager = Ember.get(this, 'modalsManager');
        const model = Ember.get(this, 'record');
        const data = {
          email: Ember.get(model, 'email'),
          portal: Ember.get(this, 'currentPortal.portal.id'),
          invitedBy: Ember.get(this, 'currentUser.user.id'),
          role: Ember.get(model, 'role')
        };
        const i18n = Ember.get(this, 'i18n'); // return get(this, 'ajax')
        //   .post('/inviteuser', {data})
        //   .then(r => modalsManager.alert({
        //     title: i18n.t('modals.defaultAlertOptions.title'),
        //     confirm: i18n.t('modals.defaultAlertOptions.confirm'),
        //     body: i18n.t('settings.addUser.inviteSentModal.body', {email: r.email})
        //   }).then(() => console.log('Mail sent')))
        //   .catch(e => modalsManager.detailedAjaxErrorHandler(e));

        return modalsManager.process({
          body: '',
          iconClass: 'text-center fal fa-spinner fa-spin fa-3x fa-fw',
          title: 'Sending email, please wait',
          process: () => new Promise(resolve => resolve(Ember.get(this, 'ajax').post('/inviteuser', {
            data
          }))) // .then(response => get(this, 'notify').success(response.message))

          /*.catch(err => {
                           console.error("Ajax Error", err);
                           get(this, 'modalsManager').userFriendlyErrorHandler(err);
                          })*/

        }).then(r => modalsManager.alert({
          title: "Your invite has been sent",
          confirm: i18n.t('modals.defaultAlertOptions.confirm'),
          body: i18n.t('settings.addUser.inviteSentModal.body', {
            email: r.email
          })
        }).then(() => {
          return true;
        })).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      }

    }
  });

  _exports.default = _default;
});