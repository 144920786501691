define("campaign-warrior-app/data/states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Each key is a country-code from `countries.js`
   * Each value is an array of states for country
   *
   * @type object
   */
  var _default = {
    us: [{
      name: 'Alabama'
    }, {
      name: 'Alaska'
    }, {
      name: 'Arizona'
    }, {
      name: 'Arkansas'
    }, {
      name: 'California'
    }, {
      name: 'Colorado'
    }, {
      name: 'Connecticut'
    }, {
      name: 'Delaware'
    }, {
      name: 'District of Columbia'
    }, {
      name: 'Florida'
    }, {
      name: 'Georgia'
    }, {
      name: 'Hawaii'
    }, {
      name: 'Idaho'
    }, {
      name: 'Illinois'
    }, {
      name: 'Indiana'
    }, {
      name: 'Iowa'
    }, {
      name: 'Kansas'
    }, {
      name: 'Kentucky'
    }, {
      name: 'Louisiana'
    }, {
      name: 'Maine'
    }, {
      name: 'Maryland'
    }, {
      name: 'Massachusetts'
    }, {
      name: 'Michigan'
    }, {
      name: 'Minnesota'
    }, {
      name: 'Mississippi'
    }, {
      name: 'Missouri'
    }, {
      name: 'Montana'
    }, {
      name: 'Nebraska'
    }, {
      name: 'Nevada'
    }, {
      name: 'New Hampshire'
    }, {
      name: 'New Jersey'
    }, {
      name: 'New Mexico'
    }, {
      name: 'New York'
    }, {
      name: 'North Carolina'
    }, {
      name: 'North Dakota'
    }, {
      name: 'Ohio'
    }, {
      name: 'Oklahoma'
    }, {
      name: 'Oregon'
    }, {
      name: 'Pennsylvania'
    }, {
      name: 'Rhode Island'
    }, {
      name: 'South Carolina'
    }, {
      name: 'South Dakota'
    }, {
      name: 'Tennessee'
    }, {
      name: 'Texas'
    }, {
      name: 'Utah'
    }, {
      name: 'Vermont'
    }, {
      name: 'Virginia'
    }, {
      name: 'Washington'
    }, {
      name: 'West Virginia'
    }, {
      name: 'Wisconsin'
    }, {
      name: 'Wyoming'
    }],
    au: [{
      name: 'Australian Capital Territory'
    }, {
      name: 'New South Wales'
    }, {
      name: 'Victoria'
    }, {
      name: 'Queensland'
    }, {
      name: 'South Australia'
    }, {
      name: 'Western Australia'
    }, {
      name: 'Tasmania'
    }, {
      name: 'Northern Territory'
    }]
  };
  _exports.default = _default;
});