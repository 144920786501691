define("campaign-warrior-app/templates/components/cw-table/expand-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NqDgMl3t",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[34,0,[\"collapseRow\"]]],[4,[38,3],[[32,0],\"collapseRow\",[35,2],[35,1]],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[[34,0,[\"collapse-row\"]]]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[34,0,[\"expandRow\"]]],[4,[38,3],[[32,0],\"expandRow\",[35,2],[35,1]],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[[34,0,[\"expand-row\"]]]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"record\",\"index\",\"action\",\"isExpanded\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/expand-toggle.hbs"
    }
  });

  _exports.default = _default;
});