define("campaign-warrior-app/routes/404", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    telemetryService: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),
    path: null,

    async beforeModel(transition) {
      return this._super(...arguments);
    },

    model() {
      return Ember.get(this, 'store').findAll('portal');
    },

    afterModel(model) {
      Ember.get(this, 'telemetryService').pageNotFound();
    }

  });

  _exports.default = _default;
});