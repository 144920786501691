define("campaign-warrior-app/templates/components/domains/refresh-domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9GFCY/ts",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"btn\"],[24,0,\"btn btn-primary btn-xs\"],[4,[38,1],[[32,0],\"refresh\",[35,0]],null],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"far fa-fw fa-sync\"],[12],[13],[2,\" Refresh\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"record\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/domains/refresh-domain.hbs"
    }
  });

  _exports.default = _default;
});