define("campaign-warrior-app/templates/components/users/users-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WkdO9sdQ",
    "block": "{\"symbols\":[\"user\",\"column\",\"column\"],\"statements\":[[10,\"table\"],[14,0,\"cw-table\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"columns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"th\"],[15,0,[31,[[32,3,[\"className\"]]]]],[12],[2,\"\\n        \"],[8,\"column-sort-header\",[],[[\"@size\",\"@route\",\"@sortOrder\",\"@column\",\"@sortBy\",\"@meta\"],[[32,0,[\"size\"]],\"portals.portal.users.list\",[34,6],[32,3],[34,7],[32,0,[\"meta\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"columns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"td\"],[15,0,[31,[[32,2,[\"className\"]],\" \"]]],[12],[2,\"\\n\"],[6,[37,3],[[32,2,[\"component\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"              \"],[1,[30,[36,2],[[32,2,[\"component\"]]],[[\"record\",\"column\",\"refreshList\"],[[32,1],[32,2],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"              \"],[1,[30,[36,0],[[32,1],[32,2,[\"propertyName\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"refreshList\",\"component\",\"if\",\"-track-array\",\"each\",\"sortOrder\",\"sortBy\",\"users\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/users/users-list.hbs"
    }
  });

  _exports.default = _default;
});