define("campaign-warrior-app/components/pages/pages-tags-filter", ["exports", "jquery", "campaign-warrior-app/models/tag"], function (_exports, _jquery, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{pages/search-pages-form
   *   tags=tags
  
   *   metricNameToFilter=metricNameToFilter
   *   filterByMetric=(action "filterByMetric")
   *   updateFilters=(action "updateFilters")
   * }}
   * ```
   *
   * @class SearchPagesForm
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    classNames: ['pages-tags-filter-form'],

    /**
     * @property tags
     * @type Models.Tag[]
     * @default null
     */
    tags: null,

    /**
     * @property metricNameToFilter
     * @type string
     * @default null
     */
    metricNameToFilter: null,

    /**
     * @event filterByMetric
     */
    filterByMetric: null,

    /**
     * @event updateFilters
     */
    updateFilters: null,

    /**
     * @property portal
     * @type Models.Portal
     * @default null
     */
    portal: null,
    applyTagsSelection: null,
    didInsertElement: Ember.computed('registerComponent', function () {
      this.registerComponent(this);
    }),
    displayTags: Ember.computed('tags', function () {
      return this.tags;
    }),

    willDestroyElement() {
      // this._super(...argument);
      this.deregisterComponent();
    },

    actions: {
      /**
       * @method actions.onSubmit
       */
      onSubmit() {
        this.triggerUpdate();
      },

      submitForm() {
        const filterForm = (0, _jquery.default)('form');
        (0, _jquery.default)(filterForm).submit();
      },

      createTag(tagName) {
        const portal = this.currentPortal.portal;
        const store = this.store;

        const _this = this;

        const newTag = store.createRecord('tag', {
          name: tagName,
          color: _tag.default.getRandColor(),
          portal
        });
        newTag.save().then(savedTag => {
          _this.tags.addObject(savedTag);

          Ember.set(_this, 'applyTagsSelection', savedTag);
        });
      },

      /**
       * @method actions.resetForm
       * @param {object} ref to reset form
       */
      resetForm(ref) {
        Ember.setProperties(ref.formData, {
          tagsList: [],
          omitTags: [],
          setAllTags: false,
          omitAllTags: false
        }); // this.send('onSubmit(false)');

        const filters = {
          tagsList: [],
          omitTags: [],
          setAllTags: false,
          omitAllTags: false
        };
        Ember.get(ref, 'updateFilters')(filters);
      },

      /**
       * @method addTagsForFilteredPages
       */
      addTagsForFilteredPages() {
        Ember.get(this, 'addTagsForFilteredPages')();
      },

      setFilter(filterName) {
        const existingFilters = JSON.parse(window.localStorage.getItem('filters'));
        const portalId = Ember.get(Ember.get(this, 'portal'), 'id');

        if (existingFilters) {
          const storedPortalId = Ember.get(existingFilters, 'id');

          if (storedPortalId === '') {
            const dataToSend = Ember.getProperties(Ember.get(this, 'formData'), [filterName]);
            Ember.set(existingFilters, 'id', Ember.get(Ember.get(this, 'portal'), 'id'));
            existingFilters[filterName] = dataToSend[filterName];
            Ember.get(this, 'updateFilters')(existingFilters);
          } else if (portalId && storedPortalId && portalId === storedPortalId) {
            const dataToSend = Ember.getProperties(Ember.get(this, 'formData'), [filterName]);
            existingFilters[filterName] = dataToSend[filterName];
            Ember.get(this, 'updateFilters')(existingFilters);
          } else {
            const dataToSend = Ember.getProperties(Ember.get(this, 'formData'), [filterName]);
            Ember.set(dataToSend, 'id', Ember.get(Ember.get(this, 'portal'), 'id'));
            existingFilters[filterName] = dataToSend[filterName];
            Ember.get(this, 'updateFilters')(existingFilters);
          }
        } else {
          const filters = {
            tagsList: [],
            omitTags: [],
            setAllTags: false,
            omitAllTags: false
          };
          const dataToSend = Ember.getProperties(Ember.get(this, 'formData'), [filterName]);
          Ember.set(dataToSend, 'id', Ember.get(Ember.get(this, 'portal'), 'id'));
          filters[filterName] = dataToSend[filterName];
          Ember.get(this, 'updateFilters')(filters);
        }
      },

      removeFilter(filterName) {
        const existingFilters = JSON.parse(window.localStorage.getItem('filters'));

        if (existingFilters && existingFilters[filterName]) {
          if (Ember.isArray(existingFilters[filterName])) {
            if (filterName === 'tags') {
              Ember.setProperties(Ember.get(this, 'formData'), {
                setAllTags: false
              });
            }

            if (filterName === 'omitTags') {
              Ember.setProperties(Ember.get(this, 'formData'), {
                omitAllTags: false
              });
            }

            existingFilters[filterName] = [];
            let form = {};
            form[filterName] = [];
            Ember.setProperties(Ember.get(this, 'formData'), form);
          } else {
            existingFilters[filterName] = '';
            let form = {};
            form[filterName] = '';
            Ember.setProperties(Ember.get(this, 'formData'), form);
          }
        }

        Ember.get(this, 'updateFilters')(existingFilters);
      },

      /**
       * @method toggleAllTags
       */
      toggleAllTags() {
        const allTags = Ember.getProperties(Ember.get(this, 'formData'), ['setAllTags']);

        if (allTags && allTags.setAllTags) {
          Ember.setProperties(Ember.get(this, 'formData'), {
            setAllTags: false,
            tagsList: []
          });
        } else {
          Ember.setProperties(Ember.get(this, 'formData'), {
            setAllTags: true,
            tagsList: Ember.get(this, 'tagsList')
          });
        }
      },

      /**
       * @method toggleOmitTags
       */
      toggleOmitTags() {
        const omitTags = Ember.getProperties(Ember.get(this, 'formData'), ['omitAllTags']); //console.log('omittags: ', omitTags);

        if (omitTags && omitTags.omitAllTags) {
          Ember.setProperties(Ember.get(this, 'formData'), {
            omitAllTags: false,
            omitTags: []
          });
        } else {
          Ember.setProperties(Ember.get(this, 'formData'), {
            omitAllTags: true,
            omitTags: Ember.get(this, 'tags')
          });
        }
      }

    }
  });

  _exports.default = _default;
});