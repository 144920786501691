define("campaign-warrior-app/serializers/post-job", ["exports", "campaign-warrior-app/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PostJob
   * @namespace Serializers
   * @extends Serializers.Application
   */
  var _default = _application.default.extend({
    /**
     * Delete Post-Job requests returns something like `{message: 'Job is canceled'}`.
     * Ember-data requires more canonical response
     *
     * @returns {{meta: {}}} normalized json
     */
    normalizeDeleteRecordResponse() {
      return {
        meta: {}
      };
    },

    /**
     * Extract "schedule-jobs" to the "post-job-instances"
     *
     * @method normalizeQueryResponse
     * @param {DS.Store} store store itself
     * @param {DS.Model} primaryModelClass 'post-job'
     * @param {object} payload server response
     * @param {string} id null
     * @param {string} requestType 'queryRecord'
     * @returns {object} normalized result
     */
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      let included = [];
      const newPayload = {};
      newPayload.data = payload.data.map(postJob => {
        const instances = postJob.attributes['schedule-jobs'].map(instance => {
          const ret = {
            id: instance._id,
            type: 'post-job-instance',
            attributes: instance,
            relationships: {
              'post-job': {
                data: {
                  id: postJob.id,
                  type: 'post-job'
                }
              }
            }
          };
          ret.attributes['agenda-job-id'] = ret.attributes['agendaJobId'];
          delete ret.attributes['agendaJobId'];
          return ret;
        });
        postJob.relationships = postJob.relationships || {};
        postJob.relationships['post-job-instances'] = {
          data: instances.map(instance => ({
            id: instance.id,
            type: 'post-job-instances'
          }))
        };
        included.pushObjects(instances);
        return postJob;
      });
      newPayload.included = included;
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }

  });

  _exports.default = _default;
});