define("campaign-warrior-app/router", ["exports", "campaign-warrior-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    currentPortal: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    init() {
      this._super(...arguments); //todo - maybe can insert hubspot analytics here


      const _this = this;

      this.on('didTransition', () => {
        // console.log("Current Route", _this.currentRouteName);
        if (!_this.currentRouteName.includes('portals.portal')) {
          //console.log("Not on portal route, reset current portal");
          Ember.set(this, 'currentPortal.portal', null);
        }
      });
    },

    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login'); //this.route('trial-register');

    this.route('internal-register');
    this.route('register'); //this.route('suspended');

    this.route('expired');
    this.route('support'); //this.route('trial-expired');

    this.route('verification', function () {
      this.route('verify');
      this.route('required');
      this.route('resend');
    });
    this.route('mobile-error');
    this.route('portals', function () {
      this.route('portal', {
        path: '/:portal_id'
      }, function () {
        this.route('setup', function () {
          this.route('setup');
          this.route('hubspot');
          this.route('google');
          this.route('shopify');
          this.route('website');
          this.route('tracking-code');
          this.route('complete');
          this.route('related-content-settings');
        });
        this.route('pages', function () {
          this.route('page', {
            path: '/:page_id'
          }, function () {
            this.route('edit');
          });
          this.route('list');
        });
        this.route('domains', function () {
          this.route('list');
          this.route('add-impressions');
          this.route('add-impressions-slider');
        });
        this.route('tags');
        /*this.route('accounts', function () {
        });*/

        this.route('users', function () {
          this.route('list');
          this.route('invite');
          this.route('user', {
            path: '/:user_id'
          }, function () {
            this.route('manage');
          });
        });
      });
      this.route('create', function () {
        this.route('step1'); //this.route('step2');
        //this.route('step3');
      });
    });
    this.route('settings', function () {
      this.route('users', function () {
        this.route('user', {
          path: '/:user_id'
        });
      });
      this.route('add-user');
    });
    this.route('profile', function () {
      this.route('info');
    });
    this.route('reset', function () {
      this.route('pass');
      this.route('verify');
    });
    this.route('invite', function () {
      this.route('confirmation');
      this.route('confirminvite');
    }); //this.route('billing');

    this.route('account');
    this.route('complete-registration');
    this.route('404', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});