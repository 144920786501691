define("campaign-warrior-app/mixins/adapter-portal-related", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Overrides some methods for Adapters used for Models related to the Portal.
   * All their requests are done over the `/portal/:id`-resource
   *
   * @namespace Mixins
   * @class AdapterPortalRelated
   */
  var _default = Ember.Mixin.create({
    coalesceFindRequests: true,

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),

    /**
     * @method urlForQuery
     * @param {object} query query parameters hash
     * @param {string} modelName child model name
     * @return {string} url
     * @override
     */
    urlForQuery(query, modelName) {
      const portalId = query.portal_id || Ember.get(this, 'currentPortal.portal.id');
      const host = this.host || '';
      const namespace = this.namespace || '';
      const pathChild = this.pathForType(modelName);
      const pathParent = this.pathForType('portal');

      if ('page' in query) {
        if ('page.number' in query) {
          query.page.number = query['page.number'];
          delete query['page.number'];
        }

        if ('page.size' in query) {
          query.page.size = query['page.size'];
          delete query['page.size'];
        }
      } //console.log(`Portal related records query ${modelName}`, query);


      delete query.portal_id;
      return `${host}/${namespace}/${pathParent}/${portalId}/${pathChild}`;
    },

    /**
     * @method urlForFindMany
     * @param {string[]} ids list of ids for needed models
     * @param {string} modelName child model name
     * @returns {string} generated url
     */
    urlForFindMany(ids, modelName) {
      const portalId = Ember.get(this, 'currentPortal.portal.id');
      const host = this.host || '';
      const namespace = this.namespace || '';
      const pathChild = this.pathForType(modelName);
      const pathParent = this.pathForType('portal');
      return `${host}/${namespace}/${pathParent}/${portalId}/${pathChild}`;
    }

  });

  _exports.default = _default;
});