define("campaign-warrior-app/components/users/users-action-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UsersActionMenu = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service(), _dec5 = Ember.inject.service(), _dec6 = Ember.inject.service(), _dec7 = Ember.inject.service(), _dec8 = Ember.computed('record.status'), _dec9 = Ember.computed('record.id'), _dec10 = Ember.computed('record.id', 'currentPortal.portal.createdby'), _dec11 = Ember.computed('record.id', 'currentUser.user.id'), _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class UsersActionMenu extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "currentPortal", _descriptor3, this);

      _initializerDefineProperty(this, "currentUser", _descriptor4, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor5, this);

      _initializerDefineProperty(this, "i18n", _descriptor6, this);

      _initializerDefineProperty(this, "ajax", _descriptor7, this);

      _defineProperty(this, "record", null);
    }

    get isActive() {
      return this.record.status === 'Active';
    }

    get canDelete() {
      if (this.record.id !== Ember.get(this, 'currentUser.user.id') && !this.isCreator) {
        return true;
      }
    }

    get isCreator() {
      return this.record.id === Ember.get(this, 'currentPortal.portal.createdby');
    }

    get isSelf() {
      return this.record.id === Ember.get(this, 'currentUser.user.id');
    }

    resendInvite() {
      const modalsManager = this.modalsManager;
      const i18n = this.i18n;
      const user = this.record;
      const data = {
        email: Ember.get(user, 'email'),
        portal: Ember.get(this, 'currentPortal.portal.id'),
        invitedBy: Ember.get(this, 'currentUser.user.id'),
        role: Ember.get(user, 'role')
      };
      return modalsManager.process({
        body: '',
        iconClass: 'text-center fal fa-spinner fa-spin fa-3x fa-fw',
        title: 'Sending email, please wait',
        process: () => new Promise(resolve => resolve(Ember.get(this, 'ajax').post('/inviteuser', {
          data
        }))) // .then(response => get(this, 'notify').success(response.message))

        /*.catch(err => {
                         console.error("Ajax Error", err);
                         get(this, 'modalsManager').userFriendlyErrorHandler(err);
                        })*/

      }).then(r => modalsManager.alert({
        title: "Your invite has been sent",
        confirm: i18n.t('modals.defaultAlertOptions.confirm'),
        body: i18n.t('settings.addUser.inviteSentModal.body', {
          email: r.email
        })
      }).then(() => {
        return true;
      })).catch(e => modalsManager.detailedAjaxErrorHandler(e));
    }
    /*
    @action
    logoutUser() {
      const i18n = this.i18n;
      const user = this.record;
      const modalsManager = this.modalsManager;
      return this.modalsManager
        .confirm({
          title: i18n.t('modals.defaultConfirmOptions.title'),
          body: i18n.t('modals.defaultConfirmOptions.body'),
          confirm: i18n.t('modals.defaultConfirmOptions.confirm')
        })
        .then(() => {
            const data = {
              userId: get(this, 'currentUser.user.id'),
              userToRemove: user.id,
              portalId: get(this, 'currentPortal.portal.id'),
              email: user.email,
            };
            //console.log('User to delete: ', user);
            return get(this, 'ajax')
              .post('/users/logout', {data})
              .then(r => {
                if (r.status === 'success') {
                  modalsManager.alert({
                    title: 'Logged Out',
                    confirm: 'Close',
                    body: r.message
                  }).then(() => get(this, 'refreshList')())
                }
              })
              .catch(e => {
                const data = {
                  title: 'Error',
                  body: 'You do not have permission to log out this user'
                };
                return modalsManager.errorHandler(data);
              });
          }
        )
          .catch(e => this.modalsManager.detailedAjaxErrorHandler(e));
      }
    */


    removeUser() {
      const i18n = this.i18n;
      const modalsManager = this.modalsManager;
      const user = this.record;
      return this.modalsManager.confirm({
        title: i18n.t('modals.defaultConfirmOptions.title'),
        body: i18n.t('modals.defaultConfirmOptions.body'),
        confirm: i18n.t('modals.defaultConfirmOptions.confirm')
      }).then(() => {
        const data = {
          userId: Ember.get(this, 'currentUser.user.id'),
          userToRemove: user.id,
          portalId: Ember.get(this, 'currentPortal.portal.id'),
          email: user.email
        };
        return Ember.get(this, 'ajax').post('/removeInvitedUser', {
          data
        }).then(r => {
          if (r.status === 'success') {
            modalsManager.alert({
              title: 'Removed',
              confirm: 'Close',
              body: r.message
            }).then(res => Ember.get(this, 'refreshList')());
          }
        }).catch(e => {
          console.error("Error removing user", e);
          const data = {
            title: 'Error',
            body: 'You do not have permission to delete this user'
          };
          return modalsManager.errorHandler(data);
        });
      }).catch(e => {
        const data = {
          title: 'Error',
          body: e.message
        };
        return modalsManager.errorHandler(data);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "isActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canDelete", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "canDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isCreator", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isCreator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSelf", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "isSelf"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resendInvite", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "resendInvite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeUser", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "removeUser"), _class.prototype)), _class));
  _exports.default = UsersActionMenu;
});