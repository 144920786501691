define("campaign-warrior-app/templates/components/users/user-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kjOrwbVF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  admin\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[35,0,[\"role\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"record\",\"isAdmin\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/users/user-role.hbs"
    }
  });

  _exports.default = _default;
});