define("campaign-warrior-app/components/support-login-form", ["exports", "campaign-warrior-app/components/floating-form", "ember-cp-validations"], function (_exports, _floatingForm, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage
   *
   * ```hbs
   * {{login-form
   *   onSubmit=(action "loginHandler")
   *   fbLogin=(action "fbLoginHandler")
   *   twitterLogin=(action "twitterLoginHandler")
   *   linkedinLogin=(action "linkedinLoginHandler")
   *   googlePlusLogin=(action "googlePlusLoginHandler")
   * }}
   * ```
   *
   * @namespace Components
   * @class LoginForm
   * @extends Components.FloatingForm
   */
  var _default = _floatingForm.default.extend({
    classNames: ['support-form'],
    onSubmit: null,

    /**
     * @property formData
     * @type object
     * @private
     */
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        portalId: [(0, _emberCpValidations.validator)('presence', true)]
      });
      return Ember.Object.extend(Validations, {}).create(Ember.getOwner(this).ownerInjection());
    }),
    actions: {
      /*onSubmit() {
        console.log("OnSubmit()");
        get(this, 'onSubmit')(get(this, 'formData'));
      },*/
    }
  });

  _exports.default = _default;
});