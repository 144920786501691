define("campaign-warrior-app/adapters/post-job", ["exports", "campaign-warrior-app/adapters/application", "campaign-warrior-app/mixins/adapter-portal-related"], function (_exports, _application, _adapterPortalRelated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PostJob
   * @namespace Adapters
   * @extends Adapters.Application
   * @uses Mixins.AdapterPortalRelated
   */
  var _default = _application.default.extend(_adapterPortalRelated.default, {
    urlForDeleteRecord() {
      const host = Ember.get(this, 'host') || '';
      const namespace = Ember.get(this, 'namespace') || '';
      return `${host}/${namespace}/schedule/cancel`;
    },

    /**
     *
     * @method deleteRecord
     * @param {DS.Store} store store itself
     * @param {DS.Model} type 'post-job'
     * @param {DS.Snapshot} snapshot snapshot for record
     * @return {Promise} promise
     */
    deleteRecord(store, type, snapshot) {
      const url = this.urlForDeleteRecord();
      return this.ajax(url, 'POST', {
        data: {
          postJobId: snapshot.id
        }
      });
    }

  });

  _exports.default = _default;
});