define("campaign-warrior-app/routes/portals/create/step3", ["exports", "campaign-warrior-app/routes/portals/create/step"], function (_exports, _step) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Step3
   * @namespace Routes.CreatePortal
   * @extends Routes.CreatePortal.WizardStep
   */
  var _default = _step.default.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    step: 3,

    /**
     * @param {object} controller step3 controller
     */
    setupController(controller) {
      this._super(...arguments);

      Ember.set(controller, 'focux', controller.setFocus());
    }

  });

  _exports.default = _default;
});