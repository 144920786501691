define("campaign-warrior-app/templates/components/pages/tags-filter-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WPTgB9x9",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"heading-content\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    Tagging\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"apply-buttons\"],[12],[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"primary\",[30,[36,0],[[32,0],\"applyFilters\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n      \"],[10,\"span\"],[14,0,\"btn-text\"],[12],[2,\"\\n             Apply Tags\\n          \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"cancel\",[30,[36,0],[[32,0],\"clearFilters\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n      \"],[10,\"span\"],[14,0,\"btn-text\"],[12],[2,\"\\n            Clear Tags\\n          \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/tags-filter-heading.hbs"
    }
  });

  _exports.default = _default;
});