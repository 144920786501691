define("campaign-warrior-app/controllers/portals/portal/domains/add-impressions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SetupController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class SetupController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['redirectUrl', 'domainUrl']);

      _defineProperty(this, "redirectUrl", '');

      _defineProperty(this, "domainUrl", '');

      _initializerDefineProperty(this, "currentPortal", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);
    }

    refreshImpressions() {
      this.ajax.post('/refresh-impressions', {
        data: {
          portal_id: this.currentPortal.portal.id
        }
      }).then(result => {
        console.log("Number of used impressions", result);
      });
    }

    callback(res) {
      const _this = this;

      if (res.newLimit) {
        Ember.set(this, 'currentPortal.portal.pagesLimit', res.newLimit);
      }

      let destination = 'portals.portal.domains.list';

      if (this.redirectUrl === 'setup') {
        destination = 'portals.portal.setup.website';
      }

      this.router.transitionTo(destination, {
        queryParams: {
          domainUrl: _this.domainUrl,
          pagesLimit: res.newLimit
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshImpressions", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "refreshImpressions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "callback", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "callback"), _class.prototype)), _class));
  _exports.default = SetupController;
});