define("campaign-warrior-app/controllers/portals/portal/pages/create", ["exports", "campaign-warrior-app/controllers/create-portal-related-record"], function (_exports, _createPortalRelatedRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class CreatePage
   * @namespace Controllers
   * @extends Controllers.CreatePortalRelatedRecord
   */
  var _default = _createPortalRelatedRecord.default.extend({
    /**
     * @property model
     * @type Models.Page
     * @default null
     */
    model: null,
    redirectTo: 'portals.portal.pages.list',

    /**
     * @property tags
     * @type Models.Tag[]
     * @default null
     */
    tags: null
  });

  _exports.default = _default;
});