define("campaign-warrior-app/models/keyword", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Keyword
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    createDate: _emberData.default.attr('date'),
    portal: _emberData.default.belongsTo('portal'),
    page: _emberData.default.belongsTo('page'),
    createdTime: Ember.computed('updated', function () {
      //console.log('notes DATE: ', get(this, 'createDate'));
      if (Ember.get(this, 'createDate')) {
        const name = _moment.default.tz.guess(Ember.get(this, 'createDate'));

        return name + ' ' + (0, _moment.default)(Ember.get(this, 'createDate')).toString();
      }

      return 'Date not Found';
    })
  });

  _exports.default = _default;
});