define("campaign-warrior-app/templates/components/create-portal/step3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "84foSD1b",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[10,\"div\"],[14,0,\"panel panel-default\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n    Copy Tracking Code\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"subtitle text-center\"],[12],[2,\"Step 3\"],[13],[2,\"\\n\"],[6,[37,6],null,[[\"formLayout\",\"model\",\"onSubmit\"],[\"vertical\",[35,5],[30,[36,3],[[32,0],\"onSubmit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/create-portal/step3.hbs' @ L8:C8) \"],null]],[[\"controlType\",\"rows\",\"class\",\"readonly\",\"value\",\"label\"],[\"textarea\",4,\"\",true,[35,0],\"Copy the tracking code and insert into your website header.\"]]]],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"control-group\"],[14,5,\"display: inline-block;\"],[12],[2,\"\\n          \"],[1,[30,[36,4],null,[[\"class\",\"defaultText\",\"onClick\"],[\"btn btn-next btn-default btn-wide-modal\",\"Copy Code\",[30,[36,3],[[32,0],\"copyCode\"],null]]]]],[2,\"\\n\"],[2,\"          \"],[1,[30,[36,4],null,[[\"class\",\"defaultText\",\"onClick\"],[\"btn btn-next btn-primary btn-wide-modal\",\"Get Started\",[30,[36,3],[[32,0],\"getStarted\"],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"trackingCode\",\"-assert-implicit-component-helper-argument\",\"component\",\"action\",\"bs-button\",\"formData\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/create-portal/step3.hbs"
    }
  });

  _exports.default = _default;
});