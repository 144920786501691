define("campaign-warrior-app/components/manage-user-password", ["exports", "campaign-warrior-app/components/manage-record-form", "ember-cp-validations"], function (_exports, _manageRecordForm, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for change User password
   *
   * Usage:
   *
   * ```hbs
   * {{manage-user-password save=(action "change")}}
   * ```
   *
   * @class ManageUserPassword
   * @namespace Components
   * @extends Components.ManageRecordForm
   */
  var _default = _manageRecordForm.default.extend({
    classNames: ['manage-user-password'],

    /**
     * @property formData
     * @type object
     * @private
     * @readonly
     */
    submitInProgress: false,
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        password: (0, _emberCpValidations.validator)('presence', true),
        newPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
          min: 4,
          max: 56
        })],
        confirm_password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
          min: 4,
          max: 56
        }), (0, _emberCpValidations.validator)('confirmation', {
          on: 'newPassword'
        })]
      });
      return Ember.Object.extend(Validations, {
        password: Ember.computed(function () {
          return '';
        }),
        newPassword: Ember.computed(function () {
          return '';
        }),
        confirm_password: Ember.computed(function () {
          return '';
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }),
    actions: {
      save() {
        Ember.set(this, "submitInProgress", true);

        const _this = this;

        return Ember.get(this, 'save')(Ember.get(this, 'formData')).then(() => {
          Ember.set(_this, "submitInProgress", false);
        });
      }

    }
  });

  _exports.default = _default;
});