define("campaign-warrior-app/templates/components/add-many-pages-form/progress-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tqcJKeKP",
    "block": "{\"symbols\":[\"p\"],\"statements\":[[10,\"p\"],[12],[1,[34,4]],[2,\" / \"],[1,[34,5]],[2,\" \"],[1,[30,[36,7],[[35,6],75],null]],[13],[2,\"\\n\"],[6,[37,8],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[30,[36,2],[[32,1,[\"bar\"]],\"expected `p.bar` to be a contextual component but found a string. Did you mean `(component p.bar)`? ('campaign-warrior-app/templates/components/add-many-pages-form/progress-body.hbs' @ L3:C4) \"],null]],[[\"value\",\"showLabel\",\"striped\",\"animate\",\"type\"],[[35,1],[35,0,[\"showLabel\"]],[35,0,[\"striped\"]],[35,0,[\"animate\"]],[35,0,[\"type\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"options\",\"progress\",\"-assert-implicit-component-helper-argument\",\"component\",\"done\",\"overall\",\"currentUrl\",\"trim\",\"bs-progress\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/add-many-pages-form/progress-body.hbs"
    }
  });

  _exports.default = _default;
});