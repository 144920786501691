define("campaign-warrior-app/utils/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dummyCopy = dummyCopy;
  _exports.saveSet = saveSet;
  _exports.diffByKeys = _exports.checkIntersection = void 0;

  /**
   * Copy object using `JSON.parse` and `JSON.stringify`. Ember-specific keys are excluded
   *
   * @param {object} obj object to copy
   * @return {object} copied object with
   */
  function dummyCopy(obj) {
    const skippedKeys = ['__validationsMixinCount__', '_super'];
    return JSON.parse(JSON.stringify(obj, (k, v) => {
      if (!skippedKeys.includes(k) && !k.includes('__OVERRIDE_OWNER__')) {
        return v;
      }
    }));
  }
  /**
   * While https://github.com/emberjs/ember.js/pull/13355 is not merged `trySet` can't be used for `isDestroy(ed|ing)` objects
   *
   * @param {object} context object with updated `key`
   * @param {string} key path to set
   * @param {*} value new value
   * @returns {*} set result
   */


  function saveSet(context, key, value) {
    const cantSet = Ember.get(context, 'isDestroying') || Ember.get(context, 'isDestroyed');

    if (cantSet) {
      return;
    }

    return Ember.trySet(context, key, value);
  }
  /**
   * @param {string[]} originalIds list of ids
   * @param {string[]} neededIds list searched ids
   * @param {boolean} subset `true` - `neededIds` must be a subset of `originalIds`, `false` - only some intersection is required
   * @returns {boolean} `neededIds` is a subset of `originalIds` or has intersection with it
   */


  const checkIntersection = (originalIds, neededIds, subset = false) => {
    const callback = subset ? 'every' : 'some';
    return neededIds[callback](neededId => originalIds.includes(neededId));
  };
  /**
   * Returns list of items from `firstList` that don't exist in the `secondList`
   *
   * @param {object[]} firstList one list of objects
   * @param {object[]} secondList another one list of objects
   * @param {string[]} keys keys to compare
   * @return {object[]} list of objects from `firstList` that don't exist in the `secondList`
   */


  _exports.checkIntersection = checkIntersection;

  const diffByKeys = (firstList, secondList, keys) => {
    return firstList.filter(firstItem => !secondList.find(secondItem => keys.every(key => Ember.get(firstItem, key) === Ember.get(secondItem, key))));
  };

  _exports.diffByKeys = diffByKeys;
});