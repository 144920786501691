define("campaign-warrior-app/controllers/internal-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Register
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property login
     * @type Ember.Controller
     */
    login: Ember.inject.controller(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),
    recurly: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property portals
     * @type Models.Portal[]
     * @default null
     */
    portals: null,

    /**
     * @property requestInProgress
     * @type boolean
     * @default false
     */
    requestInProgress: false,
    isTrial: Ember.computed(function () {
      return true;
    }),
    planName: Ember.computed('isTrial', function () {
      if (this.isTrial) {
        return '14 Day Trial';
      }

      return 'Full Plan';
    }),

    /**
     * @property billingInfo
     * @type Models.BillingInfo
     * @readonly
     */
    billingInfo: Ember.computed.reads('currentUser.user.company.billingInfo'),
    actions: {
      doRegister(form, formData, errorBody) {
        const modalsManager = Ember.get(this, 'modalsManager');
        const i18n = Ember.get(this, 'i18n');
        modalsManager.process({
          body: '',
          iconClass: 'text-center fal fa-spinner fa-spin fa-3x fa-fw',
          title: 'Processing request, please wait',
          process: () => new Promise(resolve => resolve(Ember.get(this, 'recurly').getToken(form).then(token => {
            form['recurly-token'] = token;
            return Ember.get(this, 'ajax').post('/registerinternaluser', {
              data: form
            }).then(response => Ember.get(this, 'notify').success(response.message));
          })))
        }).then(() => Ember.get(this, 'currentUser').load()).then(() => Ember.get(this, 'login').send('doLogin', form)).catch(e => {
          if (e.name !== 'TransitionAborted') {
            modalsManager.recurlyErrorHandler(e);

            if (!e.payload.errors || !e.payload.errors.length) {
              console.error('Error body', errorBody);
            } else {
              e.payload.userMessage = e.payload.errors[0].details;
            }

            Ember.get(this, 'modalsManager').billingErrorHandler(e);
          } else {
            Ember.get(this, 'modalsManager').billingErrorHandler(e);
          }
        });
      },

      selectPlan(planName) {
        if (planName === 'trial') {
          Ember.set(this, 'isTrial', true);
        } else if (planName === 'full') {
          Ember.set(this, 'isTrial', false);
        }
      }

    }
  });

  _exports.default = _default;
});