define("campaign-warrior-app/components/sandbox-warning-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class SandboxWarningBanner
   * @namespace Components
   * @extends Ember.Components
   */
  var _default = Ember.Component.extend({
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    classNames: ['sandbox-warning-banner', 'alert']
  });

  _exports.default = _default;
});