define("campaign-warrior-app/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @class Login
   * @namespace Controllers
   * @extends Ember.Controller
   */
  let LoginController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class LoginController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor3, this);

      _initializerDefineProperty(this, "currentUser", _descriptor4, this);

      _initializerDefineProperty(this, "i18n", _descriptor5, this);

      _initializerDefineProperty(this, "ajax", _descriptor6, this);

      _initializerDefineProperty(this, "isMobile", _descriptor7, this);

      _defineProperty(this, "authInProgress", false);

      if (this.isMobile.phone) {
        return this.router.transitionTo('mobile-error');
      }
    }

    doLogin(data) {
      try {
        const remember = data && data.remember ? data.remember : false;
        return this.authWrapper(() => Ember.get(this, 'session').authenticate('authenticator:jwt', {
          password: data.password,
          identification: data.username
        }, {
          remember
        }), 'login.emailLogin.error');
      } catch (err) {
        console.error("Do login error", err);
      }
    }

    doFbLogin() {
      return this.authWrapper(() => Ember.get(this, 'session').authenticate('authenticator:torii', 'facebook'), 'login.fbLogin.error');
    }

    doGoogleLogin() {
      const ajax = Ember.get(this, 'ajax');
      return this.authWrapper(() => {
        return Ember.get(this, 'session').authenticate('authenticator:torii', 'google', {
          clb(d) {
            // `d['expires-in']` and `d['refresh-token']` are available
            return ajax.post('/google/login', {
              data: {
                'access-token': d['access-token']
              }
            }).then(data => ({
              token: data.token,
              provider: 'google'
            }));
          }

        });
      }, 'login.googleLogin.error');
    }
    /**
     * Wrapper for auth handlers
     *
     * @param {Function} auth callback
     * @param {string} errorBody i18n-key for error-modal
     * @return {RSVP.Promise} auth result
     */


    async authWrapper(auth, errorBody) {
      const modalsManager = Ember.get(this, 'modalsManager');
      const currentUser = Ember.get(this, 'currentUser');

      try {
        Ember.set(this, 'authInProgress', true);
        await auth();
        await currentUser.load();
        const router = Ember.get(this, 'router');
        const status = Ember.get(currentUser, 'user.company.status');

        if (status === 'expired') {
          router.transitionTo('expired');
        }

        return router.transitionTo('portals');
      } catch (err) {
        if (err.status === 401) {
          if (err.json && err.json.errors) {
            if (err.json.errors[0].details === 'Email Verification required') {
              return this.router.transitionTo('verification.required');
            }
          }
        }

        modalsManager.loginErrorHandler(err);
      } finally {
        Ember.set(this, 'authInProgress', false);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isMobile", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "doLogin", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "doLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doFbLogin", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "doFbLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doGoogleLogin", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "doGoogleLogin"), _class.prototype)), _class));
  _exports.default = LoginController;
  ;
});