define("campaign-warrior-app/components/pages/page-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   *
   * @class PageNotes
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['notes'],

    /**
     * @property columns
     * @type object[]
     * @private
     * @readonly
     */
    notesColumns: Ember.computed(function () {
      return [{
        className: 'description',
        title: 'Description',
        propertyName: 'description',
        searchField: 'description',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        className: 'assigned-to',
        title: 'Assigned To',
        component: 'notes/notes-names',
        propertyName: 'assignedTo',
        useFilter: false,
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true,
        assigned: true
      }, {
        className: 'created-by',
        component: 'notes/notes-names',
        title: 'Created By',
        propertyName: 'createdBy',
        useFilter: false,
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true,
        created: true
      }, {
        className: 'create-date',
        title: 'Create Date',
        propertyName: 'createdTime',
        useFilter: false,
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        className: 'delete-cell'
      }];
    }).readOnly()
  });

  _exports.default = _default;
});