define("campaign-warrior-app/templates/components/cw-table/trimmed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5fgBG7oK",
    "block": "{\"symbols\":[\"val\"],\"statements\":[[6,[37,6],[[30,[36,5],[[35,4],[35,1,[\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,1],[35,1,[\"trimSize\"]]],null]],[2,\"\\n\"],[6,[37,3],[[35,1,[\"hideTooltip\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[6,[37,0],null,[[\"placement\"],[\"top\"]],[[\"default\"],[{\"statements\":[[1,[32,1]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"bs-tooltip\",\"column\",\"trim\",\"unless\",\"record\",\"get\",\"with\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/trimmed.hbs"
    }
  });

  _exports.default = _default;
});