define("campaign-warrior-app/templates/components/hashtags/create-hashtag-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PPHDTT1L",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[6,[37,7],null,[[\"open\",\"title\",\"onSubmit\",\"onHide\"],[[35,6],[30,[36,1],[\"hashtags.create.title\"],null],[30,[36,0],[[32,0],\"confirm\"],null],[30,[36,0],[[32,0],\"decline\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"header\"]]],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('campaign-warrior-app/templates/components/hashtags/create-hashtag-modal.hbs' @ L8:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"record\",\"hideControls\",\"onlyOwnAttrs\",\"save\"],[[35,2,[\"hashtag\"]],true,true,[30,[36,0],[[32,0],\"confirm\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,4],[[32,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('campaign-warrior-app/templates/components/hashtags/create-hashtag-modal.hbs' @ L11:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"bs-button\",[[24,0,\"btn btn-default\"]],[[\"@onClick\"],[[30,[36,0],[[32,0],[32,1,[\"close\"]]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"hashtags.create.cancel\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"bs-button\",[[24,0,\"btn btn-primary\"]],[[\"@onClick\"],[[30,[36,0],[[32,0],[32,1,[\"submit\"]]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"hashtags.create.save\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"options\",\"hashtags/create-hashtag-form\",\"-assert-implicit-component-helper-argument\",\"component\",\"modalIsOpened\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/hashtags/create-hashtag-modal.hbs"
    }
  });

  _exports.default = _default;
});