define("campaign-warrior-app/helpers/round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.round = round;
  _exports.default = void 0;

  /**
   * Usage
   *
   * ```hbs
   * {{round someVal}}
   * ```
   *
   * @class Round
   * @namespace Helpers
   *
   * @param {number} val value to round
   * @return {number} rounded value
   */
  function round([val]
  /*, hash*/
  ) {
    return Math.round(Number(val));
  }

  var _default = Ember.Helper.helper(round);

  _exports.default = _default;
});