define("campaign-warrior-app/components/domains/domain-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    status: Ember.computed(function () {
      return Ember.get(this, 'record.sitemapError') ? 'Sitemap Not Found' : 'Success';
    })
  });

  _exports.default = _default;
});