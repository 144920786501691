define("campaign-warrior-app/components/pages/pages-summary/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PagesSummaryTitle
   * @namespace Components.Pages
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @event updateContentForSummary
     */
    updateContentForSummary: null,

    /**
     * @property useFilteredContent
     * @type boolean
     * @default null
     */
    useFilteredContent: null,
    actions: {
      updateContentForSummary() {
        const action = Ember.get(this, 'updateContentForSummary');

        if (action) {
          action();
        }
      }

    }
  });

  _exports.default = _default;
});