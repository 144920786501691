define("campaign-warrior-app/services/modals-manager-solid", ["exports", "ember-bootstrap-modals-manager/services/modals-manager"], function (_exports, _modalsManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Handle the 'only one modal may be opened at a time' issue
   */
  class ModalsManagerSolid extends _modalsManager.default {
    show(componentName, options) {
      //assert('Only one modal may be opened in the same time!', !this.modalIsOpened);
      try {
        const opts = Object.assign({}, this.defaultOptions, options);
        Ember.set(this, 'modalIsOpened', true);
        Ember.set(this, 'options', opts);
        Ember.set(this, 'componentName', componentName);
        const modalDefer = Ember.RSVP.defer();
        Ember.set(this, 'modalDefer', modalDefer);
        return modalDefer.promise;
      } catch (err) {
        console.error("Modals manager Show error", err);
      }
    }

  }

  _exports.default = ModalsManagerSolid;
});