define("campaign-warrior-app/templates/components/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "807XJ9Nl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"input-group colorpicker\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"value\",\"class\"],[[35,0],\"form-control\"]]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[10,\"i\"],[12],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"color\",\"input\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/color-picker.hbs"
    }
  });

  _exports.default = _default;
});