define("campaign-warrior-app/abilities/portal", ["exports", "campaign-warrior-app/abilities/cw-ability"], function (_exports, _cwAbility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Portal
   * @namespace Abilities
   * @extends Abilities.CwAbility
   */
  var _default = _cwAbility.default.extend({
    /**
     * @property canCreate
     * @type boolean
     * @default true
     */
    canCreate: Ember.computed('user.permissions.@each.permissionType', 'currentUser.user.company.inTrial', function () {
      const currentUser = Ember.get(this, 'currentUser');
      const inTrial = Ember.get(currentUser, 'user.company.inTrial');
      const portalsCount = Ember.get(currentUser, 'user.company.billingInfo.portalsCount');
      return !(inTrial && portalsCount >= 1);
    }),

    /**
     * @property canView
     * @type boolean
     * @default true
     */
    canView: Ember.computed('user.permissions.@each.{objectId,permissionType}', 'model.id', function () {
      const permissions = Ember.get(this, 'user.permissions');
      const currentPortalId = Ember.get(this, 'model.id');

      if (Ember.isEmpty(permissions)) {
        return true;
      }

      return !!permissions.find(permission => Ember.get(permission, 'objectId') === currentPortalId && Ember.get(permission, 'permissionType') === 'view_portal');
    }),

    /**
     * @property canView
     * @type boolean
     * @default true
     */
    canEdit: Ember.computed('user.permissions.@each.{objectId,permissionType}', 'model.id', function () {
      const permissions = Ember.get(this, 'user.permissions');
      const currentPortalId = Ember.get(this, 'model.id');

      if (Ember.isEmpty(permissions)) {
        return true;
      }

      return !!permissions.find(permission => Ember.get(permission, 'objectId') === currentPortalId && Ember.get(permission, 'permissionType') === 'admin_portal');
    }),
    canIsTrial: Ember.computed('currentPortal.portal.company.inTrial', function () {
      const currentUser = Ember.get(this, 'currentPortal');
      return !!Ember.get(currentUser, 'portal.company.inTrial');
    }),

    /**
     * Portal is owned by the current users company, not invited to a 3rd party companies portal
     */
    canOwnsCompany: Ember.computed('currentPortal.portal.companyId', 'currentUser.user.company.id', function () {
      return Ember.get(this, 'currentPortal.portal.companyId') === Ember.get(this, 'currentUser.user.company.id');
    })
  });

  _exports.default = _default;
});