define("campaign-warrior-app/templates/components/users/manage-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y4RcMCxD",
    "block": "{\"symbols\":[\"form\",\"unit\",\"&default\"],\"statements\":[[6,[37,6],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,0],true,[30,[36,3],[[32,0],\"onSubmit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n      \"],[10,\"h4\"],[12],[1,[35,0,[\"email\"]]],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-3\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,1],[\"users.manage.role.title\"],null]],[13],[2,\"\\n\"],[6,[37,5],null,[[\"options\",\"selected\",\"searchEnabled\",\"onChange\"],[[35,4],[35,0,[\"role\"]],false,[30,[36,3],[[32,0],[30,[36,2],[[35,0,[\"role\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[18,3,null]],\"hasEval\":false,\"upvars\":[\"formData\",\"t\",\"mut\",\"action\",\"roleOptions\",\"power-select\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/users/manage-user-form.hbs"
    }
  });

  _exports.default = _default;
});