define("campaign-warrior-app/initializers/inject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * Initializer for injections
   *
   * Services may be injected directly in the places where they are needed (e.g. Components, Controllers, Routes)
   *
   * @param {Ember.Application} application current application
   */
  function initialize(application) {
    application.inject('component:top-nav', 'portalRoute', 'route:portals.portal');
  }

  var _default = {
    name: 'injections',
    initialize
  };
  _exports.default = _default;
});