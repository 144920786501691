define("campaign-warrior-app/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/yd6bFOT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container info-modal\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"error\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"icon-wrapper\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"far fa-dumpster-fire\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"h1\"],[12],[2,\"This page cannot be found\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"desc\"],[12],[2,\"\\n          \"],[1,[34,0]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"controls\"],[12],[2,\"\\n          \"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-primary\",\"portals\"]],[[\"default\"],[{\"statements\":[[2,\"Home\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"errorDetails\",\"link-to\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/404.hbs"
    }
  });

  _exports.default = _default;
});