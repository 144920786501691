define("campaign-warrior-app/components/users/manage-user-form", ["exports", "campaign-warrior-app/components/manage-record-form"], function (_exports, _manageRecordForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _manageRecordForm.default.extend({
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    classNames: ['manage-user-form'],
    roleOptions: ['admin', 'user'],
    actions: {
      onSubmit() {}

    }
  });

  _exports.default = _default;
});