define("campaign-warrior-app/controllers/verification/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VerifyController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('token'), (_class = (_temp = class VerifyController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['token']);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _defineProperty(this, "verifying", true);

      _defineProperty(this, "error", null);

      _defineProperty(this, "success", null);
    }

    //token = '';
    init() {
      super.init();
      Ember.run.next(() => this.verifyToken());
    }

    afterModel() {
      console.log("Controller after model", this.token);
    }

    get tokenVal() {
      return this.token;
    }

    async verifyToken() {
      try {
        await this.ajax.post('/verify', {
          data: {
            token: this.token
          }
        });
        Ember.set(this, 'verifying', false);
        Ember.set(this, 'success', 'Your email address has been validated, please log in');
      } catch (err) {
        Ember.set(this, 'verifying', false);

        if (err && err.payload) {
          if (err.payload.errors[0].details === 'Invalid Token') {
            Ember.set(this, 'error', 'The link you clicked has expired or was invalid, please try again or resend your verification email');
          } else {
            Ember.set(this, 'error', err.payload.errors[0].details);
          }
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "tokenVal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "tokenVal"), _class.prototype)), _class));
  _exports.default = VerifyController;
});