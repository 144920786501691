define("campaign-warrior-app/templates/components/user-journey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f3q1P37M",
    "block": "{\"symbols\":[\"slide\"],\"statements\":[[10,\"div\"],[14,0,\"swiper-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"swiper-wrapper\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"swiper-slide\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"slide-tile\"],[15,\"onclick\",[30,[36,0],[[32,0],\"slideClick\",[32,1]],null]],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"slide-tile-content\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"slide-tile-image\"],[12],[2,\"\\n              \"],[10,\"div\"],[14,0,\"slide-tile-icon-wrapper\"],[12],[2,\"\\n                \"],[10,\"i\"],[15,0,[31,[[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"slide-tile-title\"],[12],[2,\"\\n              \"],[10,\"h4\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"slide-tile-description\"],[12],[2,\"\\n              \"],[1,[32,1,[\"description\"]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"swiper-button-prev\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"swiper-button-next\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"slides\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/user-journey.hbs"
    }
  });

  _exports.default = _default;
});