define("campaign-warrior-app/templates/components/modals/billing-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jd7rcWBV",
    "block": "{\"symbols\":[\"modal\",\"field\"],\"statements\":[[8,\"bs-modal\",[],[[\"@keyboard\",\"@backdropClose\",\"@size\",\"@open\",\"@onSubmit\"],[\"false\",\"false\",\"lg\",[34,0],[30,[36,1],[[32,0],\"confirm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"A Billing error occurred\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h5\"],[12],[2,\"Please check the following fields:\"],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[[24,0,\"single-button\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"primary\",[30,[36,1],[[32,0],\"confirm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Close\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"modalIsOpened\",\"action\",\"fields\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/billing-error.hbs"
    }
  });

  _exports.default = _default;
});