define("campaign-warrior-app/templates/components/modals/recurly-error-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kpaCXUqp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"p\"],[12],[1,[30,[36,0],[\"billing.form.recurlyError.code\"],null]],[2,\": \"],[10,\"strong\"],[12],[1,[35,1,[\"code\"]]],[13],[13],[2,\"\\n\"],[10,\"p\"],[12],[1,[30,[36,0],[\"billing.form.recurlyError.fields\"],null]],[2,\": \"],[10,\"strong\"],[12],[1,[35,1,[\"fields\"]]],[13],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"t\",\"error\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/recurly-error-body.hbs"
    }
  });

  _exports.default = _default;
});