define("campaign-warrior-app/controllers/portals/create/step", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @namespace Controllers.CreatePortal
   * @class WizardStep
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * Injected Router-service, used to navigate user between wizard's steps
     *
     * @type object
     * @property router
     */
    router: Ember.inject.service(),

    /**
     * Previously provided data
     *
     * It's restored from the Local Storage (see {{#crossLink "Storages.CreatePortalStorage"}}create-portal-storage{{/crossLink}})
     *
     * Each step has its own data fields
     *
     * @property initialData
     * @type object
     * @default {}
     */
    initialData: null,

    /**
     * Data from the local storage for new the portal
     *
     * It's updated in the LS when user is moved from one step to another
     *
     * @property portal
     * @type object
     * @default {}
     */
    portal: (0, _emberLocalStorage.storageFor)('create-portal'),
    actions: {
      /**
       * "Next"-click handler
       */
      goToNextStep()
      /* data */
      {
        Ember.get(this, 'portal').incrementProperty('step');
      }

    }
  });

  _exports.default = _default;
});