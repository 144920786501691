define("campaign-warrior-app/routes/portals/portal/stats", ["exports", "campaign-warrior-app/utils/consts", "campaign-warrior-app/mixins/portal-internal-route"], function (_exports, _consts, _portalInternalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PortalDashboard
   * @uses Mixins.PortalInternalRoute
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_portalInternalRoute.default, {
    breadCrumbRoutingKey: 'dashboard',
    rootElementClasses: Object.freeze(['dashboard-page']),

    model() {
      const store = Ember.get(this, 'store');
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      return Ember.RSVP.hash({
        pages: store.query('page', {
          portal_id,
          ...(0, _consts.defaultPaginationSettings)()
        })
      });
    },

    setupController(controller, model) {
      console.log('setting data in dashboard route: ', model);
      Ember.setProperties(controller, {
        portal: model.portal,
        pages: model.pages,
        tags: model.tags
      });
    },

    actions: {
      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});