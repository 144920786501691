define("campaign-warrior-app/routes/portals/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Portal
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),
    title: 'Portal | Campaign Warrior',

    /**
     * @param {{portal_id: string}} portal_id portal identifier
     * @returns {RSVP.Promise} portal record
     */
    async model({
      portal_id
    }) {
      console.log("Portal route model init");

      if (Ember.get(this, 'can').cannot('view portal', {
        id: portal_id
      })) {
        return Ember.get(this, 'router').transitionTo('portals');
      } //todo - the error for unauthorized portal access occurs in this query


      return Ember.get(this, 'store').findRecord('portal', portal_id, {
        reload: true,
        include: 'company'
      });
    },

    afterModel(model) {
      console.log("Portal route afterModel", model);
      let name = '';

      if (model && model.name) {
        name = model.name;
      }

      Ember.set(this, 'title', `Portal: ${name} | Campaign Warrior`);
      /* return hash({
         portalSubscription: store.query('portal-subscription', {portal: get(model, 'id')})
       });*/
    },

    /**
     * @param {Ember.Controller} controller current page controller
     * @param {Models.Portal} model current portal
     */
    setupController(controller, model) {
      this._super(...arguments);
      /*const modalsManager = get(this, 'modalsManager');
      try {
        const errorStore = get(model, 'errorStore');
        //const router = get(this, 'router');
          if (errorStore) {
          if (errorStore.errorType) {
            if (errorStore.errorType === 'sitemap-missing') {
              modalsManager.show('domains/sitemap-error-modal')
                .then(async () => {
                  set(model, 'errorStore', null);
                  await model.save();
                  //router.transitionTo('portals.portal');
                  window.open('https://www.campaignwarrior.com/support');
                }).catch(async (err) => {
                //console.error('Dismiss sitemap error', err);
                set(model, 'errorStore', null);
                await model.save();
              })
            }
          }
        }
        } catch (err) {
        console.error('Error handling errors from the portal errorStore', err);
      }*/


      Ember.set(this, 'currentPortal.portal', model);
      Ember.set(controller, 'allPortals', this.modelFor('portals'));
      Ember.set(this, 'breadCrumb', model);
      window.localStorage.setItem('activePortal', JSON.stringify(Ember.get(this, 'currentPortal.portal').id)); //set active portal
    }

  });

  _exports.default = _default;
});