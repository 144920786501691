define("campaign-warrior-app/components/cw-table/add-tag-dropdown", ["exports", "campaign-warrior-app/models/tag", "campaign-warrior-app/mixins/searchable"], function (_exports, _tag, _searchable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_searchable.default, {
    i18n: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    store: Ember.inject.service(),
    tagsList: null,
    page: null,
    searchField: 'name',
    actions: {
      addTagToPage(tag) {
        if (!tag.name) {
          createTag();
          return;
        }

        if (Ember.isEmpty(tag) || Ember.isEmpty(this.page)) {
          return false;
        }

        let pageTags = this.page.tags;

        if (this.page.tags.indexOf(tag) === -1) {
          pageTags.pushObject(tag);
        }

        this.page.save();
      },

      createTag() {
        const portal = Ember.get(this, 'currentPortal.portal');
        const store = Ember.get(this, 'store');
        const modalsManager = Ember.get(this, 'modalsManager');

        const _this = this;

        modalsManager.show('tags/create-tag-modal').then(res => {
          let tagName = res.name;
          const newTag = store.createRecord('tag', {
            name: tagName,
            description: res.description,
            color: _tag.default.getRandColor(),
            portal
          });
          newTag.save().then(tag => {
            this.page.tags.pushObject(tag);

            _this.tagsList.addObject(tag);

            _this.page.save();
          });
        });
      },

      customSuggestion(tagName) {
        return this.i18n.t('tags.manageRelatedTags.suggestion', {
          tagName
        });
      }

    }
  });

  _exports.default = _default;
});