define("campaign-warrior-app/routes/portals/portal/pages/add-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AddList
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    model() {
      return Ember.get(this, 'store').query('page', {
        portal_id: Ember.get(this.modelFor('portals.portal'), 'id')
      });
    },

    setupController(controller) {
      this._super(...arguments);

      Ember.set(controller, 'portal', this.modelFor('portals.portal'));
    }

  });

  _exports.default = _default;
});