define("campaign-warrior-app/templates/reset/pass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JO5yZC+s",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"onSubmit\"],[[30,[36,0],[[32,0],\"doResetPassword\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"reset-password-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/reset/pass.hbs"
    }
  });

  _exports.default = _default;
});