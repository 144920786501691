define("campaign-warrior-app/models/shortlink", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Shortlink = _emberData.default.Model.extend({
    url: _emberData.default.attr('string'),
    shortUrl: _emberData.default.attr('string'),
    visits: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    usageCount: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    portal: _emberData.default.belongsTo('portal'),
    domain: _emberData.default.belongsTo('domain'),
    page: _emberData.default.belongsTo('page')
  });

  var _default = Shortlink;
  _exports.default = _default;
});