define("campaign-warrior-app/adapters/publish-source", ["exports", "campaign-warrior-app/adapters/application", "campaign-warrior-app/mixins/adapter-portal-related"], function (_exports, _application, _adapterPortalRelated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PublishSource
   * @namespace Adapters
   * @extends Adapters.Application
   * @uses Mixins.AdapterPortalRelated
   */
  var _default = _application.default.extend(_adapterPortalRelated.default, {
    /**
     * @method updateRecord
     * @param {DS.Store} store store itself
     * @param {DS.Model} type publishSource model
     * @param {DS.Snapshot} snapshot record to update
     * @return {Promise} promise
     */
    updateRecord(store, type, snapshot) {
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);

      if (snapshot.adapterOptions && snapshot.adapterOptions.toggleActiveState) {
        data = {
          data: {
            attributes: {
              active: data.data.attributes.active
            }
          }
        };
      }

      let id = snapshot.id;
      let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord'); //console.log('Update publish source record data', {data});

      return this.ajax(url, 'PATCH', {
        data
      });
    }

  });

  _exports.default = _default;
});