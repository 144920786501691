define("campaign-warrior-app/helpers/subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.subtract = void 0;

  const subtract = params => {
    return params.reduce((a, b) => {
      return a - b;
    });
  };

  _exports.subtract = subtract;

  var _default = Ember.Helper.helper(subtract);

  _exports.default = _default;
});