define("campaign-warrior-app/templates/components/notes/create-note-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fVgmVFIO",
    "block": "{\"symbols\":[\"form\",\"user\",\"&default\"],\"statements\":[[6,[37,8],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,0],true,[30,[36,2],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,7],[[30,[36,6],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/notes/create-note-form.hbs' @ L2:C6) \"],null]],[[\"controlType\",\"label\",\"property\",\"rows\"],[\"textarea\",[30,[36,5],[\"notes.create.description\"],null],\"description\",4]]]],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/notes/create-note-form.hbs' @ L9:C7) \"],null]],[[\"class\",\"label\"],[\"assign-to\",[30,[36,5],[\"notes.create.assignTo\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"renderInPlace\",\"options\",\"selected\",\"searchEnabled\",\"searchField\",\"onChange\"],[true,[35,3],[35,0,[\"assignedTo\"]],false,\"user\",[30,[36,2],[[32,0],[30,[36,1],[[35,0,[\"assignedTo\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,2,[\"firstName\"]]],[2,\" \"],[1,[32,2,[\"lastName\"]]],[2,\" - \"],[1,[32,2,[\"email\"]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\\n\\n\"],[18,3,null]],\"hasEval\":false,\"upvars\":[\"record\",\"mut\",\"action\",\"usersList\",\"power-select\",\"t\",\"-assert-implicit-component-helper-argument\",\"component\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/notes/create-note-form.hbs"
    }
  });

  _exports.default = _default;
});