define("campaign-warrior-app/adapters/user", ["exports", "campaign-warrior-app/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class User
   * @namespace Adapters
   * @extends Adapters.Application
   */
  var _default = _application.default.extend({
    /**
     * Custom handler for current user. Request for current user must include `permissions` and `favorites`
     *
     * @method urlForQueryRecord
     * @param {object} query object with query-data
     * @returns {string} generated url
     */
    urlForQueryRecord(query) {
      if (query.current) {
        delete query.current;
        return `${this._super(...arguments)}/current`;
        return `${this._super(...arguments)}/current?include=favorites,invited-users,invited-company-users`;
      }

      return this._super(...arguments);
    },

    /**
     * Updating current user is done on the specific API end-point
     *
     * @method urlForUpdateRecord
     * @param {string} id user id
     * @param {string} modelClassName 'user'
     * @param {DS.Snapshot} snapshot record's snapshot
     * @returns {string} generated url
     */
    urlForUpdateRecord(id, modelClassName, snapshot) {
      if (Ember.get(snapshot, 'adapterOptions.current')) {
        return `${this.urlForFindAll(modelClassName, snapshot)}/current`;
      }

      return this._super(...arguments);
    },

    /**
     * @method urlForFindRecord
     * @returns {string} url for request
     */
    urlForFindRecord() {
      return `${this._super(...arguments)}`;
      return `${this._super(...arguments)}?include=permissions,favorites,invited-users`;
    }

  });

  _exports.default = _default;
});