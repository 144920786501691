define("campaign-warrior-app/components/wizard-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import {getOwner} from '@ember/application';
  //import {validator, buildValidations} from 'ember-cp-validations';
  var _default = Ember.Component.extend({
    classNames: ['wizard-panel'],
    steps: Ember.computed(function () {
      return [];
    })
  });

  _exports.default = _default;
});