define("campaign-warrior-app/components/column-sort-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ColumnSortHeader = (_dec = Ember.computed('meta["current-page"]'), _dec2 = Ember.computed('column.sortAsc'), _dec3 = Ember.computed('column.title'), _dec4 = Ember.computed('column.propertyName'), _dec5 = Ember.computed('sortOrder'), _dec6 = Ember.computed('isActiveSort'), _dec7 = Ember.computed('sortState'), _dec8 = Ember.computed('sortOrder', 'isActiveSort'), _dec9 = Ember._action, (_class = (_temp = class ColumnSortHeader extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "column", null);

      _defineProperty(this, "sortBy", null);

      _defineProperty(this, "meta", null);

      _defineProperty(this, "isApplied", false);

      _defineProperty(this, "sortDec", false);
    }

    //sortState = 0; //0 not applied, 1 sort dsc, 2 sort asc
    get currentPage() {
      return this.meta['current-page'];
    }

    get sortAsc() {
      return this.column.sortAsc;
    }

    get title() {
      return this.column.title;
    }

    get propertyName() {
      return this.column.propertyName;
    }

    get isActiveSort() {
      if (this.sortOrder && this.sortOrder.replace('-', '') === this.propertyName) {
        return true;
      }
    }

    get wrapperClass() {
      if (this.isActiveSort) {
        return 'sort-active';
      } else {
        return 'sort-inactive';
      }
    }

    get sortIcon() {
      if (this.sortState === 0) {
        return 'fas fa-long-arrow-down sort-icon';
      } else if (this.sortState === 1) {
        return 'fas fa-long-arrow-down rotate-down sort-icon';
      } else if (this.sortState === 2) {
        return 'fas fa-long-arrow-up rotate-up sort-icon';
      }
    }

    get sortState() {
      if (this.isActiveSort) {
        if (this.sortOrder.includes('-')) {
          Ember.set(this, 'sortDec', true);
          return 1;
        } else {
          Ember.set(this, 'sortDec', false);
          return 2;
        }
      } else {
        return 0;
      }
    }

    sortProp(propName) {
      /*if (this.isActiveSort){
        if (this.sortOrder.includes('-')){
          if (this.sortAsc){
            set(this, 'sortState', 0);
          } else {
            set(this, 'sortState', 1);
          }
        }
      }*/
      //set(this, 'sortAsc', !this.sortAsc);
      let nextSortState = this.sortState + 1;

      if (nextSortState > 2) {
        nextSortState = 0;
      } //set(this, 'sortState', nextSortState);


      let sortString = propName;

      if (nextSortState === 0) {
        sortString = '';
      }

      if (nextSortState === 1) {
        sortString = '-' + sortString;
      }

      this.sortBy(sortString);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "currentPage", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "currentPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortAsc", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "sortAsc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "propertyName", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "propertyName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isActiveSort", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isActiveSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "wrapperClass", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "wrapperClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortIcon", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "sortIcon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortState", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "sortState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortProp", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "sortProp"), _class.prototype)), _class));
  _exports.default = ColumnSortHeader;
});