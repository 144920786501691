define("campaign-warrior-app/models/domain", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Domain
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    /**
     * @type DS.StringAttribute
     * @property originalId
     */
    url: _emberData.default.attr('string'),
    trackingId: _emberData.default.attr('string'),
    _id: _emberData.default.attr('string'),
    subdomainOf: _emberData.default.attr('string'),
    totalPages: _emberData.default.attr('number'),
    processedPages: _emberData.default.attr('number'),
    rootUrl: _emberData.default.attr('string'),
    created: _emberData.default.attr('date'),
    updated: _emberData.default.attr('date'),
    sitemapError: _emberData.default.attr('boolean'),
    source: _emberData.default.attr('string'),
    portal: _emberData.default.belongsTo('portal'),
    create: Ember.computed('created', function () {
      return (0, _moment.default)(Ember.get(this, 'created')).format('hh:mm YYYY:MM:DD');
    }),
    update: Ember.computed('updated', function () {
      return (0, _moment.default)(Ember.get(this, 'updated')).fromNow(); // const name = moment.tz.guess(get(this, 'updated'));
      // return name + ' ' + moment(get(this, 'updated')).toString();
    }),
    trackingCode: Ember.computed('trackingId', function () {
      return `<script>
    var p = document.createElement("img");
    p.src = "https://index.campaignwarrior.com/pixel.png?id=${Ember.get(this, 'trackingId')}";
    p.style.display = "none";
    p.referrerPolicy = "no-referrer-when-downgrade";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(p, s);
    window.cw = { portal_id: '${Ember.get(this, 'portal.id')}'};
  </script>`;
    })
  });

  _exports.default = _default;
});