define("campaign-warrior-app/routes/billing", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Billing
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),
    rootElementClasses: Object.freeze(['billing-page']),

    model() {
      const portals = this.store.findAll('portal').then(portalsList => {});
      return portals;
      let portalsData = [];
      portals.forEach(portal => {
        const data = {
          portalName: portal.name
        };
        let extraBlocks = portal.chargeableBlocks || 0;
        const total = 29 + extraBlocks * 5;
        data.extraBlocks = extraBlocks;
        data.total = total.toFixed(2);
        portalsData.push(data);
      });
      return portalsData;
    },

    afterModel() {
      const store = Ember.get(this, 'store');
      const currentUser = Ember.get(this, 'currentUser');
      return store.findRecord('company', Ember.get(currentUser, 'user.company.id')).then(company => store.findRecord('billing-info', Ember.get(company, 'billingInfo.id'))).then(() => store.findAll('portal'));
    },

    setupController(controller) {
      if (Ember.get(this, 'can').cannot('edit billing')) {
        return this.transitionTo('portals');
      } //set(controller, 'portals', get(this, 'store').findAll('portal'));


      this._super(...arguments);
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(controller, 'requestInProgress', false);
      }
    }

  });

  _exports.default = _default;
});