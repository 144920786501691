define("campaign-warrior-app/templates/components/cw-table/view-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dw+XGpi3",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"openWebPage\",[35,0,[\"url\"]]],null],[12],[10,\"i\"],[14,0,\"far fa-external-link\"],[12],[13],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"record\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/view-page.hbs"
    }
  });

  _exports.default = _default;
});