define("campaign-warrior-app/authenticators/jwt", ["exports", "ember-simple-auth-token/authenticators/jwt"], function (_exports, _jwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Override original Authenticator, because `makeRequest` must use `host` from `ajax`-service used in CW
   *
   * See [jpadilla/ember-simple-auth-token](https://github.com/jpadilla/ember-simple-auth-token)
   *
   * @class Jwt
   * @namespace Authenticators
   * @extends EmberSimpleAuthToken.Authenticators.Jwt
   */
  var _default = _jwt.default.extend({
    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @method makeRequest
     * @param {string} url url for request
     * @param {object} data request data
     * @param {object} headers extra headers for request
     * @returns {RSVP.Promise} request itself
     */
    makeRequest(url, data, headers) {
      const host = Ember.get(this, 'ajax.host') || '';
      url = `${host}/${url}`;
      url = Ember.get(this, 'ajax').prepareUrl(url);
      return this._super(url, data, headers);
    }

  });

  _exports.default = _default;
});