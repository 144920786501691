define("campaign-warrior-app/controllers/portals/portal/setup/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SetupController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('currentPortal.portal.hubspotConnected'), _dec6 = Ember.computed('currentPortal.portal.gaConnected'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class SetupController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentPortal", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "torii", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);
    }

    get hubspotConnected() {
      let connected = this.currentPortal.portal.hubspotConnected;
      return connected;
    }

    get googleConnected() {
      return this.currentPortal.portal.googleConnected;
    }

    connectHubspot() {
      const ajax = Ember.get(this, 'ajax');

      const _this = this;

      const portalId = Ember.get(this, 'currentPortal.portal.id');
      Ember.get(this, 'torii').open('hubspot').then(function (data) {
        ajax.post('/hubspot/authorize', {
          data: {
            portalId,
            data
          }
        }).then(function (hubspotPortal) {
          //let domains = hubspotPortal.data.hubspotDomains.objects;
          _this.router.transitionTo('portals.portal.setup.hubspot'); // set(_this, 'domains', domains);

        });
      });
    }

    connectGoogle() {}

    connectWebsite() {
      const ajax = Ember.get(this, 'ajax');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "torii", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hubspotConnected", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "hubspotConnected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "googleConnected", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "googleConnected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connectHubspot", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "connectHubspot"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connectGoogle", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "connectGoogle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connectWebsite", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "connectWebsite"), _class.prototype)), _class));
  _exports.default = SetupController;
});