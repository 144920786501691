define("campaign-warrior-app/templates/portals/portal/users/user/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QuNkAQz0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-controls\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Manage User\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"control-group\"],[12],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row page-panels\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"title\",\"class\",\"expandable\",\"bodyComponent\"],[[30,[36,2],[\"users.manage.title\"],null],\"branding\",false,[30,[36,1],[\"users/manage-user-form\"],[[\"formData\"],[[35,0]]]]]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"t\",\"cw-panel\",\"-outlet\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/users/user/manage.hbs"
    }
  });

  _exports.default = _default;
});