define("campaign-warrior-app/components/notification-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    notifications: Ember.inject.service('toast'),
    socketIOService: Ember.inject.service('socket-io'),
    socket: null,
    notification: null,
    attemptedConnection: false,
    connectionFailures: 0,
    host: Ember.computed(function () {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      const buildMode = Ember.get(config, 'environment');
      return ['test'].includes(buildMode) ? Ember.get(config, 'testServer') : Ember.get(config, 'prodServer');
    }),

    didInsertElement() {
      this._super(...arguments);

      const self = this;
      const socket = this.socketIOService.socketFor(Ember.get(this, 'host'));
      Ember.set(this, 'socket', socket);
      socket.on('connect', this.beginAuth, this);
      socket.on('disconnect', function (data) {
        Ember.set(self, 'connectionFailures', Ember.get(self, 'connectionFailures') + 1);
      });
      socket.on('authorize', this.completeAuth, this);
      socket.on('notification', this.handleNotification, this);
      /*socket.on('join', function (data) {
      }, this);*/
    },

    willDestroyElement() {
      this._super(...arguments);

      const socket = Ember.get(this, 'socket');
      socket.off('connect', this.beginAuth);
      socket.off('notification', this.handleNotification);
      socket.off('authorize', this.completeAuth);
    },

    actions: {
      dismissNotification(notification) {
        Ember.set(notification, 'dismissed', true);
        Ember.set(this, 'notification', null);
      }

    },

    beginAuth() {
      if (Ember.get(this, 'connectionFailures') < 10) {
        const socket = Ember.get(this, 'socket');
        const sessionData = Ember.get(this, 'session.data');
        const token = sessionData.authenticated.token;
        socket.emit('authorize', token);
      }
    },

    completeAuth(data) {
      //const socket = get(this, 'socket');
      const socket = Ember.get(this, 'socket');
      const portalId = Ember.get(this, 'currentPortal.portal.id');

      if (portalId) {}
    },

    handleNotification(data) {
      // if (data.type == 'posted') {
      //   const notification = EmberObject.create(
      //     {
      //       message: data.message,
      //       type: data.type,
      //       dismissed: false
      //     }
      //   );
      //   set(this, 'notification', notification);
      // }
      const options = {
        'closeButton': true,
        'debug': false,
        'newestOnTop': true,
        'progressBar': false,
        'positionClass': 'toast-top-right',
        'preventDuplicates': false,
        'showDuration': '300',
        'hideDuration': '1000',
        'timeOut': '5000',
        'extendedTimeOut': '1000',
        'showEasing': 'swing',
        'hideEasing': 'linear',
        'showMethod': 'fadeIn',
        'hideMethod': 'fadeOut'
      };

      if (data.type) {
        switch (data.type) {
          case 'sitemapError':
            {
              this.displaySitemapErrorModal(data);
              break;
            }

          default:
            {
              this.notifications.info(JSON.stringify(data.message));
            }
        }
      }
      /*
      const notification = EmberObject.create(
        {
          message: data.message,
          type: data.type,
          dismissed: false
        }
      );
      set(this, 'notification', notification);
      */

    },

    async displaySitemapErrorModal(data) {
      const modalsManager = Ember.get(this, 'modalsManager');
      const portal = Ember.get(this, 'currentPortal.portal');
      await Ember.set(portal, 'errorStore', null);

      try {
        modalsManager.show('domains/sitemap-error-modal').then(async () => {
          window.open('https://www.campaignwarrior.com/faq/sitemap-error');
        }).catch(async err => {
          console.error('Error handling errors from the Modal', err);
        });
      } catch (err) {
        console.error('Error handling errors from the portal errorStore', err);
      }
    }

  });

  _exports.default = _default;
});