define("campaign-warrior-app/templates/components/pages/page-domain-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lI8Z80RR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-url\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"short-url\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"domainUrl\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/page-domain-url.hbs"
    }
  });

  _exports.default = _default;
});