define("campaign-warrior-app/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ET+FqJNh",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"portals\"],[[35,0]]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container-internal-wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"internal-content\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"loading-spinner\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"loading-icon\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fad fa-spinner fa-spin\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"portals\",\"top-nav\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});