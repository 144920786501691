define("campaign-warrior-app/components/locked-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class LockedFeature
   * @extends Ember.Component
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    classNames: ['locked-feature'],
    heading: null,
    description: null
  });

  _exports.default = _default;
});