define("campaign-warrior-app/templates/components/manage-related-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dfEHmz77",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[10,\"div\"],[14,0,\"selected-tags\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"tags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"tag\",\"removable\",\"selfRemove\"],[[32,1],true,[30,[36,0],[[32,0],\"removeTag\"],null]]]]],[2,\" \\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[1,[30,[36,6],null,[[\"page\",\"tagsList\"],[[35,2],[35,5]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"cw-tag\",\"record\",\"-track-array\",\"each\",\"tags\",\"cw-table/add-tag-dropdown\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/manage-related-tags.hbs"
    }
  });

  _exports.default = _default;
});