define("campaign-warrior-app/templates/components/manage-hashtags-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DXE7bgXs",
    "block": "{\"symbols\":[\"form\",\"&default\"],\"statements\":[[6,[37,9],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,1],true,[30,[36,8],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[1,[30,[36,5],[[30,[36,4],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/manage-hashtags-form.hbs' @ L4:C8) \"],null]],[[\"property\",\"label\"],[\"name\",\"Tag Name\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"btn-group\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"class\",\"defaultText\",\"type\"],[\"btn btn-primary\",[30,[36,2],[[35,1,[\"isNew\"]],[30,[36,0],[\"hashtags.create.form.create\"],null],[30,[36,0],[\"hashtags.create.form.edit\"],null]],null],\"primary\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"t\",\"record\",\"if\",\"bs-button\",\"-assert-implicit-component-helper-argument\",\"component\",\"hideControls\",\"unless\",\"action\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/manage-hashtags-form.hbs"
    }
  });

  _exports.default = _default;
});