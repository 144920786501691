define("campaign-warrior-app/templates/components/tags/create-tag-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+FvAhGVz",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[6,[37,6],null,[[\"open\",\"title\",\"onSubmit\",\"onHide\"],[[35,5],\"Create Tag\",[30,[36,1],[[32,0],\"confirm\"],null],[30,[36,1],[[32,0],\"decline\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('campaign-warrior-app/templates/components/tags/create-tag-modal.hbs' @ L7:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h4\"],[12],[2,\"Create a new tag\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('campaign-warrior-app/templates/components/tags/create-tag-modal.hbs' @ L11:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"record\",\"hideControls\",\"save\"],[[32,0],true,[30,[36,1],[[32,0],\"confirm\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[[32,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('campaign-warrior-app/templates/components/tags/create-tag-modal.hbs' @ L14:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"bs-button\",[],[[\"@type\",\"@disabled\",\"@onClick\"],[\"accept\",[30,[36,0],[[32,0,[\"name\"]],false,true],null],[30,[36,1],[[32,0],[32,1,[\"submit\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"Create\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"manage-tag-form\",\"-assert-implicit-component-helper-argument\",\"component\",\"modalIsOpened\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/tags/create-tag-modal.hbs"
    }
  });

  _exports.default = _default;
});