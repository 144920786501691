define("campaign-warrior-app/helpers/is-none", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNone = isNone;
  _exports.default = void 0;

  /**
   * Check if provided value is `null` or `undefined`. Works same as `Ember.isNone`
   *
   * @namespace Helpers
   * @class IsNone
   *
   * @param {*} val value to check
   * @returns {boolean} `true` - if value is `null` or `undefined`, false - otherwise
   */
  function isNone([val]) {
    return Ember.isNone(val);
  }

  var _default = Ember.Helper.helper(isNone);

  _exports.default = _default;
});