define("campaign-warrior-app/controllers/invite/confirmation", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class InviteConfirmation
   * @namespace Controllers
   * @extends EmberController
   */
  var _default = Ember.Controller.extend({
    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property queryParams
     * @type (string|object)[]
     * @default ['token']
     */
    queryParams: ['token'],

    /**
     * @property token
     * @type string
     * @default null
     */
    token: null,
    actions: {
      /**
       * @method actions.confirmInvite
       * @param {object} formData confirmation-form data
       * @return {RSVP.Promise} request result
       */
      confirmInvite(formData) {
        const data = this.prepareFormData(formData); //console.log('Confirm invite', formData);

        return Ember.get(this, 'ajax').post('/invite/confirmation', {
          data
        }).then(() => Ember.get(this, 'router').transitionTo('login')).catch(e => Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e));
      }

    },

    /**
     * @method prepareFormData
     * @param {object} formData data from confirmation-form
     * @returns {object} mapped data
     * @private
     */
    prepareFormData(formData) {
      const data = {
        token: Ember.get(formData, 'token'),
        userFields: _jquery.default.extend({}, Ember.get(formData, 'userFields'))
      };
      delete data.userFields.confirm_password;
      data.userFields.country = data.userFields.country.name;
      return data;
    }

  });

  _exports.default = _default;
});