define("campaign-warrior-app/controllers/portals/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PortalsIndex
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property session
     * @type Ember.Service
     */
    session: Ember.inject.service(),
    actions: {
      /**
       * @method actions.logout
       */
      logout() {
        Ember.get(this, 'session').invalidate();
      }

    }
  });

  _exports.default = _default;
});