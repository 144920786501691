define("campaign-warrior-app/components/reset-password-form", ["exports", "campaign-warrior-app/components/floating-form", "ember-cp-validations"], function (_exports, _floatingForm, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{reset-password-form onSubmit=(action "resetPassword")}}
   * ```
   *
   * @class ResetPasswordForm
   * @namespace Components
   * @extends Components.FloatingForm
   */
  var _default = _floatingForm.default.extend({
    classNames: ['reset-password-form'],

    /**
     * @event onSubmit
     */
    onSubmit: null,

    /**
     * @property formData
     * @type object
     * @private
     */
    submitInProgress: false,
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
          allowBlank: true,
          type: 'email'
        })]
      });
      return Ember.Object.extend(Validations, {}).create(Ember.getOwner(this).ownerInjection());
    })
  });

  _exports.default = _default;
});