define("campaign-warrior-app/templates/components/cw-table/delete-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OpZss7w0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[34,1]],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[15,0,[31,[\"far fa-fw \",[34,0]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"btnIcon\",\"btnText\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/delete-record.hbs"
    }
  });

  _exports.default = _default;
});