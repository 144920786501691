define("campaign-warrior-app/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WVsPXuZV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"text-center loading-spinner\"],[12],[10,\"i\"],[15,0,[31,[\"fad fa-spinner fa-spin fa-\",[34,0],\" fa-fw\"]]],[12],[13],[13]],\"hasEval\":false,\"upvars\":[\"size\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});