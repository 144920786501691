define("campaign-warrior-app/helpers/permission-type-humanized", ["exports", "campaign-warrior-app/helpers/titleize"], function (_exports, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.permissionTypeHumanized = permissionTypeHumanized;
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{permission-type-humanized permissionName}}
   * ```
   *
   * Input - `grant_view_portal`
   *
   * Output - `Grant View Portal`
   *
   * @namespace Helpers
   * @class PermissionTypeHumanized
   *
   * @param {string} permissionType permission type
   * @returns {string} formatted value
   */
  function permissionTypeHumanized([permissionType]
  /*, hash*/
  ) {
    return (0, _titleize.titleize)([Ember.String.dasherize(permissionType)]).replace(/-/g, ' ');
  }

  var _default = Ember.Helper.helper(permissionTypeHumanized);

  _exports.default = _default;
});