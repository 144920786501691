define("campaign-warrior-app/routes/account", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Account
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),
    title: 'Account | Campaign Warrior',
    billingInfo: null,
    rootElementClasses: Object.freeze(['account-page']),
    isTrial: Ember.computed('currentUser.company.status', function () {
      return this.currentUser.company.status === 'trial';
    }),

    model() {
      return this.store.findAll('portal');
    },

    afterModel() {
      const store = Ember.get(this, 'store');
      const currentUser = Ember.get(this, 'currentUser');
      return store.findRecord('company', Ember.get(currentUser, 'user.company.id')).then(company => store.queryRecord('billing-info', {
        id: Ember.get(company, 'billingInfo.id'),
        update: true
      })).then(billingInfo => {
        Ember.set(this, 'billingInfo', billingInfo);
        store.findAll('portal');
      });
    },

    setupController(controller) {
      if (Ember.get(this, 'can').cannot('edit billing')) {
        return this.transitionTo('portals');
      }

      Ember.set(controller, 'portals', Ember.get(this, 'store').peekAll('portal'));
      Ember.set(controller, 'billingDetails', Ember.get(this, 'billingInfo'));

      this._super(...arguments);
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(controller, 'requestInProgress', false);
      }
    },

    actions: {
      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});