define("campaign-warrior-app/templates/components/cw-table/related-model-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZGhWAErx",
    "block": "{\"symbols\":[\"instance\"],\"statements\":[[6,[37,6],[[35,5]],[[\"allowClear\",\"options\",\"search\",\"searchField\",\"selected\",\"renderInPlace\",\"onChange\"],[true,[35,4],[30,[36,2],[[32,0],\"searchInstances\"],null],[35,0],[35,3],true,[30,[36,2],[[32,0],\"updateColumnFilter\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[32,1],[35,0]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"searchField\",\"get\",\"action\",\"selectedInstance\",\"instances\",\"componentToRender\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/related-model-filter.hbs"
    }
  });

  _exports.default = _default;
});