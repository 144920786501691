define("campaign-warrior-app/templates/components/pages/pages-funnel-adder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dhwRWzwt",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[6,[37,5],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,4],true,[30,[36,2],[[32,0],\"onSubmit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"apply-tags-section funnel-stage-mass\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"select-with-apply\"],[12],[2,\"\\n      \"],[8,\"material/select\",[],[[\"@label\",\"@property\",\"@selected\",\"@options\"],[\"Funnel Stage\",[34,0],[34,0],[34,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"apply-buttons\"],[12],[2,\"\\n      \"],[8,\"bs-button\",[[16,\"disabled\",[34,3]]],[[\"@class\",\"@onClick\"],[\"btn btn-primary-outline\",[30,[36,2],[[32,0],\"openClusterModal\",[35,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Apply\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\\n  \"],[13],[2,\"\\n  \"],[10,\"input\"],[14,\"hidden\",\"\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"applyFunnelSelection\",\"funnelStagesAdder\",\"action\",\"makeDisable\",\"tagData\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/pages-funnel-adder.hbs"
    }
  });

  _exports.default = _default;
});