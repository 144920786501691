define("campaign-warrior-app/components/pages/search-pages-form", ["exports", "jquery", "campaign-warrior-app/models/tag"], function (_exports, _jquery, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{pages/search-pages-form
   *   tags=tags
  
   *   metricNameToFilter=metricNameToFilter
   *   filterByMetric=(action "filterByMetric")
   *   updateFilters=(action "updateFilters")
   * }}
   * ```
   *
   * @class SearchPagesForm
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['search-pages-form'],

    /**
     * @property tags
     * @type Models.Tag[]
     * @default null
     */
    tags: null,
    domain: 'any',

    /**
     * @property pagesUrls
     * @type string[]
     * @default []
     */
    pagesUrls: null,

    /**
     * @property domainUrls
     * @type string[]
     * @default []
     */
    domainsUrls: null,

    /**
     * @property metricNameToFilter
     * @type string
     * @default null
     */
    metricNameToFilter: null,

    /**
     * @event filterByMetric
     */
    filterByMetric: null,

    /**
     * @event updateFilters
     */
    updateFilters: null,

    /**
     * @event metaSize
     */
    metaSize: null,
    triggerUpdate: null,
    funnelStages: ['Any', 'TOFU', 'TOFU_MOFU', 'MOFU', 'MOFU_BOFU', 'BOFU'],

    /**
     * @property portal
     * @type Models.Portal
     * @default null
     */
    portal: null,
    applyTagsSelection: null,
    sliderValue: Ember.computed('metaSize', function () {
      const filters = JSON.parse(window.localStorage.getItem('filters'));

      if (filters) {
        const filterMetaSize = filters.metaSize;

        if (filterMetaSize && filterMetaSize.length) {
          return filterMetaSize;
        }
      }

      const arr = [0];
      arr.push(this.metaSize);
      return arr;
    }),
    domainsList: Ember.computed('domainsUrls', function () {
      const domains = [{
        url: 'Any',
        id: 'any'
      }];
      this.domainsUrls.map(domain => {
        domains.push(domain);
      });
      return domains;
    }),
    getSelectedDomain: Ember.computed('domainsUrls', 'formData.domain', function () {
      const selectedDomain = this.domainsUrls.filter(domain => domain.id === this.formData.domain || domain === this.formData.domain);
      return selectedDomain[0] ? selectedDomain[0] : {
        url: 'Any',
        id: 'any'
      };
    }),
    isTags: Ember.computed('formData.tagsList.length', function () {
      return this.formData.tagsList.length > 0 ? false : true;
    }),
    isOmits: Ember.computed('formData.omitTags.length', function () {
      return this.formData.omitTags.length > 0 ? false : true;
    }),
    selectedTags: Ember.computed('tags', 'formData.tagsList', function () {
      const selectedTagsvalue = this.tags.filter(tag => {
        return this.formData.tagsList.filter(newt => tag.id === newt || tag.id === newt.id).length > 0;
      });
      Ember.set(this, 'formData.tagsList', selectedTagsvalue);
      return selectedTagsvalue;
    }),
    selectedOmits: Ember.computed('tags', 'formData.omitTags', function () {
      const selectedTagsvalue = this.tags.filter(tag => {
        return this.formData.omitTags.filter(newt => tag.id === newt || tag.id === newt.id).length > 0;
      });
      Ember.set(this, 'formData.omitTags', selectedTagsvalue);
      return selectedTagsvalue;
    }),
    didInsertElement: Ember.computed('registerComponent', function () {
      return this.registerComponent(this);
    }),
    displayTags: Ember.computed('tags', function () {
      return this.tags;
    }),

    willDestroyElement() {
      // this._super(...argument);
      this.deregisterComponent();
    },

    actions: {
      /**
       * @method actions.onSubmit
       */
      onSubmit() {
        const dataToSend = Ember.getProperties(this.formData, ['domain', 'tagsList', 'omitTags', 'title', 'url', 'meta', 'metaSize', 'omitAllTags', 'setAllTags', 'funnelStage']);
        Ember.set(dataToSend, 'id', this.portal.id);
        Ember.set(dataToSend, 'auditMeta', false);
        Ember.set(dataToSend, 'auditOverMeta', false);
        this.updateFilters(dataToSend);
      },

      changed(e) {
        Ember.setProperties(this.formData, {
          metaSize: e
        });
      },

      submitForm() {
        const filterForm = (0, _jquery.default)('form');
        (0, _jquery.default)(filterForm).submit();
      },

      applyFilters() {
        /*console.log("Form Data", this.formData);
          const dataToSend = getProperties(this.formData, ['name', 'name_enabled', 'tags', 'tags_enabled', 'omitTags', 'omitTags_enabled', 'domain', 'domain_enabled', 'urls', 'urls_enabled', 'meta', 'meta_enabled', 'metaSizeFrom', 'metaSizeTo', 'metaSize_enabled'])
        let filters = {
          name: this.formData.name,
          name_enabled: this.formData.name_enabled
        }*/
        this.triggerUpdate();
      },

      saveFilters() {},

      loadFilters() {},

      /**
       * @method actions.resetForm
       * @param {object} ref to reset form
       */
      clearFilters() {
        // window.localStorage.removeItem('filters');
        Ember.setProperties(this.formData, {
          title: '',
          domain: '',
          url: '',
          meta: '',
          metaSizeFrom: null,
          metaSizeTo: null,
          funnelStage: 'Any',
          tagsList: [],
          omitTags: [],
          setAllTags: false,
          omitAllTags: false
        }); // this.send('onSubmit(false)');

        const filters = {
          domain: '',
          meta: '',
          title: '',
          url: '',
          id: '',
          metaSizeFrom: null,
          metaSizeTo: null,
          auditMeta: false,
          auditOverMeta: false,
          funnelStage: 'Any',
          tagsList: [],
          omitTags: [],
          setAllTags: false,
          omitAllTags: false
        };
        this.updateFilters(filters);
        const filterByMetric = this.filterByMetric;

        if (filterByMetric) {
          filterByMetric('');
        }

        this.triggerUpdate(true);
      },

      clearTagFilters() {// console.log('Clear tag filters');
      },

      /**
       * @method addTagsForFilteredPages
       */
      addTagsForFilteredPages() {
        Ember.get(this, 'addTagsForFilteredPages')();
      },

      toggleAllTags() {
        const allTags = Ember.getProperties(Ember.get(this, 'formData'), ['setAllTags']);

        if (allTags && allTags.setAllTags) {
          Ember.setProperties(this.formData, {
            setAllTags: false,
            tags: []
          });
        } else {
          Ember.setProperties(this.formData, {
            setAllTags: true,
            tags: this.tags
          });
        }
      },

      /**
       * @method toggleOmitTags
       */
      toggleOmitTags() {
        const omitTags = Ember.getProperties(this.formData, ['omitAllTags']); //console.log('omittags: ', omitTags);

        if (omitTags && omitTags.omitAllTags) {
          Ember.setProperties(this.formData, {
            omitAllTags: false,
            omitTags: []
          });
        } else {
          Ember.setProperties(this.formData, {
            omitAllTags: true,
            omitTags: this.tags
          });
        }
      }

    }
  });

  _exports.default = _default;
});