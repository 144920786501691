define("campaign-warrior-app/components/create-portal/step2-form", ["exports", "ember-cp-validations", "campaign-warrior-app/components/floating-form", "jquery"], function (_exports, _emberCpValidations, _floatingForm, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for second step in the Create-a-Portal wizard. It contains field for portal's `url`.
   *
   * Validation is used for `url`. It's required and should be not greater than 25 symbols
   *
   * `initialData`-property can be used to set initial form values
   *
   * Usage
   *
   * ```hbs
   * {{create-portal/step2-form initialData=initialData onSubmit=(action "onSubmit")}}
   * ```
   *
   * @namespace Components.CreatePortal
   * @class Step2Form
   * @extends Components.FloatingForm
   */
  var _default = _floatingForm.default.extend({
    classNames: ['create-portal-step2-form'],
    submitInProgress: false,
    ajax: Ember.inject.service(),
    torii: Ember.inject.service(),
    currentPortal: Ember.inject.service(),

    /**
     * Button `Scrape` is disabled if `url` value is not valid or is blank
     *
     * @property scrapingDisabled
     * @type boolean
     * @default false
     * @private
     * @readonly
     */
    scrapingDisabled: Ember.computed('formData.{url,formData.validations.attrs.url.isValid}', function () {
      return !Ember.get(this, 'formData.validations.attrs.url.isValid') || !Ember.get(this, 'formData.url');
    }).readOnly(),
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        url: [(0, _emberCpValidations.validator)('format', {
          type: 'url',
          allowBlank: true
        })]
      });
      return Ember.Object.extend(Validations, {}).create(Ember.getOwner(this).ownerInjection());
    }),

    /**
     * Try to focus on input
     *
     * @method focusOnInput
     */
    focusOnInput: Ember.observer('gotFocus', function () {
      if (Ember.get(this, 'gotFocus')) {
        Ember.run.later(() => (0, _jquery.default)('input[type=text]').focus(), 50);
      }
    }),
    actions: {
      onSubmit(form) {
        //console.log('submiting form: ', form);
        const dataToSend = Ember.getProperties(Ember.get(this, 'formData'), ['domain', 'description', 'name']); //console.log('On Submit Handler', dataToSend);

        Ember.get(this, 'onSubmit')(dataToSend);
        Ember.set(this, 'submitInProgress', true);
      },

      connectHubspot() {
        const i18n = Ember.get(this, 'i18n');
        const ajax = Ember.get(this, 'ajax');

        const _this = this;

        const portalId = Ember.get(this, 'model.id');
        const integrationId = Ember.get(this, 'hubspot.id');
        Ember.get(this, 'torii').open('hubspot').then(function (data) {
          ajax.post('/hubspot/authorize', {
            data: {
              portalId,
              data,
              integrationId
            }
          }).then(function (hubspotPortal) {});
        });
      }

    }
  });

  _exports.default = _default;
});