define("campaign-warrior-app/authenticators/torii", ["exports", "ember-simple-auth/authenticators/torii"], function (_exports, _torii) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Override original Authenticator to add Facebook, Twitter, LinkedIn and Google Plus login support
   *
   * See [simplabs/ember-simple-auth](https://github.com/simplabs/ember-simple-auth)
   *
   * @class Torii
   * @namespace Authenticators
   * @extends EmberSimpleAuth.Authenticators.Torii
   */
  var _default = _torii.default.extend({
    /**
     * @property torii
     * @type Ember.Service
     */
    torii: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @method authenticate
     * @param {string} type auth type (facebook, twitter etc)
     * @param {object} opts auth options
     * @return {RSVP.Promise} auth result
     */
    authenticate(type, opts) {
      const ajax = Ember.get(this, 'ajax');

      if (type === 'facebook') {
        return this._super(...arguments).then(data => ajax.post('/facebook/login', {
          data: {
            userToken: data.authorizationCode
          }
        }).then(response => ({
          token: response.token,
          provider: data.provider
        })));
      }

      if (type === 'twitter') {
        const _super = this._super;
        return ajax.post('/twitter/request_token').then(options => {
          return _super.call(this, type, options).then(data => ajax.post('/twitter/access_token', {
            data: {
              verifier: data.oauth_verifier,
              oauthToken: options.oauthToken,
              oauthTokenSecret: options.oauthTokenSecret
            }
          }).then(opts.clb));
        });
      }

      if (type === 'google') {
        return this._super(...arguments).then(args => ajax.post('/google/access_token', {
          data: {
            code: args.authorizationCode
          }
        }).then(opts.clb));
      }

      if (type === 'linkedin') {
        return this._super(...arguments).then(args => ajax.post('/linkedin/access_token', {
          data: {
            data: {
              attributes: {
                code: args.authorizationCode
              }
            }
          }
        }).then(opts.clb));
      }
    }

  });

  _exports.default = _default;
});