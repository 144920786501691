define("campaign-warrior-app/components/cron-input", ["exports", "campaign-warrior-app/utils/number"], function (_exports, _number) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{cron-input handleCronValue=(action "handleCronValue")}}
   * ```
   *
   * @class CronInput
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['cron-input'],

    /**
     * @property selectedTopUnit
     * @type string
     * @default "days"
     * @private
     */
    selectedTopUnit: 'day',

    /**
     * @event handleCronValue
     */
    handleCronValue: null,

    /**
     * @property hoursOptions
     * @type string[]
     * @private
     * @readonly
     */
    hoursOptions: (0, _number.nmbrs)(24),

    /**
     * @property minutesOptions
     * @type string[]
     * @private
     * @readonly
     */
    minutesOptions: (0, _number.nmbrs)(60),

    /**
     * @property week
     * @type boolean
     * @default false
     * @private
     * @readonly
     */
    week: Ember.computed.equal('selectedTopUnit', 'week'),

    /**
     * @property day
     * @type boolean
     * @default true
     * @private
     * @readonly
     */
    day: Ember.computed.equal('selectedTopUnit', 'day'),

    /**
     * @property hour
     * @type boolean
     * @default false
     * @private
     * @readonly
     */
    hour: Ember.computed.equal('selectedTopUnit', 'hour'),

    /**
     * @property units
     * @type string[]
     * @private
     * @readonly
     */
    units: Ember.computed(function () {
      return ['week', 'day', 'hour'];
    }).readOnly(),

    /**
     * @property daysOfWeek
     * @type object[]
     * @private
     * @readonly
     */
    daysOfWeek: Ember.computed(function () {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((d, index) => ({
        label: d,
        value: index
      }));
    }).readOnly(),

    /**
     * @property selected
     * @type object
     * @private
     */
    selected: Ember.computed(function () {
      return Ember.Object.extend({
        week: Ember.Object.extend({
          day: '0',
          hours: '00',
          minutes: '00',
          _day: '',
          val: Ember.computed('day', 'hours', 'minutes', function () {
            return `${Number(Ember.get(this, 'minutes'))} ${Number(Ember.get(this, 'hours'))} * * ${Number(Ember.get(this, 'day'))}`;
          })
        }).create(),
        day: Ember.Object.extend({
          hours: '00',
          minutes: '00',
          val: Ember.computed('hours', 'minutes', function () {
            return `${Number(Ember.get(this, 'minutes'))} ${Number(Ember.get(this, 'hours'))} * * *`;
          })
        }).create(),
        hour: Ember.Object.extend({
          minutes: '00',
          val: Ember.computed('minutes', function () {
            return `${Number(Ember.get(this, 'minutes'))} * * * *`;
          })
        }).create()
      }).create();
    }),

    /**
     * @method sendCronValue
     * @private
     */
    sendCronValue: Ember.observer('selected.{week,day,hour}.val', 'every', 'selectedTopUnit', function () {
      const selected = Ember.get(this, 'selected');
      const selectedTopUnit = Ember.get(this, 'selectedTopUnit');
      const d = Ember.get(selected, selectedTopUnit);
      this.send('sendCronValue', d ? Ember.get(d, 'val') : null);
    }),

    didInsertElement() {
      Ember.set(this, 'selected.week._day', Ember.get(this, 'daysOfWeek.firstObject'));
      this.sendCronValue();
    },

    actions: {
      setDayOfWeek(v) {
        Ember.set(this, 'selected.week.day', v.value);
        Ember.set(this, 'selected.week._day', v);
      },

      sendCronValue(cronValue) {
        const handleCronValue = Ember.get(this, 'handleCronValue');

        if (handleCronValue) {
          cronValue = cronValue.replace(/00/g, '0');
          handleCronValue(cronValue);
        }
      }

    }
  });

  _exports.default = _default;
});