define("campaign-warrior-app/components/select-portal-form", ["exports", "campaign-warrior-app/macros/filter-by-includes"], function (_exports, _filterByIncludes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * Base class for select-portal forms
   *
   * @namespace Components
   * @class SelectPortalForm
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ['select-portal-form'],

    /**
     * List of available portals for the current user
     *
     * @type object[]
     * @default null
     * @property portals
     */
    portals: null,

    /**
     * @private
     * @property filter
     * @default '',
     * @type string
     */
    filter: '',

    /**
     * Determines if portal names should be filtered ignoring case
     *
     * @property filteringIgnoreCase
     * @type boolean
     * @default true
     */
    filteringIgnoreCase: true,
    currentPage: 0,
    pageSize: 10,

    /**
     * @property filteredPortals
     * @type object[]
     * @default []
     * @private
     * @readonly
     */
    filteredPortals: (0, _filterByIncludes.default)('portals', 'originalName', 'filter', 'filteringIgnoreCase').readOnly(),
    portalsPage: Ember.computed('filteredPortals', 'currentPage', function () {
      return this.portalsPages[this.currentPage];
    }),
    portalsPages: Ember.computed('filteredPortals', function () {
      let pages = [];
      let filteredPortals = this.filteredPortals;
      var i,
          j,
          chunk = this.pageSize;

      for (i = 0, j = filteredPortals.length; i < j; i += chunk) {
        const portalsPage = filteredPortals.slice(i, i + chunk);

        if (portalsPage.length) {
          pages.push(portalsPage);
        }
      }

      return pages;
    }),
    totalPages: Ember.computed('filteredPortals', function () {
      return this.filteredPortals.length / this.pageSize;
    }),
    paginationList: Ember.computed('portalsPages', function () {
      let pages = [];

      for (let i = 0; i < this.portalsPages.length; i++) {
        pages.push({
          pageNo: i,
          displayNo: i + 1
        });
      }

      return pages;
    }),
    hasNextPage: Ember.computed('portalsPages', 'currentPage', function () {
      return this.currentPage < this.portalsPages.length - 1;
    }),
    hasPrevPage: Ember.computed('portalsPages', 'currentPage', function () {
      return this.currentPage > 0;
    }),
    nextPageLength: Ember.computed('portalsPages', 'currentPage', function () {
      const nextPageLength = this.portalsPages[this.currentPage + 1].length;
      return nextPageLength;
    }),
    actions: {
      /**
       * Click-handler for portals-list. Move user to the portal-details page
       *
       * @param {string} portalId portal id to move user to
       */
      goToPortal(portalId) {
        Ember.get(this, 'router').transitionTo('portals.portal', portalId);
      },

      setPage(pageNo) {
        Ember.set(this, 'currentPage', pageNo);
      },

      gotoNextPage() {
        Ember.set(this, 'currentPage', this.currentPage + 1);
      },

      gotoPrevPage() {
        Ember.set(this, 'currentPage', this.currentPage - 1);
      }

    }
  });

  _exports.default = _default;
});