define("campaign-warrior-app/components/notes/create-note-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    classNames: ['create-note-form'],
    users: null,
    usersList: Ember.computed(function () {
      const store = Ember.get(this, 'store');
      const portal = Ember.get(this, 'currentPortal.portal');
      const portal_id = Ember.get(portal, 'id');
      return store.query('user', {
        filter: {
          portal: portal_id
        }
      }).then(user => {
        // return user.filter(u => u !== get(this, 'currentUser.user'));
        return user;
      });
    }),
    actions: {
      save() {
        try {
          return Ember.get(this, 'save')(Ember.get(this, 'record'));
        } catch (err) {
          console.error(err);
        }
      }

    }
  });

  _exports.default = _default;
});