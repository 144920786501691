define("campaign-warrior-app/components/integrations/select-ga-account-modal", ["exports", "campaign-warrior-app/themes/bootstrap3", "campaign-warrior-app/components/modals-container/base"], function (_exports, _bootstrap, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    classNames: ['select-ga-account-modal'],

    /**
     * @property themeInstance
     * @type object
     */
    themeInstance: _bootstrap.default.create({
      table: 'table table-bordered'
    }),

    /**
     * @property columns
     * @type object[]
     * @default []
     */
    columns: Ember.computed(function () {
      return [];
    }),

    /**
     * @property notAddedSources
     * @type object[]
     * @default []
     * @private
     * @readonly
     */
    notAddedSources: Ember.computed('options.{newSources,existingSources}.[]', function () {
      const newSources = Ember.get(this, 'options.newSources') || []; //console.log('NEW SOURCES: ', newSources);

      return newSources; //return newSources.filter(s => !existingSourceIds.includes(s.id));
    }),

    /**
     * @property selectedSources
     * @type object[]
     * @default null
     */
    selectedSources: Ember.computed(function () {
      return [];
    }),
    actions: {
      /**
       * @method actions.toggleSource
       */
      tableHandler({
        selectedItems
      }) {
        Ember.set(this, 'selectedSources', selectedItems);
      },

      connectGaAccount(account) {
        //console.log('Selected Account', account);
        Ember.get(this, 'onConfirm')(account);
      },

      /**
       * @method actions.submit
       */
      confirm() {
        Ember.get(this, 'onConfirm')(Ember.get(this, 'selectedSources'));
      }

    }
  });

  _exports.default = _default;
});