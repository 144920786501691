define("campaign-warrior-app/instance-initializers/add-original-properties", ["exports", "ember-model-original-attributes/instance-initializers/add-original-properties"], function (_exports, _addOriginalProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _addOriginalProperties.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _addOriginalProperties.initialize;
    }
  });
});