define("campaign-warrior-app/templates/components/cw-table/moment-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yXRvTZkU",
    "block": "{\"symbols\":[\"value\",\"&default\"],\"statements\":[[2,\"\\n\"],[6,[37,13],[[30,[36,12],[[35,11],[35,10,[\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,8],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,1],[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,6],[[30,[36,5],[[30,[36,4],[[30,[36,3],[[32,0],\"toUnixTimestamp\"],null],[32,1]],null]],null]],[[\"interval\"],[1000]]]],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[6,[37,2],null,[[\"placement\"],[\"top\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[[32,1],[35,0]],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"format\",\"moment-format\",\"bs-tooltip\",\"action\",\"compute\",\"unix\",\"moment-from-now\",\"showTooltip\",\"if\",\"showAsDate\",\"column\",\"record\",\"get\",\"with\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/moment-date.hbs"
    }
  });

  _exports.default = _default;
});