define("campaign-warrior-app/mixins/searchable", ["exports", "campaign-warrior-app/utils/consts"], function (_exports, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for Components used as filter with ability to load filter options from the BE
   *
   * @class Searchable
   * @namespace Mixins
   */
  var _default = Ember.Mixin.create({
    /**
     * @property store
     * @type Ember.Service
     */
    store: Ember.inject.service(),

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),

    /**
     * @property instances
     * @type DS.Model[]
     * @default null
     * @private
     */
    instances: null,

    /**
     * @property selectedInstance
     * @type {DS.Model|DS.Model[]}
     * @private
     */
    selectedInstance: null,

    /**
     * @property searchDebounce
     * @type number
     * @default 250
     */
    searchDebounce: 250,

    /**
     * @property instanceClassName
     * @type string
     * @private
     */
    instanceClassName: null,

    /**
     * @property singleSelect
     * @type boolean
     * @private
     */
    singleSelect: null,

    /**
     * @property searchField
     * @type string
     * @private
     */
    searchField: null,

    /**
     * @property componentToRender
     * @type string
     * @private
     * @readonly
     */
    componentToRender: Ember.computed('singleSelect', function () {
      return Ember.get(this, 'singleSelect') ? 'power-select' : 'power-select-multiple';
    }).readOnly(),

    /**
     * @property options
     * @type array
     * @private
     * @readonly
     */
    options: Ember.computed('instances.[]', 'searchField', function () {
      const instances = Ember.get(this, 'instances');
      return Ember.isArray(instances) ? instances.mapBy(Ember.get(this, 'searchField')) : [];
    }).readOnly(),
    actions: {
      /**
       * @method actions.searchInstances
       * @param {string} userInput user input
       * @return {RSVP.Promise} request result
       */
      searchInstances(userInput) {
        const searchDebounce = Ember.get(this, 'searchDebounce') || 250;
        return new Ember.RSVP.Promise((resolve, reject) => Ember.run.debounce(this, this._performSearchInstances, userInput, resolve, reject, searchDebounce));
      }

    },

    /**
     * @param {string} userInput user input
     * @param {callback} resolve success-callback
     * @param {callback} reject error-callback
     * @private
     * @method _performSearchInstances
     */
    _performSearchInstances(userInput, resolve, reject) {
      const instances = Ember.get(this, 'instances');

      if (instances) {
        this._searchInstances(userInput, resolve, reject);
      } else {
        const query = {
          portal_id: Ember.get(this, 'currentPortal.portal.id'),
          ...(0, _consts.defaultPaginationSettings)()
        };

        this._searchInstances(userInput, resolve, reject, query);
      }
    },

    /**
     * @param {string} userInput user input
     * @param {callback} resolve success-callback
     * @param {callback} reject error-callback
     * @param {object} [q] custom query
     * @return {RSVP.Promise} request result
     * @method _searchInstances
     */
    _searchInstances(userInput, resolve, reject, q) {
      const searchField = Ember.get(this, 'searchField');
      const query = arguments.length === 4 ? q : Ember.get(this, 'instances.query');
      query.search = query.search || {};
      query.search[searchField] = userInput;
      return Ember.get(this, 'store').query(Ember.get(this, 'instanceClassName'), query).then(instances => {
        Ember.set(this, 'instances', instances);
        resolve(instances);
      }).catch(e => reject(e));
    }

  });

  _exports.default = _default;
});