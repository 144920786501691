define("campaign-warrior-app/validators/uniq-fs-item", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Validator for directory name. It checks that there is no item with the same name in the current level.
   *
   * Validator checks dirs or files depends on `type`-option.
   *
   * It's a custom validator for [ember-cp-validations](https://github.com/offirgolan/ember-cp-validations)
   *
   * Usage
   *
   * Check directory:
   *
   * ```js
   * buildValidations({
   *   name: validator('uniq-fs-item', {
   *     type: 'dir'
   *   })
   * });
   * ```
   *
   * Check file:
   *
   * ```js
   * buildValidations({
   *   name: validator('uniq-fs-item', {
   *     type: 'file'
   *   })
   * });
   *
   * @class UniqFsItem
   * @extends BaseValidator
   * @namespace Validators
   */
  var _default = _base.default.extend({
    i18n: Ember.inject.service(),

    validate(value, options, model) {
      const type = options.type === 'dir' ? 'dirs' : 'files';
      const key = `parent.${type}`;
      let parentItems = Ember.get(model, key);

      if (!Ember.isArray(parentItems)) {
        return true;
      }

      parentItems = parentItems.without(model);
      const i18nKeySuffix = options.type === 'dir' ? 'dir' : 'file';
      const descriptionKey = options.descriptionKey || `errors.uniqFsItem.${i18nKeySuffix}`;
      return parentItems.mapBy('name').includes(value) ? Ember.get(this, 'i18n').t(descriptionKey, {
        value
      }).toString() : true;
    }

  });

  _exports.default = _default;
});