define("campaign-warrior-app/templates/components/pages/panel-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kg67Kxz6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"Table-Header-Container\"],[12],[2,\"\\n\\n\\n  \"],[10,\"div\"],[14,0,\"titlePage\"],[12],[2,\"  \"],[10,\"h4\"],[14,0,\"panel-title\"],[12],[1,[34,0]],[13],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"foundPages\"],[12],[2,\"  Found Pages : \"],[10,\"span\"],[12],[1,[32,0,[\"meta\",\"total-records\"]]],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"title\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/panel-title.hbs"
    }
  });

  _exports.default = _default;
});