define("campaign-warrior-app/helpers/starts-with-one-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startsWith = startsWith;
  _exports.default = void 0;

  /**
   * Check if `source`-string starts with of `needed`-values
   *
   * Usage:
   *
   * ```hbs
   * {{#if (starts-with-one-of source needed)}}
   * {{/if}}
   * ```
   *
   * `source` - 'abc'
   * `needed` - 'cba abc'
   * `starts-with-one-of` - true
   *
   * @namespace Helpers
   * @class StartsWithOneOf
   *
   * @param {string} source source value
   * @param {string} needed string with separated by space needed values
   * @returns {boolean} true - if `source` starts with one of `needed`
   */
  function startsWith([source, needed]
  /*, hash*/
  ) {
    const s = String(source);
    return String(needed).split(' ').find(n => s.startsWith(String(n)));
  }

  var _default = Ember.Helper.helper(startsWith);

  _exports.default = _default;
});