define("campaign-warrior-app/components/modals/registration-error", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let RegistrationErrorModal = (_dec = Ember.computed('options'), _dec2 = Ember.computed('options.error.payload'), (_class = (_temp = class RegistrationErrorModal extends _base.default {
    constructor() {
      super();

      _defineProperty(this, "classNames", ['modals-branding', 'registration-error-modal']);
    }

    get error() {
      return this.options.error;
    }

    get message() {
      if (this.options.error.payload.message) {
        return this.options.error.payload.message;
      }

      return 'A registration error occurred, please try again';
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "error", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "message", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "message"), _class.prototype)), _class));
  _exports.default = RegistrationErrorModal;
});