define("campaign-warrior-app/components/domains/confirm-create-domain", ["exports", "campaign-warrior-app/components/modals-container/base", "ember-cp-validations"], function (_exports, _base, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ConfirmCreatePortal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    classNames: ['modals-branding', 'confirm-create-domain'],

    /**
     * @property formData
     * @type object
     * @private
     * @readonly
     */
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        confirmed: (0, _emberCpValidations.validator)('inclusion', {
          in: [true],
          message: ' '
        })
      });
      return Ember.Object.extend(Validations, {}).create(Ember.getOwner(this).ownerInjection());
    })
  });

  _exports.default = _default;
});