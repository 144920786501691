define("campaign-warrior-app/components/manage-user-form", ["exports", "campaign-warrior-app/components/manage-record-form", "campaign-warrior-app/data/countries"], function (_exports, _manageRecordForm, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ManageUserForm = (_dec = Ember.inject.service, _dec2 = Ember.computed('profilePic'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class ManageUserForm extends _manageRecordForm.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "file", null);

      _defineProperty(this, "classNames", ['manage-user-form']);

      _defineProperty(this, "save", null);

      _defineProperty(this, "countries", _countries.default);
    }

    /*
        rootDirs: computed(function () {
            return get(this, 'store').peekAll('dir').reject(d => get(d, 'parent.id'));
        }).readOnly(),*/
    get profilePicPreview() {
      if (this.record.picture) {
        return Ember.get(this, 'record.picture.preview');
      }
    }

    changeCountry(v) {
      Ember.set(this, 'record.country', v.name);
    }

    uploadFile(file) {
      Ember.set(this, 'file', file);
      return Ember.RSVP.resolve();
    }

    save() {
      console.log(this.record.country, "value");
      return this.save(this.record, this.file);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "profilePicPreview", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "profilePicPreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeCountry", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = ManageUserForm;
});