define("campaign-warrior-app/components/wizard-portal-setup", ["exports", "campaign-warrior-app/utils/number"], function (_exports, _number) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WizardPortalSetup
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    router: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['wizard-portal-setup'],
    wizardConfig: {
      steps: [{
        stepName: 'Set Your Portal Name',
        properties: [{
          ref: 'portal.name',
          type: 'text',
          label: 'Portal Name',
          required: true
        }]
      }, {
        stepName: 'Upload Your Portal Logo',
        properties: [{
          ref: 'portal.logo',
          type: 'logo-upload',
          label: 'Portal Logo',
          required: true
        }]
      }, {
        stepName: 'Enter your timezone',
        properties: [{
          ref: 'portal.timezone',
          type: 'timezone',
          label: 'Timezone',
          required: true
        }]
      }, {
        stepName: 'Set your posting blackout times',
        properties: [{
          ref: 'portal.noPostStart',
          type: 'time',
          label: 'From',
          required: false,
          prereqs: ['portal.noPostEnd']
        }, {
          ref: 'portal.noPostEnd',
          type: 'time',
          label: 'To',
          required: false,
          prereqs: ['portal.noPostStart']
        }]
      }, {
        stepName: 'Reporting Settings',
        properties: [{
          ref: 'portal.customerLogo',
          type: 'logo-upload',
          label: 'Customer Logo',
          required: false
        }, {
          ref: 'portal.agencyLogo',
          type: 'logo-upload',
          label: 'Agency Logo',
          required: false
        }]
      }]
    },

    /**
     * @property hoursOptions
     * @type string[]
     * @private
     * @readonly
     */
    hoursOptions: (0, _number.nmbrs)(24),

    /**
     * @property minutesOptions
     * @type string[]
     * @private
     * @readonly
     */
    minutesOptions: (0, _number.nmbrs)(60),
    activePortal: Ember.computed(function () {}),
    errorMessage: Ember.computed(function () {
      return null;
    }),
    totalSteps: Ember.computed(function () {
      return 4;
    }),
    currentStep: Ember.computed(function () {
      return 1;
    }),
    hasNextStep: Ember.computed(function () {
      return Ember.get(this, 'currentStep') < Ember.get(this, 'totalSteps');
    }),
    submitted: Ember.computed(function () {
      return false;
    }),
    formData: Ember.Object.extend({
      noPostStartMinutes: Ember.computed(function () {}),
      noPostEndHours: Ember.computed(function () {}),
      noPostEndMinutes: Ember.computed(function () {}),
      portal_name: Ember.computed(function () {
        return null;
      }),
      portal_timezone: Ember.computed(function () {
        return null;
      }),
      portal_logo: Ember.computed(function () {
        return Ember.get(this, 'currentPortal.portal.logo');
      }),
      portal_domain: Ember.computed(function () {
        return null;
      }),
      noPostStartHours: Ember.computed(function () {
        return null;
      })
    }).create(),
    formFilled: Ember.computed(function () {
      return false;
    }),
    completedSteps: Ember.computed(function () {
      return [];
    }),
    trackingId: Ember.computed(function () {
      return null;
    }),
    trackingCode: Ember.computed('trackingId', function () {
      return `<script>
    var p = document.createElement("img");
    p.src = "https://index.campaignwarrior.com/pixel.png?id=${Ember.get(this, 'trackingId')}";
    p.style.display = "none";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(p, s);
  </script>`;
    }),

    init() {
      //console.log('wizard-portal-setup init');
      const formData = Ember.get(this, 'formData'); //console.log('Has formData', formData);

      this._super(...arguments);
    },

    actions: {
      showStep(stepNum) {
        if (!Ember.get(this, 'submitted')) {
          if (stepNum < Ember.get(this, 'currentStep')) {
            Ember.set(this, 'formFilled', false);
            Ember.set(this, 'currentStep', stepNum);
          }
        }
      },

      nextStep() {
        const currentStep = Ember.get(this, 'currentStep');
        const totalSteps = Ember.get(this, 'totalSteps');

        if (currentStep < totalSteps) {
          //console.log('Has next step current step', currentStep);
          if (this.validateStepValues()) {
            Ember.set(this, 'errorMessage', null);
            Ember.set(this, 'currentStep', currentStep + 1);
          }
        } else {
          Ember.set(this, 'currentStep', totalSteps + 1);
          Ember.set(this, 'formFilled', true);
        }
      },

      uploadPortalLogo(file) {
        /*console.log("Upload portal logo", file.size);
        if (file.size > 1024) {
          console.log("File too large", file.size);
          set(this, 'formData.portal_logo', null);
        }*/
        //1849164
        return Ember.RSVP.resolve();
      },

      submitForm(data) {
        const submitted = Ember.get(this, 'submitted');

        if (submitted) {
          console.error("Form already submitted");
          return;
        }

        Ember.set(this, 'submitted', true);
        let fd = Ember.get(this, 'formData');
        const newPortalData = {
          name: Ember.get(fd, 'portal_name'),
          timezone: Ember.get(fd, 'portal_timezone'),
          logo: Ember.get(fd, 'portal_logo'),
          domain: Ember.get(fd, 'portal_domain'),
          noPostStartHours: Ember.get(fd, 'noPostStartHours'),
          noPostStartMinutes: Ember.get(fd, 'noPostStartMinutes'),
          noPostEndHours: Ember.get(fd, 'noPostEndHours'),
          noPostEndMinutes: Ember.get(fd, 'noPostEndMinutes'),
          creationData: {
            socialAccount: Ember.get(fd, 'social_account'),
            campaignName: Ember.get(fd, 'campaign_name')
          }
        };
        console.log("NewPortalData", newPortalData);
        const store = Ember.get(this, 'store');
        const router = Ember.get(this, 'router');
        store.createRecord('portal', newPortalData).save().then(portal => {
          Ember.set(this, 'activePortal', portal);
          Ember.get(this, 'currentUser').load().then(() => {
            store.createRecord('domain', {
              portal: Ember.get(this, 'activePortal'),
              url: Ember.get(this, 'formData.portal_domain')
            }).save().then(domain => {
              Ember.set(this, 'createdDomain', domain);
              Ember.set(this, 'trackingId', domain.trackingId); //router.transitionTo('portals')
            });
          });
        }).catch(err => {
          console.error("Caught error in portal form submission", err);
        });
      },

      onSubmit(formData) {
        console.log('wizard-portal-setup onSubmit()', formData);
      },

      changeStartHours(value) {
        Ember.set(this, 'startHours', value);
        Ember.set(this, 'formData.noPostStartHours', value);
      },

      changeStartMinutes(value) {
        Ember.set(this, 'startMinutes', value);
        Ember.set(this, 'formData.noPostStartMinutes', value);
      },

      changeEndHours(value) {
        Ember.set(this, 'endHours', value);
        Ember.set(this, 'formData.noPostEndHours', value);
      },

      changeEndMinutes(value) {
        Ember.set(this, 'endMinutes', value);
        Ember.set(this, 'formData.noPostEndMinutes', value);
      },

      cancelWizard(wizard) {
        console.log('Cancel Wizard', wizard);
      },

      showFbPages(pages) {
        console.log("Show FB Pages", pages);
      },

      getStarted() {
        Ember.set(this, 'finished', true);
      },

      copyTrackingCode() {
        const el = document.createElement('textarea');
        el.value = Ember.get(this, 'trackingCode');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }

    },

    validateStepValues() {
      const currentStep = Ember.get(this, 'currentStep');
      const formData = Ember.get(this, 'formData'); //console.log('formData', formData);

      Ember.set(this, 'errorMessage', null);

      switch (currentStep) {
        case 1:
          {
            //portal name
            const valid = Ember.get(formData, 'portal_name') && Ember.get(formData, 'portal_name').length; //console.log('Portal Name: ', get(formData, 'portal_name'));

            if (!valid) {
              console.error('Portal Name not valid');
              Ember.set(this, 'errorMessage', Ember.get(this, 'i18n').t('portals.create.wizard.setName.errorMessage'));
              return false;
            }

            return true;
          }

        case 2:
          {
            //domain
            const valid = Ember.get(formData, 'portal_domain') && Ember.get(formData, 'portal_domain').length;

            if (!valid) {
              Ember.set(this, 'errorMessage', Ember.get(this, 'i18n').t('portals.create.wizard.setDomain.errorMessage'));
              return false;
            }

            return true;
          }

        case 3:
          {
            //portal logo
            const valid = Ember.get(formData, 'portal_logo') && Ember.get(formData, 'portal_logo.src') && Ember.get(formData, 'portal_logo.src').length;

            if (!valid) {
              Ember.set(this, 'errorMessage', Ember.get(this, 'i18n').t('portals.create.wizard.setLogo.errorMessage'));
            }

            return true;
          }

        case 4:
          {
            //timezone
            return true; //timezone needs no validation, it should always have a value
          }

        case 5:
          {
            //campaign
            return true;
          }

        case 6:
          {
            //social account
            return true;
          }

        case 7:
          {
            //posting blackout times
            //may need to add validation for this later
            return true;
          }

        case 8:
          {
            return true;
          }

        default:
          {
            return true;
          }
      }
    },

    uploadfile(file, options, ajax) {
      //console.log('Upload File options', options);
      console.log("Try logo upload");
      return new Promise(function (resolve, reject) {
        if (file && file.size) {
          const fd = new FormData();
          fd.append('portalID', Ember.get(this, 'currentPortal.portal.id'));
          fd.append('file', file);

          try {
            console.log("Try upload logo");

            const process = () => ajax.post('media/upload', {
              data: fd,
              cache: false,
              processData: false,
              contentType: false
            }).catch(err => {
              console.error("Catch err", err);
            });

            process().then(res => {
              resolve(res.data.attributes.preview);
            }).catch(err => {
              reject(err);
            });
          } catch (err) {
            console.error("Caught upload error", err);
          }
        } else {
          resolve(file);
        }
      });
    },

    showFbPages(pages) {
      console.log("Wizard portal setup, show fbPages()", pages);
    }

  });

  _exports.default = _default;
});