define("campaign-warrior-app/templates/components/setup/setup-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z3yomoCv",
    "block": "{\"symbols\":[\"step\",\"index\"],\"statements\":[[10,\"div\"],[14,0,\"setup-steps\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[\"step \",[30,[36,0],[[32,1,[\"active\"]],\"active\"],null]]]],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"step-number\"],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"step-name\"],[12],[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"steps\",\"for\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/setup/setup-state.hbs"
    }
  });

  _exports.default = _default;
});