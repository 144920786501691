define("campaign-warrior-app/components/pages/related-content-settings", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let RelatedContentSettings = (_dec = Ember.computed('page.relatedContentSettings', 'defaultSettings'), _dec2 = Ember.computed('formData.layout'), _dec3 = Ember._action, (_class = (_temp = class RelatedContentSettings extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "defaultSettings", {
        funnelStage: 'any',
        sort: 'best',
        layout: 'grid',
        limit: 6,
        columns: 3,
        hideNoFeaturedImage: true,
        gridGap: '20px',
        tagExclusivity: 'AND'
      });

      _defineProperty(this, "valueRanges", {
        limit: {
          min: 1,
          max: 20
        },
        columns: {
          min: 1,
          max: 6
        }
      });

      _defineProperty(this, "funnelOptions", ['any', 'TOFU', 'TOFU_MOFU', 'MOFU', 'MOFU_BOFU', 'BOFU']);

      _defineProperty(this, "sortOptions", ['best', 'latest', 'popular', 'weight']);

      _defineProperty(this, "layoutOptions", ['grid', 'list']);

      _defineProperty(this, "tagExclusivityOptions", ['AND', 'OR']);

      _defineProperty(this, "isSubmitProgress", false);
    }

    // @computed('page.relatedContentSettings')
    // get formData(){
    //
    //   let pageTags = [];
    //
    //
    //   if (this.page.relatedContentSettings && this.page.relatedContentSettings.tags){
    //     let tagIds = this.page.relatedContentSettings.tags;
    //     let tagsList = this.tags.map((t)=>{ //this makes the ember array resolve its promise...
    //       return t;
    //     })
    //
    //     for (let t of tagsList){
    //       for (let tid of tagIds){
    //         if (tid === t.id){
    //           pageTags.push(t);
    //         }
    //       }
    //     }
    //   }
    //
    //
    //     return {
    //       funnelStage: this.page.relatedContentSettings.funnelStage || this.defaultSettings.funnelStage,
    //       sort: this.page.relatedContentSettings.sort || this.defaultSettings.sort,
    //       layout: this.page.relatedContentSettings.layout || this.defaultSettings.layout,
    //       limit: this.page.relatedContentSettings.limit || this.defaultSettings.limit,
    //       columns: this.page.relatedContentSettings.columns || this.defaultSettings.columns,
    //       hideNoFeaturedImage: this.page.relatedContentSettings.hideNoFeaturedImage || this.defaultSettings.hideNoFeaturedImage,
    //       gridGap: this.page.relatedContentSettings.gridGap || this.defaultSettings.gridGap,
    //       tagExclusivity: this.page.relatedContentSettings.tagExclusivity || this.defaultSettings.tagExclusivity,
    //       tags: pageTags
    //     }
    // }
    get formData() {
      const portal = Ember.get(this, 'page.relatedContentSettings');
      const defaultSetting = Ember.get(this, 'defaultSettings');
      let pageTags = [];

      if (this.page.relatedContentSettings && this.page.relatedContentSettings.tags) {
        let tagIds = this.page.relatedContentSettings.tags;
        let tagsList = this.tags.map(t => {
          //this makes the ember array resolve its promise...
          return t;
        });

        for (let t of tagsList) {
          for (let tid of tagIds) {
            if (tid === t.id) {
              pageTags.push(t);
            }
          }
        }
      }

      const Validations = (0, _emberCpValidations.buildValidations)({
        funnelStage: (0, _emberCpValidations.validator)('presence', true),
        sort: (0, _emberCpValidations.validator)('presence', true),
        layout: (0, _emberCpValidations.validator)('presence', true),
        limit: (0, _emberCpValidations.validator)('number', {
          allowString: true,
          integer: true,
          gt: 0,
          lte: 20
        }),
        columns: (0, _emberCpValidations.validator)('number', {
          allowString: true,
          integer: true,
          gt: 0,
          lte: 6
        }),
        hideNoFeaturedImage: (0, _emberCpValidations.validator)('presence', true),
        gridGap: (0, _emberCpValidations.validator)('presence', true),
        tagExclusivity: (0, _emberCpValidations.validator)('presence', true),
        tags: (0, _emberCpValidations.validator)('presence', true)
      });
      return Ember.Object.extend(Validations, {
        funnelStage: Ember.computed(function () {
          return Ember.get(portal, 'funnelStage') || Ember.get(defaultSetting, 'funnelStage');
        }),
        sort: Ember.computed(function () {
          return Ember.get(portal, 'sort') || Ember.get(defaultSetting, 'sort');
        }),
        layout: Ember.computed(function () {
          return Ember.get(portal, 'layout') || Ember.get(defaultSetting, 'layout');
        }),
        limit: Ember.computed(function () {
          return Ember.get(portal, 'limit') || Ember.get(defaultSetting, 'limit');
        }),
        columns: Ember.computed(function () {
          return Ember.get(portal, 'columns') || Ember.get(defaultSetting, 'columns');
        }),
        hideNoFeaturedImage: Ember.computed(function () {
          return Ember.get(portal, 'hideNoFeaturedImage') || Ember.get(defaultSetting, 'hideNoFeaturedImage');
        }),
        gridGap: Ember.computed(function () {
          return Ember.get(portal, 'gridGap') || Ember.get(defaultSetting, 'gridGap');
        }),
        tagExclusivity: Ember.computed(function () {
          return Ember.get(portal, 'tagExclusivity') || Ember.get(defaultSetting, 'tagExclusivity');
        }),
        tags: Ember.computed(function () {
          return pageTags;
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }

    get isGridLayout() {
      return this.formData.layout === 'grid';
    }

    saveRelatedContent() {
      Ember.set(this, "isSubmitProgress", true); // console.log("Related content settings form submitted", this.formData);

      let keys = Object.keys(this.formData);

      for (let k of keys) {
        if (k === 'tags') {
          this.page.relatedContentSettings.tags = this.formData['tags'].map(t => {
            return t.id;
          });
        } else {
          this.page.relatedContentSettings[k] = this.formData[k];
        }
      } // console.log("Related content settings", this.page.relatedContentSettings);


      const _this = this;

      this.page.save().then(function () {
        Ember.set(_this, "isSubmitProgress", false);
      });
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "formData", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "formData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isGridLayout", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isGridLayout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveRelatedContent", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "saveRelatedContent"), _class.prototype)), _class));
  _exports.default = RelatedContentSettings;
});