define("campaign-warrior-app/components/partner-register-form", ["exports", "campaign-warrior-app/components/floating-form", "ember-cp-validations", "campaign-warrior-app/data/countries", "campaign-warrior-app/data/states", "campaign-warrior-app/utils/object"], function (_exports, _floatingForm, _emberCpValidations, _countries, _states, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage
   *
   * ```hbs
   * {{register-form onSubmit=(action "registerHandler")}}
   * ```
   *
   * @namespace Components
   * @class RegisterForm
   * @extends Components.FloatingForm
   */
  var _default = _floatingForm.default.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),
    classNames: ['partner-register-form'],

    /**
     * @property countries
     * @private
     * @readonly
     * @type {{name: string, code: string}[]}
     */
    countries: _countries.default,
    states: _states.default,
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        first_name: (0, _emberCpValidations.validator)('presence', true),
        last_name: (0, _emberCpValidations.validator)('presence', true),
        username: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
          allowBlank: true,
          type: 'email'
        })],
        company: (0, _emberCpValidations.validator)('presence', true),
        address1: (0, _emberCpValidations.validator)('presence', true),
        postal_code: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
          allowString: true,
          integer: true
        })],
        'state.name': (0, _emberCpValidations.validator)('presence', true),
        city: (0, _emberCpValidations.validator)('presence', true),
        country: (0, _emberCpValidations.validator)('presence', true),
        timezone: (0, _emberCpValidations.validator)('presence', true),
        password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
          min: 5
        })],
        confirm_password: (0, _emberCpValidations.validator)('confirmation', {
          on: 'password'
        })
      });
      return Ember.Object.extend(Validations, {
        state: Ember.computed(function () {
          return {
            name: ''
          };
        }),
        couponCode: Ember.computed(function () {
          return '';
        }),
        showStatesAsDropdown: Ember.computed('country.code', function () {
          return Ember.get(this, 'country.code') in _states.default;
        }),
        userTimezone: Ember.computed(function () {
          return new Date();
        }),
        countryChanged: Ember.observer('country.code', function () {
          Ember.set(this, 'state', {
            name: ''
          });
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }),

    didInsertElement() {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (!window.recurly) {
        // just for tests
        return;
      }

      recurly.configure(config.recurly);
    },

    actions: {
      /**
       * @method actions.onSubmit
       */
      onSubmit() {
        //console.log('OnSubmit');
        const formData = (0, _object.dummyCopy)(Ember.get(this, 'formData'));
        formData.country = formData.country.name;

        if (formData.state.name) {
          formData.state = formData.state.name;
        } //formData.state = formData.state.name;
        //console.log('FormData', formData);


        Ember.get(this, 'onSubmit')(formData);
      },

      goToLogin() {
        Ember.get(this, 'router').transitionTo('login');
      }
      /*countryChanged(el){
        set(this, 'selectedCountry.name', el.name);
        set(this, 'selectedCountry.code', el.code);
        if (el.code in states) {
          set(this, 'showStatesAsDropdown', true);
        } else {
          set(this, 'showStatesAsDropdown', false);
        }
        set(this, 'record.user.company.billingInfo.state', '');
        set(this, 'record.user.company.billingInfo.country', el.code);
      },
       stateChanged(el){
        set(this, 'state.name', el.name);
        //set(this, 'record.user.company.billingInfo.state', el.name);
      }*/


    }
  });

  _exports.default = _default;
});