define("campaign-warrior-app/helpers/to-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toDate = toDate;
  _exports.default = void 0;

  /**
   * Converts provided value to Date
   *
   * @namespace Helpers
   * @class ToDate
   *
   * @param {*} dateStr value to be converted to Date
   * @returns {Date} date-object
   */
  function toDate([dateStr]
  /*, hash*/
  ) {
    return new Date(dateStr);
  }

  var _default = Ember.Helper.helper(toDate);

  _exports.default = _default;
});