define("campaign-warrior-app/templates/components/cw-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RdMasmi5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"panel\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n\"],[6,[37,2],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[35,9]],[[\"title\",\"expandable\",\"togglePanel\"],[[35,5],[35,8],[30,[36,3],[[32,0],\"togglePanel\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,6],[[35,5]],null]],[2,\"\\n\"],[6,[37,2],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[14,0,\"control-group\"],[14,5,\"display: unset;\"],[12],[2,\"\\n          \"],[8,\"bs-button\",[[24,0,\"btn-primary\"]],[[\"@onClick\"],[[30,[36,3],[[32,0],\"createNote\"],null]]],[[\"default\"],[{\"statements\":[[1,[34,4]]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,2],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"bodyComponent\",\"component\",\"if\",\"action\",\"submitDefaultText\",\"title\",\"titleize\",\"submitButton\",\"expandable\",\"titleComponent\",\"expanded\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-panel.hbs"
    }
  });

  _exports.default = _default;
});