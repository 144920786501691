define("campaign-warrior-app/templates/components/wizard-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "de8rGPrZ",
    "block": "{\"symbols\":[\"step\",\"step\"],\"statements\":[[10,\"div\"],[14,0,\"wizard-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"wizard \",[34,1,[\"wizardType\"]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"wizard-steps\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"steps\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"wizard-step\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"wizard-icon\"],[12],[2,\"\\n            \"],[1,[32,2,[\"icon\"]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"wizard-title\"],[12],[2,\"\\n            \"],[1,[32,2,[\"stepnum\"]]],[2,\". \"],[1,[32,2,[\"title\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"wizard-pages\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"steps\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],null,[[\"step\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"wizard-page\",\"wizard\",\"for\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/wizard-container.hbs"
    }
  });

  _exports.default = _default;
});