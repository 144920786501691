define("campaign-warrior-app/templates/portals/portal/users/invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1UEpx4pY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-controls\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Invite User\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"control-group\"],[12],[2,\"\\n    \"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-primary\",\"portals.portal.users.list\"]],[[\"default\"],[{\"statements\":[[2,\"User List\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row page-panels\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-6\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"title\",\"class\",\"expandable\",\"bodyComponent\"],[[30,[36,3],[\"users.invite.title\"],null],\"branding\",false,[30,[36,2],[\"users/invite-user-form\"],[[\"onSubmit\"],[[30,[36,1],[[32,0],\"inviteUser\"],null]]]]]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,5],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"action\",\"component\",\"t\",\"cw-panel\",\"-outlet\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/users/invite.hbs"
    }
  });

  _exports.default = _default;
});