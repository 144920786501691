define("campaign-warrior-app/components/pages/date-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DateCell = (_dec = Ember.computed('record.hsLastUpdated'), (_class = class DateCell extends Ember.Component {
    get parsedDate() {
      if (!this.record.hsLastUpdated) {
        return 'Never';
      }

      let date = new Date(this.record.hsLastUpdated);
      let isoString = date.toISOString();
      return isoString.substring(0, isoString.lastIndexOf('T'));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "parsedDate", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "parsedDate"), _class.prototype)), _class));
  _exports.default = DateCell;
});