define("campaign-warrior-app/models/hashtag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Hashtag
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    portal: _emberData.default.belongsTo('portal'),
    displayName: Ember.computed('name', 'type', function () {
      return `${this.type === 'hash' ? '#' : '@'}${this.name}`;
    })
  });

  _exports.default = _default;
});