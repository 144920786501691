define("campaign-warrior-app/templates/components/pages/pages-summary/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vk7xKUeK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"a\"],[24,6,\"#\"],[24,0,\"title\"],[4,[38,1],[[32,0],[35,0]],null],[12],[1,[34,2]],[13],[2,\"\\n\\n\"],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"togglePanel\",\"action\",\"title\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/pages-summary/title.hbs"
    }
  });

  _exports.default = _default;
});