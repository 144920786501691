define("campaign-warrior-app/templates/portals/portal/domains/add-impressions-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NSyezO27",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"extra-pay-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"inner-extra-block-slider\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"page-controls\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Buy Extra Impressions\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[3,\"    <div class=\\\"control-group\\\">\"],[2,\"\\n\"],[3,\"      <BsButton @type=\\\"submenu\\\" @onClick={{action \\\"refreshImpressions\\\"}}>\"],[2,\"\\n\"],[3,\"        Refresh Used Impressions\"],[2,\"\\n\"],[3,\"      </BsButton>\"],[2,\"\\n\"],[3,\"    </div>\"],[2,\"\\n    \"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"portal\",\"callback\"],[[32,1],[30,[36,0],[[32,0],\"callback\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"extra-pay-slider\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/domains/add-impressions-slider.hbs"
    }
  });

  _exports.default = _default;
});