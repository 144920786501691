define("campaign-warrior-app/components/pages/manage-page-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ManagePageModal
   * @namespace Components.Page
   * @extends EmberBootstrapModalsManager.Components.Base
   */
  var _default = _base.default.extend({
    classNames: ['manage-page-modal']
  });

  _exports.default = _default;
});