define("campaign-warrior-app/components/register-form", ["exports", "campaign-warrior-app/components/floating-form", "ember-cp-validations", "campaign-warrior-app/data/countries", "campaign-warrior-app/utils/object"], function (_exports, _floatingForm, _emberCpValidations, _countries, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage
   *
   * ```hbs
   * {{register-form onSubmit=(action "registerHandler")}}
   * ```
   *
   * @namespace Components
   * @class RegisterForm
   * @extends Components.FloatingForm
   */
  var _default = _floatingForm.default.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),
    classNames: ['register-form'],

    /**
     * @property countries
     * @private
     * @readonly
     * @type {{name: string, code: string}[]}
     */
    countries: _countries.default,
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        firstName: (0, _emberCpValidations.validator)('presence', true),
        lastName: (0, _emberCpValidations.validator)('presence', true),
        username: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
          allowBlank: true,
          type: 'email'
        })],
        company: (0, _emberCpValidations.validator)('presence', true),
        country: (0, _emberCpValidations.validator)('presence', true),
        //timezone: validator('presence', true),
        password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
          min: 5
        })],
        confirm_password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
          on: 'password'
        })]
      });
      return Ember.Object.extend(Validations, {}).create(Ember.getOwner(this).ownerInjection());
    }),

    /* userTimezone: computed(function () {
         return new Date();
     }),*/
    actions: {
      /**
       * @method actions.onSubmit
       */
      onSubmit() {
        const formData = (0, _object.dummyCopy)(Ember.get(this, 'formData'));
        formData.country = formData.country.name;
        Ember.get(this, 'onSubmit')(formData);
      },

      goToLogin() {
        Ember.get(this, 'router').transitionTo('login');
      }

    }
  });

  _exports.default = _default;
});