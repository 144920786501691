define("campaign-warrior-app/components/modals/accept-portal-invite-modal", ["exports", "campaign-warrior-app/components/modals-container/base", "ember-cp-validations"], function (_exports, _base, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    classNames: ['accept-portal-invite-modal']
  });

  _exports.default = _default;
});