define("campaign-warrior-app/components/modals/request-user-error-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class RequestErrorBody
   * @extends Ember.Component
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    /**
     * @property error
     * @type object
     * @default null
     * @private
     */
    error: Ember.computed.alias('options.error'),

    /**
     * @property msg
     * @type string
     * @default null
     * @private
     */
    msg: Ember.computed('error.payload.{reason,message}', function () {
      return Ember.get(this, 'error.payload.reason') || Ember.get(this, 'error.payload.userMessage') || Ember.get(this, 'error.message');
    }),
    detail: Ember.computed('error.payload.{reason,message}', function () {
      return Ember.get(this, 'error.payload.reason') || Ember.get(this, 'error.message');
    }),
    severity: Ember.computed('error.payload.severity', function () {
      return Ember.get(this, 'error.payload.severity');
    }),

    /**
     * @property payloadErrors
     * @type object
     * @readonly
     * @private
     */
    payloadErrors: Ember.computed('error.{payload.errors.[],errors.[]}', function () {
      const errors = Ember.get(this, 'error.payload.errors') || Ember.get(this, 'error.errors');

      if (errors) {
        return errors.map(e => {
          const eDetails = e.details || e.detail;
          let details;

          try {
            details = JSON.stringify(JSON.parse(eDetails), null, 2);
          } catch (error) {
            details = eDetails;
          }

          return details;
        });
      }

      return [Ember.get(this, 'error')];
    }).readOnly()
  });

  _exports.default = _default;
});