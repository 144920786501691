define("campaign-warrior-app/templates/components/modals/accept-portal-invite-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PWJVA6aX",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[6,[37,4],null,[[\"open\",\"title\",\"onSubmit\",\"onHide\"],[[35,3],\"Portal Invitation\",[30,[36,2],[[32,0],\"confirm\"],null],[30,[36,2],[[32,0],\"decline\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('campaign-warrior-app/templates/components/modals/accept-portal-invite-modal.hbs' @ L7:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h4\"],[12],[2,\"Portal Invitation\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[30,[36,0],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('campaign-warrior-app/templates/components/modals/accept-portal-invite-modal.hbs' @ L10:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"Accept portal invitation?\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[30,[36,0],[[32,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('campaign-warrior-app/templates/components/modals/accept-portal-invite-modal.hbs' @ L13:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[32,1,[\"close\"]],\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"No\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[32,1,[\"submit\"]],\"primary\"]],[[\"default\"],[{\"statements\":[[2,\"Yes\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"action\",\"modalIsOpened\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/accept-portal-invite-modal.hbs"
    }
  });

  _exports.default = _default;
});