define("campaign-warrior-app/templates/components/domains/sitemap-error-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WunbcMBQ",
    "block": "{\"symbols\":[\"modal\",\"form\",\"&default\"],\"statements\":[[6,[37,6],null,[[\"backdropClose\",\"keyboard\",\"open\",\"onSubmit\",\"onHide\",\"class\"],[false,false,[35,5],[30,[36,1],[[32,0],\"confirm\"],null],[30,[36,1],[[32,0],\"decline\"],null],\"modals-branding sitemap-error\"]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('campaign-warrior-app/templates/components/domains/sitemap-error-modal.hbs' @ L3:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"onSubmit\"],[[30,[36,1],[[32,0],\"confirm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"modal-content-wrapper\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"icon-wrapper\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fad fa-traffic-cone\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"content-wrapper\"],[12],[2,\"\\n          \"],[10,\"h2\"],[12],[2,\"Oh no! Your sitemap appears to be broken.\"],[13],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"desc\"],[12],[2,\"\\n\\n          \"],[13],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"modal-controls\"],[12],[2,\"\\n            \"],[8,\"bs-button\",[[24,0,\"btn-default\"]],[[\"@onClick\"],[[30,[36,1],[[32,0],[35,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"Decline\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[8,\"bs-button\",[[24,0,\"btn-primary\"]],[[\"@onClick\"],[[30,[36,1],[[32,0],[32,1,[\"submit\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"Get Help\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,3,null]],\"hasEval\":false,\"upvars\":[\"decline\",\"action\",\"bs-form\",\"-assert-implicit-component-helper-argument\",\"component\",\"modalIsOpened\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/domains/sitemap-error-modal.hbs"
    }
  });

  _exports.default = _default;
});