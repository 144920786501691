define("campaign-warrior-app/templates/components/create-portal/step2-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "14rFQmLc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"panel panel-default\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n    Connect to HubSpot\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"panel-description\"],[12],[2,\"Connect Campaign Warrior to HubSpot to collect information about your website\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-progress\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"progress\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"progress-bar\"],[14,5,\"width: 50%;\"],[14,\"role\",\"progressbar\"],[14,\"aria-valuenow\",\"50\"],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[2,\"\\n    \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\\n    \"],[8,\"bs-button\",[[16,\"onClick\",[30,[36,2],[[32,0],\"connectHubspot\"],null]],[24,4,\"primary\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Connect to HubSpot\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"portal\",\"log\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/create-portal/step2-form.hbs"
    }
  });

  _exports.default = _default;
});