define("campaign-warrior-app/components/tags/edit-tag-modal", ["exports", "campaign-warrior-app/components/modals-container/base", "ember-cp-validations"], function (_exports, _base, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    classNames: ['edit-tag-modal'],
    formData: Ember.computed(function () {
      const options = Ember.get(this, 'options.tag');
      const Validations = (0, _emberCpValidations.buildValidations)({
        name: (0, _emberCpValidations.validator)('presence', true)
      });
      return Ember.Object.extend(Validations, {
        name: Ember.computed(function () {
          const name = Ember.get(options, 'name');

          if (name) {
            return name;
          }

          return '';
        }),
        description: Ember.computed(function () {
          const name = Ember.get(options, 'description');

          if (name) {
            return name;
          }

          return '';
        })
      }).create(Ember.getOwner(this).ownerInjection());
    })
  });

  _exports.default = _default;
});