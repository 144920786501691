define("campaign-warrior-app/templates/components/modals/request-user-error-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U7ZU+r4e",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[1,[34,0]],[13],[2,\"\\n\\n\\n\"],[3,\"<div class=\\\"error-detail\\\">\"],[2,\"\\n\"],[3,\"  <a class=\\\"btn btn-link\\\" type=\\\"button\\\" id=\\\"error-details-toggle\\\" data-toggle=\\\"dropdown\\\" onclick=\\\"showDetails()\\\">Show\"],[2,\"\\n\"],[3,\"    Details</a>\"],[2,\"\\n\"],[3,\"  <code class=\\\"details\\\" aria-labelledby=\\\"error-details\\\" id=\\\"error-details\\\">\"],[2,\"\\n\"],[3,\"    {{#each error.payload.errors as |err|}}-->\\n<!--      {{err.details}}-->\\n<!--    {{/each}}\"],[2,\"\\n\"],[3,\"  </code>\"],[2,\"\\n\\n\"],[3,\"</div>\"],[2,\"\\n\\n\\n\"],[10,\"script\"],[12],[2,\"\\n  function showDetails() {\\n    //console.log(\\\"Show details\\\");\\n    document.getElementById('error-details').classList.toggle('show');\\n  }\\n\"],[13],[2,\"\\n\\n\"],[10,\"style\"],[12],[2,\"\\n  .error-detail .details {\\n    display: none;\\n    padding: 10px;\\n  }\\n\\n  .error-detail .details.show {\\n    display: block;\\n  }\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"msg\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/request-user-error-body.hbs"
    }
  });

  _exports.default = _default;
});