define("campaign-warrior-app/components/cw-timeline-accordion", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component shows provided data item grouped by some date-property.
   * Grouping may be done by date or by time. Last one can be used only for items that happens on the same day
   *
   * Usage:
   *
   * ```hbs
   * {{cw-timeline-accordion
   *   data=model
   *   groupBy="dateField"
   *   dateFormat="DD/MM/YYYY"
   *   timeFormat="hha"
   *   groupOption="time"
   *   itemComponent=(component "someComponent")
   * }}
   * ```
   *
   * @class CwTimelineAccordion
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['cw-timeline-accordion'],

    /**
     * Items shown in the timeline
     *
     * @property data
     * @type object[]
     * @default null
     */
    data: null,

    /**
     * Property name used to group by `data`-items
     *
     * @property groupBy
     * @type string
     * @default ''
     */
    groupBy: '',

    /**
     * Date format used if `groupOption` is `date`
     *
     * @property dateFormat
     * @default 'DD/MM/YYYY'
     * @type string
     */
    dateFormat: 'DD/MM/YYYY',

    /**
     * Date format used if `groupOption` is `time`
     *
     * @property timeFormat
     * @default 'hha'
     * @type string
     */
    timeFormat: 'hha',

    /**
     * Determines how to group `data`-items - by date or by time. May be `date` or `time`
     *
     * @property groupOption
     * @type string
     * @default 'date'
     */
    groupOption: 'date',

    /**
     * Component used to show `data`-items
     *
     * Two properties will be bind to it:
     *
     * * item - one of the `data`-items
     * * groupValue - it's formatted value used to group `data`-items
     *
     * @property itemComponent
     * @type Ember.Component
     * @default ''
     */
    itemComponent: null,

    /**
     * @property collapseAllInitially
     * @type boolean
     * @default true
     */
    collapseAllInitially: true,

    /**
     * @private
     * @default null
     * @type string[]
     */
    collapsedValues: null,

    /**
     * @private
     * @default 'DD/MM/YYYY'
     * @type string
     * @property formatter
     * @readonly
     */
    formatter: Ember.computed('dateFormat', 'timeFormat', 'groupOption', function () {
      const groupOption = Ember.get(this, 'groupOption');
      return groupOption === 'date' ? Ember.get(this, 'dateFormat') : Ember.get(this, 'timeFormat');
    }).readOnly(),

    /**
     * @property groupedData
     * @type object
     * @default {}
     * @private
     * @readonly
     */
    groupedData: Ember.computed('data.[]', 'groupBy', 'formatter', function () {
      const data = Ember.get(this, 'data');
      const groupBy = Ember.get(this, 'groupBy');
      const formatter = Ember.get(this, 'formatter');
      const grouped = {};
      data.sortBy(groupBy).forEach(item => {
        const m = (0, _moment.default)(Ember.get(item, groupBy));
        const groupValue = m.format(formatter);

        if (!grouped[groupValue]) {
          grouped[groupValue] = [];
        }

        grouped[groupValue].pushObject(item);
      });
      return grouped;
    }).readOnly(),

    init() {
      Ember.set(this, 'collapsedValues', []);

      if (Ember.get(this, 'collapseAllInitially')) {
        Ember.set(this, 'collapsedValues', Object.keys(Ember.get(this, 'groupedData')));
      }

      this._super(...arguments);
    },

    actions: {
      /**
       * Show or hide a group with provided group-value
       *
       * @param {string} groupValue date or time used to group `data`-items
       * @method actions.toggleGroup
       */
      toggleGroup(groupValue) {
        const collapsedValues = Ember.get(this, 'collapsedValues');

        if (collapsedValues.includes(groupValue)) {
          Ember.set(this, 'collapsedValues', collapsedValues.without(groupValue));
        } else {
          Ember.get(this, 'collapsedValues').pushObject(groupValue);
        }
      }

    }
  });

  _exports.default = _default;
});