define("campaign-warrior-app/utils/models-table/theme", ["exports", "campaign-warrior-app/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bootstrap.default.create({
    'collapse-row': 'glyphicon glyphicon-triangle-bottom',
    'expand-row': 'glyphicon glyphicon-triangle-right',
    clearAllFiltersIcon: 'clear-filter',
    clearFilterIcon: 'clear-filter form-control-feedback',
    footerSummaryDefaultPagination: 'col-xs-5 col-sm-5 col-xs-5 col-xs-push-2 col-sm-push-2 col-xs-push-2',
    pageSizeWrapper: 'page-size-dropdown col-xs-2 col-sm-2 col-xs-2 col-xs-pull-5 col-sm-pull-5 col-xs-pull-5',
    paginationWrapperDefault: 'col-xs-5 col-sm-5 col-xs-5'
  });

  _exports.default = _default;
});