define("campaign-warrior-app/controllers/reset/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class VerifyToken
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property queryParams
     * @type (string|object)[]
     * @default ['token']
     */
    queryParams: ['token'],

    /**
     * @property token
     * @type string
     * @default null
     */
    token: null,

    /**
     * @property newPass
     * @type string
     * @default ''
     */
    newPass: '',
    actions: {
      /**
       * @method actions.getNewPassword
       * @param {object} formData token sent to user's email
       * @return {RSVP.Promise} new password
       */
      getNewPassword(formData) {
        const password = Ember.get(formData, 'password');
        const confPassword = Ember.get(formData, 'confirmPassword');

        if (password == '' || confPassword == '' || password != confPassword) {
          const data = {
            title: 'Password not Match',
            body: 'Password and Confirm Password don\'t match'
          };
          return Ember.get(this, 'modalsManager').errorHandler(data);
        }

        return Ember.get(this, 'ajax').post('/reset/verify', {
          data: {
            token: Ember.get(formData, 'token'),
            password: password,
            confirmPassword: confPassword
          }
        }).then(() => {
          Ember.get(this, 'modalsManager').alert({
            title: 'Password Reset Request Successful',
            body: 'You have successfully update your password.',
            confirm: 'Back To Login'
          }).then(() => this.transitionToRoute('login')); // set(this, 'newPass', get(data, 'success'))
        }).catch(error => {
          const mm = Ember.get(this, 'modalsManager'); //.userFriendlyErrorHandler(error)

          mm.show('modals/password-reset-expired-modal', {});
        });
      },

      confirm() {// console.log("Verify confirm");
      }

    }
  });

  _exports.default = _default;
});