define("campaign-warrior-app/components/manage-page-form", ["exports", "campaign-warrior-app/components/manage-record-form", "campaign-warrior-app/utils/object"], function (_exports, _manageRecordForm, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for edit or create a Page
   *
   * Usage:
   *
   * ```hbs
   * {{manage-page-form record=model save=(action "save")}}
   * ```
   *
   * Here:
   * * `record` - Models.Page record
   *
   * @class ManagePageForm
   * @namespace Components
   * @extends Components.ManageRecordForm
   * @uses Mixins.SaveSet
   */
  var _default = _manageRecordForm.default.extend({
    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    classNames: ['manage-page-form'],

    /**
     * Allow to hide submit-button. Useful when form is shown in the modal
     *
     * @property hideControls
     * @type boolean
     * @default false
     */
    hideControls: false,

    /**
     * Determines if tags dropdown should be shown, `false` - it's shown, `true` - it's hidden
     *
     * @property onlyOwnAttrs
     * @type boolean
     * @default false
     */
    onlyOwnAttrs: false,

    /**
     * @property pageInfoIsLoading
     * @type boolean
     * @default false
     * @private
     */
    pageInfoIsLoading: false,

    /**
     * @property loadedPageInfo
     * @type object
     * @default null
     * @private
     */
    loadedPageInfo: null,

    /**
     * @property notUsedTags
     * @type Model.Tag[]
     * @default []
     * @readonly
     * @private
     */
    notUsedTags: Ember.computed('{record.tags,tags}.[]', function () {
      const tags = Ember.get(this, 'tags');
      const usedTags = Ember.get(this, 'record.tags');

      if (!tags) {
        return [];
      }

      if (!usedTags) {
        return tags;
      }

      return tags.filter(tag => usedTags.indexOf(tag) === -1);
    }).readOnly(),
    tagsToShow: Ember.computed(function () {
      const tagsList = [];
      const tags = Ember.get(this, 'tags');
      const page = Ember.get(this, 'record');
      tags.forEach(tag => {
        if (Ember.get(page, 'tags').indexOf(tag) === -1) {
          tagsList.push(tag);
        }
      });
      return tagsList;
    }),
    previewUrl: Ember.computed(function () {
      if (this.record.url.includes('https://') || this.record.url.includes('http://')) {
        return this.record.url;
      }

      return 'https://' + this.record.url;
    }),

    willInsertElement() {
      this.addObserver('record.validations.attrs.url.isValid', this, 'updatePageInfo');
      this.addObserver('record.url', this, 'updatePageInfo');
    },

    didInsertElement() {
      const url = Ember.get(this, 'record.url');

      if (url) {
        this.updatePageInfo();
      }
    },

    willDestroyElement() {
      this.removeObserver('record.url', this, 'updatePageInfo');
      this.removeObserver('record.validations.attrs.url.isValid', this, 'updatePageInfo');
    },

    actions: {
      /**
       * Add tag to `record`
       *
       * @param {Models.Tag} tag tag to add
       * @method actions.addTag
       */
      addTag(tag) {
        Ember.get(this, 'record.tags').pushObject(tag);
      },

      /**
       * Remove tag from `record`
       *
       * @param {Models.Tag} tag tag tag to remove
       * @method actions.removeTag
       */
      removeTag(tag) {
        Ember.get(this, 'record.tags').removeObject(tag);
      },

      /**
       * @method actions.updateAttr
       * @param {string} attrName attribute name to update
       * @param {string} val new value
       */
      updateAttr(attrName, val) {
        Ember.set(this, `record.${attrName}`, val);
      },

      /**
       * @method actions.openWebPage
       * @param {string} url current original web page url
       */
      openWebPage(url) {
        if (!url.includes('http://') && !url.includes('https://')) {
          url = 'https://' + url;
        }

        window.open(url);
      },

      refreshPageMeta(page) {
        Ember.get(this, 'ajax').post('/scrape/refresh', {
          data: page
        });
        window.location.reload(true);
      },

      addTagsForPage() {
        const page = Ember.get(this, 'record');
        page.save();
      }

    },

    /**
     * @method updatePageInfo
     * @private
     */
    updatePageInfo() {
      Ember.run.debounce(this, 'updatePageInfoOnce', 250);
    },

    /**
     * @method updatePageInfoOnce
     * @returns {RSVP.Promise} request result
     * @private
     */
    updatePageInfoOnce() {
      if (!Ember.get(this, 'record.isNew')) {
        return;
      }

      const isValid = Ember.get(this, 'record.validations.attrs.url.isValid');
      const url = Ember.get(this, 'record.url');

      if (!url || !isValid || Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying')) {
        return;
      }

      (0, _object.saveSet)(this, 'pageInfoIsLoading', true);
      return Ember.get(this, 'ajax').post('/scrape/meta', {
        data: {
          url
        }
      }).then(newPageInfo => {
        (0, _object.saveSet)(this, 'loadedPageInfo', newPageInfo); // force previewImage to be saved because it can't be changed by user

        (0, _object.saveSet)(this, 'record.previewImage', newPageInfo.image);
        (0, _object.saveSet)(this, 'pageInfoIsLoading', false);
      }).catch(() => {
        (0, _object.saveSet)(this, 'loadedPageInfo', {
          error: Ember.get(this, 'i18n').t('pages.create.form.pageInfoIsNotLoaded')
        });
        (0, _object.saveSet)(this, 'pageInfoIsLoading', false);
      });
    }

  });

  _exports.default = _default;
});