define("campaign-warrior-app/templates/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DuVMUxOt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"logo-wrapper text-center\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"cw-logo\"],[14,\"src\",\"https://d25xioqhg7be2.cloudfront.net/static-assets/cw-logo.png\"],[15,\"alt\",[30,[36,0],[\"logo.alt\"],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container info-modal\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-container-wrapper-setup\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cw-panel branding\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"panel\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n          \"],[10,\"h4\"],[12],[2,\"\\n            Account Expired\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"verification\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"desc\"],[12],[2,\"\\n              Your account has expired, please update your billing info.\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-footer\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"controls\"],[12],[2,\"\\n            \"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-secondary\",\"account\"]],[[\"default\"],[{\"statements\":[[2,\"Manage Billing\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[8,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"primary\",[30,[36,2],[[32,0],\"logout\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Log Out\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/expired.hbs"
    }
  });

  _exports.default = _default;
});