define("campaign-warrior-app/templates/components/pages/pages-tags-adder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NaFyO3w/",
    "block": "{\"symbols\":[\"form\",\"tag\"],\"statements\":[[6,[37,9],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,8],true,[30,[36,4],[[32,0],\"onSubmit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"apply-tags-section\"],[12],[2,\"\\n\\n\\n    \"],[10,\"div\"],[14,0,\"select-with-apply material-power-select\"],[12],[2,\"\\n      \"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[10,\"label\"],[14,0,\"label-span\"],[12],[2,\"Apply Tags\"],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],null,[[\"id\",\"placeholder\",\"options\",\"searchField\",\"selected\",\"onCreate\",\"onOpen\",\"onChange\"],[[35,6,[\"id\"]],\"Apply Tags\",[35,5],\"name\",[35,1],[30,[36,4],[[32,0],\"createTag\"],null],[30,[36,4],[[32,0],\"dropdownclick\"],null],[30,[36,4],[[32,0],[30,[36,3],[[35,1]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n       \"],[10,\"div\"],[14,0,\"cw-container-tag\"],[12],[1,[30,[36,0],null,[[\"tag\",\"selectionView\"],[[32,2],true]]]],[2,\" \"],[10,\"span\"],[14,0,\"removeIcons\"],[12],[10,\"i\"],[14,0,\"fas fa-times-circle\"],[12],[13],[13],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"input\"],[14,\"hidden\",\"\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"cw-tag\",\"applyTagsSelection\",\"if\",\"mut\",\"action\",\"displayTags\",\"el\",\"power-select-with-create\",\"tagData\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/pages-tags-adder.hbs"
    }
  });

  _exports.default = _default;
});