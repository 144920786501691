define("campaign-warrior-app/components/cw-table/moment-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component for `models-table cells that show dates in format `SOME TIME ago`
   *
   * Use `column.showAsDate = true` to date "as is"
   *
   * Usage:
   *
   * ```js
   * columns: [
   *   {
   *     component: 'cw-table/moment-date',
   *     propertyName: 'some',
   *     showTooltip: true
   *   }
   * ]
   * ```
   *
   * @class CwTableMomentDate
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * Bound from `models-table`. It's a Record you are processing
     *
     * @property record
     * @readonly
     */
    record: null,

    /**
     * Bound from `models-table`. It's a column-definition
     *
     * @property column
     * @readonly
     */
    column: null,

    /**
     * Determines if tooltip with formatted date should be shown
     *
     * @property showTooltip
     * @type boolean
     * @default false
     * @private
     * @readonly
     */
    showTooltip: Ember.computed.alias('column.showTooltip'),

    /**
     * @property showAsDate
     * @type boolean
     * @default false
     * @private
     * @readonly
     */
    showAsDate: Ember.computed.alias('column.showAsDate'),

    /**
     * @property format
     * @type string
     * @private
     * @readonly
     */
    format: Ember.computed('column.format', function () {
      return Ember.get(this, 'column.format') || 'ddd, MMM Do YYYY, h:mm a';
    }).readOnly(),
    actions: {
      /**
       * JS-timestamp is not the same as unix-timestamp.
       * `moment`-helpers require a unix-timestamp, so provided `val` must be changed (divided by 1000).
       *
       * Conversion is done by action because original value is taken with `get`-helper (not `Ember.get`) in the template
       * and passed form one helper to another. Helper `compute` uses actions like "normal" functions to change provided value
       *
       * @param {number} val value to fix
       * @returns {number} fixed value
       * @method actions.toUnixTimestamp
       */
      toUnixTimestamp(val) {
        val = new Date(val).getTime();
        return val / 1000;
      }

    }
  });

  _exports.default = _default;
});