define("campaign-warrior-app/components/edit-portal-modal", ["exports", "campaign-warrior-app/components/modals-container/base", "jquery", "campaign-warrior-app/utils/number", "ember-cp-validations"], function (_exports, _base, _jquery, _number, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```js
   * import Controller from '@ember/controller';
   * import {get} from '@ember/object';
   * import {inject as service} from '@ember/service';
   *
   * export default Controller.extend({
   *   modalsManager: service(),
   *   actions: {
   *     editPortal(portalToEdit) {
   *       get(this, 'modalsManager')
   *         .show('edit-portal-modal', {
   *           record: portalToEdit
   *         })
   *         .then(() => portalToEdit.save())
   *         .catch(() => {
   *           if (get(portalToEdit, 'hasDirtyAttributes')) {
   *             portalToEdit.rollbackAttributes();
   *           }
   *         })
   *     }
   *   }
   * });
   * ```
   *
   * @class EditPortalModal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    /**
     * @property store
     * @type Ember.Service
     */
    store: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    modalsManager: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),
    classNames: ['edit-portal-modal'],

    /**
     * @property previewSrc
     * @type string
     * @default ''
     * @private
     */
    previewSrc: '',

    /**
     * @property pageInfoIsLoading
     * @type boolean
     * @default false
     * @private
     */
    pageInfoIsLoading: false,

    /**
     * @property rootDir
     * @type Models.Dir
     * @default null
     * @private
     * @readonly
     */
    rootDir: Ember.computed.alias('options.record.rootDir'),

    /**
     * @property hoursOptions
     * @type string[]
     * @private
     * @readonly
     */
    hoursOptions: (0, _number.nmbrs)(24),

    /**
     * @property minutesOptions
     * @type string[]
     * @private
     * @readonly
     */
    formData: Ember.computed(function () {
      const _this = this;

      const Validations = (0, _emberCpValidations.buildValidations)({
        name: (0, _emberCpValidations.validator)('presence', true)
      });
      return Ember.Object.extend(Validations, {
        name: Ember.computed(function () {
          return Ember.get(_this, 'options.record.name');
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }),
    minutesOptions: (0, _number.nmbrs)(60),
    // timezone: computed(function () {
    //   console.log('Current user timezone: ', get(this, 'currentUser.user.timezone'));
    //   return get(this, 'currentUser.user.timezone');
    // }),
    userTimezone: Ember.computed(function () {
      //console.log('PORTAL IN EDIT PORTAL: ', get(this, 'options.record.rootDir.id'));
      return Ember.get(this, 'currentUser.user.timezone');
    }),
    portalLogo: Ember.computed(function () {
      return Ember.get(this, 'options.record.logo');
    }),
    clientLogo: Ember.computed(function () {
      return Ember.get(this, 'options.record.clientLogo');
    }),
    agencyLogo: Ember.computed(function () {
      return Ember.get(this, 'options.record.agencyLogo');
    }),
    startHours: Ember.computed(function () {
      return Ember.get(this, 'options.record.noPostStartHours') === 0 ? '0' : Ember.get(this, 'options.record.noPostStartHours');
    }),
    startMinutes: Ember.computed(function () {
      return Ember.get(this, 'options.record.noPostStartMinutes') === 0 ? '0' : Ember.get(this, 'options.record.noPostStartMinutes');
    }),
    endHours: Ember.computed(function () {
      return Ember.get(this, 'options.record.noPostEndHours') === 0 ? '0' : Ember.get(this, 'options.record.noPostEndHours');
    }),
    endMinutes: Ember.computed(function () {
      return Ember.get(this, 'options.record.noPostEndMinutes') === 0 ? '0' : Ember.get(this, 'options.record.noPostEndMinutes');
    }),
    isPortalCompany: Ember.computed('portal', 'currentUser.company', function () {
      return this.portal.companyId == this.currentUser.company.id;
    }),

    init() {
      this._super();

      Ember.set(this, 'previewSrc', Ember.get(this, 'options.record.logo'));
    },

    actions: {
      submitForm(modal) {
        Ember.set(this, 'pageInfoIsLoading', true);
        const portalFile = Ember.get(this, 'portalLogo');
        const agencyFile = Ember.get(this, 'agencyLogo');
        const clientFile = Ember.get(this, 'clientLogo'); // if (portalFile) {

        this.uploadfile(portalFile, Ember.get(this, 'options'), Ember.get(this, 'ajax')).then(logoSrc => {
          Ember.setProperties(Ember.get(this, 'options.record'), {
            'logo': logoSrc
          });
        }).then(() => this.uploadfile(agencyFile, Ember.get(this, 'options'), Ember.get(this, 'ajax')).then(agencySrc => {
          Ember.setProperties(Ember.get(this, 'options.record'), {
            'agencyLogo': agencySrc
          });
        })).then(() => this.uploadfile(clientFile, Ember.get(this, 'options'), Ember.get(this, 'ajax')).then(clientSrc => {
          Ember.setProperties(Ember.get(this, 'options.record'), {
            'clientLogo': clientSrc
          });
        })).then(() => {
          Ember.set(this, 'pageInfoIsLoading', false);
          modal.submit();
        }).catch(e => {
          return Ember.get(this, 'modalsManager').detailedAjaxErrorHandler(e);
        });
      },

      changeStartHours(value) {
        Ember.set(this, 'startHours', value);
        Ember.set(this, 'options.record.noPostStartHours', value);
      },

      changeStartMinutes(value) {
        Ember.set(this, 'startMinutes', value);
        Ember.set(this, 'options.record.noPostStartMinutes', value);
      },

      changeEndHours(value) {
        Ember.set(this, 'endHours', value);
        Ember.set(this, 'options.record.noPostEndHours', value);
      },

      changeEndMinutes(value) {
        Ember.set(this, 'endMinutes', value);
        Ember.set(this, 'options.record.noPostEndMinutes', value);
      },

      uploadPortalLogo(file) {
        Ember.set(this, 'portalLogo', file);
        return Ember.RSVP.resolve();
      },

      uploadClientLogo(file) {
        Ember.set(this, 'clientLogo', file);
        return Ember.RSVP.resolve();
      },

      uploadAgencyLogo(file) {
        Ember.set(this, 'agencyLogo', file);
        return Ember.RSVP.resolve();
      },

      resetColors() {
        Ember.setProperties(Ember.get(this, 'options.record'), {
          'styleSettings.menuBarBackground': '#0056cf',
          'styleSettings.menuDropBackground': '#002d99',
          'styleSettings.menuHighlight': '#2882fd',
          'styleSettings.menuFont': '#f1f1f1'
        });
        const addon = (0, _jquery.default)('.input-group-addon');
        (0, _jquery.default)(addon[0]).find('i').css('background', '#0056cf');
        (0, _jquery.default)(addon[1]).find('i').css('background', '#002d99');
        (0, _jquery.default)(addon[2]).find('i').css('background', '#2882fd');
        (0, _jquery.default)(addon[3]).find('i').css('background', '#f1f1f1');
      }

    },

    uploadfile(file, options, ajax) {
      //console.log('Upload File options', options);
      return new Promise(function (resolve, reject) {
        if (file && file.size) {
          const fd = new FormData();
          const dirId = Ember.get(options, 'record.rootDir.id');
          fd.append('parentID', dirId);
          fd.append('portalID', options.record.id);
          fd.append('file', file);

          const process = () => ajax.post('media/upload', {
            data: fd,
            cache: false,
            processData: false,
            contentType: false
          });

          process().then(res => {
            resolve(res.data.attributes.preview);
          }).catch(err => {
            reject(err);
          });
        } else {
          resolve(file);
        }
      });
    }

  });

  _exports.default = _default;
});