define("campaign-warrior-app/serializers/post-job-instance", ["exports", "campaign-warrior-app/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PostJobInstance
   * @namespace Serializers
   * @extends Serializers.Application
   */
  var _default = _application.default.extend({
    /**
     * Delete Post-Job-Instance requests returns something like `{message: 'Single Job is canceled'}`.
     * Ember-data requires more canonical response
     *
     * @returns {{meta: {}}} normalized json
     */
    normalizeDeleteRecordResponse() {
      return {
        meta: {}
      };
    }

  });

  _exports.default = _default;
});