define("campaign-warrior-app/templates/components/cw-breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BKL0KjFp",
    "block": "{\"symbols\":[\"route\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"linkable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],null,[[\"route\"],[[32,1,[\"path\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,1,[\"title\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,1,[\"title\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"trim\",\"link-to\",\"if\",\"routeHierarchy\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-breadcrumbs.hbs"
    }
  });

  _exports.default = _default;
});