define("campaign-warrior-app/models/favorite", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Favorite
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    /**
     * @type DS.StringAttribute
     * @property originalId
     */
    originalId: _emberData.default.attr('string'),

    /**
     * @type DS.StringAttribute
     * @property originalType
     */
    originalType: _emberData.default.attr('string'),

    /**
     * {{#crossLink "Models.Page"}}Models.Page{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property page
     */
    page: _emberData.default.belongsTo('page')
  });

  _exports.default = _default;
});