define("campaign-warrior-app/components/trial-warning-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class TrialWarningBanner
   * @namespace Components
   * @extends Ember.Components
   */
  var _default = Ember.Component.extend({
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    restrictor: Ember.inject.service(),
    classNames: ['trial-warning-banner', 'alert'],
    remaining: Ember.computed(function () {
      let result = Ember.get(this, 'restrictor').getAllRemaining();
      console.log("trial-warning-banner remaining", result);
      console.log("restrictor limits", Ember.get(this, 'restrictor.limits'));
      return result;
    }),
    remainingTags: Ember.computed('restrictor.limits', function () {
      /*  let result = get(this, 'restrictor').refreshLimit('tags');
        if (result < 0) result = 0;
        return result;*/
    }),
    remainingDomains: Ember.computed('restrictor.limits', function () {
      /*  let result = get(this, 'restrictor').refreshLimit('domains');
        if (result < 0) result = 0;
        return result;*/
    })
  });

  _exports.default = _default;
});