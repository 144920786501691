define("campaign-warrior-app/routes/expired", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    ajax: Ember.inject.service(),
    breadCrumb: null,
    title: 'Account Expired | Campaign Warrior',

    async beforeModel(transition) {
      return this._super(...arguments);
    },

    model() {},

    afterModel(model) {}

  });

  _exports.default = _default;
});