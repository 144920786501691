define("campaign-warrior-app/templates/portals/portal/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lnii0KoP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"internal-content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"tiles-section\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"tiles\",\"pages\"],[[35,1],[35,0]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"pages\",\"tiles\",\"home/home-tiles\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/index.hbs"
    }
  });

  _exports.default = _default;
});