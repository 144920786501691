define("campaign-warrior-app/templates/components/pages/add-many-pages-result-modal-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Qct6gCF",
    "block": "{\"symbols\":[\"error\",\"&default\"],\"statements\":[[10,\"p\"],[12],[1,[30,[36,6],[\"pages.addList.results.addedPages\",[30,[36,0],null,[[\"count\"],[[35,2,[\"results\",\"length\"]]]]]],null]],[13],[2,\"\\n\"],[6,[37,8],[[35,2,[\"errors\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[11,\"a\"],[24,6,\"#\"],[4,[38,5],[[32,0],\"toggleDetails\"],null],[12],[1,[30,[36,6],[\"publishing.addPost.errorsWhileAddingPages\"],null]],[13],[13],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"errors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"options\"],[[30,[36,0],null,[[\"error\"],[[32,1]]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"hash\",\"modals/request-error-body\",\"options\",\"-track-array\",\"each\",\"action\",\"t\",\"showDetails\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/add-many-pages-result-modal-body.hbs"
    }
  });

  _exports.default = _default;
});