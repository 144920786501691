define("campaign-warrior-app/components/invite-user-modal", ["exports", "campaign-warrior-app/components/modals-container/base", "ember-cp-validations"], function (_exports, _base, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for edit or create a Domain
   * @class createTagForPage
   * @namespace Components
   * @extends Components.createTagModal
   */
  var _default = _base.default.extend({
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    classNames: ['invite-user-form'],
    onSubmit: null,
    formData: Ember.computed('{portals,globalPermissions}.[]', 'userToManage.{permissionsMap,email}', function () {
      const userToManage = Ember.get(this, 'userToManage') || {};
      const currentUserId = Ember.get(this, 'currentUser.user.id');
      const Validations = (0, _emberCpValidations.buildValidations)({
        email: (0, _emberCpValidations.validator)('format', {
          type: 'email'
        })
      });
      return Ember.Object.extend(Validations, {
        currentUserId,
        role: 'portal_super_admin',
        portal: Ember.get(this, 'currentPortal.portal.id'),
        email: Ember.computed(function () {
          return userToManage ? Ember.get(userToManage, 'email') : '';
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }),
    actions: {
      cancel(modal) {
        modal.close();
      }

    }
  });

  _exports.default = _default;
});