define("campaign-warrior-app/templates/components/trial-warning-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p7RWrKW5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"limits-bar-wrapper\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"Remaining Free Account Limits\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"limits-bar\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"limit\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"limit-label\"],[12],[2,\"\\n      Posts:\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"value\"],[12],[2,\"\\n      \"],[1,[34,1]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"limit\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"limit-label\"],[12],[2,\"\\n      Campaigns:\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"value\"],[12],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"limit\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"limit-label\"],[12],[2,\"\\n      Tags:\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"value\"],[12],[2,\"\\n      \"],[1,[34,3]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"limit\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"limit-label\"],[12],[2,\"\\n      Domains:\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"value\"],[12],[2,\"\\n      \"],[1,[34,4]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"limit\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"limit-label\"],[12],[2,\"\\n      Publishing Sources:\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"value\"],[12],[2,\"\\n      \"],[1,[34,5]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"],[6,[37,6],null,[[\"route\"],[\"billing\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"portals.portal.trialWarn.upgradeMessage\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"t\",\"remainingPosts\",\"remainingCampaigns\",\"remainingTags\",\"remainingDomains\",\"remainingPublishingSources\",\"link-to\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/trial-warning-banner.hbs"
    }
  });

  _exports.default = _default;
});