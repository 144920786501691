define("campaign-warrior-app/components/cw-table/clickable-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * `column.icon` and `column.tooltip` properties are used
   *
   * @class CwTable.ClickableIcon
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @event iconClicked
     */
    iconClicked: null,

    click() {
      const iconClicked = Ember.get(this, 'iconClicked');

      if (iconClicked) {
        iconClicked(Ember.get(this, 'record'));
      }
    }

  });

  _exports.default = _default;
});