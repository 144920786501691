define("campaign-warrior-app/controllers/portals/portal/setup/hubspot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @class Developer
   * @namespace Controllers
   * @extends Ember.Controller
   */
  let HubspotController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed('currentPortal.portal.hubspotConnected'), _dec8 = Ember.computed('redirectUrl'), _dec9 = Ember.computed('currentPortal.portal.hubspotDomains', 'model'), _dec10 = Ember.computed('currentPortal.portal.hubspotDomains', 'model'), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class HubspotController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['redirectUrl']);

      _initializerDefineProperty(this, "currentPortal", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "torii", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor5, this);

      _initializerDefineProperty(this, "i18n", _descriptor6, this);

      _defineProperty(this, "redirectUrl", '');

      _defineProperty(this, "domains", null);

      _defineProperty(this, "disconnectedDomains", null);
    }

    get hubspotConnected() {
      return this.currentPortal.portal.hubspotConnected;
    }

    get backButtonText() {
      if (this.redirectUrl && this.redirectUrl === 'setup') {
        return 'Back';
      }

      return 'Cancel';
    }

    get hubspotDomains() {
      const portalId = this.currentPortal.portal.id;

      const _this = this;

      if (this.hubspotConnected) {
        /*return new Ember.RSVP.Promise((resolve, reject) => {
          _this.ajax.post('/hubspot/hubspot-domains', {data: {portalId}}).then(function (data) {
            //set(_this, 'disconnectedDomains', data.hubspotDomains);
            console.log("got disconnected hubspot domains", data);
            resolve(data.data.hubspotDomains.objects)
          });
        })*/
        return this.currentPortal.portal.hubspotDomains;
      }
    }

    get portalDomains() {
      if (this.hubspotConnected) {
        return this.currentPortal.portal.hubspotDomains.filter(hdomain => {
          return this.model.filter(domain => domain.url == hdomain.domain).length == 0;
        });
      }
    }

    connectHubspot() {
      const ajax = Ember.get(this, 'ajax');

      const _this = this;

      const portalId = Ember.get(this, 'currentPortal.portal.id');
      const this_domains = Ember.get(this, 'domains');
      const modalsManager = this.modalsManager;
      const i18n = this.i18n;
      Ember.get(this, 'torii').open('hubspot', {
        width: 800,
        height: 800
      }).then(function (data) {
        ajax.post('/hubspot/authorize', {
          data: {
            portalId,
            data
          }
        }).then(function (hubspotPortal) {
          _this.currentPortal.portal.reload(); // set(_this, 'domains', domains);

        }).then(() => {
          modalsManager.confirm({
            title: 'Install Related Content Module?',
            body: 'This will install the Campaign Warrior Related Content Module into your connected HubSpot portal',
            confirm: 'Install'
          }).then(() => {
            ajax.post('/hubspot/install-module/content-controller', {
              data: {
                portalId
              }
            }).then(response => {
              modalsManager.alert({
                title: 'Related Content Module Installed',
                confirm: i18n.t('modals.defaultAlertOptions.confirm'),
                body: `The related content module has been installed as module ${response.path}.`
              });
            });
          });
        });
      });
    }

    selectDomain(domain) {
      const ajax = Ember.get(this, 'ajax');

      const _this = this;

      const portalId = Ember.get(this, 'currentPortal.portal.id');
      const router = this.router;
      let domainUrl = domain.domain;
      let domainId = domain.id;
      ajax.post('/hubspot/connect-hs-domain', {
        data: {
          portalId,
          domainUrl,
          domainId
        }
      }).then(function (response) {
        let destination = 'portals.portal.domains.list';

        if (_this.redirectUrl === 'setup') {
          destination = 'portals.portal.setup.google';
        } else {
          destination = 'portals.portal.domains.list';
        }

        router.transitionTo(destination, {
          queryParams: {
            redirectUrl: _this.redirectUrl
          }
        });
      });
    }

    installContentController() {
      const ajax = this.ajax;
      const portalId = this.currentPortal.portal.id;
      const modalsManager = this.modalsManager;
      const i18n = this.i18n;
      return modalsManager.confirm({
        title: 'Install Related Content Module?',
        body: 'This will install the Campaign Warrior Related Content Module into your connected HubSpot portal',
        confirm: 'Install'
      }).then(() => {
        ajax.post('/hubspot/install-module/content-controller', {
          data: {
            portalId
          }
        }).then(response => {
          modalsManager.alert({
            title: 'Related Content Module Installed',
            confirm: i18n.t('modals.defaultAlertOptions.confirm'),
            body: `The related content module has been installed.`
          });
        });
      }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
    }

    goBack() {
      let destination = 'portals.portal.domains.list';

      if (this.redirectUrl === 'setup') {
        destination = 'portals.portal.setup.setup';
      } else {
        destination = 'portals.portal.domains.list';
      }

      this.router.transitionTo(destination);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "torii", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hubspotConnected", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "hubspotConnected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "backButtonText", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "backButtonText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hubspotDomains", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "hubspotDomains"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "portalDomains", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "portalDomains"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connectHubspot", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "connectHubspot"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectDomain", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectDomain"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "installContentController", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "installContentController"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goBack", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "goBack"), _class.prototype)), _class));
  _exports.default = HubspotController;
});