define("campaign-warrior-app/templates/components/support-login-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GT8cxVqv",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[10,\"h1\"],[12],[2,\"Support a portal\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n\"],[6,[37,5],null,[[\"formLayout\",\"novalidate\",\"model\",\"onSubmit\"],[\"vertical\",true,[35,4],[30,[36,3],[[32,0],\"onSubmit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('campaign-warrior-app/templates/components/support-login-form.hbs' @ L4:C6) \"],null]],[[\"class\",\"property\",\"placeholder\",\"required\"],[\"portalid\",\"portalId\",\"Portal Id\",true]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"login-button-area\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"class\",\"defaultText\",\"type\"],[\"btn-primary btn-signin\",\"Authorize\",\"link\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"bs-button\",\"action\",\"formData\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/support-login-form.hbs"
    }
  });

  _exports.default = _default;
});