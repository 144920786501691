define("campaign-warrior-app/adapters/dir", ["exports", "campaign-warrior-app/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dir
   * @namespace Adapters
   * @extends Adapters.Application
   */
  var _default = _application.default.extend({
    /**
     * Return URL for `queryRecord`-request
     *
     * If `query.dir_id` is missing request will be done for the `root`-dir
     *
     * @method urlForQueryRecord
     * @param {object} query query parameters
     * @param {string} modelName `dir`
     * @returns {string} url for query record
     */
    urlForQueryRecord(query, modelName) {
      const host = Ember.get(this, 'host') || '';
      const namespace = Ember.get(this, 'namespace') || '';
      const path = this.pathForType(modelName);
      const suffix = query.dir_id ? `/${query.dir_id}` : '';
      delete query.dir_id;
      query.include = 'dirs,files';
      return `${host}/${namespace}/${path}${suffix}`;
    }

  });

  _exports.default = _default;
});