define("campaign-warrior-app/components/pages/pages-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```
   * {{pages/pages-summary pages=somePages filterByMetric=(action "doFilter")}}
   * ```
   *
   * @class PagesSummary
   * @namespace Components.Pages
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @event filterByMetric
     */
    filterByMetric: null,

    /**
     * @property pages
     * @type Models.Page[]
     * @default null
     */
    pages: null,

    /**
     * @property posts
     * @type number[]
     * @private
     * @readonly
     */
    posts: Ember.computed.mapBy('pages', 'postsCount'),

    /**
     * @property postsSum
     * @type number
     * @private
     * @readonly
     */
    postsSum: Ember.computed.sum('posts'),

    /**
     * @property nextPosts
     * @type number[]
     * @private
     * @readonly
     */
    nextPosts: Ember.computed.mapBy('pages', 'nextPostCount'),

    /**
     * @property scheduledPostsSum
     * @type number
     * @private
     * @readonly
     */
    scheduledPostsSum: Ember.computed.sum('nextPosts'),

    /**
     * @property interacts
     * @type number[]
     * @private
     * @readonly
     */
    interacts: Ember.computed.mapBy('pages', 'interactsCount'),

    /**
     * @property interactsSum
     * @type number
     * @private
     * @readonly
     */
    interactsSum: Ember.computed.sum('interacts'),

    /**
     * @property likes
     * @type number[]
     * @private
     * @readonly
     */
    likes: Ember.computed.mapBy('pages', 'likesCount'),

    /**
     * @property likesSum
     * @type number
     * @private
     * @readonly
     */
    likesSum: Ember.computed.sum('likes'),

    /**
     * @property shares
     * @type number[]
     * @private
     * @readonly
     */
    shares: Ember.computed.mapBy('pages', 'sharesCount'),

    /**
     * @property sharesSum
     * @type number
     * @private
     * @readonly
     */
    sharesSum: Ember.computed.sum('shares'),

    /**
     * @property views
     * @type number
     * @private
     * @readonly
     */
    views: Ember.computed.mapBy('pages', 'viewsCount'),

    /**
     * @property viewsSum
     * @type number
     * @private
     * @readonly
     */
    viewsSum: Ember.computed.sum('views'),

    /**
     * @property clicks
     * @type number
     * @private
     * @readonly
     */
    clicks: Ember.computed.mapBy('pages', 'clicksCount'),

    /**
     * @property clicksSum
     * @type number
     * @private
     * @readonly
     */
    clicksSum: Ember.computed.sum('clicks'),
    totalNextPosts: Ember.computed('totalNextPostCount', function () {
      return Ember.get(this, 'totalNextPostCount');
    }),
    actions: {
      filterByMetric(metricName) {
        const filterByMetric = Ember.get(this, 'filterByMetric');

        if (filterByMetric) {
          return filterByMetric(metricName);
        }
      }

    }
  });

  _exports.default = _default;
});