define("campaign-warrior-app/abilities/company", ["exports", "campaign-warrior-app/abilities/cw-ability"], function (_exports, _cwAbility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cwAbility.default.extend({
    canIsTrial: Ember.computed('currentUser.company.status', function () {
      const status = Ember.get(this, 'currentUser.company.status');
      return status === 'trial';
    }),
    canIsExpired: Ember.computed('currentUser.company.status', function () {
      const status = Ember.get(this, 'currentUser.company.status');
      return status === 'expired';
    })
  });

  _exports.default = _default;
});