define("campaign-warrior-app/controllers/portals/portal/users/invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class InviteUser
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property model
     * @type Models.Portal[]
     * @default null
     */
    model: null,
    actions: {
      /**
       * @method actions.inviteUser
       * @param {object} data data to send
       * @return {RSVP.Promise} save result
       */
      inviteUser(data) {
        const i18n = Ember.get(this, 'i18n');
        const router = Ember.get(this, 'router');
        const modalsManager = Ember.get(this, 'modalsManager');
        return Ember.get(this, 'ajax').post('/inviteuser', {
          data
        }).then(r => modalsManager.alert({
          title: "Your invite has been sent",
          confirm: i18n.t('modals.defaultAlertOptions.confirm'),
          body: i18n.t('settings.addUser.inviteSentModal.body', {
            email: r.email
          })
        }).then(() => router.transitionTo('portals.portal.users.list'))).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      }

    }
  });

  _exports.default = _default;
});