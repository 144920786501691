define("campaign-warrior-app/components/color-picker", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component shows input with a color-picker dropdown.
   *
   * It's a small wrapper on https://farbelous.github.io/bootstrap-colorpicker/
   *
   * Usage:
   *
   * ```hbs
   * {{color-picker color=color}}
   * ```
   *
   * @class ColorPicker
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['color-picker'],

    /**
     * Selected color (may be predefined)
     *
     * @property color
     * @type string
     * @default ''
     */
    color: '',

    didInsertElement() {
      (0, _jquery.default)('.colorpicker').colorpicker({
        format: 'hex',
        autoInputFallback: false
      }).on('showPicker', () => {
        // update z-index if color-picker is shown in the modal
        const modalExists = !!(0, _jquery.default)('.modal.fade.in').length;

        if (modalExists) {
          (0, _jquery.default)('.colorpicker-visible').css('z-index', 2000);
        }
      });
    },

    willDestroyElement() {
      (0, _jquery.default)('.colorpicker').colorpicker('destroy');
    }

  });

  _exports.default = _default;
});