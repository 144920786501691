define("campaign-warrior-app/data/limits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const limits = {
    free: {
      portalUsers: 1,
      portals: 1,
      domains: 1,
      subDomains: 0,
      tagsCount: 5
    }
  };
  var _default = {
    free: {
      portals: limits.free.portals,
      tagsCount: limits.free.tagsCount,
      dailyPostCount: limits.free.dailyPostCount,
      monthlyPostCount: limits.free.monthlyPostCount,
      publishSourcesCount: limits.free.publishSources,
      campaignCount: limits.free.campaigns,
      accountActions: {
        createPost: [{
          limitType: 'daily',
          count: limits.free.dailyPostCount
        }, {
          limitType: 'monthly',
          count: limits.free.monthlyPostCount
        }],
        createTag: [{
          limitType: 'total',
          count: limits.free.tagsCount
        }]
      }
    }
  };
  _exports.default = _default;
});