define("campaign-warrior-app/torii-providers/google-analytics", ["exports", "torii/providers/oauth2-code", "torii/configuration"], function (_exports, _oauth2Code, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var GoogleOauth2 = _oauth2Code.default.extend({
    name: 'google-analytics',
    baseUrl: 'https://accounts.google.com/o/oauth2/auth',
    optionalUrlParams: ['scope', 'request_visible_actions', 'access_type', 'approval_prompt', 'hd', 'prompt'],
    requestVisibleActions: (0, _configuration.configurable)('requestVisibleActions', ''),
    accessType: (0, _configuration.configurable)('accessType', 'offline'),
    responseParams: ['code', 'state'],
    scope: (0, _configuration.configurable)('scope', 'email'),
    approvalPrompt: (0, _configuration.configurable)('approvalPrompt', 'force'),
    redirectUri: (0, _configuration.configurable)('redirectUri', 'https://cloud.campaignwarrior.com/torii/redirect.html'),
    hd: (0, _configuration.configurable)('hd', '')
  });

  var _default = GoogleOauth2;
  _exports.default = _default;
});