define("campaign-warrior-app/components/manage-add-tags", ["exports", "campaign-warrior-app/models/tag", "campaign-warrior-app/mixins/searchable"], function (_exports, _tag, _searchable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component shows dropdown with al available [tags](Model.Tag.html).
   *
   * Addons [ember-power-select-with-create](https://github.com/cibernox/ember-power-select-with-create) and [ember-power-select](https://github.com/cibernox/ember-power-select) are used
   *
   * User is able to create a new Tag in place and add it to the provided `record`. New Tag will be automatically saved to the server.
   *
   * Usage:
   *
   * ```hbs
   * {{manage-related-tags
   *   record=someRecord
   *   tags=allAvailableTabs
   * }}
   * ```
   *
   * @class ManageRelatedTags
   * @namespace Components
   * @extends Ember.Component
   * @uses Mixins.Searchable
   */
  var _default = Ember.Component.extend(_searchable.default, {
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    can: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    classNames: ['manage-related-tags'],

    /**
     * @property tagsAttrName
     * @type string
     * @default 'tags'
     */
    tagsAttrName: 'tags',

    /**
     * @property instanceClassName
     * @type string
     * @default 'tag'
     * @override
     */
    instanceClassName: 'tag',

    /**
     * @property searchField
     * @type string
     * @default 'name'
     * @override
     */
    searchField: 'name',

    /**
     * @property showCreateOption
     * @type boolean
     * @default true
     */
    showCreateOption: true,

    /**
     * @property record
     * @type DS.Record
     * @default null
     */
    record: null,

    /**
     * Determines when drop-down should be disabled
     *
     * @property isDisabled
     * @default false
     * @type boolean
     */
    isDisabled: false,

    /**
     * For internal purposes
     *
     * @private
     * @readonly
     */
    _emptyList: Ember.computed(function () {
      return [];
    }),
    actions: {
      /**
       * Add tag to `record`
       *
       * @param {Models.tag} tag tag to add
       * @method actions.addTag
       */
      addTag(tag) {
        const tags = Ember.get(this, `record.${Ember.get(this, 'tagsAttrName')}`);

        if (tags.indexOf(tag) === -1) {
          tags.pushObject(tag);
        }
      },

      /**
       * Remove tag from `record`
       *
       * @param {Models.tag} tag tag tag to remove
       * @method actions.removeTag
       */
      removeTag(tag) {
        Ember.get(this, `record.${Ember.get(this, 'tagsAttrName')}`).removeObject(tag);
      },

      /**
       * @method actions.createTag
       * @param {string} tagName tag to create
       * @return {RSVP.Promise} request result
       */
      async createTag(tagName) {
        const can = Ember.get(this, 'can'); //console.log('CREATING TAG');

        if (!can.can('create tag', null, {
          tagsCount: Ember.get(this, 'tags').length
        })) {
          Ember.get(this, 'openModal').close();
          return Ember.get(this, 'modalsManager').alert({
            title: 'Limit Exceed',
            body: 'You have reached your free tags limit. Upgrade now for unlimited tags',
            confirm: 'Ok'
          });
        }

        const newTag = Ember.get(this, 'store').createRecord('tag', {
          name: tagName,
          color: _tag.default.getRandColor(),
          portal: Ember.get(this, 'currentPortal.portal')
        });

        try {
          await newTag.save();
          await Ember.get(this, 'tags').pushObject(newTag);
          await this.send('addTag', newTag);
        } catch (err) {
          console.error('ERROR Saving new tag', err);
        }

        return newTag;
        /*
        return modalsManager
          .show('tags/manage-tag-modal', {tag: newTag})
          .then(() => newTag.save().then(() => this.send('addTag', newTag)))
          .catch(e => {
              return modalsManager
              .detailedAjaxErrorHandler(e);
          });*/
      },

      /**
       * Determines if "Add Tag ..." should be shown in the dropdown options
       *
       * @method actions.hideCreateTagOption
       * @return {boolean} true - don't show, false - show
       */
      hideCreateTagOption() {
        return !Ember.get(this, 'instances.length');
      },

      /**
       * Create a custom message shown for option "Add Tag ..."
       *
       * @method actions.customSuggestion
       * @param {string} tagName tag to create and add to `record`
       * @return {string} shown message
       */
      customSuggestion(tagName) {
        return Ember.get(this, 'i18n').t('tags.manageRelatedTags.suggestion', {
          tagName
        });
      }

    }
  });

  _exports.default = _default;
});