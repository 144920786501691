define("campaign-warrior-app/models/post", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Post
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    /**
     * @property postAttributes
     * @type DS.ObjectAttribute
     * @default null
     */
    postAttributes: _emberData.default.attr(),

    /**
     * @property postOptions
     * @type DS.ObjectAttribute
     * @default null
     */
    postOptions: _emberData.default.attr(),

    /**
     * @property status
     * @type DS.StringAttribute
     * @default null
     */
    status: _emberData.default.attr('string'),

    /**
     * @property type
     * @type DS.StringAttribute
     * @default null
     */
    type: _emberData.default.attr('string'),

    /**
      Externally Deleted
     This property flags whether a post has been deleted in hubspot or a social media platform
     It prevents the metrics service from collecting more stats on this post.
     */
    externallyDeleted: _emberData.default.attr('boolean'),

    /**
     * {{#crossLink "Models.Campaign"}}Models.Campaign{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property campaign
     */
    campaign: _emberData.default.belongsTo('campaign'),

    /**
     * {{#crossLink "Models.Page"}}Models.Page{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property page
     */
    page: _emberData.default.belongsTo('page'),

    /**
     * {{#crossLink "Models.File"}}Models.File{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property file
     */
    file: _emberData.default.belongsTo('file'),

    /**
     * {{#crossLink "Models.Tag"}}Models.Tag{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property tags
     */
    tags: _emberData.default.hasMany('tag'),

    /**
     * {{#crossLink "Models.PostJob"}}Models.PostJob{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property postJob
     */
    postJob: _emberData.default.belongsTo('post-job'),

    /**
     * {{#crossLink "Models.PublishSource"}}Models.PublishSource{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property publishSource
     */
    publishSource: _emberData.default.belongsTo('publish-source'),

    /**
     * @property created
     * @type number
     */
    created: Ember.computed.alias('postOptions.created'),

    /**
     * @property realPublishSource
     * @type Models.PublishSource
     * @default null
     */
    realPublishSource: Ember.computed('publishSource', 'postJob.publishSources.[]', function () {
      return Ember.get(this, 'publishSource.id') ? Ember.get(this, 'publishSource') : Ember.get(this, 'postJob.publishSources.firstObject');
    })
  });

  _exports.default = _default;
});