define("campaign-warrior-app/templates/components/create-portal/step1-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yW+K+V+1",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[10,\"div\"],[14,0,\"setup-panel\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"setup-heading\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"Step 1 \"],[10,\"span\"],[14,0,\"step-name\"],[12],[2,\"Adding a Portal\"],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"setup-body\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Name your Portal\"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"setup-description\"],[12],[1,[30,[36,1],[\"portals.create.step1.description\"],null]],[13],[2,\"\\n\\n\"],[6,[37,6],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,0],true,[30,[36,2],[[32,0],\"nextStep\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"\\n      \"],[8,\"material/input\",[],[[\"@type\",\"@label\",\"@property\",\"@field\",\"@validation\"],[\"text\",\"Portal Name\",[34,0,[\"name\"]],\"name\",[34,0,[\"validations\",\"errors\"]]]],null],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"setup-controls\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"btn btn-cancel\"]],[[\"@route\"],[\"portals\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"portals.create.step1.form.cancel\"],null]]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"bs-button\",[[16,\"onClick\",[30,[36,2],[[32,0],\"nextStep\"],null]],[16,\"disabled\",[30,[36,5],[[30,[36,4],[[35,0,[\"validations\",\"messages\"]],[35,3]],null],true,false],null]]],[[\"@type\"],[\"primary\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,1],[\"portals.create.step1.form.submit\"],null]],[2,\" \"],[6,[37,5],[[35,3]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fal fa-spinner fa-spin fa-fw\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"formData\",\"t\",\"action\",\"submitInProgress\",\"or\",\"if\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/create-portal/step1-form.hbs"
    }
  });

  _exports.default = _default;
});