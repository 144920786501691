define("campaign-warrior-app/components/invite-confirmation-form", ["exports", "campaign-warrior-app/components/floating-form", "ember-cp-validations", "campaign-warrior-app/data/countries"], function (_exports, _floatingForm, _emberCpValidations, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{verify-password-form token=token onSubmit=(action "verifyToken")}}`
   * ```
   *
   * @class VerifyPasswordForm
   * @namespace Components
   * @extends Components.FloatingForm
   */
  var _default = _floatingForm.default.extend({
    classNames: ['invite-confirmation-form'],
    countries: _countries.default,

    /**
     * @event onSubmit
     */
    onSubmit: null,

    /**
     * @property token
     * @type string
     * @default null
     */
    token: null,

    /**
     * @property formData
     * @type object
     * @private
     */
    formData: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({
        token: (0, _emberCpValidations.validator)('presence', true),
        'userFields.firstName': (0, _emberCpValidations.validator)('presence', true),
        'userFields.lastName': (0, _emberCpValidations.validator)('presence', true),
        'userFields.country': (0, _emberCpValidations.validator)('presence', true),
        'userFields.password': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
          min: 5
        })],
        'userFields.confirm_password': (0, _emberCpValidations.validator)('confirmation', {
          on: 'userFields.password'
        })
      });
      return Ember.Object.extend(Validations, {
        userFields: Ember.computed(function () {
          return {
            password: '',
            confirm_password: '',
            firstName: '',
            lastName: '',
            country: ''
          };
        })
      }).create(Ember.getOwner(this).ownerInjection());
    }),

    init() {
      this._super(...arguments);

      const token = Ember.get(this, 'token');

      if (token) {
        Ember.set(this, 'formData.token', token);
      }
    }

  });

  _exports.default = _default;
});