define("campaign-warrior-app/templates/portals/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SPuxo3uN",
    "block": "{\"symbols\":[],\"statements\":[[3,\"<div class=\\\"create-portal-logo logo-container\\\">\"],[2,\"\\n\"],[3,\"  <img src=\\\"https://d25xioqhg7be2.cloudfront.net/static-assets/cw-logo.png\\\" alt={{t \\\"logo.alt\\\"}}>\"],[2,\"\\n\"],[3,\"</div>\"],[2,\"\\n\"],[10,\"div\"],[14,0,\"home-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"home-wrapper\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"portals\",\"logout\"],[[35,1],[30,[36,0],[[32,0],\"logout\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"select-portal-form-external\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/index.hbs"
    }
  });

  _exports.default = _default;
});