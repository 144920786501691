define("campaign-warrior-app/controllers/portals/portal", ["exports", "campaign-warrior-app/utils/routing"], function (_exports, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Portal
   * @namespace Controllers
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    portalRoutes: _routing.portalRoutes
  });

  _exports.default = _default;
});