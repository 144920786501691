define("campaign-warrior-app/templates/portals/portal/setup/shopify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZXCPDpTh",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[10,\"div\"],[14,0,\"setup-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"setup-panel\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-heading\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[10,\"span\"],[14,0,\"step-name\"],[12],[2,\"Import your Products\"],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-body\"],[12],[2,\"\\n      \"],[10,\"h4\"],[12],[2,\"Connect to Shopify\"],[13],[2,\"\\n\\n      \"],[8,\"bs-form\",[],[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"vertical\",[34,1],[30,[36,2],[[32,0],\"connectShop\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Example: myshopname.myshopify.com\"],[13],[2,\"\\n        \"],[8,\"material/input\",[],[[\"@type\",\"@label\",\"@property\",\"@onChange\"],[\"text\",\"Shop Path\",[34,1,[\"shopName\"]],[30,[36,2],[[32,0],\"setShopName\"],null]]],null],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"p\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-controls\"],[12],[2,\"\\n\\n      \"],[8,\"bs-button\",[[16,\"onClick\",[30,[36,2],[[32,0],\"connectShop\"],null]]],[[\"@disabled\",\"@defaultText\",\"@type\"],[[34,4],\"Continue\",\"primary\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\\n\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"shopNameError\",\"formData\",\"action\",\"if\",\"validationUrl\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/setup/shopify.hbs"
    }
  });

  _exports.default = _default;
});