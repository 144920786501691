define("campaign-warrior-app/templates/components/billing/update-account-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZFqY63SB",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[8,\"bs-modal\",[],[[\"@open\",\"@onSubmit\",\"@onHide\"],[[34,0],[30,[36,1],[[32,0],[32,0,[\"confirm\"]]],null],[30,[36,1],[[32,0],[32,0,[\"decline\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Update Account Info\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,4],null,[[\"formData\",\"record\",\"hideControls\",\"onlyOwnAttrs\",\"save\"],[[35,3],[35,2,[\"currentUser\"]],true,true,[30,[36,1],[[32,0],\"confirm\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\",\"@class\",\"@disabled\"],[[30,[36,1],[[32,0],[32,1,[\"submit\"]]],null],\"primary\",\"btn-primary\",[30,[36,5],[[35,3,[\"validations\",\"messages\"]],true,false],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,6],[\"billing.updateAccountForm.update\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"modalIsOpened\",\"action\",\"options\",\"formData\",\"billing/update-account-form\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/billing/update-account-modal.hbs"
    }
  });

  _exports.default = _default;
});