define("campaign-warrior-app/components/mentions/edit-mentionable-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    classNames: ['edit-mentionable-form'],
    actions: {
      save(mention) {
        //console.log('editMentionForm.save()', mention);
        try {
          return Ember.get(this, 'save')(Ember.get(this, 'record'));
        } catch (err) {
          console.error(err);
        }
      },

      /**
       * @method actions.updateAttr
       * @param {string} attrName attribute name to update
       * @param {string} val new value
       */
      updateAttr(attrName, val) {
        Ember.set(this, `record.${attrName}`, val);
      }

    }
  });

  _exports.default = _default;
});