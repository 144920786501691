define("campaign-warrior-app/controllers/portals/create/step1", ["exports", "campaign-warrior-app/controllers/portals/create/step", "campaign-warrior-app/macros/get-props-from"], function (_exports, _step, _getPropsFrom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = ['name', 'description'];
  /**
   * First step for Create-a-Portal wizard. Here user provides a name and description for the new portal
   *
   * @namespace Controllers.CreatePortal
   * @class Step1
   * @extends Controllers.CreatePortal.WizardStep
   */

  var _default = _step.default.extend({
    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    gotFocus: false,

    /**
     * Properties `slug` and `description` are taken from the LS
     *
     * @property initialData
     */
    initialData: (0, _getPropsFrom.default)('portal', fields),
    actions: {
      /**
       * Portal creation is almost finished.
       * Do request for its creation and navigate user to it if portal is created successfully
       *
       * @method actions.finish
       * @param {{name: string, description: string}} data basic portal info
       * @return {RSVP.Promise} save result
       */

      /*
          finish(data) {
            fields.forEach(key => set(this, `portal.${key}`, get(data, key)));
            const newPortalData = getProperties(get(this, 'portal'), ['name', 'description']);
            const store = get(this, 'store');
            const modalsManager = get(this, 'modalsManager');
            return store
              .createRecord('portal', newPortalData)
              .save()
              .then(portal => {
                get(this, 'portal').reset();
                get(this, 'currentUser')
                  .load()
                  .then(() => get(this, 'router').transitionTo('portals.portal', portal));
              })
              .catch(e => modalsManager.detailedAjaxErrorHandler(e));
          },
          */
      nextStep(data) {
        Ember.get(this, 'portal').incrementProperty('step');
        Ember.set(this, 'portal.name', Ember.get(data, 'name'));
        Ember.set(this, 'portal.description', Ember.get(data, 'description'));
        Ember.get(this, 'router').transitionTo('portals.create.step2');
      },

      create(data) {
        fields.forEach(key => Ember.set(this, `portal.${key}`, Ember.get(data, key)));
        const newPortalData = Ember.getProperties(Ember.get(this, 'portal'), ['name']);
        const store = Ember.get(this, 'store');
        store.createRecord('portal', newPortalData).save().then(portal => {
          Ember.set(this, 'activePortal', portal);
          console.log("Set active portal", portal);
          Ember.get(this, 'portal').reset();
          Ember.get(this, 'currentUser').load().then(() => {
            this.send('finish');
          });
        });
      },

      goToStep2() {
        Ember.set(Ember.get(this, 'portal'), 'step', 2);
        Ember.set(this, 'portal.activePortal', Ember.get(this, 'activePortal'));
        Ember.set(this, 'model', Ember.get(this, 'activePortal'));
        console.log("Active portal id", this.activePortal.id);
        Ember.get(this, 'router').transitionTo(`portals.create.step2`);
      },

      finish() {
        Ember.get(this, 'router').transitionTo('portals.portal.setup.setup', this.activePortal.id);
      },

      setFocus() {
        Ember.set(this, 'gotFocus', false);
        Ember.run.later(() => {
          Ember.set(this, 'gotFocus', true);
        }, 300);
      }

    }
  });

  _exports.default = _default;
});