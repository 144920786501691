define("campaign-warrior-app/templates/components/server-error-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vbhfNWzo",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[6,[37,5],null,[[\"keyboard\",\"backdropClose\",\"size\",\"open\",\"onSubmit\",\"onHide\",\"class\"],[false,false,\"lg\",[35,4],[30,[36,0],[[32,0],\"confirm\"],null],[30,[36,0],[[32,0],\"decline\"],null],\"modals-branding trial-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('campaign-warrior-app/templates/components/server-error-modal.hbs' @ L4:C5) \"],null]],[[\"class\"],[\"text-center\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"icon\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fad fa-dumpster-fire\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[2,\"A Server error has occurred.\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"This issue has been logged with support\"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"modal-controls\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"class\",\"type\",\"defaultText\",\"onClick\"],[\"btn-rounded\",\"gradient\",\"Continue\",[30,[36,0],[[32,0],[32,1,[\"submit\"]]],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"action\",\"bs-button\",\"-assert-implicit-component-helper-argument\",\"component\",\"modalIsOpened\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/server-error-modal.hbs"
    }
  });

  _exports.default = _default;
});