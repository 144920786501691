define("campaign-warrior-app/templates/settings/add-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A7q7C6f4",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"class\",\"expandable\",\"title\",\"bodyComponent\"],[\"branding\",false,[30,[36,3],[\"settings.addUser.title\"],null],[30,[36,2],[\"manage-permissions-form\"],[[\"portals\",\"onSubmit\"],[[35,1],[30,[36,0],[[32,0],\"inviteUser\"],null]]]]]]]],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,5],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"component\",\"t\",\"cw-panel\",\"-outlet\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/settings/add-user.hbs"
    }
  });

  _exports.default = _default;
});