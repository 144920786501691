define("campaign-warrior-app/storages/create-portal", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Storage = _object.default.extend();

  Storage.reopenClass({
    /**
     * @returns {object} default value
     */
    initialState() {
      return {
        name: '',
        description: '',

        /**
         * current wizard's step number, used to disallow to jump to the next steps before submitting current
         */
        step: 1
      };
    }

  });
  /**
   * Local-storage for data about new portal. It's used to store data while user proceeds Create-a-Portal wizard
   *
   * This data is cleared when wizard is competed
   *
   * Usage:
   *
   * ```javascript
   * // app/controllers/some.js
   * import Controller from '@ember/controller';
   * import {storageFor} from 'ember-local-storage';
   *
   * export default Controller.extend({
   *   portal: storageFor('create-portal'),
   * });
   * ```
   *
   * @namespace Storage
   * @class CreatePortalStorage
   * @extends Ember.Object
   */

  var _default = Storage;
  _exports.default = _default;
});