define("campaign-warrior-app/controllers/portals/portal/integrations/googleanalytics/googleAnalytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentPortal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: null,
    actions: {
      onSubmit() {},

      disconnectGaAccount() {}

    }
  });

  _exports.default = _default;
});