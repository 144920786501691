define("campaign-warrior-app/templates/components/domains/table-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z39vGaSE",
    "block": "{\"symbols\":[\"dd\",\"ddm\"],\"statements\":[[3,\"<BsButton class=\\\"btn btn-primary\\\" @onClick={{action \\\"copyTrackingCode\\\"}}><i class=\\\"far fa-copy\\\" ></i> Copy Tracking Code</BsButton>\"],[2,\"\\n\"],[3,\"<BsButton class=\\\"btn btn-primary\\\" @onClick={{action \\\"refreshDomain\\\"}}><i class=\\\"far fa-history\\\"></i>Refresh</BsButton>\"],[2,\"\\n\"],[3,\"<BsButton class=\\\"btn btn-delete\\\" @onClick={{action \\\"deleteDomain\\\"}}><i class=\\\"far fa-times-circle\\\"></i>Delete</BsButton>\"],[2,\"\\n\\n\"],[8,\"bs-dropdown\",[[24,0,\"tag-action-menu\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"toggle\"]],[[24,0,\"action-menu-toggle\"]],[[],[]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fal fa-ellipsis-v\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"menu\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[11,\"a\"],[4,[38,0],[[32,0],\"copyTrackingCode\"],null],[12],[10,\"i\"],[14,0,\"far fa-copy\"],[12],[13],[10,\"span\"],[12],[2,\"Copy Tracking Code\"],[13],[13],[2,\"\\n      \"],[11,\"a\"],[4,[38,0],[[32,0],\"refreshDomain\"],null],[12],[10,\"i\"],[14,0,\"fal fa-sync-alt\"],[12],[13],[10,\"span\"],[12],[2,\"Refresh\"],[13],[13],[2,\"\\n      \"],[11,\"a\"],[4,[38,0],[[32,0],\"deleteDomain\"],null],[12],[10,\"i\"],[14,0,\"fal fa-trash\"],[12],[13],[10,\"span\"],[12],[2,\"Delete\"],[13],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/domains/table-controls.hbs"
    }
  });

  _exports.default = _default;
});