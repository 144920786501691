define("campaign-warrior-app/components/manage-domains-form", ["exports", "campaign-warrior-app/components/manage-record-form", "jquery"], function (_exports, _manageRecordForm, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for edit or add a Domain
   *
   * Usage:
   *
   * ```hbs
   * {{manage-campaign-form record=model save=(action "save") tags=tags}}
   * ```
   *
   * @class ManageDomainForm
   * @namespace Components
   * @extends Components.ManageRecordForm
   */
  var _default = _manageRecordForm.default.extend({
    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),
    classNames: ['manage-domains-form'],

    /**
     * Allow to hide submit-button. Useful when form is shown in the modal
     *
     * @property hideControls
     * @type boolean
     * @default false
     */
    hideControls: false,
    urlValue: null,

    /**
     * @property inputDisabled
     * @type boolean
     * @default false
     */
    inputDisabled: Ember.computed('can', 'record.isNew', function () {
      if (Ember.get(this, 'record.isNew')) {
        return false;
      }

      return Ember.get(this, 'can').cannot('edit domain');
    }),
    didInsertElement: Ember.computed(function () {
      Ember.get(this, 'registerComponent')(this);
    }),

    willDestroyElement() {
      // this._super(...argument);
      Ember.get(this, 'deregisterComponent')();
    },

    actions: {
      save() {
        const dataToSend = Ember.getProperties(Ember.get(this, 'record'), ['url']);

        if (dataToSend.url) {
          return this._super(dataToSend);
        } else {// const data = {
          //   title: 'URL Empty',
          //   body: 'Please Enter a valid URL'
          // };
          // return get(this, 'modalsManager').errorHandler(data);
        }
      },

      submitForm() {
        const form = (0, _jquery.default)('form');
        (0, _jquery.default)(form).submit(); // console.log('in submitForm: ', filterForm);
      }

    }
  });

  _exports.default = _default;
});