define("campaign-warrior-app/components/create-domain-modal", ["exports", "campaign-warrior-app/components/modals-container/base", "ember-cp-validations"], function (_exports, _base, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for edit or create a Domain
   * @class createDomainForm
   * @namespace Components
   * @extends Components.ManageRecordForm
   */
  var _default = _base.default.extend({
    newDomain: true,
    addDomain: false,

    /**
     * @property statusValues
     * @type string[]
     * @private
     * @readonly
     */
    domainValues: Ember.computed(function () {
      // console.log('domains in modal: ', get(this, 'options.domains'));
      const domains = Ember.get(this, 'options.domains');
      let domainValues = [];

      if (domains) {
        //console.log("Domains", domains);
        domains.map(domain => {
          let domainUrl;
          let fullUrl = Ember.get(domain, 'rootUrl');
          if (!fullUrl) fullUrl = Ember.get(domain, 'url');

          if (fullUrl.includes('www.')) {
            domainUrl = '.' + Ember.get(domain, 'url').substring(Ember.get(domain, 'url').indexOf('.') + 1);
          } else {
            domainUrl = '.' + fullUrl;
          }

          if (domainValues.indexOf(domainUrl) === -1) {
            domainValues.push(domainUrl);
          }
        });
        return domainValues;
      } else {
        return null;
      }
    }),
    record: Ember.computed(function () {
      const Validations = (0, _emberCpValidations.buildValidations)({// subdomain: validator('presence', true),
        // domain: validator('presence', true),
        // add: validator('presence', true),
        // newValue: validator('presence', true),
        // buy: validator('presence', true),
      });
      return Ember.Object.extend(Validations, {}).create(Ember.getOwner(this).ownerInjection());
    }),
    actions: {
      save(e) {// console.log('Saved form: ', e);
      },

      addChecked(e) {
        // console.log('add Checked: ', e);
        if (e.length) {
          Ember.set(this, 'newDomain', true);
          Ember.set(this, 'addDomain', false);
        } else {
          Ember.set(this, 'newDomain', false);
          Ember.set(this, 'addDomain', false);
        }

        Ember.setProperties(Ember.get(this, 'options.record'), {
          url: '',
          subdomain: e,
          buy: false,
          add: true
        });
      },

      newChecked(e) {
        // console.log('new Checked: ', e);
        if (e.length) {
          Ember.set(this, 'addDomain', true);
          Ember.set(this, 'newDomain', false);
        } else {
          Ember.set(this, 'addDomain', false);
          Ember.set(this, 'newDomain', false);
        }

        Ember.setProperties(Ember.get(this, 'options.record'), {
          subdomain: '',
          domain: '',
          url: e,
          add: false,
          buy: true
        });
      },

      cancel(modal) {
        Ember.setProperties(Ember.get(this, 'options.record'), {
          subdomain: '',
          domain: '',
          url: '',
          add: false,
          buy: false
        });
        modal.close();
      }

    }
  });

  _exports.default = _default;
});