define("campaign-warrior-app/components/pages/search-pages-heading", ["exports", "campaign-warrior-app/components/cw-panel/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _title.default.extend({
    pages: null,
    tags: null,
    meta: null,
    addTag: null,
    actions: {
      addTagsForFilteredPages(tag) {
        this.addTag(tag);
      },

      applyFilters() {
        this.formData.domain = this.formData.domain ? this.formData.domain.id : this.formData.domain;
        this.triggerUpdate();
      },

      clearFilters() {
        // window.localStorage.removeItem('filters');
        Ember.setProperties(this.formData, {
          title: '',
          domain: 'any',
          url: '',
          meta: '',
          metaSizeFrom: null,
          metaSizeTo: null,
          funnelStage: 'Any',
          tagsList: [],
          tagList_enabled: false,
          omitTags: [],
          omitTags_enabled: false
        }); // this.send('onSubmit(false)');

        const filters = {
          domain: 'any',
          meta: '',
          title: '',
          url: '',
          id: '',
          metaSizeFrom: null,
          metaSizeTo: null,
          auditMeta: false,
          auditOverMeta: false,
          funnelStage: 'Any',
          tagsList: [],
          tagList_enabled: false,
          omitTags: [],
          omitTags_enabled: false
        };
        this.updateFilters(filters);
        const filterByMetric = this.filterByMetric;

        if (filterByMetric) {
          filterByMetric('');
        }

        this.triggerUpdate(true);
      }

    }
  });

  _exports.default = _default;
});