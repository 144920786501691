define("campaign-warrior-app/templates/components/modals/request-error-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+OFfCAZJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"payload\",\"requestData\",\"type\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[35,1,[\"payload\",\"requestData\",\"type\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,1,[\"payload\",\"requestData\",\"url\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"code\"],[12],[1,[35,1,[\"payload\",\"requestData\",\"url\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"msg\",\"error\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/request-error-body.hbs"
    }
  });

  _exports.default = _default;
});