define("campaign-warrior-app/utils/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numbersFilterForModelsTable = numbersFilterForModelsTable;
  _exports.tagsFilterForModelsTable = void 0;

  /**
   * Custom filter function for `models-table`.
   *
   * Used for columns with related Tags
   *
   * @param {*} c not needed value :D
   * @param {array} neededTags list of needed tags
   * @param {DS.Model} record record to check
   * @returns {boolean} true - record has all needed tags, false - otherwise
   */
  const tagsFilterForModelsTable = function (c, neededTags, record) {
    const tags = Ember.get(record, 'tags').mapBy('id');
    return neededTags ? Ember.makeArray(neededTags).every(f => tags.includes(f)) : true;
  };
  /**
   * Custom filter function for `models-table`.
   *
   * Used for columns with numbers shown
   * @param {*} value shown in the cell value
   * @param {string} neededValue needed value
   * @returns {boolean} true - record matches needed condition
   */


  _exports.tagsFilterForModelsTable = tagsFilterForModelsTable;

  function numbersFilterForModelsTable(value, neededValue = '') {
    const v = Number(value);
    neededValue = neededValue.trim();

    if (neededValue.startsWith('>')) {
      return v > Number(neededValue.replace('>', '').trim());
    }

    if (neededValue.startsWith('<')) {
      return v < Number(neededValue.replace('<', '').trim());
    }

    if (neededValue.startsWith('=')) {
      return v === Number(neededValue.replace('=', '').trim());
    }

    if (neededValue.includes('...')) {
      const [min, max] = neededValue.split('...').map(n => Number(n.trim()));
      return v >= min && v <= max;
    }

    return !neededValue || v === Number(neededValue);
  }
});