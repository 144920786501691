define("campaign-warrior-app/routes/portals/portal/pages", ["exports", "campaign-warrior-app/mixins/portal-internal-route"], function (_exports, _portalInternalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PortalPages
   * @uses Mixins.PortalInternalRoute
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_portalInternalRoute.default, {
    breadCrumbRoutingKey: 'pages',

    beforeModel() {// const portal = this.modelFor('portals.portal');
      // const portal_id = get(portal, 'id');
      // return get(this, 'store').query('tag', {portal_id});
    }

  });

  _exports.default = _default;
});