define("campaign-warrior-app/templates/portals/portal/setup/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h5uEESEn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"setup-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"setup-panel\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-heading\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Step 2 \"],[10,\"span\"],[14,0,\"step-name\"],[12],[2,\"Setup your Portal\"],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-body\"],[12],[2,\"\\n      \"],[10,\"h4\"],[12],[2,\"Connect Your Website\"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"setup-description\"],[12],[2,\"There are two ways to connect your website.\"],[10,\"br\"],[12],[13],[2,\"\\n        1) If you are on the HubSpot CMS you can directly connect.\"],[10,\"br\"],[12],[13],[2,\"\\n        2) We poll the site map of your website. This is what search engines look for.\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-controls\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-hubspot\"]],[[\"@route\",\"@query\"],[\"portals.portal.setup.hubspot\",[30,[36,0],null,[[\"redirectUrl\"],[\"setup\"]]]]],[[\"default\"],[{\"statements\":[[2,\"HubSpot\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@query\"],[\"portals.portal.setup.website\",[30,[36,0],null,[[\"redirectUrl\"],[\"setup\"]]]]],[[\"default\"],[{\"statements\":[[2,\"Site Map\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"hash\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/setup/setup.hbs"
    }
  });

  _exports.default = _default;
});