define("campaign-warrior-app/components/floating-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base-component for panel-forms
   *
   * Usage:
   *
   * ```javascript
   * // app/components/some-form.js
   * import FloatingForm from 'floating-form';
   *
   * export default FloatingForm.extend({});
   * ```
   *
   * @namespace Components
   * @class FloatingForm
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['floating-form-wrapper'],

    /**
     * @property submitInProgress
     * @type boolean
     * @default false
     * @private
     */
    submitInProgress: false,

    /**
     * Initial values for form-fields
     *
     * @property initialData
     * @type object
     * @default null
     */
    initialData: null,

    /**
     * External action-handler for form submit, bound from the parent context
     *
     * Handler will receive one argument - form data
     *
     * @event onSubmit
     */
    onSubmit: null,

    /**
     * @private
     * @property formData
     * @type Object
     * @default {}
     */
    formData: Ember.computed(function () {
      return {};
    }),

    /**
     * Set some initial values to the `formData`
     */
    didInsertElement() {
      const initialData = Ember.get(this, 'initialData');

      if (Ember.isPresent(initialData)) {
        Object.keys(initialData).forEach(k => Ember.set(this, `formData.${k}`, initialData[k]));
      }

      this._super(...arguments);
    },

    actions: {
      /**
       * Internal handler for form submit
       * Calls external handler
       *
       * @method actions.onSubmit
       */
      onSubmit() {
        Ember.set(this, 'submitInProgress', true);
        const result = Ember.get(this, 'onSubmit')(Ember.get(this, 'formData'));

        if (result && result.finally) {
          result.finally(() => Ember.set(this, 'submitInProgress', false));
        } else {
          Ember.set(this, 'submitInProgress', false);
        }
      }

    }
  });

  _exports.default = _default;
});