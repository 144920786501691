define("campaign-warrior-app/controllers/complete-registration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompleteRegistrationController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed(), _dec8 = Ember.computed('currentUser.user'), _dec9 = Ember.computed('isTrial'), _dec10 = Ember.computed.reads('currentUser.user.company.billingInfo'), _dec11 = Ember._action, _dec12 = Ember._action, (_class = (_temp = class CompleteRegistrationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modalsManager", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "recurly", _descriptor3, this);

      _initializerDefineProperty(this, "currentUser", _descriptor4, this);

      _initializerDefineProperty(this, "notify", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _defineProperty(this, "login", null);

      _defineProperty(this, "portals", null);

      _defineProperty(this, "requestInProgress", false);

      _defineProperty(this, "isChoosen", false);
    }

    get isTrial() {
      return true;
    }

    get userData() {
      return this.currentUser.user;
    }

    get planName() {
      if (this.isTrial) {
        return '14 Day Trial';
      }

      return 'Full Plan';
    }

    get billingInfo() {
      return Ember.get(this, 'currentUser.user.company.billingInfo');
    }

    async doRegister(form, formData, errorBody) {
      const modalsManager = Ember.get(this, 'modalsManager');
      const ajax = this.ajax;
      const router = this.router;
      form.isTrial = this.isTrial;
      let token;

      try {
        token = await Ember.get(this, 'recurly').getToken(form);
      } catch (err) {
        modalsManager.billingErrorHandler(err);
        return;
      }

      if (token) {
        try {
          form['recurly-token'] = token;
          console.log("Completing registration");
          let result = await ajax.post('/complete-registration', {
            data: form
          });
          Ember.set(this, 'currentUser.company.incomplete', false);
          console.log("Registration completed", result);
          await router.transitionTo('portals.create.step1');
        } catch (err) {
          modalsManager.registrationErrorHandler(err);
          return;
        }
      }
    }

    selectPlan(planName) {
      Ember.set(this, "isChoosen", true);

      if (planName === 'trial') {
        Ember.set(this, 'isTrial', true);
      } else if (planName === 'full') {
        Ember.set(this, 'isTrial', false);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "recurly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isTrial", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isTrial"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userData", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "userData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "planName", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "planName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "billingInfo", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "billingInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doRegister", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "doRegister"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectPlan", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectPlan"), _class.prototype)), _class));
  _exports.default = CompleteRegistrationController;
  ;
});