define("campaign-warrior-app/components/domains/domain-created", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @namespace Components
   * @extends Components.ManageRecordForm
   */
  var _default = _base.default.extend({
    trackingCode: Ember.computed(function () {
      return Ember.get(this, 'options.trackingCode');
    }),
    actions: {
      copyClose(modal) {
        //console.log('Copyclose');
        const el = document.createElement('textarea');
        el.value = Ember.get(this, 'trackingCode');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        modal.submit();
      }

    }
  });

  _exports.default = _default;
});