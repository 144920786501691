define("campaign-warrior-app/routes/portals/portal/accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Accounts
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: {
      type: {
        refreshModel: true
      }
    },
    parameters: null,
    rootElementClasses: Object.freeze(['accounts-list-page']),

    async beforeModel(transition) {
      Ember.set(this, 'parameters', transition.queryParams);
    },

    model() {
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      let integrations = Ember.get(this, 'store').query('integration', {
        portal_id
      });
      return {
        integrations
      };
    },

    afterModel() {
      const qp = this.paramsFor('portals.portal.accounts.index');
      Ember.set(this, 'breadCrumb', {
        title: Ember.String.capitalize(qp.sourceTypeToFilter)
      });
    },

    setupController(controller) {
      this._super(...arguments);

      Ember.set(controller, 'portal', this.modelFor('portals.portal'));
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(controller, 'sourceTypeToFilter', 'all');
      }
    },

    actions: {
      didTransition() {
        const i18n = Ember.get(this, 'i18n');
        const controller = Ember.get(this, 'controller');
        Ember.set(controller, 'portal', this.modelFor('portals.portal'));
      },

      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});