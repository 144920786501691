define("campaign-warrior-app/helpers/file-name-trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fileNameTrim = fileNameTrim;
  _exports.default = void 0;

  /**
   * Trims file name if it's greater than allowed size. Extension is always shown! Only name is trimmed.
   *
   * Usage:
   *
   * ```hbs
   * {{file-name-trim fileName 15}}
   * ```
   *
   * Input - `some_very_long_file_name.png`
   *
   * Output - `some_very_long_[...].png`
   *
   * @namespace Helpers
   * @class FileNameTrim
   *
   * @param {string} fileName file name to trim
   * @param {number} size max file name length without trimming
   * @returns {string} trimmed file name
   */
  function fileNameTrim([fileName, size]
  /*, hash*/
  ) {
    const chunks = (fileName || '').split('.');
    const ext = chunks.pop();
    const name = chunks.join('.');

    const _size = size || 18;

    const trimmedName = name.length < _size ? name : name.substr(0, _size) + '[...]';
    return `${trimmedName}.${ext}`;
  }

  var _default = Ember.Helper.helper(fileNameTrim);

  _exports.default = _default;
});