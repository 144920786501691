define("campaign-warrior-app/components/trial-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class TrialModal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['modals-branding', 'trial-modal'],

    /**
     * @property trialEndDate
     * @type number
     * @default null
     * @private
     * @readonly
     */
    trialEndDate: Ember.computed.reads('currentUser.user.company.billingInfo.trial.end-date'),

    /**
     * @property trialExpired
     * @type boolean
     * @default false
     * @private
     * @readonly
     */
    trialExpired: Ember.computed.reads('currentUser.user.company.billingInfo.trialExpired'),

    /**
     * @property showSimple
     * @type boolean
     * @private
     * @readonly
     */
    showSimple: Ember.computed('currentUser.user.company.billingInfo.created', function () {
      return new Date().getTime() - Ember.get(this, 'currentUser.user.company.billingInfo.created') < 15 * 60 * 1000;
    }).readOnly()
  });

  _exports.default = _default;
});