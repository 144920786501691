define("campaign-warrior-app/controllers/portals/portal/integrations/hubspots/hubspot", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      Ember.run.later(() => {
        const titles = (0, _jquery.default)('.panel-title');

        for (let i = 0; i < titles.length; i++) {
          const text = (0, _jquery.default)(titles[i]).text().replace(/Hubspot/g, 'HubSpot');
          (0, _jquery.default)(titles[i]).text(text);
        }
      }, 2500);
    },

    actions: {
      onSubmit() {},

      connectHSSocial(channel) {
        console.log('Connect HubSpot Social channel', channel);
        let attributes = Ember.get(channel, 'attributes');
        console.log('Attributes', attributes.channelGuid);
        Ember.set(channel.publishingSource, 'viaIntegration', channel.integrationId);
        Ember.set(channel.publishingSource, 'viaPlatform', 'hubspot');
        Ember.set(channel.publishingSource, 'hubspotChannelGuid', attributes.channelGuid);
        console.log('Publishing Source', channel.publishingSource);
        channel.publishingSource.save();
        this.send('refreshRoute');
      },

      disconnectHSSocial(channel) {
        console.log('Disconnect HubSpot Social Channel', channel);
        Ember.set(channel.publishingSource, 'viaIntegration', null);
        Ember.set(channel.publishingSource, 'viaPlatform', null);
        Ember.set(channel.publishingSource, 'hubspotChannelGuid', null);
        channel.publishingSource.save();
        this.send('refreshRoute');
      }

    }
  });

  _exports.default = _default;
});