define("campaign-warrior-app/templates/components/portal-styling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rBHjr9qd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"panelStyle\",\"menuBarStyle\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/portal-styling.hbs"
    }
  });

  _exports.default = _default;
});