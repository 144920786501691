define("campaign-warrior-app/components/cw-table/trimmed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component for `models-table` cells that should show some trimmed value
   *
   * Usage:
   * ```js
   * columns: [
   *   {
   *     propertyName: 'some',
   *     hideTooltip: true,
   *     component: 'cw-table/trimmed',
   *     trimSize: 40
   *   }
   * ]
   * ```
   *
   * Trimmed to 40 symbols value of `some` property will be shown in the table cell
   *
   * @class CwTableTrimmed
   * @extends Ember.Component
   * @namespace Components
   */
  var _default = Ember.Component.extend({});

  _exports.default = _default;
});