define("campaign-warrior-app/routes/portals/create/step1", ["exports", "campaign-warrior-app/routes/portals/create/step"], function (_exports, _step) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Step1
   * @namespace Routes.CreatePortal
   * @extends Routes.CreatePortal.WizardStep
   */
  var _default = _step.default.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property can
     * @type Ember.Service
     */
    can: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),
    controller: null,
    step: 1,
    title: 'Add a New Portal | Campaign Warrior',

    beforeModel() {
      if (Ember.get(this, 'can').cannot('create portal')) {
        Ember.get(this, 'router').transitionTo('portals');
      }

      return this._super(...arguments);
    },

    model() {
      return Ember.get(this, 'store').findAll('portal');
    },

    activate() {
      const portalsCount = Ember.get(this, 'currentUser.user.company.billingInfo.portalsCount');
      const modalsManager = Ember.get(this, 'modalsManager');

      const _this = this;

      if (portalsCount >= 1) {
        return modalsManager.show('portals/confirm-create').then(result => {
          if (!result.confirmed) {
            _this.router.transitionTo('portals');
          }
        }).catch(() => {
          _this.router.transitionTo('portals');
        });
      }
    },

    /**
     * @param {object} controller step1 controller
     */
    setupController(controller) {
      this._super(...arguments);

      Ember.set(this, 'controller', controller);
    }

  });

  _exports.default = _default;
});