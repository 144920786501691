define("campaign-warrior-app/routes/portals/portal/developer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Developer
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    model() {
      return Ember.get(this, 'store').findAll('portal');
    },

    setupController(controller) {
      this._super(...arguments);

      const internalUserType = Ember.get(controller, 'currentUser.user.internalType');

      if (internalUserType !== 'developer' && internalUserType !== 'admin') {
        Ember.get(controller, 'router').transitionTo('portals');
      }
    }
    /*
    beforeModel(model){
      this._super(model);
      console.log("routes.beforeModel()", model);
    },
     afterModel(model){
      const user = get(this, 'currentUser');
      this._super(model);
    }*/


  });

  _exports.default = _default;
});