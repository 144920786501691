define("campaign-warrior-app/templates/components/modals/delete-dir-confirm-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RBE38jVO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[14,0,\"modal-title\"],[12],[10,\"i\"],[14,0,\"fa fa-fw fa-warning\"],[12],[13],[2,\" \"],[1,[35,0,[\"title\"]]],[13]],\"hasEval\":false,\"upvars\":[\"options\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/delete-dir-confirm-title.hbs"
    }
  });

  _exports.default = _default;
});