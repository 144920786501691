define("campaign-warrior-app/routes/portals/portal/error", ["exports", "campaign-warrior-app/utils/consts"], function (_exports, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortalErrorRoute extends Ember.Route {
    model() {
      console.log("Portal Error route model");
    }

    setupController(controller, model) {
      console.log("Portals Error route setup controller", model);
      Ember.setProperties(controller, {
        errorDetail: model.message
      });

      this._super(...arguments);
    }

  }

  _exports.default = PortalErrorRoute;
});