define("campaign-warrior-app/templates/components/page-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VO9iU6Ke",
    "block": "{\"symbols\":[\"@iconSrc\",\"@title\",\"@description\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"icon-wrapper\"],[12],[10,\"img\"],[14,0,\"icon\"],[15,\"src\",[32,1]],[14,\"alt\",\"\"],[12],[13],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"description\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[1,[30,[36,2],[[30,[36,1],[[32,2]],null],[30,[36,0],[[32,2]],null],[32,2]],null]],[13],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],[[32,3]],null],[30,[36,0],[[32,3]],null],[32,3]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[18,4,null]],\"hasEval\":false,\"upvars\":[\"t\",\"t-key-exists\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/page-helper.hbs"
    }
  });

  _exports.default = _default;
});