define("campaign-warrior-app/serializers/page", ["exports", "campaign-warrior-app/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Page
   * @namespace Serializers
   * @extends Serializers.Application
   */
  var _default = _application.default.extend({
    serialize() {
      const json = this._super(...arguments); // delete this attributes before sending a payload,
      // because of page model does not have this fields as persist in database


      delete json.data.attributes['likes-count'];
      delete json.data.attributes['shares-count'];
      return json;
    },

    /**
     * @method normalizeSingleResponse
     * @param {DS.Store} store store itself
     * @param {DS.Model} primaryModelClass Page Model
     * @param {Object} payload server response
     * @return {Object} JSON-API Document
     */
    normalizeArrayResponse(store, primaryModelClass, payload) {
      payload.data.forEach(page => {
        if (Ember.isNone(page.attributes.meta)) {
          Ember.set(page, 'attributes.meta', '');
        }
      });
      return this._super(...arguments);
    },

    /**
     * @method normalizeSaveResponse
     * @param {DS.Store} store store itself
     * @param {DS.Model} primaryModelClass Page Model
     * @param {Object} payload server response
     * @return {Object} JSON-API Document
     */
    normalizeSingleResponse(store, primaryModelClass, payload) {
      if (Ember.isNone(payload.data.attributes.meta)) {
        Ember.set(payload, 'data.attributes.meta', '');
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});