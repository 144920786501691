define("campaign-warrior-app/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Application
   * @extends DS.JSONAPISerializer
   * @namespace Serializers
   */
  class ApplicationSerializer extends _emberData.default.JSONAPISerializer {
    normalizeQueryResponse(store, clazz, payload) {
      // console.log("NormalizeQueryResponse", payload);
      const result = super.normalizeQueryResponse(...arguments);
      result.meta = result.meta || {}; //console.log("Normalize query payload", payload);

      if (payload.links) {
        result.meta.pagination = this.createPageMeta(payload.links);
      }

      return result;
    }

    createPageMeta(data) {
      let meta = {};
      Object.keys(data).forEach(type => {
        const link = data[type];
        meta[type] = {};
        let a = document.createElement('a');
        a.href = link;
        a.search.slice(1).split('&').forEach(pairs => {
          const [param, value] = pairs.split('=');

          if (param === 'page%5Bnumber%5D') {
            meta[type].number = parseInt(value);
          }

          if (param === 'page%5Bsize%5D') {
            meta[type].size = parseInt(value);
          }
        });
        a = null;
      });
      return meta;
    }

  }

  _exports.default = ApplicationSerializer;
});