define("campaign-warrior-app/models/tile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Tile
   * @namespace Models
   * @extends DS.Model
   */
  const Tile = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),
    template: _emberData.default.attr('string'),
    'action-type': _emberData.default.attr('string'),
    row: _emberData.default.attr('number'),
    column: _emberData.default.attr('number'),
    hidden: _emberData.default.attr('boolean'),
    free: _emberData.default.attr('boolean'),
    paid: _emberData.default.attr('boolean'),
    portal: _emberData.default.belongsTo('portal'),
    user: _emberData.default.belongsTo('user')
  });

  var _default = Tile;
  _exports.default = _default;
});