define("campaign-warrior-app/torii-providers/twitter", ["exports", "torii/providers/twitter-oauth1"], function (_exports, _twitterOauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Override original Facebook OAuth2 provider from [Torii](https://github.com/Vestorly/torii)
   *
   * @class TwitterOauth1
   * @namespace ToriiProviders
   * @extends Torii.Providers.TwitterOauth1
   */
  var _default = _twitterOauth.default.extend({
    open: function (options) {
      const url = `${Ember.get(this, 'requestTokenUri')}${options.oauthToken}`;
      return Ember.get(this, 'popup').open(url, ['oauth_verifier'], options);
    }
  });

  _exports.default = _default;
});