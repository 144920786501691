define("campaign-warrior-app/adapters/scrape-job", ["exports", "campaign-warrior-app/adapters/application", "campaign-warrior-app/mixins/adapter-portal-related"], function (_exports, _application, _adapterPortalRelated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ScrapeJob
   * @namespace Adapters
   * @extends Adapters.Application
   * @uses Mixins.AdapterPortalRelated
   */
  var _default = _application.default.extend(_adapterPortalRelated.default, {});

  _exports.default = _default;
});