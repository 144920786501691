define("campaign-warrior-app/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    title: 'Login | Campaign Warrior',
    rootElementClasses: Object.freeze(['login-page']),

    async beforeModel(transition) {// console.log('Login Route beforeModel()');
    }

  });

  _exports.default = _default;
});