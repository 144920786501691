define("campaign-warrior-app/components/cw-table/link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component for `models-table` cells that should be like links to other pages with at least two dynamic parts.
   *
   * Usage:
   * ```js
   * columns: [
   *   {
   *     propertyName: 'some', // shows as link anchor
   *     component: 'cw-table/link-to',
   *     parentRecordIds: ['1', '2'], // bookId, chapterId
   *     route: 'books.book.chapters.chapter.pages.page' // not `routeName`!
   *   }
   * ]
   * ```
   *
   * In this case click on link will move user to the page `/books/1/chapters/2/pages/3`. Here `3` - is a record identifier
   *
   * @class CwTableLinkTo
   * @extends Ember.Component
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),
    actions: {
      /**
       * @method actions.go
       */
      go() {
        const column = Ember.get(this, 'column');
        const parentRecordIds = Ember.get(this, 'column.parentRecordIds') || [];
        const id = Ember.get(this, 'record.id');
        const route = Ember.get(column, 'route');
        const router = Ember.get(this, 'router');
        const withoutCurrent = Ember.get(column, 'withoutCurrent');
        const domainToWebPages = Ember.get(column, 'domainLink');

        if (domainToWebPages) {
          const domainUrl = Ember.get(this, 'record.url');
          const filters = {
            domain: [],
            meta: '',
            metaSize: [],
            name: '',
            publishSourceTypes: [],
            tags: [],
            id: '',
            urls: '',
            auditMeta: false,
            setAllTags: false,
            omitAllTags: false
          };
          filters.domain = [];
          filters.domain.push(domainUrl);
          filters.id = Ember.get(this, 'currentPortal.portal.id');
          window.localStorage.setItem('filters', JSON.stringify(filters));
        }

        let args = [route, ...parentRecordIds];

        if (!withoutCurrent) {
          args.push(id);
        }

        router.transitionTo.apply(router, args);
      }

    }
  });

  _exports.default = _default;
});