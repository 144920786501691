define("campaign-warrior-app/templates/components/modals/request-error-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HPOu0oD6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[14,0,\"modal-title\"],[12],[1,[34,0]],[13]],\"hasEval\":false,\"upvars\":[\"title\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/modals/request-error-header.hbs"
    }
  });

  _exports.default = _default;
});