define("campaign-warrior-app/routes/portals/portal/domains/list", ["exports", "campaign-warrior-app/utils/consts"], function (_exports, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @class PortalPostsList
   * @namespace Routes
   * @extends Ember.Route
   */
  let DomainsListRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class DomainsListRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentPortal", _descriptor, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor2, this);

      _defineProperty(this, "title", 'Domains | Campaign Warrior');
    }

    model() {
      const store = Ember.get(this, 'store');
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      return store.query('domain', {
        portal_id,
        ...(0, _consts.defaultPaginationSettings)()
      });
    }

    activate() {
      const atLimit = this.currentPortal.portal.potentialPages >= this.currentPortal.portal.pagesLimit;
      const modalsManager = Ember.get(this, 'modalsManager');

      const _this = this;
      /* disable page upgrade modal for now
      if (atLimit) {
          _this.modalsManager.show('domains/pages-limit-upgrade-modal', {}).catch((err) => {
          console.error("Page limit upgrade modal", err);
        })
      }
         */

    }
    /**
     * @param {object} controller current page controller
     */


    setupController(controller) {
      this._super(...arguments);

      Ember.set(controller, 'newDomain', controller.setupNewDomain());
      Ember.set(controller, 'allDomains', controller.settingDomains());
      Ember.set(controller, 'interval', controller.clearCreateInterval());
    }

    willTransition() {
      const newDomain = Ember.get(this, 'controller.newDomain');

      if (Ember.get(newDomain, 'isNew')) {
        newDomain.destroyRecord();
      }
    }

    refreshRoute() {
      this.refresh();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshRoute", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "refreshRoute"), _class.prototype)), _class));
  _exports.default = DomainsListRoute;
});