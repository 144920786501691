define("campaign-warrior-app/models/snippet", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Hashtag
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    associatedEntityId: _emberData.default.attr('string'),
    associatedEntityType: _emberData.default.attr('string'),
    portal: _emberData.default.belongsTo('portal')
  });

  _exports.default = _default;
});