define("campaign-warrior-app/adapters/application", ["exports", "ember-data", "ember-ajax/mixins/ajax-support"], function (_exports, _emberData, _ajaxSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Application
   * @namespace Adapters
   * @extends DS.JSONAPIAdapter
   * @uses EmberAjax.AjaxServiceSupport
   * @uses EmberSimpleAuth.DataAdapterMixin
   */
  var _default = _emberData.default.JSONAPIAdapter.extend(_ajaxSupport.default, {
    authorizer: 'authorizer:token'
  });

  _exports.default = _default;
});