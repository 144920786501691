define("campaign-warrior-app/components/cw-panel", ["exports", "campaign-warrior-app/mixins/expandable", "ember-local-storage"], function (_exports, _expandable, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Basic panel with title and body.
   *
   * Usage:
   *
   * ```hbs
   * {{cw-panel
   *   title=(t "cool.title")
   *   expanded=false
   *   titleComponent=(component "someComponent")
   *   bodyComponent=(component "anotherComponent")
   * }}
   * ```
   *
   * Panel's `expanded`-state can be stored in the local-storage. To enable this feature provide a `name`-attribute.
   *
   * @class CwPanel
   * @namespace Components
   * @extends Ember.Component
   * @uses Mixins.Expandable
   */
  var _default = Ember.Component.extend(_expandable.default, {
    /**
     * @property currentPortal
     * @type Ember.Service
     */
    currentPortal: Ember.inject.service(),
    classNames: ['cw-panel'],

    /**
     * Panel title
     *
     * @property title
     * @type string
     * @default ''
     */
    title: '',

    /**
     * Extra component shown on the right of title
     *
     * @property titleComponent
     * @default null
     * @type Ember.Component
     */
    titleComponent: null,

    /**
     * Panel-body. Put here anything you want to show in the panel
     *
     * @property bodyComponent
     * @default null
     * @type Ember.Component
     */
    bodyComponent: null,

    /**
     * @property name
     * @type string
     * @default null
     */
    name: '',

    /**
     * @property panelsStorage
     * @type object
     * @default {}
     */
    panelsStorage: (0, _emberLocalStorage.storageFor)('panels'),

    /**
     * Identifier for current panel used in the `panelsStorage`
     *
     * @property panelId
     * @type string
     * @private
     * @readonly
     */
    panelId: Ember.computed('currentPortal.portal.id', function () {
      return `${Ember.get(this, 'currentPortal.portal.id')}-${Ember.get(this, 'name').replace(/\./g, '-')}`;
    }).readOnly(),

    /**
     * Identifier for current panel `expanded`-state stored in the `panelsStorage`. It's equal to the `panelId` with suffix `-expanded`
     *
     * @property expandedStorageId
     * @type string
     * @private
     * @readonly
     */
    expandedStorageId: Ember.computed('panelId', function () {
      return `${Ember.get(this, 'panelId')}-expanded`;
    }).readOnly(),

    /**
     * @property expanded
     * @type boolean
     * @default false
     */
    expanded: Ember.computed('expandable', {
      get() {
        if (!Ember.get(this, 'expandable')) {
          return true;
        }

        const expandedStorageId = Ember.get(this, 'expandedStorageId');
        const value = Ember.get(this, `panelsStorage.${expandedStorageId}`);
        return Ember.isNone(value) ? true : value;
      },

      set(key, value) {
        const name = Ember.get(this, 'name');

        if (name) {
          const expandedStorageId = Ember.get(this, 'expandedStorageId');
          Ember.set(this, `panelsStorage.${expandedStorageId}`, value);
        }

        return value;
      }

    }),
    actions: {
      createNote(instance) {
        return Ember.get(this, 'createNote')(instance);
      }

    }
  });

  _exports.default = _default;
});