define("campaign-warrior-app/templates/components/notes/create-note-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9MaSTSO3",
    "block": "{\"symbols\":[\"modal\",\"form\"],\"statements\":[[8,\"bs-modal\",[],[[\"@open\",\"@onSubmit\",\"@onHide\"],[[34,0],[30,[36,1],[[32,0],\"confirm\"],null],[30,[36,1],[[32,0],\"confirm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[12],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Create New Note\"]],\"parameters\":[]},{\"statements\":[[2,\"Edit Note\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[34,4],[30,[36,1],[[32,0],\"confirm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\",\"@rows\"],[\"textarea\",\"Description\",\"description\",\"4\"]],null],[2,\"\\n\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"    \"],[8,\"bs-button\",[[24,0,\"btn-primary\"]],[[\"@onClick\"],[[30,[36,1],[[32,0],[32,1,[\"submit\"]]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,5],[\"notes.create.save\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"modalIsOpened\",\"action\",\"creating\",\"if\",\"formData\",\"t\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/notes/create-note-modal.hbs"
    }
  });

  _exports.default = _default;
});