define("campaign-warrior-app/routes/portals/portal/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Support
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    model() {
      return Ember.get(this, 'store').findAll('portal');
    },

    setupController(controller) {
      this._super(...arguments);
      /*const internalUserType = get(controller, 'currentUser.user.internalType');
       if (internalUserType !== 'support' && internalUserType !== 'admin'){
        get(controller, 'router').transitionTo('portals');
      }*/

    }

  });

  _exports.default = _default;
});