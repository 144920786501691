define("campaign-warrior-app/models/external-image", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class File
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    /**
     * @type DS.StringAttribute
     * @default null
     * @property name
     */
    filename: _emberData.default.attr('string'),

    /**
     * @type DS.StringAttribute
     * @default null
     * @property preview
     */
    src: _emberData.default.attr('string'),
    alt: _emberData.default.attr('string'),
    fileSize: _emberData.default.attr('number'),
    portal: _emberData.default.belongsTo('portal')
  });

  _exports.default = _default;
});