define("campaign-warrior-app/routes/complete-registration", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    title: 'Complete Registration | Campaign Warrior',
    rootElementClasses: Object.freeze(['register-page'])
  });

  _exports.default = _default;
});