define("campaign-warrior-app/templates/components/material/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v7wwqL3O",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"filter-item material-form-select\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"filter-element-wrapper\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"label-span\"],[12],[1,[34,5]],[13],[2,\"\\n\"],[6,[37,11],null,[[\"placeholder\",\"class\",\"options\",\"searchField\",\"selected\",\"onChange\"],[[35,5],\"long-options\",[35,10],\"name\",[35,9],[30,[36,8],[[32,0],[30,[36,7],[[35,6]],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[15,0,[31,[[34,1],[30,[36,2],[[32,1],[35,0]],null]]]],[12],[13],[2,\" \"],[10,\"span\"],[12],[1,[30,[36,2],[[32,1],[35,3]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"span\"],[15,0,[31,[[34,1],[34,0]]]],[12],[13],[2,\" \"],[10,\"span\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"script\"],[12],[2,\"\\n  $(document).ready(function(){\\n    $('.material-form-select .ember-power-select-trigger').focus(function(){\\n      $(this).prev().addClass('label-active');\\n      //$('.material-form-select .label-span').addClass('label-active');\\n    })\\n    $('.material-form-select .ember-power-select-trigger').blur(function(){\\n      $(this).prev().removeClass('label-active');\\n    })\\n  })\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"iconId\",\"iconClass\",\"get\",\"optionalProp\",\"if\",\"label\",\"property\",\"mut\",\"action\",\"selected\",\"options\",\"power-select\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/material/select.hbs"
    }
  });

  _exports.default = _default;
});