define("campaign-warrior-app/templates/components/notes/notes-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oSrWr6e2",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[35,0,[\"assignedTo\"]],[35,1,[\"assigned\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[35,0,[\"assignedTo\",\"firstName\"]]],[2,\" \"],[1,[35,0,[\"assignedTo\",\"lastName\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[35,0,[\"createdBy\"]],[35,1,[\"created\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[35,0,[\"createdBy\",\"firstName\"]]],[2,\" \"],[1,[35,0,[\"createdBy\",\"lastName\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"record\",\"column\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/notes/notes-names.hbs"
    }
  });

  _exports.default = _default;
});