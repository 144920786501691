define("campaign-warrior-app/torii-providers/facebook", ["exports", "torii/providers/facebook-oauth2"], function (_exports, _facebookOauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Override original Facebook OAuth2 provider from [Torii](https://github.com/Vestorly/torii)
   *
   * Use `token` and not `code` for auth. See `response_type` in the [Manually Build a Login Flow](https://developers.facebook.com/docs/facebook-login/manually-build-a-login-flow)
   *
   * @class FacebookOauth2
   * @namespace ToriiProviders
   * @extends Torii.Providers.FacebookOauth2
   */
  var _default = _facebookOauth.default.extend({
    responseType: 'token',
    responseParams: Ember.computed(function () {
      return ['token', 'state'];
    }),
    redirectUri: Ember.computed(function () {
      const {
        protocol,
        host
      } = window.location;
      return [protocol, '//', host, '/torii/redirect.html'].join('');
    }),

    fetch(data) {
      return data;
    }

  });

  _exports.default = _default;
});