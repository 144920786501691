define("campaign-warrior-app/components/hashtags/manage-hashtags-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```js
   * import Component from '@ember/component';
   * import {get} from '@ember/object';
   * import {inject as service} from '@ember/service';
   *
   * export default Component.extend({
   *   modalsManager: service(),
   *
   *   actions: {
   *     showModal() {
   *       get(this, 'modalsManager')
   *         .show('add-many-pages-form/confirmation-modal', {
   *           newUrls: get(this, 'newUrls'),
   *           existingUrls: get(this, 'existingUrls')
   *         })
   *         .then(urls => console.log(urls));
   *     }
   *   }
   * });
   * ```
   *
   * Here `newUrls` is an array of URLs that user want to add and `existingUrls` is a list of already registered URLs.
   * Last one may be get by `store.query('page', {portal_id: '1'}).then(pages => pages.mapBy('url'))`
   *
   * @class AddManyPagesConfirmationModal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    /**
     * @property urlsToAdd
     * @type object[]
     * @default null
     * @private
     */
    hashtagsToAdd: null,

    /**
     * @property data
     * @type object[]
     * @private
     * @readOnly
     */
    data: Ember.computed('options.hashtags.[]', function () {
      //const htags =  get(this, 'options.hashtags').map(hashtag => ({hashtag}));
      return Ember.get(this, 'options.hashtags');
    }).readOnly(),

    /**
     * @property preselectedItems
     * @type object[]
     * @private
     * @readonly
     */
    preselectedItems: Ember.computed('{options.hashtags,data}.[]', function () {
      const existingHashtags = Ember.get(this, 'options.existingHashtags'); //console.log('Pre selected Hashtags', existingHashtags);
      //console.log('Data', get(this, 'data'));

      return Ember.get(this, 'data').filter(item => !existingHashtags.includes(item.name));
    }).readOnly(),

    /**
     * @property columns
     * @type object[]
     * @private
     * @readOnly
     */
    columns: Ember.computed(function () {
      return [{
        propertyName: 'name'
      }];
    }).readOnly(),
    actions: {
      onRowClick(data) {
        Ember.set(this, 'hashtagsToAdd', data.selectedItems);
      },

      confirm() {
        let hashtagsToAdd = Ember.get(this, 'hashtagsToAdd');

        if (!hashtagsToAdd) {
          hashtagsToAdd = Ember.get(this, 'preselectedItems');
        }

        return this._super(hashtagsToAdd);
      }

    }
  });

  _exports.default = _default;
});