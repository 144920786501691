define("campaign-warrior-app/routes/portals/portal/setup/related-content-settings", ["exports", "campaign-warrior-app/utils/consts"], function (_exports, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RelatedContentSettingsRoute extends Ember.Route {}

  _exports.default = RelatedContentSettingsRoute;
});