define("campaign-warrior-app/components/emmi-modals-container", ["exports", "ember-modals-manager-internal/components/emmi-modals-container"], function (_exports, _emmiModalsContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emmiModalsContainer.default;
    }
  });
});