define("campaign-warrior-app/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YuIEDErF",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"container-full portal-\",[34,1,[\"portal\",\"id\"]]]]],[12],[2,\"\\n  \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,4],null,[[\"messageStyle\",\"closeAfter\",\"classPrefix\"],[\"bootstrap\",2000,\"cw-notify\"]]]],[2,\"\\n\"],[1,[34,5]]],\"hasEval\":false,\"upvars\":[\"portal-styling\",\"currentPortal\",\"-outlet\",\"component\",\"ember-notify\",\"modals-container\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/application.hbs"
    }
  });

  _exports.default = _default;
});