define("campaign-warrior-app/components/pages/page-type-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PageTypeCell = (_dec = Ember.computed('record.hsLastUpdated'), (_class = class PageTypeCell extends Ember.Component {
    get pageType() {
      if (!this.record.hsPageType) {
        return 'External';
      }

      switch (this.record.hsPageType) {
        case 'site_page':
          return 'Site';

        case 'landing_page':
          return 'Landing';

        case 'normal_blog_post':
          return 'Post';

        case 'imported_blog_post':
          return 'Post';

        default:
          return this.record.hsPageType;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "pageType", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pageType"), _class.prototype)), _class));
  _exports.default = PageTypeCell;
});