define("campaign-warrior-app/components/material/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Input = (_dec = Ember.computed('property'), _dec2 = Ember.computed('autocomplete'), _dec3 = Ember.computed('validation', 'field'), _dec4 = Ember.computed('property', 'change'), _dec5 = Ember._action, (_class = (_temp = class Input extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "property", null);

      _defineProperty(this, "label", "");

      _defineProperty(this, "type", "text");

      _defineProperty(this, "validation", null);

      _defineProperty(this, "field", null);

      _defineProperty(this, "readOnly", null);

      _defineProperty(this, "element", 'input');

      _defineProperty(this, "rowSize", 4);

      _defineProperty(this, "columnSize", 50);

      _defineProperty(this, "isDirty", null);

      _defineProperty(this, "isBlur", true);

      _defineProperty(this, "autofocus", null);

      _defineProperty(this, "autocomplete", false);
    }

    get value() {
      return this.property ? this.property.trim() : "";
    }

    get autocompleteVal() {
      return this.autocomplete ? 'on' : 'off';
    }

    get checkValidation() {
      if (this.field) {
        const valid = this.validation.filter(property => property.attribute == this.field);

        if (valid.length > 0) {
          if (!this.isBlur) {
            Ember.set(this, 'isDirty', 'is-dirty');
            return valid[0].message;
          }
        }
      }

      return null;
    }

    onInput(value) {
      Ember.set(this, "property", value);
      Ember.set(this, 'isBlur', false);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "value", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autocompleteVal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "autocompleteVal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkValidation", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "checkValidation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInput", [_dec4, _dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class));
  _exports.default = Input;
});