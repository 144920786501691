define("campaign-warrior-app/components/content-editable-htmlsafe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ember-content-editable'],
    classNameBindings: ['clearPlaceholderOnFocus:clear-on-focus'],
    attributeBindings: ['contenteditable', 'placeholder', 'spellcheck', 'tabindex', 'disabled'],
    disabled: false,
    spellcheck: null,
    allowNewlines: true,
    autofocus: false,
    clearPlaceholderOnFocus: false,

    enter() {},

    'escape-press'() {},

    'key-up'() {},

    'key-press'() {},

    'key-down'() {},

    'length-exceeded'() {},

    'insert-newline'() {},

    paste() {},

    init() {
      this._super(...arguments);

      Ember.set(this, 'keyWhitelist', [8, // backspace
      27, // escape
      37, // left arrow
      38, // up arrow
      39, // right arrow
      40 // down arrow
      ]);
      this._pasteHandler = Ember.run.bind(this, this.pasteHandler);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      Ember.set(this, 'contenteditable', !Ember.get(this, 'disabled'));
    },

    didInsertElement() {
      this._super(...arguments);

      this.updateDom();
      this._mutationObserver = new MutationObserver(Ember.run.bind(this, this.domChanged));

      this._mutationObserver.observe(this.element, {
        attributes: false,
        childList: true,
        characterData: true,
        subtree: true
      });

      if (Ember.get(this, 'autofocus')) {
        this.element.focus();
      }

      this.element.addEventListener('paste', this._pasteHandler);
    },

    didUpdateAttrs() {
      this._super(...arguments); // if update has been initiated by a change of the dom (user entered something) we don't do anything because
      // - value has already been updated by domChanged
      // - the rendered text already shows the current value


      if (Ember.get(this, 'value') !== Ember.get(this, '_internalValue')) {
        this.updateDom();
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('paste', this._pasteHandler);

      this._mutationObserver.disconnect();
    },

    domChanged() {
      const text = this.element.innerHTML;
      Ember.setProperties(this, {
        value: text,
        _internalValue: text
      });
    },

    updateDom() {
      const value = Ember.get(this, 'value');

      if (value === undefined || value === null) {
        this.element.innerHTML = '';
      } else {
        this.element.innerHTML = value;
      }
    },

    keyUp(event) {
      Ember.get(this, 'key-up')(event);
    },

    keyPress(event) {
      // Firefox seems to call this method on backspace and cursor keyboard events, whereas chrome does not.
      // Therefore we keep a whitelist of keyCodes that we allow in case it is necessary.
      const newLength = this.element.innerHTML.length - this.getSelectionLength();

      if (Ember.get(this, 'maxlength') && newLength >= Ember.get(this, 'maxlength') && !Ember.get(this, 'keyWhitelist').includes(event.keyCode)) {
        event.preventDefault();
        Ember.get(this, 'length-exceeded')(this.element.innerHTML.length + 1);
        return false;
      }

      Ember.get(this, 'key-press')(event);
    },

    keyDown(event) {
      if (event.keyCode === 27) {
        Ember.get(this, 'escape-press')(event);
      } else if (event.keyCode === 13) {
        Ember.get(this, 'enter')(event);

        if (Ember.get(this, 'allowNewlines')) {
          Ember.get(this, 'insert-newline')(event);
        } else {
          event.preventDefault();
          return false;
        }
      }

      Ember.get(this, 'key-down')(event);
    },

    getSelectionLength() {
      const selection = window.getSelection();

      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        return range.endOffset - range.startOffset;
      }

      return 0;
    },

    pasteHandler(event) {
      event.preventDefault(); // replace any html formatted text with its plain text equivalent

      let text = '';

      if (event.clipboardData) {
        text = event.clipboardData.getData('text/plain');
      } else if (window.clipboardData) {
        text = window.clipboardData.getData('Text');
      } // check max length


      if (Ember.get(this, 'maxlength')) {
        // a selection will be replaced. substract the length of the selection from the total length
        const selectionLength = this.getSelectionLength();
        const afterPasteLength = text.length + this.element.innerHTML.length - selectionLength;

        if (afterPasteLength > Ember.get(this, 'maxlength')) {
          Ember.get(this, 'length-exceeded')(afterPasteLength);
          return false;
        }
      }

      crossSupportInsertText(text);
      Ember.get(this, 'paste')(text);
    }

  }).reopenClass({
    positionalParams: ['value']
  });

  _exports.default = _default;

  function crossSupportInsertText(text) {
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      const range = document.getSelection().getRangeAt(0);
      range.deleteContents();
      const textNode = document.createTextNode(text);
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);
      const selection = document.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
});