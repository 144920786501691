define("campaign-warrior-app/components/billing/update-account-form", ["exports", "campaign-warrior-app/data/countries", "campaign-warrior-app/data/states", "ember-cp-validations"], function (_exports, _countries, _states, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class UpdateAccountForm
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['update-account-form'],

    /**
     * @property countries
     * @private
     * @readonly
     * @type {{name: string, code: string}[]}
     */
    countries: _countries.default,

    /**
     * @property states
     * @private
     * @readonly
     */
    states: _states.default,
    selectedCountry: Ember.computed(function () {
      const code = Ember.get(this, 'record.user.company.billingInfo.country');
      let name = '';
      Ember.get(this, 'countries').map(country => {
        if (country.code == code.toLowerCase()) {
          name = country.name;
        }
      });
      return {
        code: code.toLowerCase(),
        name: name
      };
    }),
    selectedState: Ember.computed(function () {
      const name = Ember.get(this, 'record.user.company.billingInfo.state');
      return {
        name: name
      };
    }),
    showStatesAsDropdown: Ember.computed('selectedCountry.code', function () {
      return Ember.get(this, 'selectedCountry.code') in _states.default;
    }),

    /**
     * @property formData
     * @type object
     * @private
     * @readonly
     */
    // didInsertElement() {
    //   const config = getOwner(this).resolveRegistration('config:environment');
    //   if (!window.recurly) { // just for tests
    //     return;
    //   }
    //   recurly.configure(config.recurly);
    // },
    actions: {
      countryChanged(el) {
        Ember.set(this, 'selectedCountry.name', el.name);
        Ember.set(this, 'selectedCountry.code', el.code);

        if (el.code in _states.default) {
          Ember.set(this, 'showStatesAsDropdown', true);
        } else {
          Ember.set(this, 'showStatesAsDropdown', false);
        }

        Ember.set(this, 'record.user.company.billingInfo.state', '');
        Ember.set(this, 'record.user.company.billingInfo.country', el.code);
        Ember.set(this, 'formData.state.name', '');
      },

      stateChanged(el) {
        Ember.set(this, 'selectedState.name', el.name);
        Ember.set(this, 'record.user.company.billingInfo.state', el.name);
        Ember.set(this, 'formData.state.name', el.name);
      }

    }
  });

  _exports.default = _default;
});