define("campaign-warrior-app/models/portal", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    description: [(0, _emberCpValidations.validator)('length', {
      max: 250
    })]
  });
  /**
   * @class Portal
   * @namespace Models
   * @extends DS.Model
   */

  var _default = _model.default.extend(Validations, {
    /**
     * @type DS.StringAttribute
     * @default null
     * @property name
     */
    name: (0, _model.attr)('string'),
    logo: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    createdby: (0, _model.attr)('string'),
    monthRolloverDate: (0, _model.attr)('number'),
    trackingId: (0, _model.attr)('string'),
    featureLevel: (0, _model.attr)('string'),
    //todo - dont use this anymore
    hubspotConnected: (0, _model.attr)('string'),
    shopifyConnected: (0, _model.attr)('boolean'),
    shopifyShopName: (0, _model.attr)('string'),
    googleConnected: (0, _model.attr)('boolean'),
    websiteConnected: (0, _model.attr)('boolean'),
    chargeableBlocks: (0, _model.attr)('number'),
    impressionsLimit: (0, _model.attr)('number'),
    usedImpressions: (0, _model.attr)('number'),
    atImpressionsLimit: (0, _model.attr)('boolean'),
    pagesLimit: (0, _model.attr)('number'),
    pagesUsed: (0, _model.attr)('number'),
    potentialPages: (0, _model.attr)('number'),
    atPageLimit: (0, _model.attr)('boolean'),
    relatedContentKey: (0, _model.attr)('string'),
    gaViewId: (0, _model.attr)('string'),
    gaAccountName: (0, _model.attr)('string'),
    errorStore: (0, _model.attr)(),
    creationData: (0, _model.attr)(),
    hubspotDomains: (0, _model.attr)(),
    gaAccounts: (0, _model.attr)(),
    setupState: (0, _model.attr)('number'),
    relatedContentSettings: (0, _model.attr)(),
    companyId: (0, _model.attr)('string'),

    /**
     * {{#crossLink "Models.Page"}}Models.Page{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property pages
     */
    pages: (0, _model.hasMany)('page'),

    /**
     * {{#crossLink "Models.Tag"}}Models.Tag{{/crossLink}}[]
     * @type DS.HasManyRelationship
     * @property tags
     */
    tags: (0, _model.hasMany)('tag'),

    /**
     * {{#crossLink "Models.Dir"}}Models.Dir{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property rootDir
     */
    rootDir: (0, _model.belongsTo)('dir'),
    portalSubscription: (0, _model.belongsTo)('portal-subscription'),
    primaryDomain: (0, _model.belongsTo)('domain'),
    company: (0, _model.belongsTo)('company'),
    title: Ember.computed.readOnly('originalName'),

    /**
     * @type string
     * @default null
     * @property originalName
     */
    init() {
      // this.startTrack();
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});