define("campaign-warrior-app/components/text-snippets/create-snippet-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   * @class CreateSnippetModal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    classNames: ['create-snippet-modal']
  });

  _exports.default = _default;
});