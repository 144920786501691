define("campaign-warrior-app/components/limits-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class LimitsBar
   * @namespace Components
   * @extends Ember.Components
   */
  var _default = Ember.Component.extend({
    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    restrictor: Ember.inject.service(),
    classNames: ['limits-bar', 'alert'],
    remainingTags: Ember.computed(function () {
      const result = Ember.get(this, 'restrictor').getRemainingLimit('tags');
      return result;
    }),
    remainingDomains: Ember.computed(function () {
      const result = Ember.get(this, 'restrictor').getRemainingLimit('domains');
      return result;
    })
  });

  _exports.default = _default;
});