define("campaign-warrior-app/controllers/profile/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @namespace Controllers
   * @class ProfileInfo
   * @extends Ember.Controller
   */
  let UserInfo = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class UserInfo extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "notify", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor5, this);

      _initializerDefineProperty(this, "i18n", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _defineProperty(this, "showPasswordChangeForm", Ember.computed.equal('currentUser.user.authType', 'local'));
    }

    save(model, avatar) {
      const modalsManager = this.modalsManager;
      const notify = this.notify;
      const i18n = this.i18n;
      const successMsg = i18n.t('user.save.success');
      return this.uploadAvatar(avatar).then(newAvatar => {
        if (newAvatar) {
          this.store.pushPayload(newAvatar);
          const picture = this.store.peekRecord('file', newAvatar.data.id);
          Ember.set(model, 'picture', picture);
        }

        return model.save({
          adapterOptions: {
            current: true
          }
        }).then(() => notify.success(successMsg));
      }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
    }

    changePassword(formData) {
      const _this = this;

      return this.ajax.post('/change/password', {
        data: {
          password: Ember.get(formData, 'password'),
          newPassword: Ember.get(formData, 'newPassword')
        }
      }).then(() => {
        const data = {
          title: 'Success',
          body: 'Password changed successfully.'
        };
        return _this.modalsManager.successHandler(data).then(() => {
          window.location.reload();
        });
      }).catch(e => _this.modalsManager.detailedAjaxErrorHandler(e));
    }

    uploadAvatar(file) {
      if (!file) {
        return Ember.RSVP.resolve();
      }

      const i18n = Ember.get(this, 'i18n');
      const fd = new FormData();
      const modalsManager = this.modalsManager;
      fd.append('file', file);

      const process = () => this.ajax.post('media/picture', {
        data: fd,
        cache: false,
        processData: false,
        contentType: false
      });

      return modalsManager.show('modals/single-process', {
        title: i18n.t('user.uploadProcess.title'),
        body: '',
        process
      }).catch(err => {
        modalsManager.ajaxErrorHandler({
          message: 'This image file is too big'
        });
      });
    }

    returnHome() {
      const activePortal = JSON.parse(window.localStorage.getItem('activePortal'));

      if (activePortal) {
        Ember.get(this, 'router').transitionTo('portals.portal', activePortal);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePassword", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "changePassword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "returnHome", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "returnHome"), _class.prototype)), _class));
  _exports.default = UserInfo;
});