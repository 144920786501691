define("campaign-warrior-app/components/cw-table/delete-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component for `models-table` cells that show `Delete` button with a proper handler.
   *
   * Shown button is disabled if record has flag `isSaving` with value `true`.
   * It happens when button is already clicked and request to the server is in progress.
   *
   * Usage:
   *
   * ```js
   * columns: [
   *   {component: 'delete-record'}
   * ]
   * ```
   *
   * ```hbs
   * {{! columns are described above }}
   * {{models-table
   *   columns=columns
   *   columnComponents=(hash
   *      delete-record=(component
   *        "cw-table/delete-record"
   *        btnClass="btn-xs btn-default pull-right"
   *        btnIcon="fa-remove text-danger"
   *        deleteRecord=(action "deleteTag")
   *      )
   *   )
   * }}
   * ```
   *
   * @class CwTableDeleteRecord
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),
    tagName: 'button',
    attributeBindings: ['disabled:disabled'],
    classNames: ['btn'],
    classNameBindings: ['btnClass'],

    /**
     * Closure action bound externally. Must do a `delete`-request for provided `record`
     *
     * @event deleteRecord
     */
    deleteRecord: null,

    /**
     * Bound from `models-table`. It's a Record you are processing
     *
     * @property record
     * @private
     */
    record: null,

    /**
     * Additional css-classes to the shown button
     *
     * @type string
     * @default ''
     * @property btnClass
     */
    btnClass: '',

    /**
     * Custom icon may be shown at the right of the button-text (classes `fa` and `fa-fw` are already added)
     *
     * @type string
     * @default ''
     * @property btnIcon
     */
    btnIcon: '',

    /**
     * @property forceDisabled
     * @type boolean
     * @default false
     */
    forceDisabled: false,

    /**
     * @default ''
     * @type string
     * @property disabled
     * @private
     * @readonly
     */
    disabled: Ember.computed('record.isSaving', 'forceDisabled', function () {
      return Ember.get(this, 'forceDisabled') || Ember.get(this, 'record.isSaving') ? 'disabled' : '';
    }).readOnly(),

    /**
     * Text shown on the button
     *
     * @property btnText
     * @type string
     */
    btnText: Ember.computed(function () {
      return Ember.get(this, 'i18n').t('tags.delete.form.delete');
    }),

    click() {
      Ember.get(this, 'deleteRecord')(Ember.get(this, 'record'));
    }

  });

  _exports.default = _default;
});