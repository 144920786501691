define("campaign-warrior-app/templates/components/billing/upgrade-account-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2DUoVMYr",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[8,\"bs-modal\",[],[[\"@open\",\"@title\",\"@onSubmit\",\"@onHide\"],[[34,0],\"Upgrade to the Full Version\",[30,[36,1],[[32,0],\"confirm\"],null],[30,[36,1],[[32,0],\"decline\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Upgrade to the full version\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[2,\"This will unlock the full version of Campaign Warrior.\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[30,[36,1],[[32,0],[32,1,[\"close\"]]],null],\"cancel\"]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[30,[36,1],[[32,0],[32,1,[\"submit\"]]],null],\"primary\"]],[[\"default\"],[{\"statements\":[[2,\"Confirm\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"modalIsOpened\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/billing/upgrade-account-modal.hbs"
    }
  });

  _exports.default = _default;
});