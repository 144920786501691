define("campaign-warrior-app/templates/components/search-pages-filter-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dZxnV3wp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,0,\"filter-state filter-btn\"],[15,\"onclick\",[30,[36,0],[[32,0],\"toggleFilterState\"],null]],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"property\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fas fa-check\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/search-pages-filter-state.hbs"
    }
  });

  _exports.default = _default;
});