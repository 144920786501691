define("campaign-warrior-app/utils/consts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modelsTableFilterQueryParameters = _exports.defaultPaginationSettings = void 0;

  const defaultPaginationSettings = () => ({
    page: {
      size: 10,
      number: 0
    }
  });

  _exports.defaultPaginationSettings = defaultPaginationSettings;
  const modelsTableFilterQueryParameters = {
    globalFilter: 'search',
    sort: 'sort',
    sortDirection: 'sortDirection',
    page: 'page.number',
    pageSize: 'page.size'
  };
  _exports.modelsTableFilterQueryParameters = modelsTableFilterQueryParameters;
});