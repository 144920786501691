define("campaign-warrior-app/abilities/page", ["exports", "campaign-warrior-app/abilities/cw-ability", "campaign-warrior-app/utils/ability", "campaign-warrior-app/data/limits"], function (_exports, _cwAbility, _ability, _limits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Page
   * @namespace Abilities
   * @extends Abilities.CwAbility
   * @uses Mixins.PostingAbilityMixin
   */
  var _default = _cwAbility.default.extend({
    /**
     * @property canAddTags
     * @type boolean
     */
    canAddTags: (0, _ability.portalRelatedAbility)('view_portal'),

    /**
     * @property canDelete
     * @type boolean
     * @default false
     */
    canDelete: (0, _ability.portalRelatedAbility)('view_portal'),
    canExport: Ember.computed(function () {
      const currentUser = Ember.get(this, 'currentUser');
      return !Ember.get(currentUser, 'user.company.inTrial');
    })
  });

  _exports.default = _default;
});