define("campaign-warrior-app/storages/panels", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Storage = _object.default.extend();
  /**
   * Local-storage for data about panels
   *
   * Usage:
   *
   * ```javascript
   * // app/controllers/some.js
   * import Controller from '@ember/controller';
   * import {storageFor} from 'ember-local-storage';
   *
   * export default Controller.extend({
   *   panelsStorage: storageFor('panels'),
   * });
   * ```
   *
   * @namespace Storage
   * @class PanelsStorage
   * @extends Ember.Object
   */


  var _default = Storage;
  _exports.default = _default;
});