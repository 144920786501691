define("campaign-warrior-app/components/models-table-server-paginated", ["exports", "ember-models-table/components/models-table-server-paginated", "ember-models-table/templates/components/models-table", "campaign-warrior-app/utils/consts", "campaign-warrior-app/utils/models-table/theme"], function (_exports, _modelsTableServerPaginated, _modelsTable, _consts, _theme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extend original `models-table` to do global setup for all tables
   *
   * @class ModelsTableServerPaginated
   * @namespace Components
   * @extends Components.ModelsTable
   */
  var _default = _modelsTableServerPaginated.default.extend({
    layout: _modelsTable.default,
    themeInstance: _theme.default,
    metaPagesCountProperty: 'page-count',
    metaItemsCountProperty: 'total-records',
    filterQueryParameters: _consts.modelsTableFilterQueryParameters,
    // eslint-disable-next-line
    pageSizeValues: Ember.computed(function () {
      return [10, 20, 40];
    }),

    /**
     * Predefined filters that should not be empty
     *
     * Example: table with posts in the page-details page. Posts are filtered by page externally (in the route).
     * This filter should not be dropped in the table. Since posts-table has column with `Page` (even it's hidden), filter for this field will be dropped.
     * `predefinedFilters` is to prevent this and left its value as is set in  the route
     *
     * @property predefinedFilters
     * @type object
     * @default {}
     */
    // eslint-disable-next-line
    predefinedFilters: Ember.computed(function () {
      return {};
    }),
    actions: {
      /**
       * @override
       * @method actions.sort
       * @param {ModelsTableColumn} column column to sort
       * @returns {undefined}
       */
      sort(column) {
        const sortMap = {
          none: 'asc',
          asc: 'desc',
          desc: 'none'
        };
        let sortedBy = Ember.get(column, 'sortedBy') || Ember.get(column, 'propertyName');

        if (Ember.isNone(sortedBy)) {
          return;
        }

        let currentSorting = Ember.get(column, 'sorting');
        let newSorting = sortMap[currentSorting.toLowerCase()];
        let sortingArgs = [column, sortedBy, newSorting];

        if (Ember.get(this, 'multipleColumnsSorting')) {
          this._multiColumnsSorting(...sortingArgs);
        } else {
          this._singleColumnSorting(...sortingArgs);
        }

        this.userInteractionObserver();
      }

    },

    _setQueryFilter(query, column, filterTitle, filter) {
      const predefinedFilters = Ember.get(this, 'predefinedFilters');
      query.filter = query.filter || {};
      query.search = query.search || {};

      if (column.searchType === 'filter') {
        if (Ember.isBlank(filter)) {
          delete query.filter[filterTitle];
        } else {
          query.filter[filterTitle] = filter;
        }

        return;
      }

      if (filterTitle.endsWith('.id')) {
        const chunks = filterTitle.split('.');
        const filterName = chunks[0];

        if (Ember.isBlank(filter)) {
          if (predefinedFilters[filterName]) {
            query.filter[filterName] = predefinedFilters[filterName];
          } else {
            delete query.filter[filterName];
          }
        } else {
          query.filter[filterName] = filter;
        }
      } else {
        if (Ember.isBlank(filter)) {
          delete query.search[filterTitle];
        } else {
          query.search[filterTitle] = filter;
        }
      }
    },

    // todo remove when new version of ember-models-table is published
    _multiColumnsSorting(column, sortedBy, newSorting) {
      Ember.set(column, 'sorting', newSorting);
      let sortProperties = Ember.get(this, 'sortProperties');
      let sortPropertiesMap = {};
      sortProperties.forEach(p => {
        let [propertyName, order] = p.split(':');
        sortPropertiesMap[propertyName] = order;
      });
      delete sortPropertiesMap[sortedBy];
      let newSortProperties = Ember.A([]);
      let newSortFunctions = Object.create(null);
      Object.keys(sortPropertiesMap).forEach(propertyName => {
        if (propertyName !== sortedBy) {
          newSortProperties.pushObject(`${propertyName}:${sortPropertiesMap[propertyName]}`);
        }

        Ember.set(newSortFunctions, propertyName, Ember.get(column, 'sortFunction'));
      });

      if ('none' !== newSorting) {
        newSortProperties.pushObject(`${sortedBy}:${newSorting}`);
        newSortFunctions[sortedBy] = Ember.get(column, 'sortFunction');
      }

      Ember.set(this, 'sortProperties', newSortProperties);
      Ember.set(this, 'sortFunctions', newSortFunctions);
    },

    doQuery(store, modelName, query) {
      query[Ember.get(this, 'filterQueryParameters.page')]--;
      this.updateSortParams(query);
      return this._super(...arguments);
    },

    updateSortParams(query) {
      delete query[Ember.get(this, 'filterQueryParameters.sort')];
      delete query[Ember.get(this, 'filterQueryParameters.sortDirection')];
      query.sort = Ember.get(this, 'sortProperties').map(sortProp => {
        const [prop, direction] = sortProp.split(':');
        const sign = direction === 'desc' ? '-' : '';
        return `${sign}${prop}`;
      }).join(',');

      if (!query.sort) {
        delete query.sort;
      }
    }

  });

  _exports.default = _default;
});