define("campaign-warrior-app/components/manage-tag-form", ["exports", "campaign-warrior-app/components/manage-record-form"], function (_exports, _manageRecordForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form for edit or create a Tag
   *
   * Usage:
   *
   * ```hbs
   * {{manage-tag-form record=model save=(action "save")}}
   * ```
   *
   * @class ManageTagForm
   * @namespace Components
   * @extends Components.ManageRecordForm
   */
  var _default = _manageRecordForm.default.extend({
    currentPortal: Ember.inject.service(),
    classNames: ['manage-tag-form'],

    /**
     * Determines if "Edit/Create" button should be hidden
     *
     * @property hideControls
     * @type boolean
     * @default false
     */
    hideControls: false,
    portal: Ember.computed.reads('currentPortal.portal')
  });

  _exports.default = _default;
});