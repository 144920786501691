define("campaign-warrior-app/components/pages/pages-tags-adder", ["exports", "jquery", "campaign-warrior-app/models/tag"], function (_exports, _jquery, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```hbs
   * {{pages/search-pages-form
   *   tags=tags
  
   *   metricNameToFilter=metricNameToFilter
   *   filterByMetric=(action "filterByMetric")
   *   updateFilters=(action "updateFilters")
   * }}
   * ```
   *
   * @class SearchPagesForm
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    classNames: ['pages-tags-filter-form'],

    /**
     * @property tags
     * @type Models.Tag[]
     * @default null
     */
    tags: null,

    /**
     * @property metricNameToFilter
     * @type string
     * @default null
     */
    metricNameToFilter: null,

    /**
     * @event filterByMetric
     */
    filterByMetric: null,

    /**
     * @event updateFilters
     */
    updateFilters: null,

    /**
     * @property portal
     * @type Models.Portal
     * @default null
     */
    portal: null,
    applyTagsSelection: null,
    didInsertElement: Ember.computed('registerComponent', function () {
      this.registerComponent(this);
    }),
    displayTags: Ember.computed('tags', function () {
      return this.tags;
    }),

    willDestroyElement() {
      // this._super(...argument);
      this.deregisterComponent();
    },

    actions: {
      /**
       * @method actions.onSubmit
       */
      dropdownclick(objects, event) {
        const _this = this;

        if (event.target.classList.contains('fas')) {
          Ember.set(_this, 'applyTagsSelection', null);
          return false;
        }
      },

      onSubmit() {
        this.triggerUpdate();
      },

      submitForm() {
        const filterForm = (0, _jquery.default)('form');
        (0, _jquery.default)(filterForm).submit();
      },

      createTag(tagName) {
        const portal = this.currentPortal.portal;
        const store = this.store;

        const _this = this;

        const newTag = store.createRecord('tag', {
          name: tagName,
          color: _tag.default.getRandColor(),
          portal
        });
        newTag.save().then(savedTag => {
          _this.tags.addObject(savedTag);

          Ember.set(_this, 'applyTagsSelection', savedTag);
        });
      },

      /**
       * @method addTagsForFilteredPages
       */
      addTagsForFilteredPages() {
        Ember.get(this, 'addTagsForFilteredPages')();
      }

    }
  });

  _exports.default = _default;
});