define("campaign-warrior-app/components/setup/setup-connect-website-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SetupPortalPanel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed, _dec6 = Ember.computed('formData.url'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class SetupPortalPanel extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "currentPortal", _descriptor3, this);

      _initializerDefineProperty(this, "ajax", _descriptor4, this);

      _defineProperty(this, "urls", []);

      _defineProperty(this, "hasResults", false);

      _defineProperty(this, "limitExceeded", false);

      _defineProperty(this, "totalPages", 0);

      _defineProperty(this, "submitted", false);

      _defineProperty(this, "showUrlsList", false);

      _defineProperty(this, "potentialPages", 0);

      _defineProperty(this, "errorMessage", null);

      _defineProperty(this, "errorCode", null);

      _defineProperty(this, "isValidated", false);

      _defineProperty(this, "domainInvalid", false);

      _defineProperty(this, "redirectUrl", '');
    }

    get portalPagesLimit() {
      if (this.formData.pagesLimit) {
        return this.formData.pagesLimit;
      }

      return this.currentPortal.portal.pagesLimit;
    }

    get validationUrl() {
      var url_pattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i);
      return !this.formData.url.match(url_pattern);
    }

    setUrl(urlVal) {
      Ember.set(this.formData, 'url', urlVal);
    }

    connectWebsite() {
      let domain = this.store.createRecord('domain', {
        portal: this.currentPortal.portal,
        url: this.formData.url
      });
      domain.save().then(() => {
        this.currentPortal.portal.websiteConnected = true;
        this.currentPortal.portal.primaryDomain = domain;
        this.currentPortal.portal.setupState.websiteConnected = true;
        this.currentPortal.portal.save().then(() => {
          if (this.redirectUrl && this.redirectUrl === 'setup') {
            this.router.transitionTo('portals.portal.setup.tracking-code');
          } else {
            this.router.transitionTo('portals.portal.domains.list');
          }
        });
      });
    }

    connectDomain() {
      const _this = this;

      Ember.set(this, 'submitted', true);
      let url = this.formData.url.replace('https://', '').replace('http://', '');
      this.ajax.post('https://cloud.campaignwarrior.com/api/v1/scrape/checksitemap', {
        data: {
          url
        }
      }).then(res => {
        Ember.set(_this, 'hasResults', true);
        Ember.set(_this, 'submitted', false);

        if (res.urls) {
          const urls = res.urls.slice(0, _this.portalPagesLimit);
          Ember.set(_this, 'urls', urls);
          Ember.set(_this, 'totalPages', res.urls.length);
          Ember.set(_this, 'PanelTitle', 'Congratulations, your sitemap exists!');
          Ember.set(_this, 'potentialPages', res.urls.length);

          if (res.urls.length > _this.portalPagesLimit) {
            Ember.set(_this, 'limitExceeded', true);
          }
        } else if (res.status === 'error') {
          if (res.code === 'sitemapnotfound') {
            Ember.set(_this, 'PanelTitle', 'Sorry, your sitemap does not exist!');
            Ember.set(_this, 'errorCode', 'sitemapnotfound');
            Ember.set(_this, 'errorMessage', 'Sitemap Not Found');
          } else if (res.code === 'domainnotfound') {
            Ember.set(_this, 'PanelTitle', 'We have some issues!');
            Ember.set(_this, 'errorCode', 'servererror');
            Ember.set(_this, 'errorMessage', 'There was a problem connecting to your website, Please check if your website exists');
            Ember.set(_this, 'domainInvalid', true);
          } else {
            Ember.set(_this, 'PanelTitle', 'We have some issues!');
            Ember.set(_this, 'errorCode', 'servererror');
            Ember.set(_this, 'errorMessage', 'There was a problem reading your sitemap, please contact support for more information');
          }
        } else {
          Ember.set(_this, 'PanelTitle', 'We have some issues!xxx');
          Ember.set(_this, 'errorCode', 'unreachable');
          Ember.set(_this, 'errorMessage', 'There was a problem reading your sitemap, please try again later');
        }
      });
    }

    saveListAsDomain() {
      const _this = this;

      let url = this.formData.url.replace('https://', '').replace('http://', '');
      this.ajax.post('https://cloud.campaignwarrior.com/api/v1/scrape/saveasdomain', {
        data: {
          urls: _this.urls,
          portal: _this.currentPortal.portal.id,
          potentialPages: _this.potentialPages,
          domain: url
        }
      }).then(res => {
        if (res.status === 'success') {
          this.currentPortal.portal.websiteConnected = true;
          this.currentPortal.portal.trackingId = res.trackingId;
          this.currentPortal.portal.save().then(() => {
            if (this.redirectUrl && this.redirectUrl === 'setup') {
              this.router.transitionTo('portals.portal.setup.tracking-code', {
                queryParams: {
                  redirectUrl: this.redirectUrl
                }
              });
            } else {
              this.router.transitionTo('portals.portal.domains.list');
            }
          });
        }
      });
    }

    continueInvalidSitemap() {
      const _this = this;

      this.ajax.post('https://cloud.campaignwarrior.com/api/v1/scrape/saveasdomain', {
        data: {
          urls: [],
          portal: _this.currentPortal.portal.id,
          domain: _this.formData.url
        }
      }).then(res => {
        if (res.status === 'success') {
          this.currentPortal.portal.websiteConnected = true;
          this.currentPortal.portal.trackingId = res.trackingId;
          this.currentPortal.portal.save().then(() => {
            if (this.redirectUrl && this.redirectUrl === 'setup') {
              this.router.transitionTo('portals.portal.setup.tracking-code');
            } else {
              this.router.transitionTo('portals.portal.domains.list');
            }
          });
        }
      });
    }

    resetWebsite() {
      const _this = this;

      Ember.set(this, 'hasResults', false);
      Ember.set(this, 'PanelTitle', 'Enter the URL of the website to scrape');
      Ember.set(this, 'errorCode', null);
      Ember.set(this, 'errorMessage', null);
      Ember.set(this, 'domainInvalid', false);
    }

    toggleShowList() {
      Ember.set(this, 'showUrlsList', !this.showUrlsList);
    }

    goBack() {
      let destination;

      if (this.redirectUrl === 'setup') {
        destination = 'portals.portal.setup.setup';
      } else {
        destination = 'portals.portal.domains.list';
      }

      this.router.transitionTo(destination);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "portalPagesLimit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "portalPagesLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validationUrl", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "validationUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setUrl", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connectWebsite", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "connectWebsite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connectDomain", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "connectDomain"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveListAsDomain", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "saveListAsDomain"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "continueInvalidSitemap", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "continueInvalidSitemap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetWebsite", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "resetWebsite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleShowList", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goBack", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "goBack"), _class.prototype)), _class));
  _exports.default = SetupPortalPanel;
});