define("campaign-warrior-app/templates/components/pages/page-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y4OA1EDA",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"data\",\"columns\",\"selectRowOnClick\",\"showColumnsDropdown\",\"showGlobalFilter\"],[[35,1],[35,0],false,false,false]]]]],\"hasEval\":false,\"upvars\":[\"notesColumns\",\"notes\",\"models-table-server-paginated\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/pages/page-notes.hbs"
    }
  });

  _exports.default = _default;
});