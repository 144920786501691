define("campaign-warrior-app/components/material/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Select extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "property", null);

      _defineProperty(this, "label", "");

      _defineProperty(this, "options", null);

      _defineProperty(this, "selected", null);

      _defineProperty(this, "optionalProp", null);

      _defineProperty(this, "iconClass", "");

      _defineProperty(this, "iconId", "");

      _defineProperty(this, "changed", null);
    }

  }

  _exports.default = Select;
});