define("campaign-warrior-app/routes/suspended", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),
    breadCrumb: null,

    async beforeModel(transition) {
      /*if (get(this, 'currentUser.user')) {
        get(this, 'currentUser').load();
        const portals = await get(this, 'store').findAll('portal');
        const portalsExist = portals && portals.length > 0;
      */

      /*if (get(this, 'currentUser.user.company.billingInfo.trialExpired') && !portalsExist) {
        // modalsManager
        //     .show('trial-expired-modal', {
        //       portalsExist: portalsExist
        //     })
        //     .then(() => {
        get(this, 'router').transitionTo('billing');
        //     })
        //   .catch(() => {
        //       console.log('Going to portals');
        // });
        // console.log('currentUSER IN PORTALS: ', get(this, 'currentUser.user'));
        // return get(this, 'router').transitionTo('billing');
      }*/

      /*
          } else {
            get(this, 'router').transitionTo('login');
          }
        */
      return this._super(...arguments);
    },

    /**
     * @returns {Promise} All portals available for current user
     */
    model() {
      return Ember.get(this, 'store').findAll('portal');
    },

    afterModel(model) {
      /* if (!get(model, 'length')) {
         return get(this, 'router').transitionTo('portals.create.setup-wizard');
       }*/
    },

    actions: {
      refreshRoute() {// console.log('in refresh route');
        // this.refresh();
      }

    }
  });

  _exports.default = _default;
});