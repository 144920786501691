define("campaign-warrior-app/templates/components/manage-add-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sldm7NEk",
    "block": "{\"symbols\":[\"tag\",\"tag\",\"tag\"],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],null,[[\"options\",\"renderInPlace\",\"disabled\",\"search\",\"searchMessage\",\"searchField\",\"onChange\",\"onCreate\",\"showCreateWhen\",\"buildSuggestion\"],[[35,7],true,[35,2],[30,[36,0],[[32,0],\"searchInstances\"],null],[30,[36,6],[\"publishing.addPost.tagsDropdown.searchNoCreateMessage\"],null],[35,5],[30,[36,0],[[32,0],\"addTag\"],null],[30,[36,0],[[32,0],\"createTag\"],null],[30,[36,0],[[32,0],\"hideCreateTagOption\"],null],[30,[36,0],[[32,0],\"customSuggestion\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"tag\"],[[32,3]]]]],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,4],null,[[\"renderInPlace\",\"options\",\"disabled\",\"search\",\"onChange\"],[true,[35,3],[35,2],[30,[36,0],[[32,0],\"searchInstances\"],null],[30,[36,0],[[32,0],\"addTag\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,2,[\"originalName\"]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]],[10,\"p\"],[12],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"selected-tags\"],[12],[2,\"\\n\"],[6,[37,12],[[30,[36,11],[[30,[36,11],[[35,10,[\"tags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"tag\",\"removable\",\"selfRemove\"],[[32,1],true,[30,[36,0],[[32,0],\"removeTag\"],null]]]]],[2,\" \\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"cw-tag\",\"isDisabled\",\"instances\",\"power-select\",\"searchField\",\"t\",\"tags\",\"showCreateOption\",\"if\",\"record\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/manage-add-tags.hbs"
    }
  });

  _exports.default = _default;
});