define("campaign-warrior-app/components/iframe-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*import {addPrefix} from '../utils/url';*/

  /**
   * Usage:
   *
   * ```hbs
   * {{iframe-wrapper page=page}}
   * ```
   *
   * @class IframeWrapper
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['iframe-wrapper'],

    /**
     * @property page
     * @type Models.Page
     * @default null
     */
    page: null,

    /**
     * @property showErrorOverlay
     * @type boolean
     * @default false
     * @private
     */
    showErrorOverlay: false,

    /**
     * @property width
     * @type string
     * @default '100%'
     */
    width: '100%',

    /**
     * @property height
     * @type string
     * @default '500px'
     */
    height: '500px',

    /**
     * @property isValid
     * @type boolean
     * @private
     * @readonly
     */
    isValid: Ember.computed.readOnly('page.validations.attrs.url.isValid'),

    /**
     * @property src
     * @type string
     * @private
     * @readonly
     */
    src: Ember.computed('page', function () {
      let url = Ember.get(this, 'page.url'); // console.log("Page", get(this, 'page.url'));

      if (!url) return '';

      if (!url.includes('https://') || !url.includes('http://')) {
        url = 'https://' + url;
      }

      return url; //return get(this, 'isValid') ? addPrefix(get(this, 'page.url')) : '';
    })
  });

  _exports.default = _default;
});