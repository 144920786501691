define("campaign-warrior-app/components/cw-table/icon-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   *
   * ```js
   * [
   *   {
   *     component: 'cw-table/icon-title',
   *     icon: 'fa-link',
   *     title: 'Some Title'
   *   }
   * ]
   * ```
   *
   * @class CwTableIconTitle
   * @name Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property ascIcon
     */
    ascIcon: false,

    /**
     * @property descIcon
     */
    descIcon: false,

    /**
     * @property sortValue
     */
    sortValue: 0,

    /**
     * @property sorting
     */
    icon: Ember.computed(function () {
      return Ember.get(this, 'column.useSorting');
    }),
    actions: {
      setSortIcon() {
        const sortValue = Ember.get(this, 'sortValue');

        if (sortValue == 0) {
          Ember.set(this, 'ascIcon', true);
          Ember.set(this, 'icon', false);
          Ember.set(this, 'sortValue', 1);
        } else if (sortValue == 1) {
          Ember.set(this, 'ascIcon', false);
          Ember.set(this, 'descIcon', true);
          Ember.set(this, 'sortValue', 2);
        } else if (sortValue == 2) {
          Ember.set(this, 'descIcon', false);
          Ember.set(this, 'icon', true);
          Ember.set(this, 'sortValue', 0);
        }
      }

    }
  });

  _exports.default = _default;
});