define("campaign-warrior-app/components/mentions/edit-mentionable-modal", ["exports", "campaign-warrior-app/components/modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   * @class EditMentionableModal
   * @namespace Components
   * @extends EmberBootstrapModalManager.Components.Base
   */
  var _default = _base.default.extend({
    mention: null,
    actions: {// confirm(updatedData) {
      //   return this._super(updatedData);
      // }
    }
  });

  _exports.default = _default;
});