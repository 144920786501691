define("campaign-warrior-app/components/cw-table/view-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      openWebPage(url) {
        if (!url.includes('http://') && !url.includes('https://')) {
          url = 'https://' + url;
        }

        window.open(url);
      }

    }
  });

  _exports.default = _default;
});