define("campaign-warrior-app/routes/portals/portal/domain", ["exports", "campaign-warrior-app/mixins/portal-internal-route"], function (_exports, _portalInternalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PortalPages
   * @uses Mixins.PortalInternalRoute
   * @namespace Routes
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_portalInternalRoute.default, {
    breadCrumbRoutingKey: 'domain',

    beforeModel() {
      const portal = this.modelFor('portals.portal');
      const portal_id = Ember.get(portal, 'id');
      return Ember.get(this, 'store').query('domain', {
        portal_id
      });
    }

  });

  _exports.default = _default;
});