define("campaign-warrior-app/controllers/invite/confirminvite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class InviteConfirmation
   * @namespace Controllers
   * @extends EmberController
   */
  var _default = Ember.Controller.extend({
    /**
     * @property ajax
     * @type Ember.Service
     */
    ajax: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property queryParams
     * @type (string|object)[]
     * @default ['token']
     */
    queryParams: ['token'],

    /**
     * @property token
     * @type string
     * @default null
     */
    token: null,

    /**
     * Set some initial values to the `formData`
     */
    init() {
      this._super(...arguments);

      Ember.run.next(() => this.draftUpdated());
    },

    draftUpdated: Ember.observer('token', function () {
      const token = Ember.get(this, 'token');
      const data = {
        token
      };

      if (token) {
        Ember.get(this, 'modalsManager').show('modals/accept-portal-invite-modal').then(result => {
          console.log("Invitiation confirmation result", result);
          return Ember.get(this, 'ajax').post('/invitation/confirm', {
            data
          }).then(() => {
            console.log('successfully accepted', data);
            Ember.get(this, 'router').transitionTo('portals');
          }).catch(e => {
            e.message = 'Sorry, this invitation has expired';
            Ember.get(this, 'modalsManager').ajaxErrorHandler(e);
            Ember.get(this, 'router').transitionTo('portals');
          });
        }).catch(() => {
          //console.log('rejecting invitation');
          return Ember.get(this, 'ajax').post('/invitation/reject', {
            data
          }).then(() => {
            //console.log('successfully rejected');
            Ember.get(this, 'router').transitionTo('portals');
          }).catch(e => {
            e.message = 'Sorry, this invitation has expired';
            Ember.get(this, 'modalsManager').ajaxErrorHandler(e);
            Ember.get(this, 'router').transitionTo('portals');
          });
        });
      }
    }),
    actions: {}
  });

  _exports.default = _default;
});