define("campaign-warrior-app/templates/components/cw-table/icon-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IpJmx+e7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[4,[38,6],[[32,0],\"setSortIcon\"],null],[12],[2,\" \"],[1,[35,5,[\"title\"]]],[2,\" \"],[6,[37,2],[[35,5,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[15,0,[31,[\"fad fa-fw \",[34,5,[\"icon\"]]]]],[12],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[3,\"<i class=\\\"fa fa-sort\\\"></i>\"],[2,\"\\n\\n\"],[6,[37,2],[[35,5,[\"useSorting\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[\"fad\\n    \",[30,[36,2],[[35,4],\"fa-sort faSize\"],null],\"\\n      \",[30,[36,2],[[35,3],[35,0,[\"sort-asc\"]],\"gcSize\"],null],\"\\n      \",[30,[36,2],[[35,1],[35,0,[\"sort-desc\"]],\"gcSize\"],null]]]],[12],[13],[2,\"\\n\\n    \"],[3,\"<i class=\\\"if column.sortAsc themeInstance.sort-asc}}\\\"></i>\"],[2,\"\\n    \"],[3,\"<i class=\\\"if column.sortDesc themeInstance.sort-desc}}\\\"></i>\"],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"descIcon\",\"if\",\"ascIcon\",\"icon\",\"column\",\"action\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/icon-title.hbs"
    }
  });

  _exports.default = _default;
});