define("campaign-warrior-app/templates/portals/portal/pages/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AxpvhwIX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-lg-6 col-xs-6 col-sm-12\"],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"title\",\"class\",\"name\",\"bodyComponent\"],[[30,[36,4],[\"pages.create.title\"],null],\"branding\",\"page-create-form\",[30,[36,3],[\"manage-page-form\"],[[\"tags\",\"record\",\"save\"],[[35,2],[35,1],[30,[36,0],[[32,0],\"save\"],null]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-lg-6 col-xs-6 col-sm-12\"],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"title\",\"class\",\"name\",\"bodyComponent\"],[[30,[36,4],[\"pages.metaImage.title\"],null],\"branding\",\"page-create-meta\",[30,[36,3],[\"cw-image\"],[[\"src\"],[[35,1,[\"previewImage\"]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"title\",\"class\",\"name\",\"bodyComponent\"],[[30,[36,4],[\"pages.webView.title\"],null],\"branding\",\"page-create-iframe\",[30,[36,3],[\"iframe-wrapper\"],[[\"page\"],[[35,1]]]]]]]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,6],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"tags\",\"component\",\"t\",\"cw-panel\",\"-outlet\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/portal/pages/create.hbs"
    }
  });

  _exports.default = _default;
});