define("campaign-warrior-app/components/manage-record-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base Component for forms to create or edit some record. You must extend it and not use directly:
   *
   * ```js
   * // app/components/manage-campaign-form.js
   * import ManageRecordForm from 'campaign-warrior-app/components/manage-record-form';
   * export default ManageRecordForm.extend({});
   * ```
   *
   * ```hbs
   * {{manage-campaign-form record=model save=(action "save")}}
   * ```
   *
   * This component doesn't provide a layout. You have to create your own template. So, template
   * `app/templates/components/manage-campaign-form.hbs` must be written.
   *
   *
   * Here `model` can be a result of:
   *
   * * `store.findRecord('model', '1')` - it's 'edit'
   * * `store.createRecord('model')` - it's 'create'
   *
   * @class ManageRecordForm
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property i18n
     * @type Ember.Service
     */
    i18n: Ember.inject.service(),

    /**
     * @property modalsManager
     * @type Ember.Service
     */
    modalsManager: Ember.inject.service(),

    /**
     * @property page
     * @type DS.Record
     * @default null
     */
    record: null,

    /**
     * External action-handler for `save` click
     *
     * @event save
     */
    save: null,

    /**
     * @property disabledState
     * @type string
     * @default ''
     * @private
     * @readonly
     */
    disabledState: Ember.computed('record.isSaving', function () {
      return Ember.get(this, 'record.isSaving') ? 'disabled' : '';
    }).readOnly(),
    actions: {
      /**
       * Internal action-handler for `save` click. It calls external handler
       *
       * @method actions.save
       * @return {RSVP.Promise} save result
       */
      save() {
        //console.log('savin tag');
        const i18n = Ember.get(this, 'i18n');

        try {
          let saveThis = Ember.get(this, 'save');
          let record = Ember.get(this, 'record');
          return saveThis(record);
          return Ember.get(this, 'save')(Ember.get(this, 'record')).catch(error => Ember.get(this, 'modalsManager').alert({
            bodyComponent: 'modals/request-error-body',
            title: i18n.t('modals.defaultErrorOptions.title'),
            confirm: i18n.t('modals.defaultAlertOptions.confirm'),
            error
          }));
        } catch (err) {
          console.error("Error saving record", err);
        }
      }

    }
  });

  _exports.default = _default;
});