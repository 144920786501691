define("campaign-warrior-app/models/permission", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Permission
   * @namespace Models
   * @extends DS.Model
   */
  const Permission = _model.default.extend({
    /**
     * @type DS.StringAttribute
     * @property permissionType
     */
    permissionType: (0, _model.attr)('string'),

    /**
     * `company` or `portal`
     * @type DS.StringAttribute
     * @property level
     */
    level: (0, _model.attr)('string'),
    objectId: (0, _model.attr)('string'),
    subjectId: (0, _model.attr)('string'),

    /**
     * @type DS.BelongsToRelationship
     * @property user
     */
    user: (0, _model.belongsTo)('user'),

    /**
     * @type DS.BelongsToRelationship
     * @property portal
     */
    portal: (0, _model.belongsTo)('portal'),

    /**
     * Permission type that will be granted to the invited user
     *
     * @property grantablePermissionType
     * @type string
     * @default ''
     * @readonly
     */
    grantablePermissionType: Ember.computed('permissionType', function () {
      const permissionType = this.permissionType;
      return permissionType.indexOf('grant_') === 0 ? permissionType.substr(6) : null;
    }).readOnly()
  });

  Permission.reopenClass({
    /**
     * @method getTypeId
     * @param {Models.Permission} p permission instance
     * @return {string} unique id
     */
    getTypeId(p) {
      return `${p.permissionType}_${p.objectId}`;
    },

    /**
     * @method getGrantableTypeId
     * @param {Models.Permission} p permission instance
     * @return {string} unique id
     */
    getGrantableTypeId(p) {
      return `${p.grantablePermissionType}_${p.objectId}`;
    }

  });
  var _default = Permission;
  _exports.default = _default;
});