define("campaign-warrior-app/templates/suspended", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T8QDeeFQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container info-modal\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"suspended\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"icon-wrapper\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"far fa-traffic-cone\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"h1\"],[12],[2,\"Account Suspended\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"desc\"],[12],[2,\"The portal you are trying to view has been suspended. For more information contact the\\n          team at\\n          Campaign Warrior.\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"controls\"],[12],[2,\"\\n          \"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-link\",\"account\"]],[[\"default\"],[{\"statements\":[[2,\"Manage Billing\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-link\",\"portals\"]],[[\"default\"],[{\"statements\":[[2,\"Portals\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/suspended.hbs"
    }
  });

  _exports.default = _default;
});