define("campaign-warrior-app/models/publish-source", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PublishSource
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    /**
     * @property category
     * @type DS.StringAttribute
     */
    category: _emberData.default.attr('string'),

    /**
     * @property name
     * @type DS.StringAttribute
     */
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),

    /**
     * @property sourceType
     * @type DS.StringAttribute
     */
    sourceType: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    viaPlatform: _emberData.default.attr('string'),
    hubspotChannelGuid: _emberData.default.attr('string'),
    viaIntegration: _emberData.default.attr('string'),

    /**
     * Fields for Twitter:
     *  * alias
     *
     * @property meta
     * @type DS.ObjectAttribute
     */
    meta: _emberData.default.attr(),

    /**
     * @property sourceId
     * @type DS.StringAttribute
     */
    sourceId: _emberData.default.attr('string'),

    /**
     * @property active
     * @type DS.BooleanAttribute
     */
    active: _emberData.default.attr('boolean'),

    /**
     * {{#crossLink "Models.Portal"}}Models.Portal{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property portal
     */
    portal: _emberData.default.belongsTo('portal'),
    viaPlatformFormatted: Ember.computed('viaPlatform', function () {
      const raw = Ember.get(this, 'viaPlatform').toLowerCase();

      if (raw === 'hubspot') {
        return 'HubSpot';
      } else if (raw === 'twitter') {
        return 'Twitter';
      } else if (raw === 'facebook') {
        return 'Facebook';
      } else if (raw === 'linkedin') {
        return 'LinkedIn';
      } else {
        return 'Direct';
      }
    })
  });

  _exports.default = _default;
});