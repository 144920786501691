define("campaign-warrior-app/helpers/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trim = trim;
  _exports.default = void 0;

  /**
   * Trims string-value if it's greater than allowed size
   *
   * Usage:
   *
   * ```hbs
   * {{trim strVal 15}}
   * ```
   *
   * Input - `some_very_long_name`
   *
   * Output - `some_very_long_...`
   *
   * @namespace Helpers
   * @class Trim
   *
   * @param {string} str string to trim
   * @param {number} limit trim limit (default 15)
   * @returns {string} trimmed value
   */
  function trim([str, limit = 15]) {
    if (Ember.String.isHTMLSafe(str)) {
      str = str.string;
    }

    return str && str.length > limit ? `${str.substring(0, limit)}...` : str;
  }

  var _default = Ember.Helper.helper(trim);

  _exports.default = _default;
});