define("campaign-warrior-app/controllers/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @class Account
   * @namespace Controllers
   * @extends Ember.Controller
   */
  let AccountController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.computed.reads('currentUser.user.company.billingInfo'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember.computed('currentPortal.portal.company'), _dec16 = Ember._action, (_class = (_temp = class AccountController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['upgrade']);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "currentPortal", _descriptor2, this);

      _initializerDefineProperty(this, "recurly", _descriptor3, this);

      _initializerDefineProperty(this, "ajax", _descriptor4, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor5, this);

      _initializerDefineProperty(this, "notify", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _initializerDefineProperty(this, "session", _descriptor8, this);

      _defineProperty(this, "portals", null);

      _defineProperty(this, "domains", null);

      _defineProperty(this, "requestInProgress", false);
    }

    init() {
      super.init(...arguments);
    }

    get billingInfo() {
      return this.currentUser.user.company.billingInfo;
    }

    reload() {
      this.send('refreshRoute');
    }

    showAccountModal() {
      const currentUser = Ember.get(this, 'currentUser');
      const modalsManager = Ember.get(this, 'modalsManager'); //const store = get(this, 'store');

      modalsManager.show('billing/update-account-modal', {
        currentUser
      }).then(data => {
        const formData = data;
        const body = {
          firstName: Ember.get(formData, 'firstName'),
          lastName: Ember.get(formData, 'lastName'),
          address1: Ember.get(formData, 'address1'),
          address2: Ember.get(formData, 'address2'),
          city: Ember.get(formData, 'city'),
          postalCode: Ember.get(formData, 'postal_code'),
          state: Ember.get(formData, 'state.name'),
          phone: Ember.get(formData, 'phone'),
          country: Ember.get(formData, 'country.code')
        };
        return Ember.get(this, 'ajax').put('/update/account', {
          data: body
        }).then(response => {
          Ember.get(this, 'notify').success(response.message);
          this.send('refreshRoute');
        }).catch(err => {
          modalsManager.alert({
            title: 'Error',
            body: err.payload.message,
            confirm: 'Close'
          });
        });
      }).catch(e => {
        return modalsManager.userFriendlyErrorHandler(e);
      });
    }

    showCardModal() {
      const currentUser = Ember.get(this, 'currentUser');
      const modalsManager = Ember.get(this, 'modalsManager');
      modalsManager.show('billing/update-card-modal', {
        currentUser
      }).then(data => {
        return Ember.get(this, 'ajax').put('/update/card', {
          data: {
            'recurly-token': data
          }
        }).then(response => {
          if (response.status && response.status == 'success') {
            Ember.get(this, 'notify').success(response.message);
            this.send('refreshRoute');
          }
        });
      }).catch(e => {
        return modalsManager.userFriendlyErrorHandler(e);
      });
    }

    showCancelAccountModal() {
      const ajax = Ember.get(this, 'ajax');
      const modalsManager = Ember.get(this, 'modalsManager');

      const _this = this;

      modalsManager.confirm({
        title: 'Are you sure you want to close your account?',
        body: 'This will cancel your subscription for Campaign Warrior, you will no longer be able to log in',
        confirm: 'Yes',
        decline: 'No'
      }).then(() => {
        ajax.put('/cancel/plan').then(() => {
          modalsManager.alert({
            title: 'Subscription Terminated',
            confirm: 'Log Out',
            body: 'We are sorry to see you go'
          }).then(() => {
            Ember.get(_this, 'session').invalidate();
          });
        });
      });
    }

    showUpgradeModal() {
      const ajax = this.ajax;
      const modalsManager = this.modalsManager;

      const _this = this;

      modalsManager.show('billing/upgrade-account-modal').then(() => {
        ajax.post('/billing/upgradetrial').then(() => {
          modalsManager.alert({
            title: 'Subscription Upgraded',
            confirm: 'Continue',
            body: 'You have successfully upgraded'
          }).then(() => {
            _this.send('refreshRoute');
          });
        });
      });
    }

    get currentPortalsCompany() {
      return this.currentPortal.portal.company;
    }

    returnHome() {
      const activePortal = JSON.parse(window.localStorage.getItem('activePortal'));

      if (activePortal) {
        Ember.get(this, 'router').transitionTo('portals.portal', activePortal);
      } else {
        Ember.get(this, 'router').transitionTo('portals');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentPortal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "recurly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "billingInfo", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "billingInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reload", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "reload"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showAccountModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showAccountModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showCardModal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "showCardModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showCancelAccountModal", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "showCancelAccountModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showUpgradeModal", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "showUpgradeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentPortalsCompany", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "currentPortalsCompany"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "returnHome", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "returnHome"), _class.prototype)), _class));
  _exports.default = AccountController;
});