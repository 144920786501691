define("campaign-warrior-app/abilities/scrape-job", ["exports", "campaign-warrior-app/abilities/cw-ability", "campaign-warrior-app/mixins/abilities/scraping"], function (_exports, _cwAbility, _scraping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ScrapeJob
   * @namespace Abilities
   * @extends Abilities.CwAbility
   * @uses Mixins.ScrapingAbilityMixin
   */
  var _default = _cwAbility.default.extend(_scraping.default, {});

  _exports.default = _default;
});