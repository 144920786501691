define("campaign-warrior-app/templates/components/cw-table/clickable-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ayC5GAQ3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-fw \",[34,0,[\"icon\"]]]]],[12],[13],[2,\"\\n\"],[6,[37,2],[[35,0,[\"tooltip\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[6,[37,1],null,[[\"placement\"],[\"top\"]],[[\"default\"],[{\"statements\":[[1,[35,0,[\"tooltip\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"column\",\"bs-tooltip\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/clickable-icon.hbs"
    }
  });

  _exports.default = _default;
});