define("campaign-warrior-app/components/pages/pages-list-panel", ["exports", "campaign-warrior-app/utils/filters"], function (_exports, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesListPanel = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.computed.reads('pages'), _dec4 = Ember.computed('meta["current-page"]'), _dec5 = Ember.computed('meta["page-count"]', 'meta["current-page"]'), _dec6 = Ember.computed('pagesListForAddTag', 'tagsList'), _dec7 = Ember._action, (_class = (_temp = class PagesListPanel extends Ember.Component {
    /**
     * @property i18n
     * @type Ember.Service
     */

    /**
     * @property pagesListForAddTag
     */
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "i18n", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _defineProperty(this, "record", null);

      _defineProperty(this, "sortOrder", '');

      _defineProperty(this, "route", 'portals.portal.pages.list');

      _initializerDefineProperty(this, "pagesListForAddTag", _descriptor3, this);
    }

    get currentPageNum() {
      return this.meta['current-page'];
    }

    get count() {
      const total = this.meta['page-count'] || this.meta['current-page'];
      if (!total) return [];
      return new Array(total + 1).join('x').split('').map((e, i) => i + 1);
    }

    /**
     * @property columns
     * @type object[]
     * @private
     * @readonly
     */
    get columns() {
      const i18n = this.i18n;
      return [{
        title: i18n.t('pages.summary.columns.title'),
        propertyName: 'title',
        component: 'cw-table/page-name-cell',
        route: 'portals.portal.pages.page.edit',
        className: 'title-cell',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        title: 'Meta Pic',
        propertyName: 'previewImage',
        component: 'pages/meta-picture',
        className: 'meta-picture-cell text-center',
        useSorting: false,
        sortAsc: false
      }, {
        title: 'Meta Description',
        className: 'meta-description-cell',
        propertyName: 'meta'
      }, {
        className: 'metasize-cell',
        propertyName: 'metaSize',
        component: 'cw-table/metasize-cell',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true,
        title: i18n.t('dashboard.tables.pages.titles.metaSize')
      }, {
        title: 'Tags',
        propertyName: 'tags',
        component: 'cw-table/tags-list',
        className: 'tags-cell',
        componentForSortCell: 'cw-table/icon-title',
        tags: this.tagsList,
        pagesList: this.pagesListForAddTag,
        id: 'id',
        useSorting: true,
        sortAsc: true
      }, {
        title: 'Url',
        propertyName: 'url',
        component: 'pages/page-listing-url',
        className: 'url-cell',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        propertyName: 'viewsCount',
        title: 'Views',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true,

        /*icon: 'fa-eye',*/
        filterFunction: _filters.numbersFilterForModelsTable,
        className: 'metric-cell'
      }, {
        title: 'Source',
        propertyName: 'source',
        className: 'source-cell',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        title: 'Type',
        propertyName: 'hsPageType',
        component: 'pages/page-type-cell',
        className: 'type-cell',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        title: 'Rating',
        propertyName: 'overallRating',
        className: 'rating-cell',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        title: 'Funnel',
        propertyName: 'funnelStage',
        component: 'cw-table/funnel-stage-dropdown',
        className: 'funnel-cell',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: true
      }, {
        title: 'Updated',
        propertyName: 'hsLastUpdated',
        component: 'pages/date-cell',
        className: 'updated-cell',
        componentForSortCell: 'cw-table/icon-title',
        useSorting: true,
        sortAsc: false
      }, {
        title: '',
        component: 'pages/page-action-menu',
        className: 'actions-cell',
        id: 'id',
        useSorting: false,
        sortAsc: false
      }];
    }

    sortBy(propertyName) {
      Ember.set(this, 'sortOrder', propertyName);
      this.triggerUpdate();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pagesListForAddTag", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "currentPageNum", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "currentPageNum"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "count", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "count"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "columns", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortBy", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "sortBy"), _class.prototype)), _class));
  _exports.default = PagesListPanel;
  ;
});