define("campaign-warrior-app/models/billing-info", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class BillingInfo
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _model.default.extend({
    /**
     * @property created
     * @type DS.NumberAttribute
     * @default null
     */
    created: (0, _model.attr)('number'),

    /**
     * @property portalsCount
     * @type DS.NumberAttribute
     * @default null
     */
    portalsCount: (0, _model.attr)('number'),

    /**
     * @property plan
     * @type DS.StringAttribute
     * @default null
     */
    plan: (0, _model.attr)('string'),

    /**
     * @property status
     * @type DS.StringAttribute
     * @default null
     */
    status: (0, _model.attr)('string'),

    /**
     * @property uuid
     * @type DS.StringAttribute
     * @default null
     */
    uuid: (0, _model.attr)('string'),

    /**
     * @property trial
     * @type DS.ObjectAttribute
     * @default null
     */
    trial: (0, _model.attr)(),

    /**
     * @property isPaid
     * @type DS.StringAttribute
     * @default null
     */
    isPaid: (0, _model.attr)(),
    sandboxed: (0, _model.attr)(),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    address1: (0, _model.attr)('string'),
    address2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    postcode: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    cardType: (0, _model.attr)('string'),
    ccNumber: (0, _model.attr)('string'),
    ccExpiryMonth: (0, _model.attr)('string'),
    ccExpiryYear: (0, _model.attr)('string'),
    currentSubscription: (0, _model.attr)('mixed'),

    /**
     * {{#crossLink "Models.Company"}}Models.Company{{/crossLink}}
     * @type DS.BelongsToRelationship
     * @property company
     */
    company: (0, _model.belongsTo)('company'),
    portalSubscriptions: (0, _model.hasMany)('portal-subscription'),

    /**
     * @property notPaid
     * @type boolean
     * @default true
     */
    notPaid: Ember.computed.not('isPaid'),

    /**
     * @property trialExpired
     * @type boolean
     * @default false
     * @readonly
     */

    /*
    trialExpired: computed('trial.end-date', 'notPaid', function () {
      if (get(this, 'trial')) {
        const notPaid = get(this, 'notPaid');
        const trialEndDate = moment(new Date(get(this, 'trial.end-date')));
        const currentDate = moment(new Date());
        return trialEndDate.diff(currentDate, 'days') <= 0 && notPaid;
      }
      return true;
    }).readOnly(),*/
    costPerPortal: Ember.computed('plan', function () {
      const plan = this.plan;
      const planCosts = {
        portal1: 49
      };
      return planCosts[plan];
    }),
    totalCost: Ember.computed('costPerPortal', 'portalsCount', function () {
      const portalsCount = this.portalsCount;
      const planCost = this.costPerPortal;
      return portalsCount * planCost;
    })
  });

  _exports.default = _default;
});