define("campaign-warrior-app/components/cw-table/related-model-filter", ["exports", "campaign-warrior-app/mixins/searchable"], function (_exports, _searchable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component with filter for `models-table`.
   *
   * It's useful for filters with related models.
   *
   * Usage:
   *
   * ```
   * columns = [
   *   {
   *     componentForFilterCell: 'cw-table/related-model-filter',
   *     instanceClassName: 'tag',
   *     singleSelect: false,
   *     searchField: 'name',
   *     searchDebounce: 250
   *   }
   * ];
   * ```
   *
   * Internally `power-select` or `power-select-multiple` is rendered depends on `singleSelect`.
   *
   * Component automatically loads options for filter when user starts to input something.
   *
   * Component should be used only for Models that are related to the `Portal` (e.g. `Campaign`, `Tag` and not `User` or `BillingInfo`)
   *
   *
   * @class CwTableRelatedModelFilter
   * @namespace Components
   * @extends Ember.Component
   * @uses Mixins.Searchable
   */
  var _default = Ember.Component.extend(_searchable.default, {
    classNames: ['related-model-filter'],

    /**
     * @property searchDebounce
     * @type number
     * @default 500
     */
    searchDebounce: Ember.computed.reads('column.searchDebounce'),

    /**
     * @property instanceClassName
     * @type string
     * @private
     */
    instanceClassName: Ember.computed.reads('column.instanceClassName'),

    /**
     * @property singleSelect
     * @type boolean
     * @private
     */
    singleSelect: Ember.computed.reads('column.singleSelect'),

    /**
     * @property searchField
     * @type string
     * @private
     */
    searchField: Ember.computed.reads('column.searchField'),

    /**
     * @method columnsFilterStringIsDropped
     * @private
     */
    columnsFilterStringIsDropped: Ember.observer('column.filterString', function () {
      if (!Ember.get(this, 'column.filterString')) {
        Ember.set(this, 'selectedInstance', null);
      }
    }),
    actions: {
      /**
       * @method actions.updateColumnFilter
       * @param {DS.Model} instance selected instance
       */
      updateColumnFilter(instance) {
        Ember.set(this, 'selectedInstance', instance);

        if (instance) {
          const filter = Ember.isArray(instance) ? instance.mapBy('id').join(',') : Ember.get(instance, 'id');
          Ember.set(this, 'column.filterString', filter);
        } else {
          Ember.set(this, 'column.filterString', '');
        }
      }

    },

    /**
     * @param {string} userInput user input
     * @param {callback} resolve success-callback
     * @return {RSVP.Promise} request result
     * @method _searchInstances
     */
    _searchInstances(userInput, resolve) {
      const predefinedInstances = Ember.get(this, 'column.predefinedFilterOptions');
      const searchField = Ember.get(this, 'searchField');

      if (Ember.isArray(predefinedInstances)) {
        const filteredInstances = userInput ? predefinedInstances.filter(predefinedInstance => {
          const value = String(Ember.get(predefinedInstance, searchField)).toLowerCase();
          return value.includes(userInput.toLowerCase());
        }) : predefinedInstances;
        Ember.set(this, 'instances', filteredInstances);
        return resolve(filteredInstances);
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});