define("campaign-warrior-app/templates/components/users/resend-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L5RNNCdo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[30,[36,4],[[35,3]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"i\"],[24,0,\"fal fa-fw fa-repeat\"],[4,[38,0],[[32,0],\"resendInvite\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"canResend\",\"if\",\"isAdmin\",\"not\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/users/resend-invite.hbs"
    }
  });

  _exports.default = _default;
});