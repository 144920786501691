define("campaign-warrior-app/models/user-role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UserRole = _emberData.default.Model.extend({
    role: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    portal: _emberData.default.belongsTo('portal')
  });

  var _default = UserRole;
  _exports.default = _default;
});