define("campaign-warrior-app/templates/portals/create/step2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZqFYmJm4",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"initialData\",\"gotFocus\",\"onSubmit\",\"portal\"],[[35,3],[35,2],[30,[36,1],[[32,0],\"finish\"],null],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"currentPortal\",\"action\",\"gotFocus\",\"initialData\",\"create-portal/step2-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/create/step2.hbs"
    }
  });

  _exports.default = _default;
});