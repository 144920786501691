define("campaign-warrior-app/templates/components/cw-table/image-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "evxHmA+q",
    "block": "{\"symbols\":[\"src\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[35,3],[35,2,[\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[32,1]],[14,\"alt\",\"\"],[15,5,[34,0]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"imgStyle\",\"if\",\"column\",\"record\",\"get\",\"with\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-table/image-cell.hbs"
    }
  });

  _exports.default = _default;
});