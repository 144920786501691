define("campaign-warrior-app/templates/components/setup/setup-install-tracking-code-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HhsM4re4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"setup-container tracking-code-display\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"setup-panel\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-heading\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Step 2.1 \"],[10,\"span\"],[14,0,\"step-name\"],[12],[2,\"Insert your tracking code\"],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"setup-body\"],[12],[2,\"\\n      \"],[10,\"h4\"],[12],[2,\"Insert your tracking code\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        This unique tracking code will add new pages that are visited to your website list. It means that you don't have to scrape the website again and again. It also makes your related content appear magically.\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"This is placed in the \"],[10,\"b\"],[12],[2,\"HTML Header\"],[13],[2,\" or \"],[10,\"b\"],[12],[2,\"Footer\"],[13],[2,\" of your website code.\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"tracking-code-wrapper\"],[12],[2,\"\\n  \"],[10,\"textarea\"],[14,0,\"tracking-area\"],[14,\"readonly\",\"\"],[14,1,\"tracking-code-area\"],[12],[2,\"    \"],[1,[34,0]],[2,\"  \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"setup-controls\"],[12],[2,\"\\n      \"],[8,\"bs-button\",[[24,0,\"btn btn-secondary marginRight\"]],[[\"@onClick\"],[[30,[36,1],[[32,0],\"copyCode\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Copy\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@query\"],[\"portals.portal.setup.google\",[30,[36,2],null,[[\"redirectUrl\"],[\"setup\"]]]]],[[\"default\"],[{\"statements\":[[2,\"Continue\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"trackingCode\",\"action\",\"hash\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/setup/setup-install-tracking-code-panel.hbs"
    }
  });

  _exports.default = _default;
});