define("campaign-warrior-app/templates/portals/create/step3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rD3f9hZL",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"initialData\",\"onSubmit\"],[[35,1],[30,[36,0],[[32,0],\"finish\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"initialData\",\"create-portal/step3\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals/create/step3.hbs"
    }
  });

  _exports.default = _default;
});