define("campaign-warrior-app/templates/portals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/FCw+quS",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"portals\"],[[35,0]]]]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"top-nav\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/portals.hbs"
    }
  });

  _exports.default = _default;
});