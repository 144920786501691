define("campaign-warrior-app/templates/verification/required", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QXmDeTc3",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"logo-wrapper text-center\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"cw-logo\"],[14,\"src\",\"https://d25xioqhg7be2.cloudfront.net/static-assets/cw-logo.png\"],[15,\"alt\",[30,[36,0],[\"logo.alt\"],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container info-modal\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"panel-container-wrapper-setup\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cw-panel branding\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"panel\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n          \"],[10,\"h4\"],[12],[2,\"\\n            Email Verification Required\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"verification\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"desc\"],[12],[2,\"\\n              You need to verify you email address before you can login.\"],[10,\"br\"],[12],[13],[2,\"\\n              Please check your inbox.\\n            \"],[13],[2,\"\\n\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-footer\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"controls\"],[12],[2,\"\\n            \"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-primary\",\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Login\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/verification/required.hbs"
    }
  });

  _exports.default = _default;
});