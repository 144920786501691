define("campaign-warrior-app/controllers/portals/portal/pages/page/edit", ["exports", "campaign-warrior-app/controllers/edit-portal-related-record", "campaign-warrior-app/utils/consts"], function (_exports, _editPortalRelatedRecord, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class EditPage
   * @namespace Controllers
   * @extends Controllers.EditPortalRelatedRecord
   * @uses Mixins.ManagePostJob
   */
  var _default = _editPortalRelatedRecord.default.extend({
    currentPortal: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),

    /**
     * @property model
     * @type Models.Page
     * @default null
     */
    model: null,

    /**
     * Route-name where user will be redirected after `model` is successfully deleted
     *
     * @type string
     * @property redirectTo
     */
    redirectTo: 'portals.portal.pages.list',

    /**
     * @property posts
     * @type Models.Post[]
     * @default null
     */
    posts: null,
    notes: null,
    users: null,

    /**
     * @property postJobs
     * @type Models.PostJob[]
     * @default null
     */
    postJobs: null,

    /**
     * @property predefinedFilter
     * @type object
     * @default null
     */
    predefinedFilters: null,
    tags: null,
    modelPage: Ember.computed('model', function () {
      const model = Ember.get(this, 'model');
      return model;
    }),
    actions: {
      /**
       * @method actions.deletePage
       * @returns {RSVP.Promise} request result
       */
      deletePage() {
        const page = Ember.get(this, 'model');
        const modalsManager = Ember.get(this, 'modalsManager');
        const i18n = Ember.get(this, 'i18n');
        return modalsManager.promptConfirm({
          bodyComponent: 'pages/delete-confirm/body',
          confirm: i18n.t('pages.delete.confirm.confirm'),
          confirmButtonType: 'cancel',
          declineButtonType: 'primary',
          decline: i18n.t('pages.delete.confirm.decline'),
          promptValue: 'DELETE',
          title: i18n.t('pages.delete.confirm.title')
        }).then(() => page.destroyRecord().then(() => Ember.get(this, 'router').transitionTo(Ember.get(this, 'redirectTo'))).catch(e => modalsManager.detailedAjaxErrorHandler(e))).catch(() => page.rollback());
      },

      refreshPageMeta() {
        const page = this.model;
        const ajax = this.ajax;
        const portal = this.currentPortal.portal;
        const modalsManager = this.modalsManager;

        const _this = this;

        ajax.post('/update/pageondemand', {
          data: {
            portalId: portal.id,
            pageId: page.id
          }
        }).then(function (res) {
          _this.send('refreshRoute');
        });
        modalsManager.alert({
          title: 'Refresh Page Metadata',
          confirm: 'Refresh',
          body: 'This will refresh your pages metadata, this process may take up to 90 seconds to complete.'
        }).catch(e => modalsManager.detailedAjaxErrorHandler(e));
      },

      refreshPageStats() {
        const page = Ember.get(this, 'model');
        const portal = Ember.get(this, 'currentPortal.portal');
        return Ember.get(this, 'ajax').post('/update/pagemetrics', {
          data: {
            portalId: portal.id,
            pageId: page.id
          }
        }).then(function (res) {// console.log('Updated', res)
        });
      },

      openWebPage(url) {
        if (!url.includes('http://') && !url.includes('https://')) {
          url = 'https://' + url;
        }

        window.open(url);
      },

      createNote() {
        const page = Ember.get(this, 'model');
        const portal = Ember.get(this, 'currentPortal.portal');
        const portal_id = Ember.get(portal, 'id');
        const createDate = Date.now();
        const newNote = Ember.get(this, 'store').createRecord('note', {
          description: '',
          portal,
          page,
          createDate
        });
        const modalsManager = Ember.get(this, 'modalsManager');
        modalsManager.show('notes/create-note-modal', {
          note: newNote,
          store: Ember.get(this, 'store')
        }).then(note => {
          Ember.set(newNote, 'description', Ember.get(note, 'description'));
          Ember.set(newNote, 'assignedTo', Ember.get(note, 'assignedTo'));
          newNote.save().then(() => {
            const store = Ember.get(this, 'store');
            const notes = store.query('note', {
              portal_id,
              filter: {
                page: Ember.get(page, 'id')
              },
              ...(0, _consts.defaultPaginationSettings)()
            });
            Ember.set(this, 'notes', notes);
          });
        }).catch(e => {
          //newNote.destroyRecord();
          return modalsManager.userFriendlyErrorHandler(e);
        });
      }

    }
  });

  _exports.default = _default;
});