define("campaign-warrior-app/templates/components/manage-tag-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CwJrKMPx",
    "block": "{\"symbols\":[\"form\",\"&default\"],\"statements\":[[6,[37,7],null,[[\"formLayout\",\"model\",\"novalidate\",\"onSubmit\"],[\"vertical\",[35,1],true,[30,[36,6],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"material/input\",[],[[\"@type\",\"@label\",\"@property\"],[\"text\",\"Tag Name\",[32,0,[\"record\",\"name\"]]]],null],[2,\"\\n\"],[2,\"      \"],[8,\"material/input\",[],[[\"@element\",\"@type\",\"@label\",\"@property\"],[\"textarea\",\"text\",\"Tag Description\",[32,0,[\"record\",\"description\"]]]],null],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"btn-group\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"class\",\"defaultText\",\"type\"],[\"btn btn-primary\",[30,[36,2],[[35,1,[\"isNew\"]],[30,[36,0],[\"tags.create.form.create\"],null],[30,[36,0],[\"tags.create.form.edit\"],null]],null],\"primary\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"t\",\"record\",\"if\",\"bs-button\",\"hideControls\",\"unless\",\"action\",\"bs-form\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/manage-tag-form.hbs"
    }
  });

  _exports.default = _default;
});