define("campaign-warrior-app/abilities/billing", ["exports", "campaign-warrior-app/abilities/cw-ability"], function (_exports, _cwAbility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Billing
   * @namespace Abilities
   * @extends Abilities.CwAbility
   * @uses Mixins.PostingAbilityMixin
   */
  var _default = _cwAbility.default.extend({
    /**
     * @property canEdit
     * @type boolean
     * @default false
     */
    canEdit: Ember.computed('user.{id,company.owner.id}', function () {
      // eslint-disable-next-line ember/no-get
      return Ember.get(this, 'user.id') === Ember.get(this, 'user.company.owner.id');
    })
  });

  _exports.default = _default;
});