define("campaign-warrior-app/components/page-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PagePanel
   * @extends Ember.Component
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    classNames: ['record-panel', 'page-panel'],

    /**
     * @event toggleFavoritePage
     */
    toggleFavoritePage: null
  });

  _exports.default = _default;
});