define("campaign-warrior-app/services/current-portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class CurrentPortal
   * @namespace Services
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property portal
     * @type Models.Portal
     */
    portal: null,

    init() {
      this._super(); // force injection initialization


      Ember.get(this, 'router');
    },

    /**
     * Reset `portal` to `null` if user is moved to the Route outside of `portals.portal`
     *
     * @method transitionDone
     */
    transitionDone: Ember.computed('router.currentRouteName', function () {
      const currentRouteName = Ember.get(this, 'router.currentRouteName');

      if (!currentRouteName.startsWith('portals.portal')) {
        Ember.set(this, 'portal', null);
      }
    })
  });

  _exports.default = _default;
});