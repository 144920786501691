define("campaign-warrior-app/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component used to show spinner on `loading.hbs`
   *
   * Usage:
   *
   * ```hbs
   * {{loading-spinner size="3x"}}
   * ```
   *
   * @class LoadingSpinner
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property size
     * @type string
     * @default '2x'
     */
    size: '2x'
  });

  _exports.default = _default;
});