define("campaign-warrior-app/templates/components/cw-panel-promo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O8B0lpqC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"panel\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"bodyComponent\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "campaign-warrior-app/templates/components/cw-panel-promo.hbs"
    }
  });

  _exports.default = _default;
});