define("campaign-warrior-app/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Application
   * @namespace Routes
   * @extends Ember.Route
   * @uses EmberSimpleAuth.ApplicationRouteMixin
   */
  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),

    /**
     * @property currentUser
     * @type Ember.Service
     */
    currentUser: Ember.inject.service('current-user'),

    /**
     * Redirect user if it's moving to the `/`.
     * Authenticated user is redirected to the `portals`, not authenticated - to the `login`.
     *
     * @param {Transition} transition current transition
     * @return {RSVP.Promise} result for request for current user
     */
    beforeModel(transition) {
      const movingToIndex = transition.targetName === 'index';
      const loggedIn = Ember.get(this, 'session.isAuthenticated');

      if (loggedIn) {
        return this._loadCurrentUser().then(() => {
          if (movingToIndex) {
            Ember.get(this, 'router').transitionTo('portals');
          } else {
            if (transition.targetName === 'login' || transition.targetName === 'register') {
              Ember.get(this, 'router').transitionTo('portals');
            }
          }
        });
      } else {
        if (movingToIndex) {
          Ember.get(this, 'router').transitionTo('login');
        } else {}
      }
    },

    sessionAuthenticated() {
      this._super(...arguments);

      this._loadCurrentUser();
    },

    sessionInvalidated() {
      this._super(...arguments); // from https://github.com/simplabs/ember-simple-auth/issues/1107


      window.location.replace('/login');
    },

    _loadCurrentUser() {
      try {
        return Ember.get(this, 'currentUser').load();
      } catch (err) {
        Ember.get(this, 'session').invalidate();
      }
    }

  });

  _exports.default = _default;
});