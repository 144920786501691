define("campaign-warrior-app/models/campaign", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class File
   * @namespace Models
   * @extends DS.Model
   */
  var _default = _emberData.default.Model.extend({
    portal: _emberData.default.belongsTo('portal')
  });

  _exports.default = _default;
});