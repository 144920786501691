define("campaign-warrior-app/controllers/create-portal-related-record", ["exports", "campaign-warrior-app/controllers/manage-portal-related-record"], function (_exports, _managePortalRelatedRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Controller with general behavior for pages used to create some Record related to the [Portal](Models.Portal.html).
   *
   * Action [save](#method_actions.save) can be used to handle changes in the [model](#property_model) and save it to the server.
   *
   * User will be redirected to the route described in the [redirectTo](#property_redirectTo).
   *
   * **Don't use this controller directly! You must only extend it!**
   *
   * Usage:
   *
   * ```js
   * // app/controllers/campaigns/create.js
   * import CreatePortalRelatedRecordController from 'campaign-warrior-app/controllers/create-portal-related-record';
   * export default CreatePortalRelatedRecordController.extend({
   *   redirectTo: 'portals.portal.campaigns'
   * });
   * ```
   *
   * Route for this page **must** be like this:
   *
   * ```js
   * // app/routes/campaigns/create.js
   * import Route from '@ember/routing/route';
   * import PortalRelatedManageRecordRoute from 'campaign-warrior-app/mixins/portal-related-manage-record-route';
   *
   * export default Route.extend(PortalRelatedManageRecordRoute, {
       modelClassName: 'campaign'
   * });
   * ```
   *
   * Here `campaign` - it's a model name.
   *
   * Check docs for Mixin [PortalRelatedManageRecordRoute](Mixins.PortalRelatedManageRecordRoute.html).
   *
   * @class CreatePortalRelatedRecord
   * @extends Controllers.ManagePortalRelatedRecord
   * @namespace Controllers
   */
  var _default = _managePortalRelatedRecord.default.extend({
    /**
     * Newly created record (in the Route's model-hook)
     *
     * @property model
     * @type DS.Record
     */
  });

  _exports.default = _default;
});