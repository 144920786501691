define("campaign-warrior-app/components/cw-panel/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Title bound from CwPanel
     *
     * @property title
     * @default ''
     * @type string
     */
    title: '',

    /**
     * Closure action from CwPanel
     *
     * @event togglePanel
     */
    togglePanel: null,
    actions: {
      togglePanel() {
        Ember.get(this, 'togglePanel')();
      }

    }
  });

  _exports.default = _default;
});