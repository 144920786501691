define("campaign-warrior-app/torii-providers/hubspot", ["exports", "torii/providers/oauth2-code"], function (_exports, _oauth2Code) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Bitly OAuth2 provider
   *
   * @class HubspotOAuth2
   * @namespace ToriiProviders
   * @extends Torii.Providers.OAuth2CodeProvider
   */
  var _default = _oauth2Code.default.extend({
    baseUrl: 'https://app.hubspot.com/oauth/authorize',
    name: 'hubspot',
    scopes: 'content%20social%20timeline%20oauth',
    responseType: 'code',
    responseParams: Ember.computed(function () {
      return ['code'];
    }),
    redirectUri: Ember.computed(function () {
      const {
        protocol,
        host
      } = window.location;
      return [protocol, '//', host, '/torii/redirect.html'].join('');
    }),
    open: function () {
      const clientId = '9e63356e-3937-4d25-93e2-ca18e75304f7'; //const url = `${this.baseUrl}?client_id=${clientId}&scopes=${this.scopes}&redirect_uri=${'https://app.campaignwarrior.com/torii/redirect.html'}`;

      const url = 'https://app.hubspot.com/oauth/authorize?client_id=9e63356e-3937-4d25-93e2-ca18e75304f7&redirect_uri=https://cloud.campaignwarrior.com/torii/redirect.html&scope=content%20oauth';
      return Ember.get(this, 'popup').open(url, ['code'], {
        width: 800,
        height: 800
      });
    }
  });

  _exports.default = _default;
});