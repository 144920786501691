define("campaign-warrior-app/models/integration", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    'external-id': _emberData.default.attr('string'),
    'external-name': _emberData.default.attr('string'),
    connected: _emberData.default.attr('boolean'),
    channels: _emberData.default.attr(),
    portal: _emberData.default.belongsTo('portal'),
    connectable: Ember.computed(function () {
      return !this.connected;
    })
  });

  _exports.default = _default;
});