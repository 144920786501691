define("campaign-warrior-app/components/users/user-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @namespace Components
   */
  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    currentPortal: Ember.inject.service(),
    isAdmin: Ember.computed(function () {
      return Ember.get(this, 'record.id') === Ember.get(this, 'currentPortal.portal.createdby');
    }),
    displayValue: Ember.computed(function () {
      let status = this.record.status;

      if (this.record.isAdmin) {
        return 'Creator';
      }

      if (status === 'pending') {
        return 'Pending';
      }

      return this.record.status;
    })
  });

  _exports.default = _default;
});