define("campaign-warrior-app/components/create-portal/step3", ["exports", "campaign-warrior-app/components/floating-form"], function (_exports, _floatingForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _floatingForm.default.extend({
    /**
     * @property router
     * @type Ember.Service
     */
    router: Ember.inject.service(),
    classNames: ['create-portal-step3-form'],
    trackingCode: Ember.computed(function () {
      return Ember.get(this, 'initialData.trackingCode');
    }),
    actions: {
      copyCode() {
        const el = document.createElement('textarea');
        el.value = Ember.get(this, 'trackingCode');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      },

      getStarted() {
        Ember.get(this, 'router').transitionTo('portals');
      }

    }
  });

  _exports.default = _default;
});